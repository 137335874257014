// import React, { useEffect, useState } from 'react';
// import ValidatorHeader from './ValidatorHeader';
// import { ethers } from 'ethers';
// // import config from './config';
// import abi from '../abi.json';
// import Moralis from 'moralis';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import Loader from '../loaderdemo';
// import Footer from '../Footer';                                




// export default function Walletpage() {

//     const validatorEmail = useSelector((state) => state.validator_email);

//     const [validatorWalletAddress, setValidatorWalletAddress] = useState('');

//     const [todayCreditedData, setTodayCreditedData] = useState(0);
//     const [weeklyCreditedData, setWeeklyCreditedData] = useState(0);
//     const [monthlyCreditedData, setMonthlyCreditedData] = useState(0);
//     const [previousmonthCreditedData, setPreviousMonthCreditedData] = useState(0);

//     const moralis_apiKey = process.env.REACT_APP_MORALIS_APIKEY;
//     const moralis_chainId = process.env.REACT_APP_MORALIS_CHAIN; 
//     const moralis_token_contract_address = process.env.REACT_APP_MORALIS_TOKEN_ADDRESS;

//     useEffect(() => {
//         const fetchData = async () => {
//             try {

//                 const response = await fetch(`https://ex.d5art.com/api/fetch_walletAddress_from_validator?validatorEmail=${validatorEmail}`);

//                 if (!response.ok) {
//                     throw new Error(`Request failed with status: ${response.status}`);
//                 }

//                 const data = await response.json();
//                 console.log('5555', data.data[0].walletAddress);
//                 console.log('5555', data);


//                 if (response.ok) {

//                     setValidatorWalletAddress(data.data[0].walletAddress.toString());


//                 } else {
//                     console.error('Error fetching data:', data.error);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         if (validatorEmail) {
//             fetchData();
//         }
//     }, [validatorEmail]);


//     const [creditData, setCreditData] = useState([]);
//     const [debitData, setDebitData] = useState([]);
//     const [months, setMonths] = useState([]);
//     const [dataLoaded, setDataLoaded] = useState(false);

//     const [totalCredited, setTotalCredited] = useState(0);
//     const [totalDebited, setTotalDebited] = useState(0);



//     const [loading, setLoading] = useState(false);

//     const [balance, setBalance] = useState(null);


//     const [mappedTransfers, setMappedTransfers] = useState([]);


//     const [creditedData, setCreditedData] = useState(0);
//     const [debitedData, setDebitedData] = useState(0);

// console.log("credit data",creditedData)

//     const [startIndex, setStartIndex] = useState(0);
//     const transactionsPerPage = 5;

//     const handleNextClick = () => {
//         setStartIndex(prevIndex => prevIndex + transactionsPerPage);
//     };

//     const handlePrevClick = () => {
//         setStartIndex(prevIndex => Math.max(0, prevIndex - transactionsPerPage));
//     };


//     // const filteredTransactions = mappedTransfers
//     //     .filter(transaction =>
//     //         (transaction.toAddress === validatorWalletAddress.toLowerCase() || transaction.fromAddress === validatorWalletAddress.toLowerCase()) &&
//     //         transaction.value_decimal !== null
//     //     );

//     const filteredTransactions = mappedTransfers.filter(transaction =>
//         (transaction.toAddress === validatorWalletAddress.toLowerCase() || transaction.fromAddress === validatorWalletAddress.toLowerCase()) &&
//         transaction.value_decimal !== null
//     );

//     const uniqueTransactionHashes = new Set();
//     const uniqueTransactions = [];

//     filteredTransactions.forEach(transaction => {
//         if (!uniqueTransactionHashes.has(transaction.transaction_hash)) {
//             uniqueTransactionHashes.add(transaction.transaction_hash);
//         }
//         uniqueTransactions.push(transaction);
//     });

//     const displayedTransactions = uniqueTransactions
//         .slice(startIndex, startIndex + transactionsPerPage);

//         console.log("dis222",displayedTransactions)



//     console.log("displayedTransactions", displayedTransactions)


//     const getTransaction = async () => {
//         try {
//             await Moralis.start({
//                 apiKey: moralis_apiKey

//             });

//             const response = await Moralis.EvmApi.token.getTokenTransfers({
//                 "chain": moralis_chainId,
//                 "address": moralis_token_contract_address
//             });

//             console.log('response', response.raw);
//             const transfers = response.raw.result;
//             const updatedTransfers = transfers.map(transfer => {

//                 const roundedValue = parseFloat(transfer.value_decimal).toFixed(0);
//                 const timestamp = new Date(transfer.block_timestamp).toLocaleString('en-US', {
//                     day: 'numeric',
//                     month: 'short',
//                     hour: 'numeric',
//                     minute: 'numeric',
//                     hour12: true
//                 });

//                 return {
//                     transactionHash: transfer.transaction_hash,
//                     fromAddress: transfer.from_address,
//                     toAddress: transfer.to_address,
//                     amount: transfer.value,
//                     timestamp: timestamp,
//                     value_decimal: roundedValue
//                 };
//             });

//             console.log('33333333333333', updatedTransfers);
//             setMappedTransfers(updatedTransfers);

//         } catch (e) {
//             console.error(e);
//         }

//     }
//     getTransaction();




//     // useEffect(() => {

//     //     const getBalance = async (validatorWalletAddress) => {

//     //         const contractAddress = '0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8';
//     //         //const accountAddress = '0x797fFC09444EAe34C1700948E41ae185577aFd83';
//     //         const accountAddress =  validatorWalletAddress;
//     //         console.log("acc222",accountAddress)

//     //         try {
//     //             const provider = new ethers.providers.JsonRpcProvider("https://rpc-amoy.polygon.technology");
//     //             const contract = new ethers.Contract(contractAddress, abi, provider);
//     //             console.log('contract', contract)

//     //             const decimals = await contract.decimals();
//     //             const balance = await contract.balanceOf(accountAddress);


//     //             const currentBalance = balance.toString();
//     //             const balanceOutput = (currentBalance / 10 ** decimals).toFixed(0);

//     //             console.log(`Token Balance for ${accountAddress}: ${balanceOutput}`);
//     //             // setBalance(`Token Balance for ${accountAddress}: ${balanceOutput}`);
//     //             setBalance(balanceOutput);
//     //         } catch (error) {
//     //             console.error('Error:', error);
//     //         }
//     //     };

//     //     getBalance(validatorWalletAddress);
//     // }, [validatorWalletAddress]);


//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true)
//           try {
//             const response = await fetch(
//               `https://deep-index.moralis.io/api/v2.2/${validatorWalletAddress}/erc20?chain=${moralis_chainId}&token_addresses=${moralis_token_contract_address}`,
//               {
//                 method: 'GET',
//                 headers: {
//                   Accept: 'application/json',
//                   'X-API-Key': moralis_apiKey,
//                 },
//               }
//             );

//             if (!response.ok) {
//               throw new Error(`HTTP error! Status: ${response.status}`);
//             }

//             const data = await response.json();

//             console.log("data", data);

//             if (data && data.length > 0) {
//               const tokenData = data[0];
//               const decimals = tokenData.decimals || 18; 
//               const balance = tokenData.balance;
//               const balanceOutput = (balance / 10 ** decimals).toFixed(0);

//               console.log(`Token Balance: ${balanceOutput}`);
//               setBalance(balanceOutput);
//               setLoading(false)
//             } else {
//               console.log('No token data found for the specified address.');
//               setLoading(false)
//             }

//           } catch (error) {
//             console.error('Error fetching balance data:', error);
//             setLoading(false)
//           }
//         };

//         fetchData();
//       }, [validatorWalletAddress]);


//     useEffect(() => {
//         const getTransaction = async (validatorWalletAddress) => {
//             try {
//                 // await Moralis.start({
//                 // });

//                 // Fetch token transfers
//                 // const response = await Moralis.EvmApi.token.getTokenTransfers({
//                 //     "chain": "0x13882",
//                 //     "address": "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8"
//                 // });



//                 const chain = moralis_chainId;
//                 const address1 = moralis_token_contract_address
//                 const response = await axios.get(
//                     `https://deep-index.moralis.io/api/v2.2/erc20/${address1}/transfers?chain=${chain}`,
//                     {
//                         headers: {
//                             'accept': 'application/json',
//                             'X-API-Key': moralis_apiKey 
//                         }
//                     }
//                 );

//                 const transfers = response.data.result
//                 console.log(transfers)

//                 const address = validatorWalletAddress;
//                 console.log("address33333",address)
//                // const address = '0x787eCEE25B884b4116a5ABe9A063B70c08a7aA13';

//                const filteredTransfers1 = transfers.filter(trans =>
//                 trans.from_address.toLowerCase() === address.toLowerCase() ||
//                 trans.to_address.toLowerCase() === address.toLowerCase()
//             );

//             const mappedTransfers1 = filteredTransfers1.map(data => ({
//                 Decimal: data.token_decimals,
//                 Amount: data.value,
//                 Value: data.value / (10 ** data.token_decimals),
//                 From : data.from_address,
//                 To : data.to_address
//             }));

//             const monthlyData1 = mappedTransfers1.reduce((acc, item) => {
//                 if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
//                     acc.credited_data += parseFloat(item.Value);
//                 } 
//                 if(item.From && item.From.toLowerCase() === address.toLowerCase()) {
//                     acc.debited_data += parseFloat(item.Value);
//                 }
//                 return acc;
//             }, { credited_data: 0, debited_data: 0 }); 

//             console.log(monthlyData1.credited_data, monthlyData1.debited_data, "000000000000000000000000000000");

//             setCreditedData(monthlyData1.credited_data)
//             setDebitedData(monthlyData1.debited_data)


//             const today = new Date();
//                 today.setHours(0, 0, 0, 0); // Set time to the beginning of the day

//                 const thisWeekStart = new Date(today);
//                 thisWeekStart.setDate(thisWeekStart.getDate() - today.getDay()); // Adjust to the start of the week

//                 const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1); // Adjust to the start of the month

//                 // Calculate the start of the previous month
//                 const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);

//                 // Filter transfers for today, this week, and this month
//                 const filteredTransfers2 = transfers.filter(trans =>
//                 (trans.from_address.toLowerCase() === address.toLowerCase() ||
//                     trans.to_address.toLowerCase() === address.toLowerCase())
//                 );


//                 // Map filtered transfers to extract required data and calculate value
//                 const mappedTransfers2 = filteredTransfers2.map(data => ({
//                     Month: new Date(data.block_timestamp).toLocaleString('en-us', { month: 'short' }),
//                     Year: new Date(data.block_timestamp).getFullYear(),
//                     Decimal: data.token_decimals,
//                     Amount: data.value,
//                     Value: data.value / (10 ** data.token_decimals),
//                     From: data.from_address,
//                     To: data.to_address,
//                     block_timestamp: data.block_timestamp
//                 }));


//                 // Calculate credited amounts for today, this week, and this month
//                 let creditedAmountToday = 0;
//                 let creditedAmountThisWeek = 0;
//                 let creditedAmountThisMonth = 0;
//                 let creditedAmountLastMonth = 0;

//                 mappedTransfers2.forEach(item => {
//                     if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
//                         if (new Date(item.block_timestamp) >= today) {
//                             creditedAmountToday += parseFloat(item.Value);
//                         }
//                         if (new Date(item.block_timestamp) >= thisWeekStart) {
//                             creditedAmountThisWeek += parseFloat(item.Value);
//                         }
//                         if (new Date(item.block_timestamp) >= thisMonthStart) {
//                             creditedAmountThisMonth += parseFloat(item.Value);
//                         }
//                         if (new Date(item.block_timestamp) >= lastMonthStart && new Date(item.block_timestamp) < thisMonthStart) {
//                             creditedAmountLastMonth += parseFloat(item.Value);
//                         }
//                     }
//                 });

//                 console.log('Credited amount today:', creditedAmountToday);
//                 console.log('Credited amount this week:', creditedAmountThisWeek);
//                 console.log('Credited amount this month:', creditedAmountThisMonth);
//                 console.log('Credited amount last month:', creditedAmountLastMonth);

//                 setTodayCreditedData(creditedAmountToday);
//                 setWeeklyCreditedData(creditedAmountThisWeek);
//                 setMonthlyCreditedData(creditedAmountThisMonth)
//                 setPreviousMonthCreditedData(creditedAmountLastMonth)


//             //////////////////////


//                 // Get current date and date 6 months ago
//                 const currentDate = new Date();
//                 const sixMonthsAgo = new Date();
//                 sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

//                 // Filter transfers for the last 6 months for the specified address
//                 const filteredTransfers = transfers.filter(trans =>

//                     trans.from_address.toLowerCase() === address.toLowerCase() ||
//                     trans.to_address.toLowerCase() === address.toLowerCase()
//                 );


//                 // Map filtered transfers to extract required data and calculate value
//                 const mappedTransfers = filteredTransfers.map(data => ({

//                     Decimal: data.token_decimals,
//                     Amount: data.value,
//                     Value: data.value / (10 ** data.token_decimals),
//                     From : data.from_address,
//                     To : data.to_address
//                 }));

//                 console.log(mappedTransfers)
//                 const contract_address = moralis_token_contract_address;
//                 const contractAddr = contract_address;
//                 //const contractAddr = '0x797fFC09444EAe34C1700948E41ae185577aFd83';

//                 // Aggregate monthly data
//                 const monthlyData = mappedTransfers.reduce((acc, item) => {
//                     const key = `${item.Month} ${item.Year}`;
//                     acc[key] = acc[key] || { Month: item.Month, Year: item.Year, TotalCredit: 0, TotalDebit: 0 };

//                     // Check if data.to_address is defined before accessing it
//                     if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
//                         acc[key].TotalCredit += parseFloat(item.Value);
//                     } 
//                     if(item.From && item.From.toLowerCase() === address.toLowerCase()) {
//                         acc[key].TotalDebit += parseFloat(item.Value);
//                     }
//                     return acc;
//                 }, {});



//                 // Get the last 6 months in reverse order
//                 const months = [];
//                 const creditData = [];
//                 const debitData = [];
//                 for (let i = 5; i >= 0; i--) {
//                     const date = new Date();
//                     date.setMonth(date.getMonth() - i);
//                     const monthAbbreviation = date.toLocaleString('en-us', { month: 'short' });
//                     const year = date.getFullYear();
//                     const key = `${monthAbbreviation} ${year}`;
//                     const monthData = monthlyData[key] || { Month: monthAbbreviation, Year: year, TotalCredit: 0, TotalDebit: 0 };
//                     months.push(monthData.Month);
//                     creditData.push(monthData.TotalCredit);
//                     debitData.push(monthData.TotalDebit);
//                 }

//                 console.log('months:', months);
//                 console.log('creditData:', creditData);
//                 console.log('debitData:', debitData);


//                 setCreditData(creditData);
//                 setDebitData(debitData);
//                 setMonths(months);

//                 const totalCredited = creditData.reduce((acc, curr) => acc + curr, 0);
//                 const totalDebited = debitData.reduce((acc, curr) => acc + curr, 0);

//                 setTotalCredited(totalCredited);
//                 setTotalDebited(totalDebited);
//                 console.log('Total Credited:', totalCredited);
//                 console.log('Total Debited:', totalDebited);




//                 setTimeout(() => {
//                     setDataLoaded(true);
//                 }, 3000);
//             } catch (error) {
//                 console.error('Error fetching token transfers:', error);
//             }
//         };

//         getTransaction(validatorWalletAddress);
//     }, [validatorWalletAddress]);




//     const [isOpen, setisOpen] = useState(false)

//     const handlePopup = () => {
//         setisOpen(!isOpen)
//     }



//     if (loading) {
//         return <Loader />;
//     }


//     ////////////////////////




//     return (
//         <div className=' bg-[#191919] font-poppins ' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
//             <ValidatorHeader />

//             <div className='flex justify-center py-10'>
//                 <div className='flex flex-col w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
//                     <div className=' flex justify-center text-white '>
//                         <div className='w-[90%] 2xl:w-[85%] flex flex-col md:flex-row gap-5 md:gap-7 py-14'>
//                             <div className='md:w-[40%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
//                                 <div className='flex justify-center'>
//                                     <img src='/assets/Group 106.png' className='w-[17.5rem]'></img>
//                                 </div>
//                                 <div>
//                                     <p className='text-center px-7'>A digital way to store your tokens. </p>
//                                 </div>
//                             </div>
//                             <div className='md:w-[60%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
//                                 <div className='flex flex-col md:flex-row gap-5 md:gap-0 h-full'>
//                                     <div className='md:w-[60%] flex items-center justify-center'>
//                                         <div className='flex flex-col gap-5'>
//                                             <div>
//                                                 <p>Main Account</p>
//                                             </div>
//                                             <div>
//                                                 <p className='text-2xl font-bold'>First Savings Account</p>
//                                             </div>
//                                             <div>
//                                                 <p>****  ****  ****  2345</p>
//                                             </div>
//                                             <div className='flex gap-5'>
//                                                 <div className='border border-[#04F7FF80] w-12 h-12 flex items-center justify-center rounded-xl bg-white/10'>
//                                                     <i class="fa-solid fa-arrow-up-long rotate-45 text-2xl text-[#04F7FF80] "></i>
//                                                 </div>
//                                                 <div>
//                                                     <p className='font-bold text-lg'>$ {creditedData}</p>
//                                                     <p>Income</p>
//                                                 </div>
//                                                 <div className='border border-[#04F7FF80] w-12 h-12 flex items-center justify-center rounded-xl bg-white/10'>
//                                                     <i class="fa-solid fa-arrow-down-long rotate-45 text-2xl text-[#FF0F0F] "></i>
//                                                 </div>
//                                                 <div>
//                                                     <p className='font-bold text-lg'>$ {debitedData} </p>
//                                                     <p>Expenses</p>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                     <div className='md:w-[40%] flex items-center justify-center'>
//                                         <div className='flex flex-col gap-5'>
//                                             <div className='flex justify-center'>
//                                                 <img src='/assets/image 22.png' className='w-[75%]'></img>
//                                             </div>
//                                             <div>
//                                                 <p className='text-center mt-3'>Wallet Balance: <span>{balance}<br /> D5ART Tokens</span></p>
//                                             </div>
//                                             <div className='flex justify-center'>
//                                                 <div class="relative inline-flex  group" onClick={handlePopup}>
//                                                     <div
//                                                         class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-[#04F7FF80]  rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
//                                                     </div>
//                                                     <button
//                                                         class="relative inline-flex items-center justify-center px-8 py-2 font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl "
//                                                     >Withdraw
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             {/* <div className='flex justify-center'>
//                                                 <button className='px-5 py-2 bg-gradient-to-b from-[#0087A5E5] to-[#0E2DA7] rounded-lg'>Transfer Now  </button>
//                                             </div> */}

//                                             {isOpen && (
//                                                 <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
//                                                     <div className='w-[95%] md:w-[35%] bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
//                                                         <div className='flex justify-end'><i className='fa-solid fa-close text-white px-3 py-1 cursor-pointer' onClick={handlePopup}></i></div>
//                                                         <div className='px-5 md:px-10 pb-10 grid gap-5'>
//                                                             <p className='text-center text-sky-400 font-semibold text-xl'>Withdraw Amount</p>
//                                                             <input className='border border-sky-700  rounded-lg w-[100%] bg-transparent py-2 px-5 outline-none' placeholder='Address'></input>
//                                                             <input className='border border-sky-700 rounded-lg w-[100%] bg-transparent py-2 px-5 outline-none' placeholder='Enter Amount'></input>

//                                                             <div className='flex justify-center'>
//                                                                 <button className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white'>
//                                                                     Submit
//                                                                 </button>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className=' flex justify-center text-white '>
//                         <div className='w-[90%] 2xl:w-[85%] flex flex-col md:flex-row gap-5 md:gap-7 pb-14'>
//                             <div className='flex items-center md:w-[40%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl 2xl:text-xl'>
//                                 <div className=''>
//                                     <div className='p-5 border-b-[2px] border-[#04F7FF80]'>
//                                         <div className='flex justify-between'>
//                                             <div><p className='font-bold text-xl'>TokenFlow</p></div>
//                                             <div>
//                                                 <div className='border border-[#04F7FF80] w-10 h-10 flex items-center justify-center rounded-xl bg-white/10'>
//                                                     <i class="fa-solid fa-arrow-right-long text-xl "></i>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         <div>
//                                             <div className='flex justify-around mt-3'>
//                                                 <p>Daily</p>
//                                                 <p>Weekly</p>
//                                                 <p>Monthly</p>
//                                             </div>
//                                             <div className='flex justify-around mt-3'>
//                                                 <p className='font-bold'>$ {todayCreditedData}</p>
//                                                 <p className='font-bold'>$ {weeklyCreditedData}</p>
//                                                 <p className='font-bold'>$ {monthlyCreditedData}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='p-5'>
//                                         <p>Last Month </p>
//                                         <div className='flex'>
//                                             <div className='w-[60%]'>
//                                                 <div><img src='/assets/Group 106.png' ></img>
//                                                 </div>
//                                             </div>
//                                             <div className='w-[40%] flex items-center justify-center'>
//                                                 <div className='flex flex-col gap-5'>
//                                                     <div className=' border border-[#04F7FF80] text-[#04F7FF80] flex gap-3 items-center justify-center rounded-xl bg-white/10 px-3 py-1'>
//                                                         <div className='w-[20%]'>
//                                                             <i class="fa-solid fa-arrow-down-long text-center text-xl "></i>
//                                                         </div>
//                                                         <div className='w-[80%] '>
//                                                             <p>$ {creditedData}</p>
//                                                             <p>Income</p>
//                                                         </div>
//                                                     </div>
//                                                     <div className=' border border-[#04F7FF80] text-[#FF0F0F] flex gap-3 items-center justify-center rounded-xl bg-white/10 px-3 py-1'>
//                                                         <div className='w-[20%]'>
//                                                             <i class="fa-solid fa-arrow-up-long text-center text-xl "></i>
//                                                         </div>
//                                                         <div className='w-[80%] '>
//                                                             <p>$ {debitedData}</p>
//                                                             <p>Expenses</p>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='md:w-[60%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
//                                 <p className='text-2xl font-bold mb-5'>Recent Activities</p>

//                                 <div>
//                                     {displayedTransactions.map((transaction, index) => (
//                                         <div key={index} className='flex-col'>
//                                             <div className='flex py-5 px-3'>
//                                                 {transaction.toAddress === validatorWalletAddress.toLocaleLowerCase() ? (
//                                                     <div className='w-[10%]'>
//                                                         <div><img src='/assets/Green.png' alt='Green' /></div>
//                                                     </div>
//                                                 ) : (
//                                                     <div className='w-[10%]'>
//                                                         <div><img src='/assets/Red.png' alt='Red' /></div>
//                                                     </div>
//                                                 )}
//                                                 <div className='w-[70%] px-5'>
//                                                     <div>
//                                                         <p className='font-bold text-lg'>
//                                                             {transaction.toAddress === validatorWalletAddress.toLocaleLowerCase() ? 'Credited' : 'Debited'}
//                                                         </p>
//                                                         {transaction.timestamp && (
//                                                             <p className='text-xs text-gray-500'>{transaction.timestamp}</p>
//                                                         )}
//                                                     </div>
//                                                 </div>
//                                                 <div className='w-[20%]'>
//                                                     <p>{transaction.value_decimal} <span>Tokens</span></p>
//                                                 </div>
//                                             </div>
//                                             {index < transactionsPerPage - 1 && <div className='w-full h-[1px] bg-gray-800'></div>}
//                                         </div>
//                                     ))}

//                                     <div className='w-full h-[1px] bg-gray-800'></div>
//                                     <div className='py-3 flex justify-between px-5'>
//                                         <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handlePrevClick}>Prev</button>
//                                         <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handleNextClick}>Next</button>
//                                     </div>
//                                 </div>


//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//             <Footer />
//         </div>
//     )
// }




import React, { useEffect, useState } from 'react';
import ValidatorHeader from './ValidatorHeader';
import { ethers } from 'ethers';
import Moralis from 'moralis';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Loader from '../loaderdemo';
import Footer from '../Footer';




export default function Walletpage() {

    const validatorEmail = useSelector((state) => state.validator_email);

    const [validatorWalletAddress, setValidatorWalletAddress] = useState('');

    const [todayCreditedData, setTodayCreditedData] = useState(0);
    const [weeklyCreditedData, setWeeklyCreditedData] = useState(0);
    const [monthlyCreditedData, setMonthlyCreditedData] = useState(0);
    const [previousmonthCreditedData, setPreviousMonthCreditedData] = useState(0);

    const [todayDebitedData, setTodayDebitedData] = useState(0);
    const [weeklyDebitedData, setWeeklyDebitedData] = useState(0);
    const [monthlyDebitedData, setMonthlyDebitedData] = useState(0);
    const [previousmonthDebitedData, setPreviousMonthDebitedData] = useState(0);

    const moralis_apiKey = process.env.REACT_APP_MORALIS_APIKEY;
    const moralis_chainId = process.env.REACT_APP_MORALIS_CHAIN;
    const moralis_token_contract_address = process.env.REACT_APP_MORALIS_TOKEN_ADDRESS;

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_walletAddress_from_validator?validatorEmail=${validatorEmail}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('5555', data.data[0].walletAddress);
                console.log('5555', data);


                if (response.ok) {

                    setValidatorWalletAddress(data.data[0].walletAddress.toString());


                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (validatorEmail) {
            fetchData();
        }
    }, [validatorEmail]);


    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [months, setMonths] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [totalCredited, setTotalCredited] = useState(0);
    const [totalDebited, setTotalDebited] = useState(0);



    const [loading, setLoading] = useState(false);

    const [balance, setBalance] = useState(null);


    //     const [mappedTransfers, setMappedTransfers] = useState([]);


    const [creditedData, setCreditedData] = useState(0);
    const [debitedData, setDebitedData] = useState(0);

    // console.log("credit data",creditedData)

    //     const [startIndex, setStartIndex] = useState(0);
    //     const transactionsPerPage = 5;

    //     const handleNextClick = () => {
    //         setStartIndex(prevIndex => prevIndex + transactionsPerPage);
    //     };

    //     const handlePrevClick = () => {
    //         setStartIndex(prevIndex => Math.max(0, prevIndex - transactionsPerPage));
    //     };


    //     const filteredTransactions = mappedTransfers.filter(transaction =>
    //         (transaction.toAddress === validatorWalletAddress.toLowerCase() || transaction.fromAddress === validatorWalletAddress.toLowerCase()) &&
    //         transaction.value_decimal !== null
    //     );

    //     const uniqueTransactionHashes = new Set();
    //     const uniqueTransactions = [];

    //     filteredTransactions.forEach(transaction => {
    //         if (!uniqueTransactionHashes.has(transaction.transaction_hash)) {
    //             uniqueTransactionHashes.add(transaction.transaction_hash);
    //         }
    //         uniqueTransactions.push(transaction);
    //     });

    //     const displayedTransactions = uniqueTransactions
    //         .slice(startIndex, startIndex + transactionsPerPage);

    //         console.log("dis222",displayedTransactions)



    //     console.log("displayedTransactions", displayedTransactions)


    //     const getTransaction = async () => {
    //         try {
    //             await Moralis.start({
    //                 apiKey: moralis_apiKey

    //             });

    //             const response = await Moralis.EvmApi.token.getTokenTransfers({
    //                 "chain": moralis_chainId,
    //                 "address": moralis_token_contract_address
    //             });

    //             console.log('response', response.raw);
    //             const transfers = response.raw.result;
    //             const updatedTransfers = transfers.map(transfer => {

    //                 const roundedValue = parseFloat(transfer.value_decimal).toFixed(0);
    //                 const timestamp = new Date(transfer.block_timestamp).toLocaleString('en-US', {
    //                     day: 'numeric',
    //                     month: 'short',
    //                     hour: 'numeric',
    //                     minute: 'numeric',
    //                     hour12: true
    //                 });

    //                 return {
    //                     transactionHash: transfer.transaction_hash,
    //                     fromAddress: transfer.from_address,
    //                     toAddress: transfer.to_address,
    //                     amount: transfer.value,
    //                     timestamp: timestamp,
    //                     value_decimal: roundedValue
    //                 };
    //             });

    //             console.log('33333333333333', updatedTransfers);
    //             setMappedTransfers(updatedTransfers);

    //         } catch (e) {
    //             console.error(e);
    //         }

    //     }
    //     getTransaction();





    //     useEffect(() => {
    //         const fetchData = async () => {
    //             setLoading(true)
    //           try {
    //             const response = await fetch(
    //               `https://deep-index.moralis.io/api/v2.2/${validatorWalletAddress}/erc20?chain=${moralis_chainId}&token_addresses=${moralis_token_contract_address}`,
    //               {
    //                 method: 'GET',
    //                 headers: {
    //                   Accept: 'application/json',
    //                   'X-API-Key': moralis_apiKey,
    //                 },
    //               }
    //             );

    //             if (!response.ok) {
    //               throw new Error(`HTTP error! Status: ${response.status}`);
    //             }

    //             const data = await response.json();

    //             console.log("data", data);

    //             if (data && data.length > 0) {
    //               const tokenData = data[0];
    //               const decimals = tokenData.decimals || 18; 
    //               const balance = tokenData.balance;
    //               const balanceOutput = (balance / 10 ** decimals).toFixed(0);

    //               console.log(`Token Balance: ${balanceOutput}`);
    //               setBalance(balanceOutput);
    //               setLoading(false)
    //             } else {
    //               console.log('No token data found for the specified address.');
    //               setLoading(false)
    //             }

    //           } catch (error) {
    //             console.error('Error fetching balance data:', error);
    //             setLoading(false)
    //           }
    //         };

    //         fetchData();
    //       }, [validatorWalletAddress]);


    //     useEffect(() => {
    //         const getTransaction = async (validatorWalletAddress) => {
    //             try {



    //                 const chain = moralis_chainId;
    //                 const address1 = moralis_token_contract_address
    //                 const response = await axios.get(
    //                     `https://deep-index.moralis.io/api/v2.2/erc20/${address1}/transfers?chain=${chain}`,
    //                     {
    //                         headers: {
    //                             'accept': 'application/json',
    //                             'X-API-Key': moralis_apiKey 
    //                         }
    //                     }
    //                 );

    //                 const transfers = response.data.result
    //                 console.log(transfers)

    //                 const address = validatorWalletAddress;
    //                 console.log("address33333",address)
    //                // const address = '0x787eCEE25B884b4116a5ABe9A063B70c08a7aA13';

    //                const filteredTransfers1 = transfers.filter(trans =>
    //                 trans.from_address.toLowerCase() === address.toLowerCase() ||
    //                 trans.to_address.toLowerCase() === address.toLowerCase()
    //             );

    //             const mappedTransfers1 = filteredTransfers1.map(data => ({
    //                 Decimal: data.token_decimals,
    //                 Amount: data.value,
    //                 Value: data.value / (10 ** data.token_decimals),
    //                 From : data.from_address,
    //                 To : data.to_address
    //             }));

    //             const monthlyData1 = mappedTransfers1.reduce((acc, item) => {
    //                 if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
    //                     acc.credited_data += parseFloat(item.Value);
    //                 } 
    //                 if(item.From && item.From.toLowerCase() === address.toLowerCase()) {
    //                     acc.debited_data += parseFloat(item.Value);
    //                 }
    //                 return acc;
    //             }, { credited_data: 0, debited_data: 0 }); 

    //             console.log(monthlyData1.credited_data, monthlyData1.debited_data, "000000000000000000000000000000");

    //             setCreditedData(monthlyData1.credited_data)
    //             setDebitedData(monthlyData1.debited_data)


    //             const today = new Date();
    //                 today.setHours(0, 0, 0, 0); // Set time to the beginning of the day

    //                 const thisWeekStart = new Date(today);
    //                 thisWeekStart.setDate(thisWeekStart.getDate() - today.getDay()); // Adjust to the start of the week

    //                 const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1); // Adjust to the start of the month

    //                 // Calculate the start of the previous month
    //                 const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);

    //                 // Filter transfers for today, this week, and this month
    //                 const filteredTransfers2 = transfers.filter(trans =>
    //                 (trans.from_address.toLowerCase() === address.toLowerCase() ||
    //                     trans.to_address.toLowerCase() === address.toLowerCase())
    //                 );


    //                 // Map filtered transfers to extract required data and calculate value
    //                 const mappedTransfers2 = filteredTransfers2.map(data => ({
    //                     Month: new Date(data.block_timestamp).toLocaleString('en-us', { month: 'short' }),
    //                     Year: new Date(data.block_timestamp).getFullYear(),
    //                     Decimal: data.token_decimals,
    //                     Amount: data.value,
    //                     Value: data.value / (10 ** data.token_decimals),
    //                     From: data.from_address,
    //                     To: data.to_address,
    //                     block_timestamp: data.block_timestamp
    //                 }));


    //                 // Calculate credited amounts for today, this week, and this month
    //                 let creditedAmountToday = 0;
    //                 let creditedAmountThisWeek = 0;
    //                 let creditedAmountThisMonth = 0;
    //                 let creditedAmountLastMonth = 0;

    //                 mappedTransfers2.forEach(item => {
    //                     if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
    //                         if (new Date(item.block_timestamp) >= today) {
    //                             creditedAmountToday += parseFloat(item.Value);
    //                         }
    //                         if (new Date(item.block_timestamp) >= thisWeekStart) {
    //                             creditedAmountThisWeek += parseFloat(item.Value);
    //                         }
    //                         if (new Date(item.block_timestamp) >= thisMonthStart) {
    //                             creditedAmountThisMonth += parseFloat(item.Value);
    //                         }
    //                         if (new Date(item.block_timestamp) >= lastMonthStart && new Date(item.block_timestamp) < thisMonthStart) {
    //                             creditedAmountLastMonth += parseFloat(item.Value);
    //                         }
    //                     }
    //                 });

    //                 console.log('Credited amount today:', creditedAmountToday);
    //                 console.log('Credited amount this week:', creditedAmountThisWeek);
    //                 console.log('Credited amount this month:', creditedAmountThisMonth);
    //                 console.log('Credited amount last month:', creditedAmountLastMonth);

    //                 setTodayCreditedData(creditedAmountToday);
    //                 setWeeklyCreditedData(creditedAmountThisWeek);
    //                 setMonthlyCreditedData(creditedAmountThisMonth)
    //                 setPreviousMonthCreditedData(creditedAmountLastMonth)


    //             //////////////////////


    //                 // Get current date and date 6 months ago
    //                 const currentDate = new Date();
    //                 const sixMonthsAgo = new Date();
    //                 sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    //                 // Filter transfers for the last 6 months for the specified address
    //                 const filteredTransfers = transfers.filter(trans =>

    //                     trans.from_address.toLowerCase() === address.toLowerCase() ||
    //                     trans.to_address.toLowerCase() === address.toLowerCase()
    //                 );


    //                 // Map filtered transfers to extract required data and calculate value
    //                 const mappedTransfers = filteredTransfers.map(data => ({

    //                     Decimal: data.token_decimals,
    //                     Amount: data.value,
    //                     Value: data.value / (10 ** data.token_decimals),
    //                     From : data.from_address,
    //                     To : data.to_address
    //                 }));

    //                 console.log(mappedTransfers)
    //                 const contract_address = moralis_token_contract_address;
    //                 const contractAddr = contract_address;
    //                 //const contractAddr = '0x797fFC09444EAe34C1700948E41ae185577aFd83';

    //                 // Aggregate monthly data
    //                 const monthlyData = mappedTransfers.reduce((acc, item) => {
    //                     const key = `${item.Month} ${item.Year}`;
    //                     acc[key] = acc[key] || { Month: item.Month, Year: item.Year, TotalCredit: 0, TotalDebit: 0 };

    //                     // Check if data.to_address is defined before accessing it
    //                     if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
    //                         acc[key].TotalCredit += parseFloat(item.Value);
    //                     } 
    //                     if(item.From && item.From.toLowerCase() === address.toLowerCase()) {
    //                         acc[key].TotalDebit += parseFloat(item.Value);
    //                     }
    //                     return acc;
    //                 }, {});



    //                 // Get the last 6 months in reverse order
    //                 const months = [];
    //                 const creditData = [];
    //                 const debitData = [];
    //                 for (let i = 5; i >= 0; i--) {
    //                     const date = new Date();
    //                     date.setMonth(date.getMonth() - i);
    //                     const monthAbbreviation = date.toLocaleString('en-us', { month: 'short' });
    //                     const year = date.getFullYear();
    //                     const key = `${monthAbbreviation} ${year}`;
    //                     const monthData = monthlyData[key] || { Month: monthAbbreviation, Year: year, TotalCredit: 0, TotalDebit: 0 };
    //                     months.push(monthData.Month);
    //                     creditData.push(monthData.TotalCredit);
    //                     debitData.push(monthData.TotalDebit);
    //                 }

    //                 console.log('months:', months);
    //                 console.log('creditData:', creditData);
    //                 console.log('debitData:', debitData);


    //                 setCreditData(creditData);
    //                 setDebitData(debitData);
    //                 setMonths(months);

    //                 const totalCredited = creditData.reduce((acc, curr) => acc + curr, 0);
    //                 const totalDebited = debitData.reduce((acc, curr) => acc + curr, 0);

    //                 setTotalCredited(totalCredited);
    //                 setTotalDebited(totalDebited);
    //                 console.log('Total Credited:', totalCredited);
    //                 console.log('Total Debited:', totalDebited);




    //                 setTimeout(() => {
    //                     setDataLoaded(true);
    //                 }, 3000);
    //             } catch (error) {
    //                 console.error('Error fetching token transfers:', error);
    //             }
    //         };

    //         getTransaction(validatorWalletAddress);
    //     }, [validatorWalletAddress]);




    const [isOpen, setisOpen] = useState(false)

    const handlePopup = () => {
        setisOpen(!isOpen)
    }

    // fetch transactions history from database

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/get_validator_transactions?validatorEmail=${validatorEmail}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();

                console.log("transaction history", data)

                if (data && data.data && data.data.length > 0) {
                    setValidatorWalletAddress(data.data[0].walletAddress.toString());
                } else {
                    console.error('No data found for the given validator email');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (validatorEmail) {
            fetchData();
        }
    }, [validatorEmail]);


    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 5; // Number of transactions to display per page

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`https://ex.d5art.com/api/get_validator_transactions?validatorEmail=${validatorEmail}`);

    //             if (!response.ok) {
    //                 throw new Error(`Request failed with status: ${response.status}`);
    //             }

    //             const data = await response.json();
    //             console.log("Transaction history:", data);

    //             if (data && data.data && data.data.length > 0) {
    //                 setTransactions(data.data);
    //             } else {
    //                 console.error('No data found for the given validator email');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     if (validatorEmail) {
    //         fetchData();
    //     }
    // }, [validatorEmail]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/get_validator_transactions?validatorEmail=${validatorEmail}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Fetched data:", data);

                if (data && data.data && data.data.length > 0) {
                    setValidatorWalletAddress(data.data[0].validator_address.toString());
                    setTransactions(data.data);

                    const transactions = data.data;

                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    const thisWeekStart = new Date(today);
                    thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay());

                    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

                    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

                    let creditedToday = 0, creditedThisWeek = 0, creditedThisMonth = 0, creditedLastMonth = 0;
                    let debitedToday = 0, debitedThisWeek = 0, debitedThisMonth = 0, debitedLastMonth = 0;
                    let totalCreditedData = 0, totalDebitedData = 0;

                    // Process transactions
                    transactions.forEach(item => {
                        const transactionDate = new Date(item.created_at);
                        const value = parseFloat(item.amount);

                        if (item.status === "Credited") {
                            totalCreditedData += value;
                            if (transactionDate >= today) creditedToday += value;
                            if (transactionDate >= thisWeekStart) creditedThisWeek += value;
                            if (transactionDate >= thisMonthStart) creditedThisMonth += value;
                            if (transactionDate >= lastMonthStart && transactionDate <= lastMonthEnd) creditedLastMonth += value;
                        }

                        if (item.status === "Debited") {
                            totalDebitedData += value;
                            if (transactionDate >= today) debitedToday += value;
                            if (transactionDate >= thisWeekStart) debitedThisWeek += value;
                            if (transactionDate >= thisMonthStart) debitedThisMonth += value;
                            if (transactionDate >= lastMonthStart && transactionDate <= lastMonthEnd) debitedLastMonth += value;
                        }
                    });

                    // Set the data to the state
                    setTodayCreditedData(creditedToday);
                    setWeeklyCreditedData(creditedThisWeek);
                    setMonthlyCreditedData(creditedThisMonth);
                    setPreviousMonthCreditedData(creditedLastMonth);
                    setTodayDebitedData(debitedToday);
                    setWeeklyDebitedData(debitedThisWeek);
                    setMonthlyDebitedData(debitedThisMonth);
                    setPreviousMonthDebitedData(debitedLastMonth);
                    setCreditedData(totalCreditedData);  // Total Income
                    setDebitedData(totalDebitedData);    // Total Expenses

                    console.log('Total Income:', totalCreditedData);
                    console.log('Total Expenses:', totalDebitedData);
                } else {
                    console.error('No data found for the given validator email');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (validatorEmail) {
            fetchData();
        } else {
            console.warn('Validator email is not provided.');
        }
    }, [validatorEmail]);





    const handlePrevClick = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(transactions.length / transactionsPerPage)));
    };

    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const displayedTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const formatTimestamp = (timestamp) => {
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(new Date(timestamp));
    };


    // withdraw rewards to original wallet address

    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');

    const handleWithdraw = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/transfer_rewards', {
                address,
                amount,
                validatorEmail,
                validatorWalletAddress
            });

            console.log('Withdraw response:', response.data);
            // Handle response if needed
        } catch (error) {
            console.error('Error withdrawing funds:', error);
        }
    };





    if (loading) {
        return <Loader />;
    }


    ////////////////////////




    return (
        <div className=' bg-[#191919] font-poppins ' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <ValidatorHeader />

            <div className='flex justify-center py-10'>
                <div className='flex flex-col w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
                    <div className=' flex justify-center text-white '>
                        <div className='w-[90%] 2xl:w-[85%] flex flex-col md:flex-row gap-5 md:gap-7 py-14'>
                            <div className='md:w-[40%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
                                <div className='flex justify-center'>
                                    <img src='/assets/Group 106.png' className='w-[17.5rem]'></img>
                                </div>
                                <div>
                                    <p className='text-center px-7'>A digital way to store your tokens. </p>
                                </div>
                            </div>
                            <div className='md:w-[60%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
                                <div className='flex flex-col md:flex-row gap-5 md:gap-0 h-full'>
                                    <div className='md:w-[60%] flex items-center justify-center'>
                                        <div className='flex flex-col gap-5'>
                                            <div>
                                                <p>Main Account</p>
                                            </div>
                                            <div>
                                                <p className='text-2xl font-bold'>First Savings Account</p>
                                            </div>
                                            <div>
                                                <p>****  ****  ****  2345</p>
                                            </div>
                                            <div className='flex gap-5'>
                                                <div className='border border-[#04F7FF80] w-12 h-12 flex items-center justify-center rounded-xl bg-white/10'>
                                                    <i class="fa-solid fa-arrow-up-long rotate-45 text-2xl text-[#04F7FF80] "></i>
                                                </div>
                                                <div>
                                                    <p className='font-bold text-lg'>$ {creditedData}</p>
                                                    <p>Income</p>
                                                </div>
                                                <div className='border border-[#04F7FF80] w-12 h-12 flex items-center justify-center rounded-xl bg-white/10'>
                                                    <i class="fa-solid fa-arrow-down-long rotate-45 text-2xl text-[#FF0F0F] "></i>
                                                </div>
                                                <div>
                                                    <p className='font-bold text-lg'>$ {debitedData} </p>
                                                    <p>Expenses</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='md:w-[40%] flex items-center justify-center'>
                                        <div className='flex flex-col gap-5'>
                                            <div className='flex justify-center'>
                                                <img src='/assets/image 22.png' className='w-[75%]'></img>
                                            </div>
                                            <div>
                                                <p className='text-center mt-3'>Wallet Balance: <span>{creditedData}<br /> D5ART Tokens</span></p>
                                            </div>
                                            <div className='flex justify-center'>
                                                <div class="relative inline-flex  group" onClick={handlePopup}>
                                                    <div
                                                        class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-[#04F7FF80]  rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
                                                    </div>
                                                    <button
                                                        class="relative inline-flex items-center justify-center px-8 py-2 font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl "
                                                    >Withdraw
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className='flex justify-center'>
                                                <button className='px-5 py-2 bg-gradient-to-b from-[#0087A5E5] to-[#0E2DA7] rounded-lg'>Transfer Now  </button>
                                            </div> */}

                                            {isOpen && (
                                                <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                                    <div className='w-[95%] md:w-[35%] bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                                        <div className='flex justify-end'><i className='fa-solid fa-close text-white px-3 py-1 cursor-pointer' onClick={handlePopup}></i></div>
                                                        <div className='px-5 md:px-10 pb-10 grid gap-5'>
                                                            <p className='text-center text-sky-400 font-semibold text-xl'>Withdraw Amount</p>
                                                            <input
                                                                type='text'
                                                                className='border border-sky-700 rounded-lg w-[100%] bg-transparent py-2 px-5 outline-none'
                                                                placeholder='Address'
                                                                value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                            <input
                                                                type='number'
                                                                className='border border-sky-700 rounded-lg w-[100%] bg-transparent py-2 px-5 outline-none'
                                                                placeholder='Enter Amount'
                                                                value={amount}
                                                                onChange={(e) => setAmount(e.target.value)}
                                                            />

                                                            <div className='flex justify-center'>
                                                                <button className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white'
                                                                onClick={handleWithdraw}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' flex justify-center text-white '>
                        <div className='w-[90%] 2xl:w-[85%] flex flex-col md:flex-row gap-5 md:gap-7 pb-14'>
                            <div className='flex items-center md:w-[40%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl 2xl:text-xl'>
                                <div className=''>
                                    <div className='p-5 border-b-[2px] border-[#04F7FF80]'>
                                        <div className='flex justify-between'>
                                            <div><p className='font-bold text-xl'>TokenFlow</p></div>
                                            <div>
                                                <div className='border border-[#04F7FF80] w-10 h-10 flex items-center justify-center rounded-xl bg-white/10'>
                                                    <i class="fa-solid fa-arrow-right-long text-xl "></i>
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <div className='flex justify-around mt-3'>
                                                <p>Daily</p>
                                                <p>Weekly</p>
                                                <p>Monthly</p>
                                            </div>
                                            <div className='flex justify-around mt-3'>
                                                <p className='font-bold'>$ {todayCreditedData}</p>
                                                <p className='font-bold'>$ {weeklyCreditedData}</p>
                                                <p className='font-bold'>$ {monthlyCreditedData}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-5'>
                                        <p>Last Month </p>
                                        <div className='flex'>
                                            <div className='w-[60%]'>
                                                <div><img src='/assets/Group 106.png' ></img>
                                                </div>
                                            </div>
                                            <div className='w-[40%] flex items-center justify-center'>
                                                <div className='flex flex-col gap-5'>
                                                    <div className=' border border-[#04F7FF80] text-[#04F7FF80] flex gap-3 items-center justify-center rounded-xl bg-white/10 px-3 py-1'>
                                                        <div className='w-[20%]'>
                                                            <i class="fa-solid fa-arrow-down-long text-center text-xl "></i>
                                                        </div>
                                                        <div className='w-[80%] '>
                                                            <p>$ {creditedData}</p>
                                                            <p>Income</p>
                                                        </div>
                                                    </div>
                                                    <div className=' border border-[#04F7FF80] text-[#FF0F0F] flex gap-3 items-center justify-center rounded-xl bg-white/10 px-3 py-1'>
                                                        <div className='w-[20%]'>
                                                            <i class="fa-solid fa-arrow-up-long text-center text-xl "></i>
                                                        </div>
                                                        <div className='w-[80%] '>
                                                            <p>$ {debitedData}</p>
                                                            <p>Expenses</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='md:w-[60%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl p-5 2xl:text-xl'>
                                <p className='text-2xl font-bold mb-5'>Recent Activities</p>

                                <div>
                                    {displayedTransactions.map((transaction, index) => (
                                        <div key={index} className='flex-col'>
                                            <div className='flex py-5 px-3'>
                                                <div className='w-[10%]'>
                                                    <div>
                                                        <img
                                                            src={transaction.status === 'Credited' ? '/assets/Green.png' : '/assets/Red.png'}
                                                            alt={transaction.status === 'Credited' ? 'Green' : 'Red'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-[70%] px-5'>
                                                    <div>
                                                        <p className='font-bold text-lg'>
                                                            {transaction.status === 'Credited' ? 'Credited' : 'Debited'}
                                                        </p>
                                                        {transaction.created_at && (
                                                            <p className='text-xs text-gray-500'>{formatTimestamp(transaction.created_at)}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='w-[20%]'>
                                                    <p>{transaction.amount} <span>Tokens</span></p>
                                                </div>
                                            </div>
                                            {index < displayedTransactions.length - 1 && <div className='w-full h-[1px] bg-gray-800'></div>}
                                        </div>
                                    ))}

                                    <div className='w-full h-[1px] bg-gray-800'></div>
                                    <div className='py-3 flex justify-between px-5'>
                                        <button
                                            className='px-5 rounded-full border border-[#0E2DA7]'
                                            onClick={handlePrevClick}
                                            disabled={currentPage === 1}
                                        >
                                            Prev
                                        </button>
                                        <button
                                            className='px-5 rounded-full border border-[#0E2DA7]'
                                            onClick={handleNextClick}
                                            disabled={currentPage === Math.ceil(transactions.length / transactionsPerPage)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

