
import React, { useState, useEffect } from 'react';

export default function Assessment_Loader() {
    const [dots, setDots] = useState('');

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots === '...') {
            return '';
          } else {
            return prevDots + '.';
          }
        });
      }, 500);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <div className='flex items-center justify-center h-screen bg-black'>
            <div>
                <div className='h-[8rem] w-[12.5rem]'>
                <img src='/assets/profile_loader.gif' className='w-[75%] mx-auto'></img>
                </div>
                <p className='text-auto font-bricolagegrotesque text-sky-300'>Redirecting to assessment {dots}</p>
            </div>
        </div>

//         <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
//     <div className="flex flex-col items-center">
//         <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-blue-800 mb-4"></div>
//         <p className="text-auto font-bricolagegrotesque text-white/80">Transferring token rewards, please wait...</p>
//     </div>
// </div>

    )
}

