import React, { useEffect, useState } from 'react';
import axios from 'axios';

import LoginHeader from './LoginHeader';
import { useSelector } from 'react-redux';
import { setSeekerEmail } from '../store'
import { useDispatch } from 'react-redux';
import Footer from '../Footer';
import { NavLink } from 'react-router-dom';
import LandingPopup from '../LandingPopup';

export default function Home() {
    const email = useSelector((state) => state.seeker_email);
console.log("Email Logged In",email)

const dispatch = useDispatch();

const Logout = () => {
    dispatch(setSeekerEmail(""));
    window.location.reload();
  };



  const placeholderText = 'Search skill here...';
  const [displayedText, setDisplayedText] = useState('');
  
  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
        setDisplayedText(placeholderText.substring(0, index + 1));
        index++;

        if (index === placeholderText.length) {
            clearInterval(intervalId);
        }
    }, 100);

    return () => {
        clearInterval(intervalId);
    };
}, []);


    const [moveImage, setMoveImage] = useState(false);

    useEffect(() => {
        const moveInterval = setInterval(() => {
            setMoveImage((prevMove) => !prevMove);
        }, 1500); // Adjust the interval as needed

        return () => {
            clearInterval(moveInterval);
        };
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    const containerStyles = {
        width: '90%',
        borderRadius: '30px',
        backdropFilter: 'blur(50px)',
        padding: '40px',
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
        
    };

    const [coursesData, setCoursesData] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://edu.d5art.com/api/Allcourses');
                const data = await response.json();
                console.log("course", response)
                const filteredCourses = data
                    .filter(course => course.status === 'enabled')
                    .map(course => ({
                        title: course.course_name,
                        level: course.category,
                        duration: `${course.duration_hours} hours`,
                        reviews: course.rating || 0,
                        imageSrc: `/assets/${course.image_url}`,
                        description: course.course_description,
                        course_id: course.course_id,
                    }));

                console.log("course", response)
                setCoursesData(filteredCourses);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
        fetchCourses();
    }, []);





   
    const [currentCourseIndex, setCurrentCourseIndex] = useState(0);

    const goCourseToPrev = () => {
        setCurrentCourseIndex((prevIndex) => (prevIndex === 0 ? coursesData.length - 1 : prevIndex - 1));
    };

    const goCourseToNext = () => {
        setCurrentCourseIndex((prevIndex) => (prevIndex === coursesData.length - 1 ? 0 : prevIndex + 1));
    };

    const currentCourse = coursesData[currentCourseIndex];

    const breakpoint = 767;
    if (window.innerWidth <= breakpoint) {
        containerStyles.padding = '20px';
    }

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 2500);

        return () => clearInterval(timer);
    }, []);

    const handleBlog = (url) => {
        const fullUrl = url.startsWith('http') ? url : `https://${url}`;
        window.open(fullUrl, '_blank');
    }

    const teamMembers = [
        {
            name: 'Sakthi Visakan Rajaguru',
            role: 'CEO',
            imageSrc: '/assets/Sakthi.png',
        },
        {
            name: 'Alun Richard’s',
            role: 'ADVISORY BOARD MEMBER',
            imageSrc: '/assets/alun.png',
        },
        {
            name: 'Graziella Thake',
            role: 'HUMAN CAPITAL DOMAIN EXPERT',
            imageSrc: '/assets/Graziella.png',
        },
       
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1));
    };


    const description = currentCourse?.description;
    const truncatedDescription = description ? `${description.split(' ').slice(0, 20).join(' ')}...` : '';


    const [verifystep, setSeekerverifystep] = useState('');

  

    useEffect(() => {
        const fetchData_seeker = async (email) => {
          try {
            const response = await axios.get(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);
            console.log("response", response);
            const responseData = response.data;
    
            if (responseData && responseData.data && responseData.data.length > 0) {
    
    
              const data = responseData.data[0];
              console.log("dattaaaa", data.original_walletAddress);
    
    
    
              if (data) {
                setSeekerverifystep(data.verify_step);
         
              }
    
    
            } else {
              console.error('No data found in response');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
    
        if (email) {
          fetchData_seeker(email);
        }
      }, [email]);

      

    return (
        <div className='bg-[#101010] font-poppins text-white 'style={{ backgroundImage: `url(/assets/bg.png)` }}>
        <LoginHeader />
        
        {(  verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                        <LandingPopup />
                      )}


        <div className='Dive-into-world flex justify-center pb-12 pt-4 2xl:h-screen'>
            <div className='md:flex w-[90%] md:w-[90%]'>
            <div className='md:w-[50%] px-5'>
                <h1 className='font-poppins  font-poppins py-5 lg: xl:leading-loose 2xl:leading-[6rem] text-2xl lg:text-5xl '>
                    Dive into the World’s First 
                    <span className='font-poppins'
                        style={{
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                        }}
                    >  WEB 3 Skill Exchange
                    </span> platform
                </h1>

                {/* <p className='flex font-poppins w-full 2xl:w-[90%] 2xl:my-10 py-1 rounded-full border border-sky-700'>
                    <input
                        className='w-[90%] bg-transparent rounded-full 2xl:text-lg text-sm text-white outline-none pl-5 md:px-10'
                        value={displayedText}
                        placeholder={placeholderText}
                        readOnly
                    />
                    <i className='fa-solid fa-magnifying-glass w-[10%] text-center text-lg  md:text-3xl text-sky-700'></i>
                </p> */}
                <div className='flex justify-between font-poppins pt-10 md:pt-10 2xl:pt-5  md:px-7 text-gray-500'>
                    <div>
                        <p className='text-lg md:text-4xl 2xl:text-5xl'>3.5k</p>
                        <p className='2xl:text-lg'>Skill ID's</p>
                    </div>
                    <div>
                        <p className='text-lg md:text-4xl 2xl:text-5xl'>3.5k</p>
                        <p className='2xl:text-lg'>Recuiter's </p>
                    </div>
                    <div>
                        <p className='text-lg md:text-4xl 2xl:text-5xl'>3.5k</p>
                        <p className='2xl:text-lg'>Global presence</p>
                    </div>
                </div>
            </div>
            <div className='md:w-[50%] pt-14 px-10'>
                <div className='flex h-full'>
                    <div className='h-full flex items-center'>
                        <img style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translateY(65px)' : 'translateY(0)',
                        }} className='' src='/assets/Ellipse1.png' alt='circle1'></img>
                    </div>
                    <div style={{
                        transition: 'transform 1s ease-in-out',
                        transform: moveImage ? 'translateY(-65px)' : 'translateY(0)',
                    }}
                    ><img src='/assets/Ellipse2.png' alt='circle2'></img>
                    </div>
                    <div className='absolute  '>
                        <img className='h-[17rem] md:h-[30rem] 2xl:h-[35rem] -translate-y-[5rem] 2xl:translate-y-0' 
                        src='/assets/seeker_image4.png' alt='image1' />
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className='About-Skill md:h-screen font-poppins py-28 md:pt-12 md:pb-5' >
            <div className='md:flex md:h-full '>
                <div className='md:w-[50%] flex items-center justify-center md:justify-end'>
                    <div className='grid content-center overflow-hidden'>
                        <div className='pl-10 md:pl-20' style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translateX(40px)' : 'translatex(0)',
                        }}
                        ><img className='w-[70%]' src='/assets/Ellipse2.png' alt='circle2'></img>
                        </div>
                        <div>
                            <img src='/assets/Ellipse3.png' alt='circle3'></img>
                        </div>
                    </div>
                </div>
                <div className='md:w-[50%] grid content-between '>
                    <div className='flex justify-end '>
                        <div className='w-[20%] mr-20'>
                            <img src='/assets/Ellipse2.png' alt='circle1'></img>
                        </div>
                    </div>
                    <div className='flex justify-center '>
                        <div className='w-[32%] mr-20'>
                            <img src='/assets/Ellipse1.png' alt='circle1'></img>
                        </div>
                    </div>
                </div>

                
                <div className='absolute  w-full pt-20 md:pt-0 -translate-y-[45rem] md:-translate-y-0'>
                    <div className='flex justify-center pt-10'>
                        <div className='w-[90%] md:w-[80%] border border-sky-600 rounded-3xl backdrop-blur-md'>
                            <div className='flex justify-center '>
                                <button className='bg-black -translate-y-7 rounded-full'>
                                    <button className='cursor-text   text-center font-poppins px-10 md:px-20 rounded-full py-2  border border-sky-600 text-lg md:text-4xl font-bold '
                                        style={{
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                                        }}
                                    >About Skill ID</button>
                                </button>
                            </div>
                            <div className='md:flex '>
                                <div className='md:w-[45%] flex justify-center'>
                                    <div className='w-[70%]'>
                                        <img src='/assets/seeker_image2.png'></img>
                                    </div>
                                </div>
                                <div className='md:w-[55%] px-5 md:px-0  md:pr-10'>
                                    <p className='py-10 md:py-20  text-lg md:text-2xl'>Skill ID is a unique identifier assigned to each skill that a user acquires or validates through our platform. Skill IDs can be integrated with your  <span
                                        style={{
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                        }}
                                    >NFT CV
                                    </span>, to showcase your expertise and explore tailored opportunities.<span
                                        style={{
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                        }}
                                    >
                                        </span>  </p>
                                </div>
                            </div>
                            <div className='flex justify-center pb-10'>
                                <NavLink to='/RMS/Seeker/SkillArena'><button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                    <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[127px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Explore</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className='Explore-WEb3 md:h-screen md:mt-20'>
            <div className='md:flex h-full '>
                <div className='md:w-[50%] flex items-center justify-end'>
                    <div className='pl-20 mt-20' style={{
                        transition: 'transform 1s ease-in-out',
                        transform: moveImage ? 'translatey(100px)' : 'translatey(0)',

                    }}
                    ><img className='w-[70%]' src='/assets/Ellipse2.png' alt='circle2'></img>
                    </div>
                </div>
                <div className='md:w-[50%] '>
                    <div className='flex justify-center '>
                        <div className='w-[32%] mr-20'>
                            <img src='/assets/Ellipse1.png' alt='circle1'></img>
                        </div>
                    </div>
                    <div className='flex justify-end mt-[10rem]'>
                        <div className='w-[25%] mr-10'>
                            <img src='/assets/Ellipse2.png' alt='circle1'></img>
                        </div>
                    </div>
                </div>
                <div className='absolute w-full h-full overflow-hidden -translate-y-[40rem] md:-translate-y-0'>
                
                <div className='flex justify-center gap-3 items-center h-full'>
                            <button onClick={goCourseToPrev} className='prev-button'>
                                <i className="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                            </button>
                            <div className='w-[80%] md:w-[90%]' style={{ ...containerStyles, }}>
                                <p className='text lg:text-3xl font-bold text-white font-poppins'>Explore <span style={{
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                                }}>WEB 3</span> Learning with us</p>
                                <div className='lg:flex justify-between'>
                                    <div className='md:flex gap-10 md:py-10'>
                                        <div className='mt-3 md:-0 md:w-[50%]'>
                                            <img src={currentCourse?.imageSrc} alt={currentCourse?.title} />
                                        </div>
                                        <div className='md:w-[50%] text-white flex flex-col gap-5 font-poppins'>
                                            <h1 className='font-poppins text-2xl mt-5 md:mt-0 md:text-5xl'>{currentCourse?.title}</h1>
                                            <p className='flex flex-col md:flex-row justify-between '>
                                                <p>
                                                    <i className='fa-solid fa-list mr-2 text-xl'></i>
                                                    {currentCourse?.level}
                                                </p>
                                                <p>
                                                    <i className='fa-solid fa-calendar text-xl'></i>
                                                    <i className='fa-solid fa-clock -translate-x-2 translate-y-1 border-[2px] rounded-full border-black'></i>
                                                    {currentCourse?.duration}
                                                </p>
                                            </p>
                                            <p>
                                            <p>{truncatedDescription}</p>
                                            </p>
                                            <div className='flex'>
                                                <div className='w-[50%]'>
                                                    <p className='flex gap-1'>
                                                        <i className='fa-solid fa-star text-yellow-600'></i>
                                                        <i className='fa-solid fa-star text-yellow-600'></i>
                                                        <i className='fa-solid fa-star text-yellow-600'></i>
                                                        <i className='fa-solid fa-star text-yellow-600'></i>
                                                        <i className='fa-solid fa-star text-yellow-600'></i>
                                                    </p>
                                                    {/* <p className='mt-2 text-xs'>({currentCourse?.reviews} Reviews)</p> */}
                                                </div>
                                                <div className='w-[50%] flex justify-end'>
                                                    <NavLink to={`https://edu.d5art.com/precourse?id=${currentCourse?.course_id}`} >
                                                        <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                                            <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>{' '}
                                                            <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Enroll</p>{' '}
                                                            <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                        </button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={goCourseToNext} className='next-button'>
                                <i className="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                            </button>
                        </div>

                </div>
            </div>
        </div>
        <div className='The-Ideal-solution md:mt-20 py-5 md:py-0'>
            <div className='md:flex h-screen overflow-hidden'>
                <div className='md:w-[50%] flex justify-center'>
                    <div className='w-[32%] '>
                        <img src='/assets/Ellipse1.png' alt='circle1'></img>
                    </div>
                </div>
                <div className='md:w-[50%]'>
                    <div className='flex justify-end '>
                        <div className='w-[20%] mr-10'>
                            <img src='/assets/Ellipse2.png' alt='circle1'></img>
                        </div>
                    </div>
                    <div className='-rotate-45'>
                        <div className='w-[50%] mt-10' style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translatey(70px)' : 'translatey(0)',

                        }}
                        ><img src='/assets/Ellipse2.png' alt='circle2'></img>
                        </div>
                    </div>
                </div>
                <div className='absolute h-full w-full -translate-y-[25rem] md:-translate-y-0'>
                    <div className='flex justify-center items-center h-full '>
                        <div className='w-[90%] border border-sky-600 rounded-3xl  md:rounded-tr-[500px] md:rounded-l-[30px] md:rounded-br-3xl backdrop-blur-2xl flex'>
                            <div className='md:w-[65%] p-7 md:p-12 grid centent-between'>
                                <p className='md:absolute  text-2xl md:text-4xl font-bold px-5 md:px-28 text-white font-poppins uppercase'> <span style={{
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                                }}>Who we are ?</span> </p>
                                <p className='py-7 md:py-20 text-xl font-poppins md:pr-20'>We are a multidimensional product engineering company aiming to leverage the potentials of Blockchain, AI & Quantum to build a Future Ready Talent Pool to help bridge the skill gap in emerging Web 3.0 technology .
<br/><br/>
                                We also offer the WORLD’S FIRST NFT-Based CV, a ground-breaking approach designed to eliminate potential human identity bias and foster a more inclusive and merit-driven recruitment environment.
                             

                                </p>
                                <div>
                                <NavLink to={`https://fabclabs.global/`} >
                                    <button className='flex font-poppins pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' >
                                        <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[137px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Contact</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                    </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='md:w-[35%]'>
                                <div className='absolute'>
                                    <img src='/assets/image3.png'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
        <div className='Stay-Updated py-10 md:pb-14'>
            <div className='lg:flex  h-[100vh] md:h-[120vh] overflow-hidden'>
                <div className='md:w-[50%] flex justify-center items-end'>
                    <div className='w-[32%] '>
                        <img src='/assets/Ellipse1.png' alt='circle1'></img>
                    </div>
                </div>
                <div className='md:w-[50%] grid content-between'>
                    <div className='-rotate-45'>
                        <div className='w-[50%]' style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translatey(70px)' : 'translatey(0)',

                        }}
                        ><img src='/assets/Ellipse2.png' alt='circle2'></img>
                        </div>
                    </div>
                    <div className='flex justify-end '>
                        <div className='w-[20%] mr-10'>
                            <img src='/assets/Ellipse2.png' alt='circle1'></img>
                        </div>
                    </div>
                </div>
                <div className='absolute md:h-full w-full mt-12 md:mt-24 -translate-y-[22rem] md:translate-y-0'>
                        <h1 className='text-white text-center font-poppins text-4xl font-bold'>Stay <span style={{
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                        }}>Updated</span></h1>
                        <div className='stay-updated '>
                            <div className='px-10 md:px-28 py-10 pt-12 w-full flex flex-col md:flex-row md:justify-between gap-3 md:gap-7'>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/how-to-deploy-a-smart-contract-on-ton-network-f99bf728934a')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 0 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('/assets/1_76R-dyhg_Md21Lr83RREfQ.webp')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/build-your-own-custom-blockchain-using-polygon-supernet-e71bd75afe70')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 1 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('/assets/1_23.gif')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/polygon-the-next-generation-of-blockchain-f75bb217ace0')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 2 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('/assets/1_zcczsArZudz3kD2mVfoOoQ.webp')" }}>
                                </div>
                                <div onClick={() => handleBlog('https://medium.com/@FABCLLC/enhancing-trust-and-transparency-polygon-id-steps-to-create-an-organization-issuer-node-f997b32b3d1b')}
                                    className={`cursor-pointer group h-[10rem] md:h-[27.5rem] rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === 3 ? 'md:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[27.5rem]' : 'opacity-50 md:w-[20%] h-[2rem] md:h-[27.5rem] border-0'}`}
                                    style={{ backgroundImage: "url('/assets/polygon_id.gif')" }}>
                                </div>
                            </div>
                        </div>
                        <div className='w-[85%] mx-auto bg-white/10 rounded-2xl backdrop-blur-md z-20'>
                        <p className='font-poppins font-bold py-5 px-3 md:py-10 text-lg text-center'>The blog section offers insights into the latest trends, tips, and best practices in recruitment, career development, and blockchain technology.</p>
                        </div>
                    </div>
            </div>
        </div>
        <div className='Council-of-SME md:pb-5 py-10'>
            <div className='md:flex h-screen overflow-hidden '>
                <div className='md:w-[50%] flex justify-center'>
                    <div className='rotate-45'>
                        <div className='w-[75%]' style={{
                            transition: 'transform 1s ease-in-out',
                            transform: moveImage ? 'translatey(70px)' : 'translatey(0)',

                        }}
                        ><img src='/assets/Ellipse2.png' alt='circle2'></img>
                        </div>
                    </div>
                </div>
                <div className='md:w-[50%] grid content-between'>
                    <div className='flex justify-center'>
                        <div className='w-[20%] mr-10'>
                            <img src='/assets/Ellipse2.png' alt='circle1'></img>
                        </div>
                    </div>
                    <div className='flex justify-end '>
                        <div className='w-[32%] mr-20'>
                            <img src='/assets/Ellipse1.png' alt='circle1'></img>
                        </div>
                    </div>
                </div>
                <div className='absolute flex items-center md:h-full w-full md:pt-20 text-white -translate-y-[30rem] md:-translate-y-0'>
                    <div className='flex justify-center '>
                        <div className='w-[90%] md:w-[85%]'>
                            <p className='font-poppins text-4xl text-center font-bold'>Meet the  <span style={{
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                            }}> LEADERS</span></p>
                            <p className='font-poppins font-bold py-5 md:py-10 text-lg text-center'>Our LEADERS are the driving force behind our success. Comprising seasoned professionals with diverse expertise, they bring a wealth of knowledge, innovation, and leadership to our organization.</p>
                            <div className='hidden md:block'>
                                <div className='flex justify-around mt-5'>
                                    <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid gap-3'>
                                        <div className='flex justify-center'>
                                            <div>
                                                <img src='/assets/Sakthi.png' className='rounded-3xl'></img>
                                            </div>
                                        </div>
                                        <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Sakthi Visakan Rajaguru</p>
                                        <p className='font-black text-center font-poppins text-lg'>CEO</p>

                                    </div>
                                    <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                        <div className='flex justify-center'>
                                            <div>
                                                <img src='/assets/alun.png' className='rounded-3xl'></img>
                                            </div>
                                        </div>
                                        <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Alun Richard’s</p>
                                        <p className='font-black text-center font-poppins text-lg'>ADVISORY BOARD MEMBER</p>

                                    </div>
                                    <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                        <div className='flex justify-center'>
                                            <div>
                                                <img src='/assets/Graziella.png' className='rounded-3xl'></img>
                                            </div>
                                        </div>
                                        <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Graziella Thake</p>
                                        <p className='font-black text-center font-poppins text-lg'>HUMAN CAPITAL DOMAIN EXPERT</p>

                                    </div>
                                    {/*   <div className='p-5 w-[22.5%] rounded-3xl backdrop-blur-3xl grid  gap-3'>
                                        <div className='flex justify-center'><div>
                                            <img src='/assets/adam.png' className='rounded-3xl'></img>
                                        </div></div>
                                        <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>Adam Roosevelt</p>
                                        <p className='font-black text-center font-poppins text-lg'>SUBJECT MATTER EXPERT</p>

                        </div> */}
                                </div>
                            </div>
                            <div className='block md:hidden '>
                                <div className='flex justify-between mt-5'>
                                    <button onClick={goToPrev}><i class="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i></button>
                                    <div className='p-5 w-[70%] rounded-3xl bg-white/5 grid gap-3'>
                                        <div className='flex justify-center'>
                                            <div>
                                                <img src={teamMembers[currentIndex].imageSrc} className='rounded-3xl' alt={teamMembers[currentIndex].name} />
                                            </div>
                                        </div>
                                        <p className='font-black text-center font-poppins text-[#0087a5] text-lg'>{teamMembers[currentIndex].name}</p>
                                        <p className='font-black text-center font-poppins text-lg'>{teamMembers[currentIndex].role}</p>
                                    </div>
                                    <button onClick={goToNext}><i class="fa-solid fa-chevron-right  px-3 py-2 rounded-full bg-white/10"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer />
    </div>


);
}