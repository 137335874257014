import React from "react";
 import Header from "./LoginHeader";
import Footer from "../Footer";

export default function Faq() {
  return (
    <div className="font-bricolagegrotesque text-white bg-[#191919]" style={{ backgroundImage: "url('./FABC.png')" }}>
      <Header />

      <div className="main-container">
        <div className=" py-7">
          <p className="text-3xl font-bold text-center">FAQ</p>
        </div>
        <div className="flex justify-center ">
          <div className="w-[70%]">
            <div>
              <p className="font-bold text-xl">What is FABC?</p>
            </div>
            <div>
              <p className="text-sm mt-5">
              FABC is the world's first native Web3 unbiased Skill Exchange platform. Our mission is to bridge the gap between education and employment by leveraging Blockchain technology and AI. We offer a decentralized and transparent system where learners can acquire and validate skills through blockchain-based NFT CVs. These tokenized skill certifications ensure individual integrity and immutability, providing a trustworthy and secure way for employers to providing a trustworthy and secure way for employers to verify qualifications. 
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="w-[70%]">
            <div>
              <p className="font-bold text-xl">What are NFT certificates?</p>
            </div>
            <div>
              <p className="text-sm mt-5">
              NFT certificates are blockchain-verified digital certificates that you earn as you complete courses. They represent milestones in your skill development and provide a tangible record of your achievements 
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="w-[70%]">
            <div>
              <p className="font-bold text-xl">
              How can FABC help me find employment?
              </p>
            </div>
            <div>
              <p className="text-sm mt-5">
              By providing you with verified skills and a Blockchain-based NFT CV, we make it easier for employers to trust your qualifications. Our platform connects you with opportunities that match your abilities, helping you secure employment. 

              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="w-[70%]">
            <div>
              <p className="font-bold text-xl">
              What support is available to learners? 

              </p>
            </div>
            <div>
              <p className="text-sm mt-5">
              We offer comprehensive support through our community, interactive modules, and practical assessments. Our team is always available to help you with any questions or challenges you may face. 
              </p>
            </div>
          </div>
        </div>

        
     
      </div>

      <Footer />
    </div>
  );
}