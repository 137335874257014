import React, { useState, useEffect } from 'react';
import { Spinner } from "@material-tailwind/react";



   


const Spinners =   ({ isVisible }) => {

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);
  
  return (
    isVisible && (
        
    <div className="z-50 fixed top-0 left-0  w-screen h-screen bg-black bg-opacity-50 backdrop-blur-lg">
    <div className='flex justify-center items-center pt-48'>
    <div className='flex justify-center items-center gap-5'>
     
    <div class="relative flex justify-center items-center">
    <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#1eef32]"></div>
    <img src="/assets/D5ART_Small_440x200 pxl.png"  class="rounded-full h-10 w-24"/>
</div><br/>
<p className='text-white text-2xl font-bold'>Hold on a moment your <span className='text-[#0EA4A9]'>NFT ID</span> is minting</p>

    </div>    </div>
    
</div>






)
  );
};

export default Spinners;









{/* <div className='flex items-center justify-center h-screen bg-black'>
      <div>
          <div className='h-[8rem] w-[12.5rem]'>
          <img src='/assets/profile_loader.gif' className='w-[75%] mx-auto'></img>
          </div>
          <p className='text-center font-bricolagegrotesque text-sky-300'>Hold on a moment your <span className='text-[#0EA4A9]'>NFT ID</span> is minting {dots}
          {/* <span className='font-poppins'
                                style={{
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                }}
                            > Loading {dots} </span> */}
  //                           </p>
  //     </div>
  // </div> */}