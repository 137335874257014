



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import AdminHeader from './AdminHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';


const AdminTicket = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [adminStatus, setAdminStatus] = useState('');
  const [adminDescription, setAdminDescription] = useState('');
  const [adminModalIsOpen, setAdminModalIsOpen] = useState(false);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [adminResolveDescription, setAdminResolveDescription] = useState('');
  const [Role, setRole] = useState('Recruiter');
  // const [selectedTicket, setSelectedTicket] = useState({ id: '' });


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  //   useEffect(() => {
  //     fetchRecuiterTickets();
  //   }, []);

  //   const fetchRecuiterTickets = async () => {
  //     const response = await axios.get('https://ex.d5art.com/api/recruiter_tickets');
  //     console.log("recruiter tickets",response)
  //     setTickets(response.data);
  //   };

  //   useEffect(() => {
  //     fetchSeekerTickets();
  //   }, []);

  //   const fetchSeekerTickets = async () => {
  //     const response = await axios.get('https://ex.d5art.com/api/seeker_tickets');
  //     console.log("seeker tickets",response)
  //     setTickets(response.data);
  //   };

  //   useEffect(() => {
  //     fetchValidatorTickets();
  //   }, []);

  //   const fetchValidatorTickets = async () => {
  //     const response = await axios.get('https://ex.d5art.com/api/validator_tickets');
  //     console.log("validator tickets",response)
  //     setTickets(response.data);
  //   };

  useEffect(() => {
    const fetchTickets = async () => {
      let url = '';
      if (Role === 'Recruiter') {
        url = 'https://ex.d5art.com/api/recruiter_tickets';
      } else if (Role === 'Seeker') {
        url = 'https://ex.d5art.com/api/seeker_tickets';
      } else if (Role === 'Validator') {
        url = 'https://ex.d5art.com/api/validator_tickets';
      }

      try {
        const response = await axios.get(url);
        console.log(`${Role} tickets`, response);
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, [Role]);



  const handleAdminStatusChange = async (e) => {
    e.preventDefault();

    console.log("selectedTicket", selectedTicket)
    try {
      const response = await axios.post(`https://ex.d5art.com/api/tickets/${selectedTicket}`, {
        status: adminStatus,
        description: adminResolveDescription,
        role: Role,
      });

      console.log('response handleAdminStatusChange', response);
      setAdminModalIsOpen(false);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };







  const handleImageClick = (filePath) => {
    setImageSrc(`https://ex.d5art.com/api/${filePath}`);
    setImageModalIsOpen(true);
  };

  const handleDownloadClick = async (filePath) => {
    const fileName = filePath.split('/').pop();
    const fileUrl = `https://ex.d5art.com/api/${filePath}`;

    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      });

      saveAs(response.data, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCloseImageModal = () => {
    setImageModalIsOpen(false);
    setImageSrc('');
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTickets = tickets
    .filter((ticket) =>
      ticket.subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.timestamp?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.user_email?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className=' min-h-screen bg-cover font-poppins bg-[#171616] overflow-y-scroll ' style={{ backgroundImage: `url(./assets/FABC.png)` }}>
       <AdminHeader />
      
        <div className='flex justify-start'><h1 className='text-2xl font-bold mb-4 '
        style={{
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          backgroundImage: 'linear-gradient(to right, #FFFFFF, #26ADA6)',
        }}>Ticket List</h1></div>
        <div className="flex space-x-2 items-center mb-4">
          <input
            type="text"
            placeholder="Search by Subject or Email"
            className="bg-[#2D2D2D] text-white px-4 py-2 rounded-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-[#26ADA6] text-white px-4 py-2 rounded-full"
            onClick={() => setCurrentPage(1)}
          >
            Search
          </button>
          <select
            className="bg-[#26ADA6] text-white px-4 py-2 rounded-full"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <select
            className="bg-[#26ADA6] text-white px-4 py-2 rounded-full"
            value={Role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="Recruiter">Recruiter</option>
            <option value="Seeker">Seeker</option>
            <option value="Validator">Validator</option>

          </select>
        </div>

        <div className='md:p-8 p-4 bg-[#2D2D2D] overflow-hidden rounded-2xl'>
          <div className='relative w-full overflow-x-auto sm:rounded-lg'>
            <table className="min-w-full w-full text-sm text-left text-gray-500 rounded-md p-5">
              <thead className='bg-gray-800 text-[#26ADA6] text-center divide-y divide-gray-700'>
                <tr className='border-b border-gray-700'>
                  <th className="py-4 px-3">ID</th>
                  <th className="py-4 px-3">Email</th>
                  <th className="py-4 px-3">Subject</th>
                  <th className="py-4 px-3">Status</th>
                  <th className="py-4 px-3">Timestamp</th>
                  {/* <th className="py-4 px-3">Image</th> */}
                  <th className="py-4 px-3">Actions</th>
                </tr>
              </thead>
              <tbody className='bg-gray-800 text-white divide-y divide-gray-700'>
                {currentTickets.map((ticket) => (
                  <tr key={ticket.id}>
                    <td className="py-4 px-3">#{ticket.token_id}</td>
                    <td className="py-4 px-3">{ticket.mail_id}</td>
                    <td className="py-4 px-3">{ticket.subject}</td>
                    <td className="py-4 px-3">{ticket.status}</td>
                    <td className="py-4 px-3">{new Date(ticket.date_created).toLocaleString()}</td>
                    {/* <td className="py-4 px-3">
                      {ticket.file_path && (
                        <>
                          <img
                            src={`https://ex.d5art.com/api/${ticket.file_path}`}
                            alt="Ticket"
                            className="w-20 h-20 cursor-pointer"
                            onClick={() => handleImageClick(ticket.file_path)}
                          />
                          <button
                            className="bg-blue-500 text-white px-2 py-1 rounded mt-2"
                            onClick={() => handleDownloadClick(ticket.file_path)}
                          >
                            Download
                          </button>
                        </>
                      )}
                    </td> */}
                    <td className="py-4 px-3">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={() => {
                          setSelectedTicket(ticket.token_id);
                          setAdminStatus(ticket.status);
                          setAdminDescription(ticket.description);
                          setAdminModalIsOpen(true);
                        }}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="bg-[#26ADA6] text-white px-4 py-2 rounded-full"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="bg-[#26ADA6] text-white px-4 py-2 rounded-full ml-2"
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= tickets.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {adminModalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <h2 className="text-xl mb-4">Update Ticket</h2>
            <form onSubmit={handleAdminStatusChange}>
              <label className="block mb-2">
                Status:
                <select
                  value={adminStatus}
                  onChange={(e) => setAdminStatus(e.target.value)}
                  className="border px-2 py-1 w-full"
                >
                  <option value="Open">Open</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Closed">Closed</option>
                </select>
              </label>
              <label className="block mb-2">
                Description:
                <textarea
                  value={adminDescription}
                  onChange={(e) => setAdminDescription(e.target.value)}
                  className="border px-2 py-1 w-full"
                  readOnly
                ></textarea>
              </label>

              <label className="block mb-2">
                Resolve:
                <textarea
                  value={adminResolveDescription}
                  onChange={(e) => setAdminResolveDescription(e.target.value)}
                  className="border px-2 py-1 w-full"
                ></textarea>
              </label>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setAdminModalIsOpen(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {imageModalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md">
          <div className=" rounded-lg shadow-lg max-w-1/2 max-h-1/2 overflow-auto">
            <div className="absolute top-0 right-0 mt-5 mr-5">
              <a
                href={imageSrc}
                download
                className=" text-white text-xl px-4 py-2 rounded ml-2"
                target='_blank'
              >
                <FontAwesomeIcon icon={faExpand} />
              </a>
              <button className='text-white text-xl' onClick={handleCloseImageModal}>
                <FontAwesomeIcon icon={faXmarkCircle} />
              </button>

            </div>
            <div className="flex justify-center items-center h-full">
              <img src={imageSrc} alt="Full Screen" className="w-[50%] mx-auto" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTicket;

