import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import ThreeJSComponent from './Threejscomponent';
import Threejscomponent2 from './Threejscomponent2';
import { Input } from "@material-tailwind/react";
import Slider from 'react-slick';
import './seeker.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import Spinners from './Spinners';
import { NavLink, useNavigate } from 'react-router-dom';

import axios from 'axios';

import LoginHeader from './LoginHeader';
import { toast, Toaster } from 'react-hot-toast';
import { setSeekerEmail, setRecruiterEmail, setAdminEmail, setValidatorEmail } from '../store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PreferredRoles from './PreferredRoles.json';
import Skillslist from './Skills.json';



export default function Register() {

    const Seeker_Login_Email = useSelector((state) => state.seeker_email);
    console.log("Email Logged In2222222", Seeker_Login_Email)

    const dispatch = useDispatch();

    const [seekeremail, setseekerEmail] = useState('');
    const [onepassword, setonePassword] = useState('');
    const [employeremail, setemployerEmail] = useState('');
    const [oneEmpPassword, setoneEmpPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedAvatar, setSelectedAvatar] = useState('avatar1');

    const sliderRef = useRef(null);
    const sliderRef1 = useRef(null);



    const handleNext = () => {

        const requiredFields = document.querySelectorAll('input[required], select[required] ');

        // Check if any required field is empty
        const isEmpty = Array.from(requiredFields).some(field => !field.value);

        // If any required field is empty, show a toast message and prevent moving to the next slider
        if (isEmpty) {
            toast.error('All fields are required..!');
            return;
        }

        if (sliderRef.current) {
            sliderRef.current.slickNext();
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };


    const handleSlideChange1 = (index) => {

    };
    const handleSlideChange = (index) => {
        setCurrentStep(index + 1);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange,
    };
    const customPaging = (i) => (
        <div className={`dot ${currentStep === i + 1 ? 'active' : ''}`}></div>
    );
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange1,
        customPaging: customPaging,
    };
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption);

    };

  
    const [selectedJob, setSelectedJob] = React.useState([]);
    const [inputValueJob, setInputValueJob] = React.useState('');

    const handleRemoveSkill = (removedSkill) => {
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            skills: prevInfo.skills.filter((skill) => skill !== removedSkill),
        }));
    };

    const handleRemoveJobRole = (removedRole) => {
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            jobrole: prevInfo.jobrole.filter((role) => role !== removedRole),
        }));
    };

    const handleResumeUpload = (event) => {
        const file = event.target.files[0];
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            resume: file,
        }));
    };

    const removeOption = (index) => {
        const updatedOptions = [...selectedJob];
        updatedOptions.splice(index, 1);
        setSelectedJob(updatedOptions);
    };
    const removeOption2 = (index) => {
        const updatedOptions = [...selectedSkill];
        updatedOptions.splice(index, 1);
        setSelectedSkill(updatedOptions);
    };
   
    const JobOptions = PreferredRoles;


    const SkillOptions = Skillslist;

    const [selectedEmp, setSelectedEmp] = useState(null);

    const handleChanges = (selectedOption) => {
        setSelectedEmp(selectedOption);
    };
    const employmentOptions = [
        { value: 'Full Time ', label: 'Full Time' },
        { value: 'Part Time', label: 'Part Time' },
        { value: 'Freelancer', label: 'Freelancer' },

    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
    };
    const customStylesJob = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#ccc', // Color of the placeholder text
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#090909', // Background color of the dropdown menu
            border: '1px solid gray',
            borderRadius: '10px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: '#090909', // Background color of the options list
            borderRadius: '10px',
            height: '200px',

        }),
    };


    const [selectedSkillValidator, setSelectedSkillValidator] = React.useState([]);
    const [inputValueSkillValidator, setInputValueSkillValidator] = React.useState('');

    const handleSkillValidator = (selectedOption) => {
        if (selectedSkill.length < 6) {
            setSelectedSkillValidator([...selectedSkillValidator, selectedOption]);
        }
        setInputValueSkillValidator('');
    };

    const removeOption3 = (index) => {
        const updatedOptions = [...selectedSkillValidator];
        updatedOptions.splice(index, 1);
        setSelectedSkillValidator(updatedOptions);
    };

    const SkillOptionsValidator = [
        { value: 'Skill1', label: 'HTML' },
        { value: 'Skill2', label: 'React' },
        { value: 'Skill3', label: 'Node' },
        { value: 'Skill4', label: 'Next' },
        { value: 'Skill5', label: 'Bootstrap' },
        { value: 'Skill6', label: 'Tailwind' },
    ];


    const [selectedstate, setSelectedSate] = useState(null);

    const handleChanges1 = (selectedOption) => {
        setSelectedSate(selectedOption);
    };
    
    const customStyles1 = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',
        }),
    };
    const customStylesJob1 = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
    };

    const [isTransformed, setIsTransformed] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    const toggleModal = () => {
        setIsLoading1(true);
        navigate('/');
        if (isOpen) {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(false);
                setIsTransformed(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(true);
                setIsTransformed(true);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsTransformed(true);
        } else {

            setIsTransformed(false);
        }
    }, [isOpen]);
    const [active, setActive] = useState('div1');

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };
    const [isOpen5, setIsOpen5] = useState(false);
    const togglemodal5 = () => {
        setIsOpen5(!isOpen5);

        setTimeout(() => {
            dispatch(setSeekerEmail(Seeker_Login_Email));
            navigate('/RMS/Seeker/');
        });


    };









    const navigate = useNavigate();







    const avatarSources = ['/assets/image 90.png', '/assets/image 90 (1).png', '/assets/image 3yr.png'];
    const serveravatar = ['/pictures/image 90.png', '/pictures/image 90 (1).png', '/image 3yr.png']

    const [selectedSkill, setSelectedSkill] = React.useState([]);
    const [inputValueSkill, setInputValueSkill] = React.useState('');
    const handleJobRole = (selectedOption) => {
        if (selectedJob.length < 3) {
            setSelectedJob([...selectedJob, selectedOption]);
            setSkillsInfo({ ...skillsInfo, jobrole: [...skillsInfo.jobrole, selectedOption.label] });
        }
        setInputValueJob('');
    };


    const handleSkill = (selectedOption) => {
        if (selectedSkill.length < 6) {
            const proficiencyPercentage = 0;
            setSelectedSkill([...selectedSkill, { ...selectedOption, percentage: proficiencyPercentage }]);
            setSkillsInfo({
                ...skillsInfo,
                skills: [...skillsInfo.skills, selectedOption.label],
                proficiency: [...skillsInfo.proficiency, { skill: selectedOption.label, percentage: proficiencyPercentage }],
            });
        }
        setInputValueSkill('');
    };

    const handleSkillPercentageChange = (index, percentage) => {
        setSelectedSkill((prevSkills) => {
            const updatedSkills = [...prevSkills];
            updatedSkills[index] = { ...updatedSkills[index], percentage };
            return updatedSkills;
        });

        setSkillsInfo({
            ...skillsInfo,
            proficiency: skillsInfo.proficiency.map((proficiency, i) => {
                if (i === index) {
                    return { ...proficiency, percentage };
                }
                return proficiency;
            }),
        });
        setInputValueSkill('');
    };


    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const [personalInfo, setPersonalInfo] = useState({
        //   name: '',
        email: Seeker_Login_Email,
        contractType: [],
        //  country: '',
        //   state: '',
        // mobileNumber: '',
        avatar: '/assets/image 90.png',
        description: '',
        //  password: '',
        //  confirmpassword: ''
    });

    console.log(personalInfo);

    const [educationInfo, setEducationInfo] = useState({
        sslcName: '',
        sslcPercentage: '',
        sslcInstitution: '',
        sslcYear: '',
        sslcDescription: '',
        sslcFile: null,
        hscName: '',
        hscPercentage: '',
        hscInstitution: '',
        hscFile: null,
        hscYear: '',
        hscDescription: '',
        cgpaName: '',
        cgpaPercentage: '',
        cgpaInstitution: '',
        cgpaYear: '',
        cgpaDescription: '',
        cgpaFile: null,
        pgName: '',
        pgPercentage: '',
        pgInstitution: '',
        pgYear: '',
        pgDescription: '',
        pgFile: null,

    });

    console.log(educationInfo)

    const [skillsInfo, setSkillsInfo] = useState({
        resume: null,
        skills: [],
        proficiency: [],
        jobrole: []
    });
    console.log(skillsInfo)

    const [projectInfo, setProjectInfo] = useState({
        project: {
            no: ''
        },
        project1: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
        project2: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
        project3: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
    });

    console.log("000000000000000", projectInfo)

    const [certificateandexpInfo, setCertificateandexpInfo] = useState({

        certificate1: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        certificate2: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        certificate3: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        experienceYears: {
            no: ''
        },
        experience1: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
        experience2: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
        experience3: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
    })
    console.log(certificateandexpInfo)




    const handleInputData = (section, field, value) => {
        switch (section) {
            case 'personalInfo':
                if (field === 'avatar') {
                    setSelectedAvatar(value);
                } else if (field === 'country' || field === 'contractType') {

                    // setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label }));

                    if (Array.isArray(value)) {

                        const selectedValues = value.map(option => option.label);
                        setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: selectedValues }));
                    } else {
                        setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label }));
                    }
                } else if (field === 'password' || field === 'confirmpassword') {
                    setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));

                    setPasswordsMatch(value === personalInfo.password);

                } else {
                    setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
                }
                break;



            default:
                break;
        }
    };


    const handleSliderAfterChange = (index) => {
        const avatarIndex = index + 1;
        const newAvatar = `avatar${avatarIndex}`;
        const avatarImageSrc = avatarSources[index];
        console.log("newavatar", newAvatar);
        setSelectedAvatar(newAvatar);

        setPersonalInfo((prevInfo) => ({
            ...prevInfo,
            avatar: avatarImageSrc,
        }));
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Personal Info:', personalInfo);
        console.log('education Info', educationInfo);
        console.log("skills&tools", skillsInfo);
        console.log("projects info", projectInfo);
        console.log("certificate and exp info", certificateandexpInfo);

    };




    const [showCongrats, setShowCongrats] = useState(false);

    const [sslcPercentage, setSslcPercentage] = useState('');
    const [hscPercentage, setHscPercentage] = useState('');
    const [cgpa, setCgpa] = useState('');
    const [pgCgpa, setPgCgpa] = useState('');


    const [sslcFile, setSslcFile] = useState(null);
    const [hscFile, setHscFile] = useState(null);
    const [cgpaFile, setCgpaFile] = useState(null);
    const [pgCgpaFile, setPgCgpaFile] = useState(null);


    const [ProjectFile, setProjectFile] = useState(null);
    const [Project2File, setProject2File] = useState(null);
    const [Project3File, setProject3File] = useState(null);

    const [CeritificateFile, setCeritificateFile] = useState(null);
    const [Ceritificate2File, setCeritificate2File] = useState(null);
    const [Ceritificate3File, setCeritificate3File] = useState(null);


    const sslcFileInputRef = useRef(null);
    const hscFileInputRef = useRef(null);
    const cgpaFileInputRef = useRef(null);
    const pgFileInputRef = useRef(null);

    const ProjectFileInputRef = useRef(null);
    const Project2FileInputRef = useRef(null);
    const Project3FileInputRef = useRef(null);

    const CeritificateFileInputRef = useRef(null);
    const Ceritificate2FileInputRef = useRef(null);
    const Ceritificate3FileInputRef = useRef(null);

    const handleSslcIconClick = () => {
        sslcFileInputRef.current.click();
    };

    const handleHscIconClick = () => {
        hscFileInputRef.current.click();
    };

    const handleCgpaIconClick = () => {
        cgpaFileInputRef.current.click();
    };

    const handlePgIconClick = () => {
        pgFileInputRef.current.click();
    };

    const handleProjectIconClick = (projectId) => {
        if (projectId === 'project1') {
            ProjectFileInputRef.current.click();
        } else if (projectId === 'project2') {
            Project2FileInputRef.current.click();
        } else if (projectId === 'project3') {
            Project3FileInputRef.current.click();
        }
    };
    const handleProject2IconClick = () => {
        Project2FileInputRef.current.click();
    };

    const handleProject3IconClick = () => {
        Project3FileInputRef.current.click();
    };

    const handleCeritificateIconClick = (certificateId) => {
        if (certificateId == "certificate1") {
            CeritificateFileInputRef.current.click();
        }
        else if (certificateId == "certificate2") {
            Ceritificate2FileInputRef.current.click();
        }
        else if (certificateId == "certificate3") {
            Ceritificate3FileInputRef.current.click();
        }

    };

    const handleCeritificate2IconClick = () => {
        Ceritificate2FileInputRef.current.click();
    };

    const handleCeritificate3IconClick = () => {
        Ceritificate3FileInputRef.current.click();
    };

    const handlePercentageInputChange = (event, setPercentage) => {
        const value = event.target.value;

        if (/^[0-9%.]*$/.test(value)) {
            setPercentage(value);
        }
    };

    const handleSslcFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, sslcFile: file }));
    };


    const handleHscFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, hscFile: file }));
    };

    const handleCgpaFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaFile: file }));
    };

    const handlePgFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, pgFile: file }));
    };

    const handleProjectFileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProjectFile(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };




    const handleCertificateInputChange = (event, certificateId, field) => {
        const value = event.target.value;
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                [field]: value
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);
    };

    const handleCeritificateFileInputChange = (event, certificateId) => {
        const file = event.target.files[0];

        if (file.size > 2 * 1024 * 1024) { // 2 MB in bytes
            toast.error(`${file.name} is larger than 2 MB.`);
            return;
        } 
    
        const acceptedFormats = ['application/pdf', 'application/msword'];
        if (!acceptedFormats.includes(file.type)) {
            toast.error(`${file.name} has an invalid file type.`);
            return;
        }

        setCeritificateFile(file);

        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);
    };




    const handleExperienceInputChange = (event, experienceId, field) => {
        const value = event.target.value;
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            [experienceId]: {
                ...certificateandexpInfo[experienceId],
                [field]: value
            }
        };

        setCertificateandexpInfo(updatedExperienceInfo);
    };




    const handleProjectInputChange = (e, projectId, field) => {
        const value = e.target.value;


        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                [field]: field === 'keywords' ? value.split(',').map(keyword => keyword.trim()) : value,
            },
        }));
    };



    const handleProject2FileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProject2File(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };
    const handleProject3FileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProject3File(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };


    const handleCeritificate2FileInputChange = (event, certificateId) => {
        const file = event.target.files[0];
        setCeritificate2File(file);
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);

    };
    const handleCeritificate3FileInputChange = (event, certificateId) => {
        const file = event.target.files[0];
        setCeritificate3File(file);
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);
    };

    const [inputValue, setInputValue] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };
    const handleInputChange2 = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue2(value);
        }
    };
    const handleInputChange3 = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue3(value);
        }
    };
    const [inputFrom, setInputFrom] = useState('');
    const [inputFrom2, setInputFrom2] = useState('');
    const [inputFrom3, setInputFrom3] = useState('');


    const handleInputFrom2 = (date) => {
        setInputFrom2(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience2: {
                ...certificateandexpInfo.experience2,
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };
    const handleInputFrom3 = (date) => {
        setInputFrom3(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience3: {
                ...certificateandexpInfo.experience3,
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const [inputTo, setInputTo] = useState('');
    const [ongoing, setOngoing] = useState(false);

    const [inputTo2, setInputTo2] = useState('');
    const [inputTo3, setInputTo3] = useState('');
    const [ongoing2, setOngoing2] = useState(false);
    const [ongoing3, setOngoing3] = useState(false);

    const handleInputFrom = (date, experienceId) => {
        setInputFrom(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            [experienceId]: {
                ...certificateandexpInfo[experienceId],
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const handleInputTo = (date) => {
        setInputTo(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience1: {
                ...certificateandexpInfo.experience1,
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setOngoing(false); // Ensure ongoing is false when a date is selected
    };

    const handleOngoingChange = () => {
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience1: {
                ...certificateandexpInfo.experience1,
                to: ongoing ? '' : 'Present',
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setInputTo(''); // Clear the date if marking as ongoing
        setOngoing(!ongoing);
    };


    


    const handleInputTo2 = (date) => {
        setInputTo2(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience2: {
                ...certificateandexpInfo.experience2,
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setOngoing2(false); // Ensure ongoing is false when a date is selected
    };

    const handleOngoingChange2 = () => {
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience2: {
                ...certificateandexpInfo.experience2,
                to: ongoing2 ? '' : 'Present',
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setInputTo2(''); // Clear the date if marking as ongoing
        setOngoing2(!ongoing2);
    };

    const handleInputTo3 = (date) => {
        setInputTo3(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience3: {
                ...certificateandexpInfo.experience3,
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setOngoing3(false); // Ensure ongoing is false when a date is selected
    };

    const handleOngoingChange3 = () => {
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience3: {
                ...certificateandexpInfo.experience3,
                to: ongoing3 ? '' : 'Present',
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
        setInputTo3(''); // Clear the date if marking as ongoing
        setOngoing3(!ongoing3);
    };

   


    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const [isToggle2Open, setIsToggle2Open] = useState(false);
    const [isToggle3Open, setIsToggle3Open] = useState(false);
    const [isTogglemarks, setIsTogglemarks] = useState(false);
    const [isTogglemarks2, setIsTogglemarks2] = useState(false);
    const [isTogglemarks3, setIsTogglemarks3] = useState(false);


    const handlemarks = () => {
        setIsTogglemarks(!isTogglemarks);
    };

    const handlemarks2 = () => {
        setIsTogglemarks2(!isTogglemarks2);
    };

    const handlemarks3 = () => {
        setIsTogglemarks3(!isTogglemarks3);
    };

    const handleToggleOpen = () => {
        setIsToggleOpen(!isToggleOpen);
    };

    const handleToggle2Open = () => {
        setIsToggle2Open(!isToggle2Open);
    };

    const handleToggle3Open = () => {
        setIsToggle3Open(!isToggle3Open);
    };
    const [isToggleDrawer, setIsToggleDrawer] = useState(false);

    const handleToggleDrawer = () => {
        setIsToggleDrawer(!isToggleDrawer);
    };
    const [isToggle2Drawer, setIsToggle2Drawer] = useState(false);

    const handleToggle2Drawer = () => {
        setIsToggle2Drawer(!isToggle2Drawer);
    };

    const [isToggleEXP, setIsToggleEXP] = useState(false);

    const handleToggleEXP = () => {
        setIsToggleEXP(!isToggleEXP);
    };
    const [isToggle2EXP, setIsToggle2EXP] = useState(false);

    const handleToggle2EXP = () => {
        setIsToggle2EXP(!isToggle2EXP);
    };



    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [password, setPassword] = useState('');

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const [email, setEmail] = useState('');
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const [passwordVisibleSeeker, setPasswordVisibleSeeker] = useState(false);
    const [passwordSeeker, setPasswordSeeker] = useState('');

    const handlePasswordChange = (e) => {
        setPasswordSeeker(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handlePasswordVisibility = () => {
        setPasswordVisibleSeeker(!passwordVisibleSeeker);
    };
    const handleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    console.log(email)
    console.log(passwordSeeker)
    console.log(confirmPassword)
















    const generateRandomNumber = () => {
        const min = Math.pow(10, 9);
        const max = Math.pow(10, 10) - 1;

        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    // console.log(generateRandomNumber)




    const seekerValidatePersonalInfo = () => {

        for (const key in personalInfo) {

            if (personalInfo.hasOwnProperty(key) && (Array.isArray(personalInfo[key]) ? personalInfo[key].length === 0 : personalInfo[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }


        return true;
    };


    const resumeFileInputRef = useRef(null);

    const handleResumeIconClick = () => {
        resumeFileInputRef.current.click();
    };

    const handleResumeRemove = () => {
        setSkillsInfo({ ...skillsInfo, resume: null });
        resumeFileInputRef.current.value = null;
    };




    const savePersonalInfo = async () => {
        try {

            if (!seekerValidatePersonalInfo()) {
                // toast.error('All Fields Are Required!!!');
                return;
            }

            const response = await axios.post('https://ex.d5art.com/api/UpdatePersonalInfo_for_LMS_user', personalInfo);
            console.log("data", response.data);

            toast.success("Data stored successfully")

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error saving personal info:', error);
        }


    };















    const getEducationFormData = () => {
        const formData = new FormData();
        formData.append('email', personalInfo.email);

        // SSLC
        formData.append('sslcName', educationInfo.sslcName);
        formData.append('sslcPercentage', educationInfo.sslcPercentage);
        formData.append('sslcInstitution', educationInfo.sslcInstitution);
        formData.append('sslcYear', educationInfo.sslcYear);
        formData.append('sslcDescription', educationInfo.sslcDescription);
        formData.append('sslcFile', educationInfo.sslcFile);

        // HSC
        formData.append('hscName', educationInfo.hscName);
        formData.append('hscPercentage', educationInfo.hscPercentage);
        formData.append('hscInstitution', educationInfo.hscInstitution);
        formData.append('hscYear', educationInfo.hscYear);
        formData.append('hscDescription', educationInfo.hscDescription);
        formData.append('hscFile', educationInfo.hscFile);

        // CGPA
        formData.append('cgpaName', educationInfo.cgpaName);
        formData.append('cgpaPercentage', educationInfo.cgpaPercentage);
        formData.append('cgpaInstitution', educationInfo.cgpaInstitution);
        formData.append('cgpaYear', educationInfo.cgpaYear);
        formData.append('cgpaDescription', educationInfo.cgpaDescription);
        formData.append('cgpaFile', educationInfo.cgpaFile);

        // PG

        formData.append('pgName', educationInfo.pgName);
        formData.append('pgPercentage', educationInfo.pgPercentage);
        formData.append('pgInstitution', educationInfo.pgInstitution);
        formData.append('pgYear', educationInfo.pgYear);
        formData.append('pgDescription', educationInfo.pgDescription);
        formData.append('pgFile', educationInfo.pgFile);

        return formData;
    };

    // const validateEducationInfo = () => {
    //     const requiredFields = ['sslcPercentage', 'sslcInstitution', 'sslcYear', 'sslcDescription', 'sslcFile',
    //         'hscPercentage', 'hscInstitution', 'hscYear', 'hscDescription', 'hscFile',
    //         'cgpaPercentage', 'cgpaInstitution', 'cgpaYear', 'cgpaDescription', 'cgpaFile'];

    //     const missingFields = requiredFields.filter(field => !educationInfo[field]);

    //     if (missingFields.length > 0) {
    //         toast.error(`Please fill in all the education info fields: ${missingFields.join(', ')}`);
    //         return false;
    //     }

    //     // Add more specific validation rules if necessary

    //     return true;
    // };

    const validateEducationInfo = () => {
        // const requiredFields = ['sslcName', 'sslcPercentage', 'sslcInstitution', 'sslcYear', 'sslcFile'];
        const requiredFields = ['sslcName', 'sslcPercentage', 'sslcInstitution', 'sslcYear'];

        const missingFields = requiredFields.filter(field => !educationInfo[field]);

        const displayToastMessage = (index) => {
            if (index >= missingFields.length) {
                return;
            }

            let message = '';
            switch (missingFields[index]) {
                case 'sslcName':
                    message = 'Please enter the grade.';
                    break;
                case 'sslcPercentage':
                    message = 'Please enter the percentage.';
                    break;
                case 'sslcInstitution':
                    message = 'Please enter the institution name.';
                    break;
                case 'sslcYear':
                    message = 'Please enter the year.';
                    break;
                
                // case 'sslcFile':
                //     message = 'Please upload the file.';
                //     break;
                default:
                    break;
            }

            toast.error(message, {
                onClose: () => {
                    displayToastMessage(index + 1);
                }
            });
        };

        if (missingFields.length > 0) {
            displayToastMessage(0);
            return false;
        }

        return true;
    };



    const handleEducationSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateEducationInfo()) {
            // toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveEducationInfo', getEducationFormData());

            console.log('Server response:', response.data);

            toast.success("Data stored successfully")

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error sending educationInfo to server:', error.message);
        }
    };

    const getSkillsFormData = () => {

        const formData1 = new FormData();
        formData1.append('email', personalInfo.email);

        formData1.append('resume', skillsInfo.resume);
        formData1.append('skills', JSON.stringify(skillsInfo.skills));
        formData1.append('proficiency', JSON.stringify(skillsInfo.proficiency));
        formData1.append('jobrole', JSON.stringify(skillsInfo.jobrole));
        return formData1;
    };

    // const validateSkillsInfo = () => {
    //     if (skillsInfo.skills.length === 0 || skillsInfo.proficiency.length === 0 || skillsInfo.jobrole.length === 0 || !skillsInfo.resume) {
    //         toast.error('Please fill in all the skill info fields.');
    //         return false;
    //     }

    //     return true;
    // };

    const validateSkillsInfo = () => {
        if (skillsInfo.skills.length === 0) {
            toast.error('Please enter your skills.');
            return false;
        }
        if (skillsInfo.proficiency.length === 0) {
            toast.error('Please enter your proficiency.');
            return false;
        }
        if (skillsInfo.jobrole.length === 0) {
            toast.error('Please enter your job role.');
            return false;
        }
        // if (!skillsInfo.resume) {
        //     toast.error('Please upload your resume.');
        //     return false;
        // }
        return true;
    };



    const handleSkillInfoSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateSkillsInfo()) {
            // toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveSkillsInfo', getSkillsFormData());

            console.log('Server response:', response.data);

            toast.success("Data stored successfully")

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }


        } catch (error) {
            console.error('Error sending skillInfo to server:', error.message);
        }
    }

    const getProjectsFormData = () => {

        const formData2 = new FormData();
        formData2.append('email', personalInfo.email);

        formData2.append('totalProjectNo', projectInfo.project.no);
        formData2.append('project1Name', projectInfo.project1.name);
        formData2.append('project1Description', projectInfo.project1.description);
        formData2.append('project1Keywords', JSON.stringify(projectInfo.project1.keywords));
        formData2.append('project1Link', projectInfo.project1.link);
        formData2.append('project1File', projectInfo.project1.file);

        formData2.append('project2Name', projectInfo.project2.name);
        formData2.append('project2Description', projectInfo.project2.description);
        formData2.append('project2Keywords', JSON.stringify(projectInfo.project2.keywords));
        formData2.append('project2Link', projectInfo.project2.link);
        formData2.append('project2File', projectInfo.project2.file);

        formData2.append('project3Name', projectInfo.project3.name);
        formData2.append('project3Description', projectInfo.project3.description);
        formData2.append('project3Keywords', JSON.stringify(projectInfo.project3.keywords));
        formData2.append('project3Link', projectInfo.project3.link);
        formData2.append('project3File', projectInfo.project3.file);
        return formData2;
    };

    const validateProjectInfo = () => {
        const projects = [projectInfo.project1, projectInfo.project2, projectInfo.project3];
        for (const project of projects) {
            if (project.name || project.description || project.keywords.length || project.file) {
                // If any field of the project is entered, then all fields must be filled out
                if (!project.name || !project.description || project.keywords.length === 0 || !project.file) {
                    toast.error('Please fill in all fields for each project.');
                    return false;
                }
            }
        }
        return true;
    };

    const handleProjectInfoSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateProjectInfo()) {
            // toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveProjectsInfo', getProjectsFormData());

            console.log('Server response:', response.data);

            toast.success("Data stored successfully")

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error sending projects Info to server:', error.message);
        }

    }

    const generate_seeker_wallet_address = async () => {

        if (!seekerValidatePersonalInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        try {

            if (!personalInfo.email) {

                toast.error('Email ID not found!');

                return;
            }
            const response = await fetch('https://ex.d5art.com/api/generate_SeekerWalletAddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: personalInfo.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const email = personalInfo.email;
                if (!email) {
                    console.error('Email is not defined');
                    return;
                }

                const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Fetched data:', data.data[0].avatar);

                if (data && data.data && data.data[0]) {
                    setName(data.data[0].name);
                    setAvatar(data.data[0].avatar);
                } else {
                    console.error('Invalid data structure:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (personalInfo.email) {
            fetchData();
        }
    }, [personalInfo.email]);




    const getCertandExpFormData = () => {

        const formData3 = new FormData();
        formData3.append('email', personalInfo.email);

        formData3.append('certificate1', JSON.stringify(certificateandexpInfo.certificate1));
        formData3.append('certificate2', JSON.stringify(certificateandexpInfo.certificate2));
        formData3.append('certificate3', JSON.stringify(certificateandexpInfo.certificate3));

        formData3.append('experienceYears', certificateandexpInfo.experienceYears.no);
        formData3.append('experience1', JSON.stringify(certificateandexpInfo.experience1));
        formData3.append('experience2', JSON.stringify(certificateandexpInfo.experience2));
        formData3.append('experience3', JSON.stringify(certificateandexpInfo.experience3));

        formData3.append('certificate1File', certificateandexpInfo.certificate1.file);
        formData3.append('certificate2File', certificateandexpInfo.certificate2.file);
        formData3.append('certificate3File', certificateandexpInfo.certificate3.file);
        return formData3;
    };

    const saveCertificateAndExpInfo = async (e) => {
        e.preventDefault();

        if (!seekerValidatePersonalInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        const generatedWalletAddress = await generate_seeker_wallet_address();

        setIsLoading(true);

        try {

            const response = await axios.post('https://ex.d5art.com/api/nftId_Address', {

                // name: personalInfo.name,
                name: name,
                email: personalInfo.email,
                salt: generateRandomNumber(),
                avatar: personalInfo.avatar


            });
            const data = await response.data;
            console.log(data.status)
            console.log(data)

            console.log("nft id", response.data);


            // setTimeout(() => {
            //     setIsLoading(false);
            //     if (personalInfo.email) {
            //         console.log("Navigating to Congrats page with email:", personalInfo.email);
            //         navigate(`/Congrats?email=${personalInfo.email}`);
            //     } else {

            //         toast.error('OOPS!! Email ID not found..');

            //     }
            // }, 1000);


            if (response.data) {
                setTimeout(() => {
                    setIsLoading(false);
                    if (personalInfo.email) {
                        console.log("Navigating to Congrats page with email:", personalInfo.email);
                        navigate(`/Congrats?email=${personalInfo.email}`);
                        console.log("email", personalInfo.email)
                    } else {
                        toast.error('OOPS!! Email ID not found..');
                    }
                }, 1000);
            } else {
                toast.error('Failed to obtain NFT ID.');
            }

        } catch (error) {
            console.error('Errdor getting nft id:', error.response?.data || error.message);
        }

        try {

            const response = await axios.post('https://ex.d5art.com/api/saveCertandExpInfo', getCertandExpFormData());

            console.log('Server response:', response.data);

            console.log('Server response:', personalInfo.email);



        } catch (error) {
            console.error('Error while saving certificate and experience:', error);
        } finally {

            setIsLoading(false);
        }



    };

    const customWrapperStyles = {
        width: '91%'
    };



    return (
        <div className='bg-[#101010] text-white h-screen font-poppins bg-cover' style={{ backgroundImage: `url(./assets/Loginbg.png)` }}>
            <Toaster />
           





            <div>

                <div className="rounded-2xl py-10 md:py-0  z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row md:items-center md:justify-center bg-black bg-opacity-10 backdrop-blur-md overflow-y-auto">
                    <div className="modal-container w-[85%] mx-auto md:mx-0 md:w-4/5 md:absolute top-1">
                        <div className="modal-content  ">
                            <div className='slide-in-from-top1' >
                                <div>
                                    <div>
                                        <div className=' items-center'>
                                            <div className=''>
                                                <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
                                            </div>
                                            <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={togglemodal5}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="roadmap">
                                        <button className={currentStep === 1 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        <p className='text-white md:pt-3 flex'><p>-</p><span className='hidden md:block'>--------------</span></p>
                                        <button className={currentStep === 2 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        <p className='text-white md:pt-3 flex'><p>-</p><span className='hidden md:block'>--------------</span></p>
                                        <button className={currentStep === 3 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        <p className='text-white md:pt-3 flex'><p>-</p><span className='hidden md:block'>--------------</span></p>
                                        <button className={currentStep === 4 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        <p className='text-white md:pt-3 flex'><p>-</p><span className='hidden md:block'>--------------</span></p>
                                        <button className={currentStep === 5 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                    </div>
                                    <form onSubmit={handleSubmit}>

                                        <div className=' mt-4 mb-20'>

                                            <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>


                                                <div className='flex justify-center' >
                                                    <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                    <div className='flex flex-col md:flex-row gap-7 md:gap-9 w-[90%] mx-auto md:mx-0 md:w-[100%]'>
                                                        <div className='md:w-[60%] flex items-center'>
                                                            <div className='w-full flex flex-col gap-7'>
                                                                <div className='flex flex-col gap-7 md:px-12  '>

                                                                    {/* <div className='flex justify-center py-4'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='text' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Name'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'name', e.target.value)}></input>                                                                            <i class="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        
                                                                        </div> */}

                                                                    {/*  <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            
                                                                             <div className='flex border-b border-gray-500 w-full'>
                                                                                <input className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' type='email' placeholder='Enter your mail ID'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'email', e.target.value)}
                                                                                ></input>
                                                                                <i class="fa-solid fa-envelope text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div> 
        </div> */}

                                                                </div>


                                                                <div className='flex gap-4 mt-5 md:px-12'>
                                                                    {/* <div className='w-[50%]'>

                                                                            <Select
                                                                                value={countryOptions.find(option => option.value === personalInfo.country)}
                                                                                onChange={(selectedOption) => handleInputData('personalInfo', 'country', selectedOption)}
                                                                                options={countryOptions}
                                                                                placeholder='Country'
                                                                                styles={customStyles}
                                                                            />
                                                                        </div> */}

                                                                    <div className='md:px-2 md:pl-5  flex  md:w-[50%]'>

                                                                        <Select
                                                                            value={employmentOptions.find(option => option.value === personalInfo.contractType)}
                                                                            onChange={(selectedOption) => handleInputData('personalInfo', 'contractType', selectedOption)}
                                                                            options={employmentOptions}
                                                                            placeholder='Are you looking for ?'
                                                                            styles={customStyles}
                                                                            className='w-[100%]'
                                                                            isMulti
                                                                        />
                                                                        <span className='text-red-500 pl-1'>*</span>
                                                                    </div>

                                                                </div>
                                                                <div className='flex md:w-[80%] mx-auto'>
                                                                    <textarea type='text' className='w-[100%] rounded-[20px] outline-none bg-[#090909] h-40 border border-sky-700 inner-shadow2 p-3 text-sm' placeholder='About yourself....'
                                                                        onChange={(e) => handleInputData('personalInfo', 'description', e.target.value)}></textarea>
                                                                    <span className='text-red-500 pl-1'>*</span>
                                                                </div>


                                                                {/*  <div className='flex flex-col gap-3 px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='number' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Mobile number'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'mobileNumber', e.target.value)}  ></input>                                                                           <i class="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>

                                                                     <div className='flex flex-col gap-3 px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                           <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisible ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter Password'
                                                                                    value={personalInfo.password}
                                                                                    onChange={(e) => handleInputData('personalInfo', 'password', e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handleTogglePasswordVisibility}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>  
                                                                        </div>
                                                                    </div>  */}

                                                                {/* <div className='flex flex-col gap-3 px-12 '>
                                                                        <div className='flex justify-center'>
                                                                           <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                           <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={personalInfo.confirmpassword}
                                                                                    onChange={(e) => handleInputData('personalInfo', 'confirmpassword', e.target.value)}
                                                                                />
                                                                                {passwordsMatch ? (
                                                                                    <i className='fa-solid fa-check text-green-500 text-xl mt-3'></i>
                                                                                ) : (
                                                                                    <i className='fa-solid fa-times text-red-500 text-xl mt-3'></i>
                                                                                )}
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>  
                                                                        </div>  
                                                                    </div>  */}

                                                            </div>
                                                        </div>


                                                        <div className='md:w-[30%]'>


                                                            <Slider
                                                                className='bg-[#111111] w-[75%] md:w-[100%] mx-auto md:mx-0'
                                                                ref={sliderRef1}
                                                                {...settings1}
                                                                afterChange={(index) => handleSliderAfterChange(index)}
                                                            >
                                                                <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar1')}>
                                                                    <img className='px-3 md:ml-10' src='/assets/image 90.png' alt='Avatar 1'></img>
                                                                </div>
                                                                <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar2')}>
                                                                    <img className='px-3' src='/assets/image 90 (1).png' alt='Avatar 2'></img>
                                                                </div>
                                                                <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar3')}>
                                                                    <img className='px-3' src='/assets/image 3yr.png' alt='Avatar 3'></img>
                                                                </div>

                                                            </Slider>


                                                            <p className='text-center text-white'>Choose Avatar</p>

                                                        </div>


                                                    </div>
                                                    <div className='flex justify-center mb-5 mt-5 2xl:mt-0'>
                                                        <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
                                                            savePersonalInfo();
                                                        }}>
                                                            <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                        </button>
                                                    </div>
                                                </div>


                                                <div className='flex justify-center px-7'>
                                                    <p className='text-white text-center mt-5 font-play text-lg'>Educational information</p>
                                                    <div className="hidden md:block">
                                                        <div className='flex py-5 text-white '>
                                                            <p className='md:w-[20%] pl-3'>Highest Qualification</p>
                                                            <p className='md:w-[12.5%]'>CGPA</p>
                                                            <p className='md:w-[27.5%]'>Institution</p>
                                                            <p className='md:w-[12.5%] pl-5'>Year</p>
                                                            <p className='md:w-[27.5%]'>Certificate</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col gap-4 md:px-2'>
                                                        {/* SSLC Section */}
                                                        <div className='flex flex-col gap-4'>
                                                            <div className='flex flex-col md:flex-row gap-4 items-center'>
                                                                <div className='w-[100%] md:w-[15%] flex'>
                                                                    <input className='w-[100%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-3 shadow-sm shadow-sky-700'
                                                                        type='text'
                                                                        value={educationInfo.sslcName}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, sslcName: e.target.value }))}

                                                                        placeholder='Qualification'
                                                                    >

                                                                    </input>
                                                                    <span className='text-red-500 pl-1'>*</span>
                                                                </div>
                                                                <div className='w-[100%] md:w-[15%] flex'>
                                                                    <input
                                                                        type='text'
                                                                        maxLength={3}
                                                                        value={educationInfo.sslcPercentage}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (!isNaN(value) && value <= 100) {
                                                                                setEducationInfo((prevInfo) => ({ ...prevInfo, sslcPercentage: value }));
                                                                            }
                                                                        }}
                                                                        className='w-[100%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        placeholder='Enter CGPA'
                                                                    />
                                                                    <span className='text-red-500 pl-1'>*</span>
                                                                </div>
                                                                <div className='w-[100%] md:w-[30%] flex'>
                                                                    <input
                                                                        type='text'
                                                                        value={educationInfo.sslcInstitution}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, sslcInstitution: e.target.value }))}
                                                                        className='w-[100%] text-white  bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5'
                                                                        placeholder='Enter the Institution Name'
                                                                    />
                                                                    <span className='text-red-500 pl-1'>*</span>
                                                                </div>
                                                                <div className='w-[100%] md:w-[10%] flex'>
                                                                    <input
                                                                        type='number'
                                                                        maxLength={4}
                                                                        value={educationInfo.sslcYear}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, sslcYear: e.target.value }))}
                                                                        placeholder='Year'
                                                                        className='w-[100%] text-white bg-[#090909] shadow-sm shadow-sky-700 rounded-full outline-none border border-sky-700 text-sm py-2 pl-5'
                                                                    />
                                                                    <span className='text-red-500 pl-1'>*</span>
                                                                </div>
                                                                <div className='w-[100%] md:w-[30%] flex'>
                                                                    <div
                                                                        onClick={handleSslcIconClick}
                                                                        className='w-[25%] py-2 cursor-pointer text-center shadow-sm shadow-sky-700 rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                    >
                                                                        <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                        <input
                                                                            type="file"
                                                                            ref={sslcFileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            onChange={handleSslcFileInputChange}
                                                                            accept=".pdf,.doc,.docx"
                                                                        />

                                                                    </div>
                                                                    {/* <span className='text-red-500 pl-1'>*</span> */}

                                                                    <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                        {educationInfo.sslcFile ? `Selected file: ${educationInfo.sslcFile.name}` : '(Upload certificate, accepted format is pdf)'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='flex md:gap-3'>
                                                              
                                                                <div className='w-[100%] md:w-[50%]  flex'>

                                                                    <textarea
                                                                        type="text"
                                                                        placeholder='Describe your Impressive achievements...'
                                                                        value={educationInfo.sslcDescription}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, sslcDescription: e.target.value }))}
                                                                        className='text-white w-full bg-[#090909] rounded-2xl outline-none border border-sky-700 shadow-sm shadow-sky-700 text-sm px-5 py-3 h-16'
                                                                    ></textarea>
                                                                    {/* <span className='text-red-500 pl-1'>*</span> */}

                                                                </div>
                                                                <div className='w-0 md:w-[10%]'></div>
                                                                <div className='w-0 md:w-[37%]'></div>
                                                                {/* <div className='w-[3%] grid content-end'>
                                                                    <i
                                                                        className={`fa-solid fa-plus text-2xl cursor-pointer ${isTogglemarks ? 'open' : ''}`}
                                                                        onClick={handlemarks}
                                                                    ></i>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className={`collapsible-content max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isTogglemarks ? 'max-h-screen' : ''}`}>
                                                            <div className='flex justify-end'>
                                                                <button className='border border-gray-500 px-2 text-gray-500 text-xs  rounded-full' onClick={handlemarks}>
                                                                    <i className='fa-solid fa-close mr-2'></i>remove
                                                                </button>
                                                            </div>
                                                            <div className='flex flex-col gap-3'>
                                                                <div className='flex flex-col md:flex-row gap-4 items-center '>
                                                                    <input className='w-[100%] md:w-[10%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        type='text'
                                                                        value={educationInfo.hscName}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, hscName: e.target.value }))}

                                                                        placeholder='Grade'
                                                                    ></input>
                                                                    <input
                                                                        type='text'
                                                                        maxLength={3}
                                                                        value={educationInfo.hscPercentage}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (!isNaN(value) && value <= 100) {
                                                                                setEducationInfo((prevInfo) => ({ ...prevInfo, hscPercentage: value }));
                                                                            }
                                                                        }}
                                                                        className='w-[100%] md:w-[20%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        placeholder='Enter your %'
                                                                    />
                                                                    <input
                                                                        type='text'
                                                                        value={educationInfo.hscInstitution}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, hscInstitution: e.target.value }))}
                                                                        placeholder='Enter the Institution Name'
                                                                        className='w-[100%] md:w-[30%] shadow-sm shadow-sky-700 text-white bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5'
                                                                    />

                                                                    <input
                                                                        type='number'
                                                                        maxLength={4}
                                                                        value={educationInfo.hscYear}
                                                                        placeholder='Year'
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, hscYear: e.target.value }))}
                                                                        className='w-[100%] md:w-[10%] text-white bg-[#090909] shadow-sm shadow-sky-700 rounded-full outline-none border border-sky-700 text-sm py-2 pl-5'
                                                                    />

                                                                    <div className='w-[100%] md:w-[30%] flex'>
                                                                        <div
                                                                            onClick={handleHscIconClick}
                                                                            className='w-[25%] py-2 cursor-pointer text-center shadow-sm shadow-sky-700 rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                        >
                                                                            <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                            <input
                                                                                type="file"
                                                                                ref={hscFileInputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleHscFileInputChange}
                                                                                accept=".pdf,.doc,.docx"
                                                                            />
                                                                        </div>
                                                                        <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                            {educationInfo.hscFile ? `Selected file: ${educationInfo.hscFile.name}` : '(Upload certificate)'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex md:gap-3'>
                                                                    <div className='w-0 md:w-[10%]'></div>
                                                                    <div className='w-[92%] md:w-[50%]'>
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder='Describe your Impressive achievements...'
                                                                            value={educationInfo.hscDescription}
                                                                            onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, hscDescription: e.target.value }))}
                                                                            className='text-white w-full bg-[#090909] rounded-2xl outline-none border border-sky-700 shadow-sm shadow-sky-700 text-sm px-5 py-3 h-16'
                                                                        ></textarea>                </div>
                                                                    <div className='w-0 md:w-[37%]'></div>
                                                                    <div className='w-[3%] grid content-end'>
                                                                        <i
                                                                            className={`fa-solid fa-plus text-2xl cursor-pointer ${isTogglemarks2 ? 'open' : ''}`}
                                                                            onClick={handlemarks2}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className={`collapsible-content max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isTogglemarks2 ? 'max-h-screen' : ''}`}>
                                                            <div className='flex justify-end'>
                                                                <button className='border border-gray-500 px-2 text-gray-500 text-xs  rounded-full' onClick={handlemarks2}>
                                                                    <i className='fa-solid fa-close mr-2'></i>remove
                                                                </button>
                                                            </div>
                                                            <div className='flex flex-col gap-3'>
                                                                <div className='flex flex-col md:flex-row gap-4 items-center '>
                                                                    <input className='w-[100%] md:w-[10%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        type='text'
                                                                        value={educationInfo.cgpaInstitutionName}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaName: e.target.value }))}

                                                                        placeholder='Grade'
                                                                    ></input>
                                                                    <input
                                                                        type='text'
                                                                        maxLength={3}
                                                                        value={educationInfo.cgpaPercentage}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (!isNaN(value) && value <= 100) {
                                                                                setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaPercentage: value }));
                                                                            }
                                                                        }}
                                                                        className='w-[100%] md:w-[20%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        placeholder='Enter your %'
                                                                    />
                                                                    <input
                                                                        type='text'
                                                                        value={educationInfo.cgpaInstitution}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaInstitution: e.target.value }))}
                                                                        placeholder='Enter the Institution Name'
                                                                        className='w-[100%] md:w-[30%] text-white bg-[#090909] rounded-full outline-none shadow-sm shadow-sky-700 border border-sky-700 text-sm py-2 pl-5'
                                                                    />
                                                                    <input
                                                                        type='number'
                                                                        maxLength={4}
                                                                        value={educationInfo.cgpaYear}
                                                                        placeholder='Year'
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaYear: e.target.value }))}
                                                                        className='w-[100%] md:w-[10%] text-white bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                    />
                                                                    <div className='w-[100%] md:w-[30%] flex'>
                                                                        <div
                                                                            onClick={handleCgpaIconClick}
                                                                            className='w-[25%] py-2 cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909] shadow-sm shadow-sky-700'
                                                                        >
                                                                            <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                            <input
                                                                                type="file"
                                                                                ref={cgpaFileInputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleCgpaFileInputChange}
                                                                                accept=".pdf,.doc,.docx"
                                                                            />
                                                                        </div>
                                                                        <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                            {educationInfo.cgpaFile ? `Selected file: ${educationInfo.cgpaFile.name}` : '(Upload certificate)'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex md:gap-3'>
                                                                    <div className='w-0 md:w-[10%]'></div>
                                                                    <div className='w-[92%] md:w-[50%]'>
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder='Describe your Impressive achievements...'
                                                                            value={educationInfo.cgpaDescription}
                                                                            onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaDescription: e.target.value }))}
                                                                            className='text-white w-full bg-[#090909] rounded-2xl outline-none border border-sky-700 shadow-sm shadow-sky-700 text-sm px-5 py-3 h-16'
                                                                        ></textarea>                </div>
                                                                    <div className='w-0 md:w-[37%]'></div>
                                                                    <div className='w-[3%] grid content-end'>
                                                                        <i
                                                                            className={`fa-solid fa-plus text-2xl cursor-pointer ${isTogglemarks2 ? 'open' : ''}`}
                                                                            onClick={handlemarks3}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
{/* 
                                                        <div className={`collapsible-content max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isTogglemarks3 ? 'max-h-screen' : ''}`}>
                                                            <div className='flex justify-end'>
                                                                <button className='border border-gray-500 px-2 text-gray-500 text-xs  rounded-full' onClick={handlemarks3}>
                                                                    <i className='fa-solid fa-close mr-2'></i>remove
                                                                </button>
                                                            </div>
                                                            <div className='flex flex-col gap-3'>
                                                                <div className='flex flex-col md:flex-row gap-4 items-center '>
                                                                    <input className='w-[100%] md:w-[10%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        type='text'
                                                                        value={educationInfo.pgName}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, pgName: e.target.value }))}

                                                                        placeholder='Grade'
                                                                    ></input>
                                                                    <input
                                                                        type='text'
                                                                        maxLength={3}
                                                                        value={educationInfo.pgPercentage}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (!isNaN(value) && value <= 100) {
                                                                                setEducationInfo((prevInfo) => ({ ...prevInfo, pgPercentage: value }));
                                                                            }
                                                                        }}
                                                                        className='w-[100%] md:w-[20%] text-white px-2 bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                        placeholder='Enter your %'
                                                                    />
                                                                    <input
                                                                        type='text'
                                                                        value={educationInfo.pgInstitution}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, pgInstitution: e.target.value }))}
                                                                        placeholder='Enter the Institution Name'
                                                                        className='w-[100%] md:w-[30%] text-white bg-[#090909] rounded-full outline-none shadow-sm shadow-sky-700 border border-sky-700 text-sm py-2 pl-5'
                                                                    />

                                                                    <input
                                                                        type='number'
                                                                        maxLength={4}
                                                                        placeholder='Year'
                                                                        value={educationInfo.pgYear}
                                                                        onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, pgYear: e.target.value }))}
                                                                        className='w-[100%] md:w-[10%] text-white bg-[#090909] rounded-full outline-none border border-sky-700 text-sm py-2 pl-5 shadow-sm shadow-sky-700'
                                                                    />

                                                                    <div className='w-[100%] md:w-[30%] flex'>
                                                                        <div
                                                                            onClick={handlePgIconClick}
                                                                            className='w-[25%] py-2 cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909] shadow-sm shadow-sky-700'
                                                                        >
                                                                            <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                            <input
                                                                                type="file"
                                                                                ref={pgFileInputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={handlePgFileInputChange}
                                                                                accept=".pdf,.doc,.docx"
                                                                            />
                                                                        </div>
                                                                        <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                            {educationInfo.pgFile ? `Selected file: ${educationInfo.pgFile.name}` : '(Upload certificate)'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='flex md:gap-3'>
                                                                    <div className='w-0 md:w-[10%]'></div>
                                                                    <div className='w-[100%] md:w-[50%]'>
                                                                        <textarea
                                                                            type="text"
                                                                            placeholder='Describe your Impressive achievements...'
                                                                            value={educationInfo.cgpaDescription}
                                                                            onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaDescription: e.target.value }))}
                                                                            className='text-white w-full bg-[#090909] rounded-2xl outline-none border border-sky-700 shadow-sm shadow-sky-700 text-sm px-5 py-3 h-16'
                                                                        ></textarea>                </div>
                                                                    <div className='w-0 md:w-[40%]'></div>
                                                                </div>
                                                            </div>
                                                        </div> */}



                                                    </div>
                                                    <div className='flex justify-center mt-3 pb-7'>
                                                        <button type="button">
                                                            <button
                                                                type="button"
                                                                className='flex pt-3 mt-20 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white'
                                                                onClick={handleEducationSubmit}>
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                                <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center py-4'>
                                                    <p className='text-white text-center mt-1 font-play text-lg'>Skills & Tools</p>
                                                    <div className='flex flex-col-reverse md:flex-row justify-center gap-5 mt-10 w-[90%] md:w-[100%] mx-auto md:mx-0'>
                                                        <div className='md:w-[45%] flex'>
                                                            <div className='w-[100%] px-5 rounded-2xl py-4 bg-[#090909] border border-dashed border-sky-900 inner-shadow2'>
                                                                <div className='flex justify-center'>
                                                                    <img
                                                                        src='/assets/Upload.png'
                                                                        className='w-[3.5rem] cursor-pointer'
                                                                        onClick={handleResumeIconClick}
                                                                        alt="Upload"
                                                                    />
                                                                </div>
                                                                <p className='text-center text-gray-500 mt-3'>Upload Your Resume</p>
                                                                <input
                                                                    type="file"
                                                                    ref={resumeFileInputRef}
                                                                    onChange={(e) => setSkillsInfo({ ...skillsInfo, resume: e.target.files[0] })}
                                                                    style={{ display: 'none' }}
                                                                    accept=".pdf,.doc,.docx"
                                                                />
                                                                <p className='text-center text-gray-500 mt-3'>(The file should be less than 5 mb, accepted format is pdf, doc, docx)</p>
                                                            </div>
                                                            {/* <span className='text-red-500 pl-1'>*</span> */}
                                                        </div>
                                                        <div className='md:w-[45%] flex'>
                                                            <div className='w-[100%] px-5 rounded-2xl py-4 text-sm inner-shadow2 bg-[#090909]  grid content-center gap-5 border border-sky-900  '>
                                                                <div className='flex justify-center gap-5'>
                                                                    <div className='md:w-[35%] flex flex-col gap-3'>
                                                                        <Select
                                                                            inputValueSkill={inputValueSkill}
                                                                            onInputChange={(value) => setInputValueSkill(value)}
                                                                            value={null}
                                                                            onChange={handleSkill}
                                                                            options={SkillOptions}
                                                                            placeholder='Add Skills'
                                                                            styles={customStylesJob}
                                                                        />
                                                                        <div className="h-[6rem] overflow-y-scroll">
                                                                            <ul className='flex flex-col text-center gap-3 mt-2'>
                                                                                {selectedSkill.map((option, index) => (
                                                                                    <li className='rounded-full text-xs px-3 py-1 bg-[#00768b]' key={index}>
                                                                                        {option.label}
                                                                                        <span
                                                                                            className='ml-1'
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => removeOption2(index)}>
                                                                                            &#x2715;
                                                                                        </span>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='md:w-[55%] h-[9rem] overflow-y-scroll scrollbar'>
                                                                        {selectedSkill.map((option, index) => (
                                                                            <div key={index}>
                                                                                <p>{option.label}</p>
                                                                                <div className='flex items-center'>
                                                                                    <input
                                                                                        type='range'
                                                                                        min='0'
                                                                                        max='100'
                                                                                        className='w-full'
                                                                                        value={option.percentage || 0}
                                                                                        onChange={(e) => handleSkillPercentageChange(index, parseInt(e.target.value))}
                                                                                    />
                                                                                    <p className='ml-2'>{option.percentage || 0}%</p> {/* Display the selected percentage */}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className='text-red-500 pl-1'>*</span>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col md:flex-row justify-center gap-5 mt-[2rem] w-[90%] md:w-[100%] mx-auto md:mx-0'>
                                                        <div className='md:w-[45%] flex'>
                                                            <div className='w-[100%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-900 inner-shadow2 '>
                                                                {/*
                                                            {!skillsInfo.resume ? (
                                                                <p className='text-center '>Preview</p>
                                                            ) : (
                                                                <div className='py-1 flex justify-between items-center'>
                                                                    <p className='text-gray-400'>
                                                                        Selected Resume:&nbsp;<span className='text-white'>{skillsInfo.resume.name}</span>
                                                                    </p>
                                                                    <i
                                                                        className="fa-solid fa-xmark cursor-pointer"
                                                                        style={{ color: "#f7f7f8" }}
                                                                        onClick={handleResumeRemove}
                                                                    ></i>
                                                                </div>
                                                            )}
                                                            */}

                                                                <p className='text-center'>Preview</p>
                                                                {skillsInfo.resume && (
                                                                    <div className='py-1 flex justify-between items-center'>
                                                                        <p className='text-gray-400'>Selected Resume:&nbsp;<span className='text-white '>{skillsInfo.resume.name}</span></p>
                                                                        <i
                                                                            className="fa-solid fa-xmark cursor-pointer"
                                                                            style={{ color: "#f7f7f8" }}
                                                                            onClick={handleResumeRemove}
                                                                        ></i>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <span className='text-[#090909]/0 pl-1'>*</span>
                                                        </div>
                                                        <div className='md:w-[45%] flex'>
                                                            <div className='w-[100%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-900 inner-shadow2 '>
                                                                <div className='md:w-[45%] '>
                                                                    <Select
                                                                        inputValueJob={setInputValueJob}
                                                                        onInputChange={(value) => setInputValueJob(value)}
                                                                        value={null}
                                                                        onChange={handleJobRole}
                                                                        options={JobOptions}
                                                                        placeholder='Preferred Roles'
                                                                        styles={customStylesJob}
                                                                    />
                                                                </div>

                                                                <div className='w-[100%] flex justify-center overflow-y-scroll '>
                                                                    <div className='mt-3'>
                                                                        <p>Selected Options:</p>
                                                                        <ul className='flex flex-col md:flex-row  gap-3 mt-2'>
                                                                            {selectedJob.map((option, index) => (
                                                                                <li className='rounded-full flex justify-between text-xs px-3 py-1 bg-[#00768b] ' key={index}> {option.label}
                                                                                    <span
                                                                                        className='ml-1'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => removeOption(index)}>
                                                                                        &#x2715;
                                                                                    </span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className='text-red-500 pl-1'>*</span>
                                                        </div>
                                                    </div>
                                                    {/*<div  className='flex justify-center gap-5'> <div className='w-[45%]'><textarea
                                                    type="text"
                                                    placeholder='Describe About Your Skills And Hobbies '
                                                    // value={educationInfo.hscDescription}
                                                    // onChange={(e) => setEducationInfo((prevInfo) => ({ ...prevInfo, hscDescription: e.target.value }))}
                                                    className='text-white inner-shadow2 w-full bg-[#090909] rounded-2xl outline-none border border-sky-700 shadow-sm shadow-sky-700 text-sm px-5 py-3 h-24 mt-[2rem] '
                                                                    ></textarea>   </div><div  className='w-[45%]'></div></div>*/}

                                                    <div className='flex justify-center mt-10'>

                                                        <button
                                                            type="button"

                                                        // onClick={handleNext}
                                                        >

                                                            <button
                                                                type="button"
                                                                className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white'
                                                                onClick={handleSkillInfoSubmit}
                                                            >
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                                <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        </button>


                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center p-5'>
                                                    <p className='text-white text-center text-lg font-play '>Projects<span className5='text-[#FFBA00] text-sm ml-2'>( Optional )</span></p>
                                                    <div className='flex flex-col md:flex-row mt-10  gap-3 md:gap-0 '>
                                                        <div className='flex flex-col  gap-3 md:gap-7 md:w-[50%] md:pl-10 '>
                                                            <div className='md:w-[90%]'>
                                                                <input type='number'
                                                                    className='w-[100%] text-white pl-5 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840] outline-none'
                                                                    placeholder='Total number of Projects Completed... (eg: 5)'
                                                                    value={projectInfo.project.no}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (!isNaN(value) && value <= 100) {
                                                                            handleProjectInputChange(event, 'project', 'no');
                                                                        }
                                                                    }}
                                                                    min="0"
                                                                    max="100"
                                                                />
                                                            </div>

                                                            <div className='md:w-[90%]'>
                                                                <input
                                                                    type='text'
                                                                    className='w-[100%] text-white outline-none pl-5 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Enter your Project name'
                                                                    value={projectInfo.project1.name}
                                                                    onChange={(e) => handleProjectInputChange(e, 'project1', 'name')}
                                                                />                                                            </div>
                                                            <div className='flex gap-5'>
                                                                <textarea
                                                                    className='w-[100%] md:w-[90%] bg-[#090909] text-white outline-none p-5 h-[9rem] border-2 rounded-2xl border-[#0a3840]'
                                                                    placeholder='About the project...'
                                                                    value={projectInfo.project1.description}
                                                                    onChange={(e) => handleProjectInputChange(e, 'project1', 'description')}
                                                                ></textarea>                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-10'>
                                                            <div className='flex  text-white px-2 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                <input
                                                                    type='text'
                                                                    className='w-[100%] bg-transparent pl-5 outline-none'
                                                                    placeholder='Add keywords...   (eg: Html, React, Node)'
                                                                    value={projectInfo.project1.keywords}
                                                                    onChange={(e) => handleProjectInputChange(e, 'project1', 'keywords')}
                                                                />

                                                            </div>
                                                            <div className='flex  text-white px-2 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                <input
                                                                    type='text'
                                                                    className='w-[100%] bg-transparent pl-5 outline-none'
                                                                    placeholder='Link (optional)'
                                                                    value={projectInfo.project1.link}
                                                                    onChange={(e) => handleProjectInputChange(e, 'project1', 'link')}
                                                                />                                                            </div>
                                                            <div className=' flex justify-between'>
                                                                <div className='flex gap-3'>
                                                                    <div
                                                                        onClick={() => handleProjectIconClick('project1')}
                                                                        className='w-24 py-2 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                    >
                                                                        <i className='fa-solid fa-arrow-up-from-bracket' style={{ cursor: 'pointer' }}></i>
                                                                        <input
                                                                            type='file'
                                                                            ref={ProjectFileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            onChange={(event) => handleProjectFileInputChange(event, 'project1')}
                                                                            accept=".pdf,.doc,.docx"
                                                                        />
                                                                    </div>
                                                                    <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                        {ProjectFile ? `Selected file: ${ProjectFile.name}` : '(upload project regarding Files if you have, accepted format is pdf, doc, docx )'}
                                                                    </p>
                                                                </div>
                                                                <div className='flex items-end'>
                                                                    <i
                                                                        className={`fa-solid fa-plus text-2xl cursor-pointer ${isToggleOpen ? 'open' : ''}`}
                                                                        onClick={handleToggleOpen}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={`collapsible-content max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggleOpen ? 'max-h-screen' : ''}`}>
                                                            <div className='flex justify-end pt-3'>
                                                                <button className='border border-gray-500 px-2 text-gray-500 text-xs  rounded-full' onClick={handleToggleOpen}>
                                                                    <i className='fa-solid fa-close mr-2'></i>remove
                                                                </button>
                                                            </div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:pl-10 pt-5'>
                                                                    <div className='md:w-[90%]'>
                                                                        <input
                                                                            type='text'
                                                                            className='w-[100%] text-white pl-5 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Enter your Project name'
                                                                            value={projectInfo.project2.name}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project2', 'name')}
                                                                        />                                                                    </div>
                                                                    <div className='flex gap-5'>
                                                                        <textarea
                                                                            className='md:w-[90%] bg-[#090909] text-white outline-none p-3 h-32 border-2 rounded-2xl border-[#0a3840]'
                                                                            placeholder='About the project...'
                                                                            value={projectInfo.project2.description}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project2', 'description')}
                                                                        ></textarea>                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col md:pt-5 gap-3 md:gap-7 md:w-[50%] md:px-10'>
                                                                    <div className='flex  text-white px-2 py-2 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                        <input
                                                                            type='text'
                                                                            className='md:w-[90%] bg-transparent pl-5 outline-none'
                                                                            placeholder='Add keywords...   (eg: Html, React, Node)'
                                                                            value={projectInfo.project2.keywords}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project2', 'keywords')}
                                                                        />
                                                                    </div>
                                                                    <div className='flex  text-white px-2 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                        <input
                                                                            type='text'
                                                                            className='w-[100%] bg-transparent pl-5 outline-none'
                                                                            placeholder='Link (optional)'
                                                                            value={projectInfo.project2.link}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project2', 'link')}
                                                                        />                                                                    </div>
                                                                    <div className=' flex justify-between'>
                                                                        <div >
                                                                            <div className='flex'>
                                                                                <div
                                                                                    onClick={() => handleProject2IconClick('project2')}
                                                                                    className='w-24 py-2 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                                >
                                                                                    <i className='fa-solid fa-arrow-up-from-bracket' style={{ cursor: 'pointer' }}></i>
                                                                                    <input
                                                                                        type='file'
                                                                                        ref={Project2FileInputRef}
                                                                                        style={{ display: 'none' }}
                                                                                        onChange={(event) => handleProject2FileInputChange(event, 'project2')}
                                                                                        accept=".pdf,.doc,.docx"
                                                                                    />
                                                                                </div>
                                                                                <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                                    {Project2File ? `Selected file: ${Project2File.name}` : '(upload project regarding Files if you have, accepted format is pdf, doc, docx)'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <i
                                                                                className={`fa-solid fa-plus text-2xl cursor-pointer ${isToggle2Open ? 'open' : ''}`}
                                                                                onClick={handleToggle2Open}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`collapsible-content max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggle2Open ? 'max-h-screen' : ''}`}>
                                                            <div className='flex justify-end pt-3'>
                                                                <button className='border border-gray-500 px-2 text-gray-500 text-xs  rounded-full' onClick={handleToggle2Open}>
                                                                    <i className='fa-solid fa-close mr-2'></i>remove
                                                                </button>
                                                            </div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:pl-10 pt-5'>
                                                                    <div className='md:w-[90%]'>
                                                                        <input
                                                                            type='text'
                                                                            className='w-[100%] text-white pl-5 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Enter your Project name'
                                                                            value={projectInfo.project3.name}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project3', 'name')}
                                                                        />                                                                    </div>
                                                                    <div className='flex gap-5'>
                                                                        <textarea
                                                                            className='w-[100%] md:w-[90%] bg-[#090909] text-white outline-none p-3 h-32 border-2 rounded-2xl border-[#0a3840]'
                                                                            placeholder='About the project...'
                                                                            value={projectInfo.project3.description}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project3', 'description')}
                                                                        ></textarea>                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col md:pt-5 gap-3 md:gap-7 md:w-[50%] md:px-10'>
                                                                    <div className='flex  text-white px-2 py-2 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                        <input
                                                                            type='text'
                                                                            className='w-[100%] md:w-[90%] bg-transparent pl-5 outline-none'
                                                                            placeholder='Add keywords...   (eg: Html, React, Node)'
                                                                            value={projectInfo.project3.keywords}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project3', 'keywords')}
                                                                        />
                                                                    </div>
                                                                    <div className='flex  text-white px-2 py-2.5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'>
                                                                        <input
                                                                            type='text'
                                                                            className='w-[100%] bg-transparent pl-5 outline-none'
                                                                            placeholder='Link (optional)'
                                                                            value={projectInfo.project3.link}
                                                                            onChange={(e) => handleProjectInputChange(e, 'project3', 'link')}
                                                                        />                                                                    </div>
                                                                    <div className=' flex justify-between'>
                                                                        <div >
                                                                            <div className=' flex'>
                                                                                <div
                                                                                    onClick={() => handleProject3IconClick('project3')}
                                                                                    className='w-24 py-2 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                                >
                                                                                    <i className='fa-solid fa-arrow-up-from-bracket' style={{ cursor: 'pointer' }}></i>
                                                                                    <input
                                                                                        type='file'
                                                                                        ref={Project3FileInputRef}
                                                                                        style={{ display: 'none' }}
                                                                                        onChange={(event) => handleProject3FileInputChange(event, 'project3')}
                                                                                        accept=".pdf,.doc,.docx"
                                                                                    />
                                                                                </div>
                                                                                <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                                    {Project3File ? `Selected file: ${Project3File.name}` : '(upload project regarding Files if you have, accepted format is pdf, doc, docx)'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='hidden md:block'><img className='absolute  translate-x-[37.5rem] 2xl:translate-x-[55rem] translate-y-10' src="/assets/avatarcom.png"></img></div>

                                                    <div className='flex  justify-center mt-24'>

                                                        <button
                                                            type="button"

                                                        // onClick={handleNext}
                                                        >

                                                            <button
                                                                type="button"
                                                                className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white'
                                                                onClick={handleProjectInfoSubmit}
                                                            >
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                                <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        </button>

                                                    </div>
                                                </div>


                                                <div className='flex justify-center p-5'>
                                                    <p className='text-white text-center text-lg font-play '>Certificates<span className='text-[#FFBA00] text-sm ml-2'>( Optional )</span></p>
                                                    <div className='flex justify-center'>
                                                        <Spinners isVisible={isLoading} />
                                                    </div>
                                                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 mt-10'>
                                                        <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-10 '>
                                                            <div >
                                                                <input
                                                                    className='w-[100%] text-white py-2.5 pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Enter your certificate name'
                                                                    onChange={(event) => handleCertificateInputChange(event, 'certificate1', 'name')}
                                                                />                                                            </div>
                                                            <div>
                                                                <input
                                                                    className='w-[100%] py-2.5 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Source of Obtaining'
                                                                    onChange={(event) => handleCertificateInputChange(event, 'certificate1', 'source')}
                                                                />                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-5 md:pr-10'>
                                                            <div>
                                                                <input
                                                                    className='w-[100%] py-2 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Link (optional)'
                                                                    onChange={(event) => handleCertificateInputChange(event, 'certificate1', 'link')}
                                                                />                                                            </div>
                                                            <div className='flex justify-between'>
                                                                <div className='flex'>
                                                                    <div
                                                                        onClick={() => handleCeritificateIconClick('certificate1')}
                                                                        className='w-20 py-2.5 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                    >
                                                                        <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                        <input
                                                                            type="file"
                                                                            ref={CeritificateFileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            onChange={(event) => handleCeritificateFileInputChange(event, 'certificate1')}
                                                                            accept=".pdf,.doc,.docx"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                        {CeritificateFile ? `Selected file: ${CeritificateFile.name}` : '(upload course ceritificate, accepted format is pdf, doc, docx)'}
                                                                    </p>
                                                                </div>
                                                                <i className={`fa-solid fa-plus text-2xl mt-1 ml-14 cursor-pointer ${isToggleDrawer ? 'open' : ''}`}
                                                                    onClick={handleToggleDrawer}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={`collapsible-content flex flex-col max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggleDrawer ? 'max-h-screen' : ''}`}>
                                                            <div className="flex justify-end pt-3"><button className="border border-gray-500 px-2 text-gray-500 text-xs  rounded-full" onClick={handleToggleDrawer}><i className="fa-solid fa-close mr-2"></i>remove</button></div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-10 pt-5'>
                                                                    <div >
                                                                        <input
                                                                            className='w-[100%] text-white py-2.5 pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Enter your certificate name'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate2', 'name')}
                                                                        />                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            className='w-[100%] py-2.5 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Source of Obtaining'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate2', 'source')}
                                                                        />                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-5 md:pr-10 md:pt-5'>

                                                                    <div>
                                                                        <input
                                                                            className='w-[100%] py-2 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Link (optional)'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate2', 'link')}
                                                                        />                                                                    </div>
                                                                    <div className='flex justify-between'>
                                                                        <div className='flex'>
                                                                            <div
                                                                                onClick={() => handleCeritificate2IconClick('certificate2')}
                                                                                className='w-20 py-2 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                            >
                                                                                <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                                <input
                                                                                    type="file"
                                                                                    ref={Ceritificate2FileInputRef}
                                                                                    style={{ display: 'none' }}
                                                                                    onChange={(event) => handleCeritificate2FileInputChange(event, 'certificate2')}
                                                                                    accept=".pdf,.doc,.docx"
                                                                                />
                                                                            </div>
                                                                            <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                                {Ceritificate2File ? `Selected file: ${Ceritificate2File.name}` : '(upload course ceritificate, accepted format is pdf, doc, docx)'}
                                                                            </p></div>
                                                                        <i className={`fa-solid fa-plus text-2xl mt-1 ml-14 cursor-pointer ${isToggle2Drawer ? 'open' : ''}`}
                                                                            onClick={handleToggle2Drawer}></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`collapsible-content flex flex-col max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggle2Drawer ? 'max-h-screen' : ''}`}>
                                                            <div className="flex justify-end pt-3"><button className="border border-gray-500 px-2 text-gray-500 text-xs  rounded-full" onClick={handleToggle2Drawer}><i className="fa-solid fa-close mr-2"></i>remove</button></div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-10 pt-5'>
                                                                    <div >
                                                                        <input
                                                                            className='w-[100%] text-white py-2.5 pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Enter your certificate name'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate3', 'name')}
                                                                        />                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            className='w-[100%] py-2.5 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Source of Obtaining'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate3', 'source')}
                                                                        />                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col gap-3 md:gap-7 md:w-[50%] md:px-5 md:pr-10 md:pt-5'>
                                                                    <div>
                                                                        <input
                                                                            className='w-[100%] py-2 text-white pl-5 bg-[#090909] rounded-2xl border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                            placeholder='Link (optional)'
                                                                            onChange={(event) => handleCertificateInputChange(event, 'certificate3', 'link')}
                                                                        />                                                                    </div>
                                                                    <div className='flex'>
                                                                        <div
                                                                            onClick={() => handleCeritificate3IconClick('certificate3')}
                                                                            className='w-20 py-2 flex justify-center items-center cursor-pointer text-center rounded-full border border-dashed border-sky-700 bg-[#090909]'
                                                                        >
                                                                            <i className="fa-solid fa-arrow-up-from-bracket" style={{ cursor: 'pointer' }}></i>
                                                                            <input
                                                                                type="file"
                                                                                ref={Ceritificate3FileInputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={(event) => handleCeritificate3FileInputChange(event, 'certificate3')}
                                                                                accept=".pdf,.doc,.docx"
                                                                            />
                                                                        </div>
                                                                        <p className='text-xs mt-3 ml-3 text-gray-400'>
                                                                            {Ceritificate3File ? `Selected file: ${Ceritificate3File.name}` : '(upload course ceritificate, accepted format is pdf, doc, docx)'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-10'>

                                                        <p className='text-white text-center text-lg font-play '>Experience<span className='text-[#FFBA00] text-sm ml-2'>( Optional )</span></p>

                                                        <div className='flex flex-col md:flex-row gap-5 justify-center pt-5 md:px-5'>
                                                            <div className='md:w-[35%]'>
                                                                <input
                                                                    type='number'
                                                                    className='w-[100%] text-white px-2 py-2 outline-none bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Total No of Years Experience... (eg: 5)'

                                                                    value={certificateandexpInfo.experienceYears.no}
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        if (!isNaN(value) && value <= 100) {
                                                                            handleExperienceInputChange(event, 'experienceYears', 'no');
                                                                        }
                                                                    }}
                                                                    min="0"
                                                                    max="100"
                                                                />

                                                            </div>
                                                            <div className='hidden md:block w-[35%]'></div>
                                                            <div className='hidden md:block w-[10%]'></div>
                                                            <div className='hidden md:block w-[10%]'></div>

                                                        </div>
                                                        <div className='flex flex-col md:flex-row gap-3 md:gap-5 justify-center pt-3 md:pt-5 md:px-10'>
                                                            <div className='md:w-[55%] bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] pl-3 flex'>
                                                                <i class="fa-solid fa-building-user mt-2.5"></i>
                                                                <input
                                                                    type='text'
                                                                    className='w-[80%] text-white px-2 py-2 rounded-lg bg-transparent outline-none'
                                                                    placeholder='Enter your Organization name'
                                                                    onChange={(event) => handleExperienceInputChange(event, 'experience1', 'companyname')}
                                                                />
                                                            </div>
                                                            <div className='md:w-[45%]'>
                                                                <input
                                                                    type='text'
                                                                    className='w-[100%] text-white px-2 py-2 outline-none bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                    placeholder='Enter your Role'
                                                                    onChange={(event) => handleExperienceInputChange(event, 'experience1', 'role')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col md:flex-row gap-3 md:gap-5 justify-center pt-3 md:pt-5 md:pl-5'>
                                                            <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                <DatePicker
                                                                    //  selected={inputFrom.dob ? new Date(inputFrom.dob) : null}
                                                                    selected={inputFrom}
                                                                    onChange={(date) => handleInputFrom(date, 'experience1', 'dob')}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="From"
                                                                    className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                    maxDate={new Date()}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    dropdownMode="select"
                                                                    wrapperClassName="custom-datepicker-wrapper"
                                                                    popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                />
                                                                <i className="fa-regular fa-calendar text-white mt-3 pr-3 w-[10%]"></i>
                                                            </div>
                                                            <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                <DatePicker
                                                                    selected={inputTo}
                                                                    onChange={handleInputTo}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="To"
                                                                    className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                    maxDate={new Date()}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    dropdownMode="select"
                                                                    wrapperClassName="custom-datepicker-wrapper"
                                                                    popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                />
                                                                <i className="fa-regular fa-calendar text-white mt-2.5 w-[10%] "></i>

                                                            </div>
                                                            <div className='md:w-[20%] flex justify-between md:justify-start'>
                                                                <div className='flex items-center'>
                                                                    <p className='text-[#FFBA00] font-play md:pr-7'>- or -</p>
                                                                </div>
                                                                <div className='flex'>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={ongoing}
                                                                        onChange={handleOngoingChange}
                                                                        className="form-checkbox h-5 w-5 text-white mt-2 md:mt-3"
                                                                    />
                                                                    <div className='flex items-center'>
                                                                        <label className="ml-1 text-white">Ongoing</label>
                                                                    </div>
                                                                </div>
                                                                <div className='w-[10%] block md:hidden'>
                                                                    <i className={`fa-solid fa-plus text-2xl mt-1  cursor-pointer ${isToggleEXP ? 'open' : ''}`}
                                                                        onClick={handleToggleEXP}></i>
                                                                </div>
                                                            </div>
                                                            <div className='w-[10%] md:block hidden'>
                                                                <i className={`fa-solid fa-plus text-2xl mt-1 ml-5 cursor-pointer ${isToggleEXP ? 'open' : ''}`}
                                                                    onClick={handleToggleEXP}></i>
                                                            </div>
                                                        </div>
                                                        <div className={`collapsible-content flex flex-col max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggleEXP ? 'max-h-screen' : ''}`}>
                                                            <div className="flex justify-end pt-3"><button className="border border-gray-500 px-2 text-gray-500 text-xs  rounded-full" onClick={handleToggleEXP}><i className="fa-solid fa-close mr-2"></i>remove</button></div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-5 md:px-10 justify-center pt-5'>
                                                                <div className='md:w-[55%] bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] pl-3 flex'>
                                                                    <i class="fa-solid fa-building-user mt-2.5"></i>
                                                                    <input
                                                                        type='text'
                                                                        className='w-[80%] text-white px-2 py-2 rounded-lg bg-transparent outline-none'
                                                                        placeholder='Enter your Organization name'
                                                                        onChange={(event) => handleExperienceInputChange(event, 'experience2', 'companyname')}
                                                                    />                                                                </div>

                                                                <div className='md:w-[45%]'>
                                                                    <input
                                                                        type='text'
                                                                        className='w-[100%] text-white px-2 py-2 outline-none bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                        placeholder='Enter your Role'
                                                                        onChange={(event) => handleExperienceInputChange(event, 'experience2', 'role')}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-5 md:pl-5 justify-center pt-3 md:pt-5'>
                                                                <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                    <DatePicker
                                                                        selected={inputFrom2}
                                                                        onChange={(date) => handleInputFrom2(date)}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="From"
                                                                        className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        wrapperClassName="custom-datepicker-wrapper"
                                                                        popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                    />
                                                                    <i className="fa-regular fa-calendar text-white mt-2.5 w-[10%] pr-3"></i>
                                                                </div>
                                                                <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                    <DatePicker
                                                                        selected={inputTo2}
                                                                        onChange={handleInputTo2}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="To"
                                                                        className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        wrapperClassName="custom-datepicker-wrapper"
                                                                        popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                        disabled={ongoing2}
                                                                    />
                                                                    <i className="fa-regular fa-calendar text-white mt-2.5 w-[10%] pr-3"></i>
                                                                </div>
                                                                <div className='md:w-[20%] flex justify-between md:justify-start'>
                                                                    <div className='flex items-center'>
                                                                        <p className='text-[#FFBA00] font-play md:pr-7'>- or -</p>
                                                                    </div>
                                                                    <div className='flex'>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={ongoing2}
                                                                            onChange={handleOngoingChange2}
                                                                            className="form-checkbox h-5 w-5 text-white mt-2 md:mt-3"
                                                                        />
                                                                        <div className='flex items-center'>
                                                                            <label className="ml-1 text-white">Ongoing</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-[10%] block md:hidden'>
                                                                        <i className={`fa-solid fa-plus text-2xl cursor-pointer ${isToggle2EXP ? 'open' : ''}`}
                                                                            onClick={handleToggle2EXP}></i>
                                                                    </div>
                                                                </div>
                                                                <div className='w-[10%] hidden md:block'>
                                                                    <i className={`fa-solid fa-plus text-2xl mt-1 ml-5 cursor-pointer ${isToggle2EXP ? 'open' : ''}`}
                                                                        onClick={handleToggle2EXP}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`collapsible-content flex flex-col max-h-0 overflow-hidden transition-max-h duration-500 ease-in-out ${isToggle2EXP ? 'max-h-screen' : ''}`}>
                                                            <div className="flex justify-end pt-3"><button className="border border-gray-500 px-2 text-gray-500 text-xs  rounded-full" onClick={handleToggle2EXP}><i className="fa-solid fa-close mr-2"></i>remove</button></div>

                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-5 md:px-10 justify-center pt-5'>
                                                                <div className='md:w-[55%] bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] pl-3 flex'>
                                                                    <i class="fa-solid fa-building-user mt-2.5"></i>
                                                                    <input
                                                                        type='text'
                                                                        className='w-[80%] text-white px-2 py-2 rounded-lg bg-transparent outline-none'
                                                                        placeholder='Enter your Organization name'
                                                                        onChange={(event) => handleExperienceInputChange(event, 'experience3', 'companyname')}
                                                                    />

                                                                </div>

                                                                <div className='md:w-[45%]'>
                                                                    <input
                                                                        type='text'
                                                                        className='w-[100%] text-white px-2 py-2 outline-none bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]'
                                                                        placeholder='Enter your Role'
                                                                        onChange={(event) => handleExperienceInputChange(event, 'experience3', 'role')}
                                                                    />

                                                                </div>

                                                            </div>
                                                            <div className='flex flex-col md:flex-row gap-3 md:gap-5 md:pl-5 justify-center pt-3 md:pt-5'>
                                                                <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                    <DatePicker
                                                                        selected={inputFrom3}
                                                                        onChange={(date) => handleInputFrom3(date)}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="From"
                                                                        className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        wrapperClassName="custom-datepicker-wrapper"
                                                                        popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                    />
                                                                    <i class="fa-regular fa-calendar text-white mt-2.5 w-[10%] pr-3"></i>
                                                                </div>
                                                                <div className='md:w-[30%] flex bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840] '>
                                                                    <DatePicker
                                                                        selected={inputTo3}
                                                                        onChange={handleInputTo3}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="To"
                                                                        className="form-control bg-black bg-opacity-5 w-[100%] text-white outline-none py-2 pl-7"
                                                                        maxDate={new Date()}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        dropdownMode="select"
                                                                        wrapperClassName="custom-datepicker-wrapper"
                                                                        popperContainer={({ children }) => <div style={customWrapperStyles}>{children}</div>}
                                                                        disabled={ongoing3}
                                                                    />
                                                                    <i class="fa-regular fa-calendar text-white mt-2.5 w-[10%] pr-3"></i>
                                                                </div>
                                                                <div className='md:w-[20%] flex'>
                                                                    <div className='flex items-center'>
                                                                        <p className='text-[#FFBA00] font-play pr-7'>- or -</p>
                                                                    </div>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={ongoing3}
                                                                        onChange={handleOngoingChange3}
                                                                        className="form-checkbox h-5 w-5 text-white mt-2 md:mt-3"
                                                                    />
                                                                    <div className='flex items-center'>
                                                                        <label className="ml-1 text-white">Ongoing</label>
                                                                    </div>
                                                                </div>
                                                                <div className='w-[10%]'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex  justify-center mt-10 mb-5'>
                                                        <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={(e) => {
                                                            //generate_nftid();
                                                            //  generate_seeker_wallet_address();
                                                            saveCertificateAndExpInfo(e);


                                                            //handleClickCongrats(); 
                                                        }}>


                                                            <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[128px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                            <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Register</p>

                                                            <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                        </button>

                                                    </div>


                                                </div>
                                            </Slider>



                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>

            </div>

        </div>

    )
}