import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import AdminHeader from './AdminHeader'
import axios from 'axios';
import Spinners from '../Spinners';
import './Admin.css';
import { Link } from 'react-router-dom'; 
import { toast, Toaster } from 'react-hot-toast';

export default function JobSeeker() {

    const location = useLocation();
    const email = new URLSearchParams(location.search).get('email');
    console.log("email", email);
    const [seekerDetails, setSeekerDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [isCopied, setIsCopied] = useState(false);

    const copyNftId = () => {
        navigator.clipboard.writeText(seekerDetails.nft_Id);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000); // Reset copied message after 2 seconds
    };


    useEffect(() => {
        const fetchSeekerDetails = async () => {
            try {
                console.log('Fetching seeker details...');
                const response = await axios.post(`https://ex.d5art.com/api/get_seekers_details`, { email });
                console.log("response", response.data)
                if (response.data) {
                    const data = response.data;
                    console.log("seeker data", data)
                    setSeekerDetails(data);
                } else {
                    console.error('Failed to fetch seeker details');
                }
            } catch (error) {
                console.error('Error fetching seeker details:', error);
            }
        };
        
        if (email) {
            fetchSeekerDetails();
        }
    }, [email]);

    useEffect(() => {
        console.log("seekerDetails updated:", seekerDetails);
    }, [seekerDetails]);
    
    console.log("seeker data111", seekerDetails);

    const handleMintNFTClick = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('https://ex.d5art.com/api/get_seeker_detail', { email });
            console.log("minting NFT ID", response.data);
            setIsLoading(false);
            toast.success('Minted Successfully');
            window.location.reload(); 

        } catch (error) {
            console.error('Error minting NFT:', error);
        }  finally {
            setIsLoading(false); 
        }
       
    };
    


//     return (
//         <div className='font-poppins bg-[#171616] w-screen ' style={{ backgroundImage: 'url("/assets/FABC.png")' }} >
//             <AdminHeader />
//             <div className='flex justify-center'>
//                 <div className='flex flex-col gap-10 w-[90%] py-10 text-white'>
//                     <div>
//                         <div className='flex justify-center'><p className='text-2xl font-bold' style={{
//                             backgroundClip: 'text',
//                             WebkitBackgroundClip: 'text',
//                             color: 'transparent',
//                             backgroundImage: 'linear-gradient(to right, #26ADA6, #FFFFFF)',
//                         }}>Job Seekers</p></div>
//                         <div className='flex flex-col md:flex-row gap-5 md:gap-0'>
//                             <div className='md:w-[20%]'>
//                                 <div className=' flex justify-center'> <img src='./adminassets/image 24.png'></img></div>
//                             </div>
//                             <div className='md:w-[80%] flex justify-center items-center'>
//                                 <div className='flex flex-col md:flex-row justify-center w-full'>
//                                     <div className='md:w-[40%] flex flex-col md:gap-7'>
//                                         <p className='flex gap-10'><p className='font-bold'>Skill ID: </p><span>0xGEJYGRJYGW72U3474G23</span></p>
//                                         <p className='flex gap-10'><p className='font-bold'>Name: </p><span>HERARIEN</span></p>
//                                     </div>
//                                     <div className='md:w-[40%] flex flex-col md:gap-7'>
//                                         <p className='flex gap-10'><p className='font-bold'>Employment: </p><span>Unemployed</span></p>
//                                         <p className='flex gap-10'><p className='font-bold'>Location: </p><span>Chennai</span></p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>         
//                     </div>
//                     <div className='flex flex-col md:flex-row gap-5 md:gap-10'>
//                         <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                             <div><img src='/adminassets/icon Delivered.png'></img></div>
//                             <div className='w-[40%]'><p className='text-3xl font-bold'>202</p><p className='font-bold text-xs'>Response Recieve</p></div>
//                         </div>
//                         <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                             <div><img src='/adminassets/Icon_Order.png'></img></div>
//                             <div className='w-[40%]'><p className='text-3xl font-bold'>17</p><p className='font-bold text-xs'>Interests Recieve</p></div>
//                         </div>
//                         <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                             <div><img src='/adminassets/Icon_Order-1.png'></img></div>
//                             <div className='w-[40%]'><p className='text-3xl font-bold'>0</p><p className='font-bold text-xs'>Offers</p></div>
//                         </div>
//                         <div className='md:w-[30%] grid gap-2 py-5 px-10 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                             <p className='font-bold text-xs'>Percentage</p>
//                             <p className='text-2xl '>85 %</p>
//                             <div><div className='w-full h-3 rounded-xl bg-[#E2E8F0]'><div className='w-[80%] h-3 rounded-xl bg-[#1B7F96]'></div></div></div>
//                         </div>
//                     </div>
//                     <div>
//                         <p className='text-2xl font-semibold'>Experience:</p>
//                         <div className='mt-3 grid gap-3 p-5 px-10 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                             <div className='flex justify-end'><img src='/adminassets/Rectangle 152.png' className='absolute translate-x-10 -translate-y-4'></img><p className='absolute -translate-x-[5rem] -translate-y-3'>Jun 22 - Aug 22</p></div>
//                             <p className='font-bold'>Blockchain Developer Intern</p>
//                             <p>FABC LLC</p>
//                             <p className='text-xs'> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//                         </div>
//                     </div>
//                     <div>
//                         <p className='text-2xl font-semibold'>Education::</p>
//                         <div className='mt-3 flex flex-col md:flex-row gap-5'>
//                             <div className='md:w-[50%] flex flex-col gap-5'>
//                                 <div className='flex gap-5'>
//                                     <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                         <p className='flex justify-between'><p className='font-bold text-lg '>10th </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>45%</p></div></p>
//                                         <p className='text-center font-bold mt-3'>XYZ. School</p>
//                                         <p className='text-center text-xs mb-3'>Bio - Mathematics</p>
//                                         <p className='text-center text-[#26ADA6] font-bold text-lg'>2016</p>
//                                     </div>
//                                     <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                         <p className='flex justify-between'><p className='font-bold text-lg '>10th </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>45%</p></div></p>
//                                         <p className='text-center font-bold mt-3'>XYZ. School</p>
//                                         <p className='text-center text-xs mb-3'>Bio - Mathematics</p>
//                                         <p className='text-center text-[#26ADA6] font-bold text-lg'>2016</p>
//                                     </div>
//                                 </div>
//                                 <div className='flex gap-5'>
//                                     <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                         <p className='flex justify-between'><p className='font-bold text-lg '>BE </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>45%</p></div></p>
//                                         <p className='text-center font-bold mt-3'>XYZ. School</p>
//                                         <p className='text-center text-xs mb-3'>Electrical Engineer</p>
//                                         <p className='text-center text-[#26ADA6] font-bold text-lg'>2017</p>
//                                     </div>
//                                     <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                         <p className='flex justify-between'><p className='font-bold text-lg '>ME </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>45%</p></div></p>
//                                         <p className='text-center font-bold mt-3'>XYZ. School</p>
//                                         <p className='text-center text-xs mb-3'>Power Electronics</p>
//                                         <p className='text-center text-[#26ADA6] font-bold text-lg'>2017</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='md:w-[50%] flex flex-col md:flex-row gap-5'>
//                                 <div className='md:w-[50%] grid content-center gap-3 p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                     <p className='text-xl'>Domain & Skills</p>
//                                     <div className='bg-[#E6EEFC4D] p-3 rounded-xl mt-2'>
//                                         <div className='flex justify-start'><p className='bg-[#FFFFFF80] text-black rounded-md px-3'>UI/UX Designer</p></div>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>UI Architect</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>UX Research</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>UX Testing</p>
//                                     </div>
//                                     <div className='bg-[#E6EEFC4D] mt-3 p-3 rounded-xl'>
//                                         <div className='flex justify-start'><p className='bg-[#FFFFFF80] text-black rounded-md px-3'>Blockchain Developer</p></div>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Solidity</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Truffle ganache</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Tact</p>
//                                     </div>
//                                 </div>
//                                 <div className='md:w-[50%]  grid content-center gap-2 p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
//                                     <p className=''>Employment type preferred:</p>
//                                     <div className='bg-[#E6EEFC4D] p-3 rounded-xl mt-2'>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Full Time</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Part Time</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Freelancer</p>
//                                     </div>
//                                     <p className='my-3'>Preferred Roles:</p>

//                                     <div className='bg-[#E6EEFC4D]  p-3 rounded-xl'>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Blockchain UI Designer</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>UX researcher</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>UI designer</p>
//                                         <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>Blockchain Developer</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='flex flex-col md:flex-row justify-center gap-7'>
//                     <button className='md:w-[15%] bg-[#4FA0A7] rounded-lg text-sm text-black py-1.5'>Go Back</button>
//                     <button className='md:w-[15%] bg-[#941A1A] rounded-lg text-sm  py-1.5'>Remove the account</button>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

return (
    <div className='font-poppins bg-[#171616] w-screen' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
        <AdminHeader />
        <Toaster />
        {seekerDetails && (
            <div className='flex justify-center'>
                <div className='flex flex-col gap-10 w-[90%] py-10 text-white'>
                    <div>
                        <div className='flex justify-center'>
                            <p className='text-2xl font-bold' style={{
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                backgroundImage: 'linear-gradient(to right, #26ADA6, #FFFFFF)',
                            }}>Job Seekers</p>
                        </div>
                        <div className='flex flex-col md:flex-row gap-5 md:gap-0'>
                            <div className='md:w-[20%]'>
                                <div className='flex justify-center'> <img src= "/adminassets/image 24.png"></img></div>
                            </div>
                            <div className='md:w-[80%] flex justify-center items-center'>
                                <div className='flex flex-col md:flex-row justify-center w-full'>          
                                    <div className='md:w-[40%] flex flex-col md:gap-7'>
                                    <p className='flex gap-10'>
                                    <p className='font-bold'>Skill ID: </p>
                                    <span>{seekerDetails.nft_Id ? seekerDetails.nft_Id.substring(0, 15) : ''}</span>

                                    <button className="copy-icon" onClick={copyNftId}>
                                        <img src="/adminassets/icons8-copy-16.png" alt="" />
                                    </button>
                                    {isCopied && <span>Copied!</span>}
                                </p>                                        <p className='flex gap-10'><p className='font-bold'>Name: </p><span>{seekerDetails.name}</span></p>
                                    </div>
                                    <div className='md:w-[40%] flex flex-col md:gap-7'>
                                        <p className='flex gap-10'><p className='font-bold'>Employment: </p><span>Unemployed</span></p>
                                        <p className='flex gap-10'><p className='font-bold'>Location: </p><span>Chennai</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-10'>
                        <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                            <div><img src='/adminassets/icon Delivered.png'></img></div>
                            <div className='w-[40%]'><p className='text-3xl font-bold'>202</p><p className='font-bold text-xs'>Response Recieve</p></div>
                        </div>
                        <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                            <div><img src='/adminassets/Icon_Order.png'></img></div>
                            <div className='w-[40%]'><p className='text-3xl font-bold'>17</p><p className='font-bold text-xs'>Interests Recieve</p></div>
                        </div>
                        <div className='md:w-[20%] flex  gap-3 justify-center p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                            <div><img src='/adminassets/Icon_Order-1.png'></img></div>
                            <div className='w-[40%]'><p className='text-3xl font-bold'>0</p><p className='font-bold text-xs'>Offers</p></div>
                        </div>
                        <div className='md:w-[30%] grid gap-2 py-5 px-10 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                            <p className='font-bold text-xs'>Percentage</p>
                            <p className='text-2xl '>85 %</p>
                            <div><div className='w-full h-3 rounded-xl bg-[#E2E8F0]'><div className='w-[80%] h-3 rounded-xl bg-[#1B7F96]'></div></div></div>
                        </div>
                    </div>
                    <div>
                    <p className='text-2xl font-semibold'>Experience:</p>
                    {seekerDetails && Object.keys(seekerDetails).map((key) => {
                        if (key.startsWith('experience') && seekerDetails[key]) {
                            const experienceData = JSON.parse(seekerDetails[key]);
                            if (experienceData.companyname && experienceData.from && experienceData.to && experienceData.role) {
                                return (
                                    <div key={key} className='mt-3 grid gap-3 p-5 px-10 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                        <div className='flex justify-end'><img src='/adminassets/Rectangle 152.png' className='absolute translate-x-10 -translate-y-4'></img><p className='absolute -translate-x-[5rem] -translate-y-3'>{experienceData.from} - {experienceData.to}</p></div>
                                        <p className='font-bold'>{experienceData.role}</p>
                                        <p>{experienceData.companyname}</p>
                                        <p className='text-xs'>{experienceData.description}</p>
                                    </div>
                                );
                            }
                        }
                        return null;
                    })}
                </div>
                
                    <div>
                        <p className='text-2xl font-semibold'>Education::</p>
                        <div className='mt-3 flex flex-col md:flex-row gap-5'>
                            <div className='md:w-[50%] flex flex-col gap-5'>
                                <div className='flex gap-5'>
                                    <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                        <p className='flex justify-between'><p className='font-bold text-lg '>SSLC </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>{seekerDetails.sslc_percentage}%</p></div></p>
                                        <p className='text-center font-bold mt-3'>{seekerDetails.sslc_institution}</p>
                                        <p className='text-center text-[#26ADA6] font-bold text-lg'>{seekerDetails.sslc_year}</p>

                                        <p className='text-center text-xs mb-3'>{seekerDetails.sslcDescription}</p>
                                    </div>
                                    <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                        <p className='flex justify-between'><p className='font-bold text-lg '>HSC </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>{seekerDetails.hsc_percentage}%</p></div></p>
                                        <p className='text-center font-bold mt-3'>{seekerDetails.hsc_institution}</p>
                                        <p className='text-center text-[#26ADA6] font-bold text-lg'>{seekerDetails.hsc_year}</p>
                                        <p className='text-center text-xs mb-3'>{seekerDetails.hscDescription}</p>
                                    </div>
                                </div>
                                <div className='flex gap-5'>
                                    <div className='w-[50%] p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                        <p className='flex justify-between'><p className='font-bold text-lg '>CGPA </p><div className='rounded-full text-xs flex justify-center items-center p-2 py-3 border-2 border-gray-500  border-t-[#B0F0FD]'><p>{seekerDetails.cgpa_percentage}%</p></div></p>
                                        <p className='text-center font-bold mt-3'>{seekerDetails.cgpa_institution}</p>
                                        <p className='text-center text-[#26ADA6] font-bold text-lg'>{seekerDetails.cgpa_year}</p>
                                        <p className='text-center text-xs mb-3'>{seekerDetails.cgpaDescription}</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className='md:w-[50%] flex flex-col md:flex-row gap-5'>
                                <div className='md:w-[50%] grid content-center gap-3 p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                    <p className='text-xl'>Domain & Skills</p>
                                    {seekerDetails.skills && JSON.parse(seekerDetails.skills).map((skill, index) => (
                                        <div key={index} className='flex justify-start'>
                                            <p className='bg-[#FFFFFF80] text-black rounded-md px-3'>{skill}</p>
                                        </div>
                                    ))}
                            
                                   
                                </div>
                                <div className='md:w-[50%]  grid content-center gap-2 p-5 rounded-xl bg-[#2D2D2D] backdrop-blur-md'>
                                    <p className=''>Employment type preferred:</p>
                                    <div className='bg-[#E6EEFC4D] p-3 rounded-xl mt-2'>
                                        <p className='px-2'><i class="fa-solid fa-circle mr-2 -translate-y-1  text-[5px]"></i>{seekerDetails.contract_type}</p>
                             
                                    </div>
                                    <p className='my-3'>Preferred Roles:</p>

                                    <div className='bg-[#E6EEFC4D] p-3 rounded-xl'>
                                    {seekerDetails.jobrole && JSON.parse(seekerDetails.jobrole).map((role, index) => (
                                        <p key={index} className='px-2'>
                                            <i className="fa-solid fa-circle mr-2 -translate-y-1 text-[5px]"></i>
                                            {role}
                                        </p>
                                    ))}
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-center gap-7'>    

                    <Link  className='md:w-[15%] bg-[#4FA0A7] rounded-lg text-sm text-black py-1.5' 
                    to='/RMS/Admin/SeekerList'>
                    <button  >Go Back</button>
                  </Link>
                  
                    {seekerDetails && (seekerDetails.is_minted === 1 || seekerDetails.is_minted === "1") ? (
                        <p className='md:w-[15%] bg-[#4FA0A7] rounded-lg text-sm py-1.5'>ALREADY MINTED</p>
                    ) : (
                        <button className='md:w-[15%] bg-[#4FA0A7] rounded-lg text-sm py-1.5' onClick={handleMintNFTClick}>Mint NFT</button>
                        )}
                    <button className='md:w-[15%] bg-[#941A1A] rounded-lg text-sm py-1.5'>Remove the account</button>
                </div>
                <div className='z-100'>
                <Spinners isVisible={isLoading} />
            </div>
                
                </div>
            </div>
        )}
    </div>
)
}