import React, { useEffect, useState } from 'react';

import LoginHeader from './LoginHeader';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function SkillArena() {
    const Email = useSelector((state) => state.user_email);
    console.log("Email Logged In",Email)

    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearch = () => {
        if (searchQuery.trim() !== '') {
            navigate('/RMS/Seeker/SkillArenalist');
        }
    };

    return (
        <div className='font-poppins bg-[#101010]  'style={{ backgroundImage: `url(/assets/bg.png)` }}>
            <LoginHeader />
            <div className='Content1'  style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='flex justify-center'>
                    <h1 className='text-center text-3xl font-bold'
                        style={{
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                        }}>Skill Arena</h1>
                </div>
                <div className='flex justify-center'>

                <div className='w-[90%] pt-10 text-white '>
                <div className='rounded-full bg-gray-100 flex'>
                    <input className='w-full outline-none px-10 py-2 text-black w-[95%] rounded-full'
                        placeholder='Search for Skill Id'
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button className='rounded-full bg-gray-300 px-6 ml-2' onClick={handleSearch}>
                    <i className="fa-solid fa-magnifying-glass text-black w-[5%] mt-1 text-center"></i>
            </button>
                   
                </div>

                        <p className='md:flex justify-between mt-5 text md:text-xs font-bold'>
                            <p className='md:flex gap-7'>
                                <p>Experience: <span className='ml-2 text-[#0087a5]'>0 - 1 year</span></p>
                                <p>Location: <span className='ml-2 text-[#0087a5]'>Any</span></p>
                                <p>Contract type: <span className='ml-2 text-[#0087a5]'>Full-time</span></p>
                            </p>
                            <p>Sort by: <span className='ml-2 text-[#0087a5]'>Relevance</span></p>
                        </p>
                        <div className='w-[17rem] mt-10 md:mt-20'
                        ><h1 className=' text-xl font-bold'
                            style={{
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                backgroundImage: 'linear-gradient(to right, #00768B, #FFBA00)',
                            }}>In-Demand Skills</h1></div>
                        <div className='hidden md:block'>
                            <div className='flex justify-between gap-3 mt-7'>
                                <div className='w-[25%]'>
                                    <div className='flex justify-center'>
                                        <img src='/assets/item1.png'></img>
                                    </div>
                                    <p className='text-center py-5'>UX Designer</p>
                                </div>
                                <div className='w-[25%]'>
                                    <div className='flex justify-center'>
                                        <img src='/assets/item2.png'></img>
                                    </div>
                                    <p className='text-center py-5'>C Programming</p>
                                </div>
                                <div className='w-[25%]'>
                                    <div className='flex justify-center'>
                                        <img src='/assets/item3.png'></img>
                                    </div>
                                    <p className='text-center py-5'>Digital Marketing</p>
                                </div>
                                <div className='w-[25%]'>
                                    <div className='flex justify-center'>
                                        <img src='/assets/item4.png'></img>
                                    </div>
                                    <p className='text-center py-5'>Cyber Security</p>
                                </div>
                            </div>
                        </div>
                        <div className='block md:hidden mt-5'>
                            <div>
                                <div className='flex gap-3'>
                                    <div className='w-[50%]'>
                                        <div className='flex justify-center'>
                                            <img src='/assets/item1.png'></img>
                                        </div>
                                        <p className='text-center py-5'>UX Designer</p>
                                    </div>
                                    <div className='w-[50%]'>
                                        <div className='flex justify-center'>
                                            <img src='/assets/item2.png'></img>
                                        </div>
                                        <p className='text-center py-5'>C Programming</p>
                                    </div>

                                </div>
                                <div className='flex gap-3'>
                                    <div className='w-[50%]'>
                                        <div className='flex justify-center'>
                                            <img src='/assets/item3.png'></img>
                                        </div>
                                        <p className='text-center py-5'>Digital Marketing</p>
                                    </div>
                                    <div className='w-[50%]'>
                                        <div className='flex justify-center'>
                                            <img src='/assets/item4.png'></img>
                                        </div>
                                        <p className='text-center py-5'>Cyber Security</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center mt-20 mb-5'>
                            <div className='w-[90%] md:w-[85%] border border-sky-600 rounded-3xl '>
                                <div className='flex justify-center -translate-y-6 md:-translate-y-8'>
                                    <p className='text-center font-play px-10 md:px-20 rounded-full py-2 backdrop-blur-md border border-sky-600 text-lg md:text-4xl font-bold'
                                        style={{
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent',
                                            backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
                                        }}
                                    >About Skill ID</p>
                                </div>
                                <div className='md:flex '>
                                    <div className='md:w-[45%] flex justify-center'>
                                        <div className='w-[70%]'>
                                            <img src='/assets/image2.png'></img>
                                        </div>
                                    </div>
                                    <div className='md:w-[55%] px-5 md:px-0  md:pr-10'>
                                    <p className='py-10 md:py-20  text-lg md:text-2xl'>Your professional portfolio is elevated with an  <span
                                    style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                    }}
                                >NFT Skill ID.
                                </span> A digital credential that showcases your expertise like never before. This unique identifier represents your skills, accumulated, validated, and tokenized to promote <span
                                    style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                    }}
                                >unbiased access from education to employment
                                    </span> in the digital era. </p>
                                    </div>
                                </div>
                                <div className='flex justify-center pb-10'>
                                    <a href='/RMS/Seeker/SkillArenalist'>
                                        <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                            <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[127px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Explore</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
