import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { ConeGeometry, MeshBasicMaterial, EdgesGeometry, LineSegments, LineBasicMaterial } from 'three';

const Cone = () => {
    const groupRef = useRef();

    // Rotate the group in the animation loop
    useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.x += 0.01;
        }
    });

    return (
        <group ref={groupRef} rotation={[0,0,5]}>
            {/* Main cone mesh */}
            <mesh>
                <coneGeometry args={[1, 2.5, 6, 4]} />
                <meshBasicMaterial color={'#0c4a6e'} />
            </mesh>

            {/* Edges */}
            <lineSegments>
                <edgesGeometry args={[new ConeGeometry(1, 2.5, 6, 1)]} />
                <lineBasicMaterial color={'#ffffff'} linewidth={2} />
            </lineSegments>
        </group>
    );
};

const ThreeJSComponent = () => {
    return (
        <Canvas>

            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Cone />
        </Canvas>
    );
};

export default ThreeJSComponent;
