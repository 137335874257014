import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import ThreeJSComponent from './Threejscomponent';
import Threejscomponent2 from './Threejscomponent2';
import { Input } from "@material-tailwind/react";
import Slider from 'react-slick';
import './seeker.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import Spinners from './Spinners';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import LoginHeader from './LoginHeader';
// import { setUserEmail } from './store'
import { useDispatch } from 'react-redux';


export default function Header() {
  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const [isTransformed, setIsTransformed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const toggleModal = () => {
    // If modal is open, show loading
    if (!isOpen) {
      setIsLoading1(true);
      setTimeout(() => {
        setIsLoading1(false);
        setIsOpen(!isOpen); // Close the modal after hiding the loading
      }, 1000); // Adjust the duration as needed
    } else {
      setIsOpen(!isOpen); // Close the modal directly
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsTransformed(true);
    } else {

      setIsTransformed(false);
    }
  }, [isOpen]);

  //const dispatch = useDispatch();

  // const Logout = () => {
  //   dispatch(setUserEmail(""));
  //   window.location.reload();
  // };
  return (
    <div>
      <nav className='flex p-7  lg:px-10  font-poppins'>
        <div className='w-[85%] lg:w-[15%]'>
          <div className='w-[80%]'>
            <img src='/assets/fABC.png' className='h-14'></img>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] hidden md:table'>
          <div className=' flex '>
            <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-16 mt-3 '>
              <a href='/'>Home</a>
              <a href='/SkillArena'>Skill Exchange</a>
              <a href=''>Courses</a>
              <a href=''>Contact us</a>

            </div>
            <div className='w-[20%] '>
              <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
              </button>
            </div>
          </div>
        </div>
        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown}>
              <i class="fa-solid fa-ellipsis-vertical text-white text-4xl "></i>
            </button>
            {isDropdown && (
              <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
                <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                  <a href='/' className='text-center'>Home</a>
                  <a href='/SkillArena' className='text-center'>Skill Exchange</a>
                  <a href='' className='text-center'>Courses</a>
                  <a href='' className='text-center'>Contact us</a>
                 
                  <div className='flex justify-center'>
                    <button className='flex py-1 pt-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                      <button className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading1 && (
          <div className=''>
            <div className='z-100'>
              <Loading isVisible1={isLoading1} />
            </div>
          </div>
        )}
        {isOpen && (

          <div className=''>
            <div className='z-100'>
              <Spinners isVisible={isLoading} />
            </div>
            <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
              <div className='w-[15%] flex justify-end'>
                <img src='/assets/LeftSide.png'></img>
              </div>
              <div className={`   ${isTransformed ? 'w-[70%]' : 'border-0 w-0  '} z-10  backdrop-blur-sm bg-transparent transform-transition duration-1000 ease-out-in rounded-3xl`}>

                <div className=' w-[100%] flex justify-center gap-2'>
                  <div className='w-[95%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10'>
                    <div className='flex justify-end '>
                      <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                    </div>
                    <h1 className='font-bold text-center text-3xl'>Are You hear for?</h1>
                    <div className='flex justify-center 2xl:gap-20 gap-10 py-10'>
                      <a href='/Register'>
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                              <div className='flex justify-center'><img src="/assets/Recuiterimg.png" />
                              </div>
                              <p className='mt-2'>Recruiter</p>

                            </div>
                            <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                              <h1 className='text-gray-400 '>Recruiter</h1>
                              <p className='text-center text-sm font-bold mt-5'> bcaubsvk  hoahsch  hcas uh oh hac uh  auhsuichoha soc</p>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a href='/Register'>
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                            <div className='flex justify-center'><img src="/assets/seekerimg.png" />
                            </div>
                            <p>Job Seeker</p>

                          </div>
                          <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                            <h1 className='text-gray-400 '>Job Seeker</h1>
                            <p className='text-center text-sm font-bold mt-5'> bcaubsvk  hoahsch  hcas uh oh hac uh  auhsuichoha soc</p>
                          </div>
                        </div>
                      </div>
                      </a>
                      <a href='/Register'>
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front bg-white/15 rounded-2xl grid content-center">
                            <div className='flex justify-center'><img src="/assets/validatorimg.png" />
                            </div>
                            <p className='mt-2'>validator</p>

                          </div>
                          <div class="flip-card-back p-5 border border-yellow-500 rounded-2xl">
                            <h1 className='text-gray-400 '>Validator</h1>
                            <p className='text-center text-sm font-bold mt-5'> bcaubsvk  hoahsch  hcas uh oh hac uh  auhsuichoha soc</p>
                          </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  </div>


                </div>
              </div>
              <div className='w-[15%]'>
                <img src='/assets/RightSide.png'></img>
              </div>
            </div>
          </div>
        )}




      </nav>
    </div>
  )
}