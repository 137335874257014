// import React from "react";

// import { useSelector,useDispatch } from "react-redux";
// import $ from 'jquery';
// import axios from 'axios';
// import toast from "react-hot-toast";
// import { setAdminEmail } from "../store";


// const AdminLogin = () => {

    // const admin_email = useSelector(state=>state.admin_email);
    // const dispatch = useDispatch();
  
    // const AdminLogin = async()=>{
    //   try{
    //     var email = $('#email').val();
    //     var password = $('#password').val();
    //     if(!email || !password){
    //       toast.error('Invalid Credentials');
    //       return false
    //     }
    //     await axios.post('https://ex.d5art.com/api/AdminLogin',{
    //       email:email,
    //       password:password
    //     }).then((res)=>{
    //       console.log(res);
    //       if(res.data.status=='success'){
    //         toast.success(res.data.msg);
    //         dispatch(setAdminEmail(email))
    //         setTimeout(()=>{
    //             window.location = '/RMS/AdminMintNFT';
    //         },1000)
    //       }else{
    //         toast.error(res.data.msg);
    //         return false
    //       }
    //     })
    //   }catch(err){
    //     console.log(err)
    //   }
    // }
  
//     return (
//       <>
   
//       <div className="relative w-screen" >
       
//         <div className="bg-opacity-95 z-100 px-6 lg:px-24 z-1 flex flex-col items-center justify-center h-full lg:h-screen">
//           <div className="AdminLogin-Text text-center text-white w-full lg:w-7/12 mx-auto mt-8 lg:mt-0">
//             {/* <h1 className="text-4xl leading-relaxed capitalize">
//               Being an Admin with great responsibility will make your organization
//               the greater
//             </h1>
//             <p className="mt-8 font-iceland text-3xl">
//               Stats with dynamics explore with more privacy
//             </p> */}
//           </div>
//           <div className="AdminLogin-form-container flex flex-col lg:flex-row justify-between items-center gap-4 mt-8 lg:mt-4 w-full">
//             <div className="AdminLogin-form-container-Left-Container px-6 lg:px-12 py-12 rounded-lg bg-gray-500 w-full lg:w-6/12 text-center backdrop-filter backdrop-blur-lg bg-opacity-40">
//               <h1 className="font-iceland text-3xl text-white font-bold">
//                 Admin Login
//               </h1>
//               <div className="mt-6">
//                 <input
//                   type="email" id="email"
//                   className="w-full px-4 py-2 rounded-lg bg-gray-200 outline-none"
//                   placeholder="Enter Your Email"
//                 />
//               </div>
//               <div className="mt-6">
//                 <input
//                   type="password" id="password"
//                   className="w-full px-4 py-2 rounded-lg bg-gray-200 outline-none"
//                   placeholder="Enter Your Password"
//                 />
//               </div>
//               <div className="w-full">
//                 <button className="px-4 py-2 bg-blue-600 rounded-lg font-iceland w-full lg:w-4/12 mt-6 text-xl" onClick={()=>AdminLogin()}>
//                   Login
//                 </button>
//               </div>
//             </div>
//             <div className="AdminLogin-form-container-Right-Container w-full lg:w-6/12">
              
//             </div>
//           </div>
//         </div>
//       </div>
//       </>
//     );
//   };
//   export default AdminLogin;


import React, { useState } from 'react'
import AdminHeader from './AdminHeader'

import { useSelector,useDispatch } from "react-redux";
import $ from 'jquery';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { setAdminEmail } from "../store";

export default function AdminLogin() {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const admin_email = useSelector(state=>state.admin_email);
  const dispatch = useDispatch();

  const AdminLogin = async()=>{              
    try{
      var email = $('#email').val();
      var password = $('#password').val();
      if(!email || !password){
        toast.error('Invalid Credentials');
        return false
      }
      await axios.post('https://ex.d5art.com/api/AdminLogin',{
        email:email,
        password:password
      }).then((res)=>{
        console.log(res);
        if(res.data.status=='success'){
          toast.success('Login successful')
          dispatch(setAdminEmail(email))
          setTimeout(()=>{
              window.location = '/RMS/Admin/SeekerList';
          },1000)
        }else{
          toast.error('OOPS!! Login failed');
          return false
        }
      })
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className='font-poppins bg-[#171616] w-screen md:h-screen' style={{ backgroundImage: 'url("/assets/FABC.png")' }} >
    <Toaster />
      <div className='flex justify-center'>
        <div className='flex flex-col md:flex-row w-[90%] pt-10 pb-5 md:pb-0'>
          <div className='md:w-[50%] text-white '>
            <p className='capitalize text-center font-bold text-2xl'>Being an admin with great responsibility will make your organization the greater </p>
            <p className='capitalize text-center text-xs pt-5'>Stats with dynamics explore with more privacy </p>
            <div className='flex justify-center pt-10'>
              <img src='/adminassets/Frame.png' className='w-[75%]'></img>
            </div>
          </div>
          <div className='md:w-[50%] text-white flex justify-center items-center'>
            <div className='flex flex-col gap-7 w-[90%] md:w-[70%]'>
              <div className='flex justify-center'><p className='text-xl font-bold' style={{
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                backgroundImage: 'linear-gradient(to right, #26ADA6, #FFFFFF)',
              }}>Admin Login</p></div>
              <div className='bg-[#2D2D2D] flex rounded-lg'>
                <i className='fa-solid fa-user text-xl text-gray-400 w-[10%] text-center rounded-lg  mt-1'></i>
                <input id="email" className='w-[90%] py-2 bg-transparent outline-none' placeholder='Username'></input>
              </div>
              <div>
                <div className='bg-[#2D2D2D] flex rounded-lg'>
                  <i className='fa-solid fa-key text-xl text-gray-400 w-[10%] text-center rounded-lg  mt-1'></i>
                  <input
                    id="password"
                    type={passwordVisible ? 'text' : 'password'}
                    className='w-[80%] py-2 bg-transparent outline-none'
                    placeholder='Password'
                  />
                  <i
                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[10%] text-center text-gray-300 text-xl mt-2 `}
                    onClick={handleTogglePasswordVisibility}
                  ></i>
                </div>
                <div className='flex justify-end'>
                  <a href='' className='text-xs text-gray-400'>Recover Password ?</a>
                </div>
              </div>
              <div className='flex justify-center'>
                <button onClick={AdminLogin} className='bg-[#26ADA6] px-10 py-1 rounded-md text-black active:translate-y-1 duration-500'>Login</button>
              </div>
            {/*  <div className='flex justify-center'>
                <hr className='w-[10%] mt-3'></hr><p className='px-1 text-sm'> Or Continue with </p><hr className='w-[10%] mt-3'></hr>
              </div>
              <div className='flex justify-center gap-5'>
                <button className='px-3 py-3 bg-white rounded-lg shadow-xl shadow-blue-700/15'><img src='/adminassets/Google Button.png' className='w-[rem]'></img></button>
                <button className='px-2.5 py-3 bg-white rounded-lg shadow-xl shadow-blue-700/15'><img src='/adminassets/Twitter Button.png' className=' cursor-pointer'></img></button>
                <button className='px-3 py-3 bg-white rounded-lg shadow-xl shadow-blue-700/15'><img src='/adminassets/Facebook Button.png' className=' cursor-pointer'></img></button>
                <button className='px-3 py-3 bg-white rounded-lg shadow-xl shadow-blue-700/15'><img src='/adminassets/Apple Button.png' className=' cursor-pointer'></img></button>
            </div> */}

            </div>
          </div>

        </div>
        <div className='absolute right-0 bottom-0 md:block hidden'>
          <img src='/adminassets/image 5.png' className='w-[12.5rem]'></img>
        </div>
      </div>
      <div className=' block md:hidden pb-5'>
        <div className='flex justify-center'>
          <img src='/adminassets/image 5.png' className='w-[10rem]'></img>
        </div>
      </div>
    </div>
  )
            }