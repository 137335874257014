
import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function JobSeekerList() {
    const [seekers, setSeekers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        axios.get('https://ex.d5art.com/api/get_seeker_details')
            .then(response => {
                console.log('Fetched seekers:', response.data);
                setSeekers(response.data);
            })
            .catch(error => {
                console.error('Error fetching seeker details:', error);
            });
    }, []);

    // const filteredSeekers = seekers.filter(seeker =>
    //     seeker.name.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const filteredSeekers = seekers.filter(seeker => {
        const name = seeker.name ? seeker.name.toLowerCase() : ''; // Null check for seeker.name
        const query = searchQuery ? searchQuery.toLowerCase() : ''; // Null check for searchQuery

        return name.includes(query);
    });


    const totalPages = Math.ceil(filteredSeekers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    //const currentData = filteredSeekers.slice(startIndex, endIndex);
    const currentData = filteredSeekers ? filteredSeekers.slice(startIndex, endIndex) : [];


    const handlePrevious = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = totalPages > 6 ? 6 : totalPages; // Only show max 6 numbers

        // Determine the range of page numbers to show
        if (totalPages <= 6) {
            // If total pages are less or equal to 6, show all pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                // Show first 3 pages and last 3 pages
                pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
            } else if (currentPage > totalPages - 3) {
                // Show first 3 pages and last 3 pages
                pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
            } else {
                // Show first page, current page in the middle, and last page
                pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }

        return pageNumbers;
    };


    return (
        <div className='font-poppins bg-[#171616] w-screen pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <AdminHeader />
            <div className='flex justify-center'>
                <div className='w-[90%] pt-10 flex flex-col gap-5'>
                    <div className='flex justify-start'>
                        <p className='text-lg font-bold' style={{
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            backgroundImage: 'linear-gradient(to right, #26ADA6, #FFFFFF)',
                        }}>Job Seeker List</p>
                    </div>
                    <div className='flex gap-5'>
                        <div className='bg-[#2D2D2D] text-white flex rounded-full md:w-[40%]'>
                            <i className='fa-solid fa-magnifying-glass text-xl text-gray-400 w-[15%] md:w-[10%] text-center rounded-full  mt-1'></i>
                            <input
                                type='search'
                                className='w-[85%] md:w-[90%] py-2 bg-transparent outline-none'
                                placeholder='Search by Name'
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div>
                            <button className='bg-[#26ADA6] px-3 py-2 text-white rounded-full'>Search</button>
                        </div>
                    </div>
                    <div className='text-white'>
                        <p className='flex'>Filter <i class="fa-solid fa-filter mt-1 px-2"></i> <p className='pl-5'>Sort by: <span className='font-bold'>Name</span><i class="fa-solid fa-arrow-down-z-a px-2"></i></p></p>
                        <div className='w-full mt-3 bg-[#2D2D2D] rounded-2xl overflow-hidden overflow-x-auto'>
                            {filteredSeekers.length === 0 ? (
                                <p className='text-center text-gray-400 py-4'>No results found</p>
                            ) : (
                                <table className='w-full text-center rounded-2xl border border-gray-600'>
                                    <thead>
                                        <tr className='text-[#26ADA6] border-b border-gray-600'>
                                            <th className='py-4 border-r border-gray-600'>S.No</th>
                                            <th className='border-r border-gray-600'>Name</th>
                                            <th className='border-r border-gray-600'>Mail ID</th>
                                            <th className='border-r border-gray-600'>Number</th>
                                            <th className='border-r border-gray-600'>NFT ID</th>
                                            <th className='border-r border-gray-600'>Minted Status</th>
                                            <th className='border-r border-gray-600'>Full Details</th>
                                            <th>Remove NFT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((seeker, index) => (
                                            <tr key={index} className='text-sm border-b border-gray-600'>
                                                <td className='py-2 border-r border-gray-600'>{startIndex + index + 1}.</td>
                                                <td className='border-r border-gray-600'>{seeker.name}</td>
                                                <td className='border-r border-gray-600'>{seeker.email}</td>
                                                <td className='border-r border-gray-600'>{seeker.mobile_number}</td>
                                                <td className='border-r border-gray-600' title={seeker.nft_Id}> {seeker.nft_Id ? seeker.nft_Id.slice(0, 25) : ''}</td>
                                                <td className='border-r border-gray-600'>
                                                {seeker.is_minted === 1 || seeker.is_minted === "1" ? 'Minted' : 'Yet to Mint'}
  
                                                </td>

                                                <td className='border-r border-gray-600'>
                                                    <Link to={`/RMS/Admin/SeekerDetails?email=${seeker.email}`} className='text-[#26ADA6] border rounded-full py-1 px-3'>
                                                        View all details <i className="fa-solid fa-chevron-down pl-2 text-xs"></i>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <button className='text-[#FF0101] border border-[#FF0101] rounded-full py-1 px-3'>
                                                        Remove NFT
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {[...Array(itemsPerPage - currentData.length)].map((_, index) => (
                                            <tr key={index + currentData.length} className='text-sm'>
                                                <td className='py-2'>{startIndex + currentData.length + index + 1}.</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div className='flex justify-center gap-5 py-5'>
                                <button onClick={handlePrevious} disabled={currentPage === 1}>
                                    <i className="fa-solid fa-chevron-left mt-1"></i>
                                </button>
                                {renderPageNumbers().map((page, index) => (
                                    <p
                                        key={index}
                                        onClick={() => typeof page === 'number' && setCurrentPage(page)}
                                        className={currentPage === page ? 'text-yellow-600 cursor-pointer' : 'cursor-pointer'}
                                    >
                                        {page}
                                    </p>
                                ))}
                                <button onClick={handleNext} disabled={currentPage === totalPages}>
                                    <i className="fa-solid fa-chevron-right mt-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
