


import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
//import Header from './Header';
import Moralis from 'moralis';
import { ethers } from 'ethers';
// import Web3 from 'web3';
import LoginHeader from './LoginHeader';
import Footer from '../Footer';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setRecruiterEmail } from '../store';
import Select from 'react-select';

import Loader from '../loaderdemo';
import ErrorPage from '../please_wait_page';
import Skillslist from '../Seeker/Skills.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';


export default function SkillArenalist() {
    const [starCount, setStarCount] = useState(0);
    const [nftItems, setNftItems] = useState([]);
    const [userInfo, setUserInfo] = useState({});

    const [loading, setLoading] = useState(true);

    const email = useSelector((state) => state.recruiter_email);
  //  console.log("Email Logged In", email)

    const dispatch = useDispatch();

    const [selectedSkill, setSelectedSkill] = React.useState([]);
    const [inputValueSkill, setInputValueSkill] = React.useState('');

    const handleSkill = (selectedOption) => {
        if (selectedSkill.length < 6) {
            setSelectedSkill([...selectedSkill, selectedOption]);
        }
        setInputValueSkill('');
    };
    const removeOption2 = (index) => {
        const updatedOptions = [...selectedSkill];
        updatedOptions.splice(index, 1);
        setSelectedSkill(updatedOptions);
    };

    const SkillOptions = Skillslist;

    // const SkillOptions = [
    //     { value: 'Skill1', label: 'HTML' },
    //     { value: 'Skill2', label: 'React' },
    //     { value: 'Skill3', label: 'Node' },
    //     { value: 'Skill4', label: 'Next' },
    //     { value: 'Skill5', label: 'Bootstrap' },
    //     { value: 'Skill6', label: 'Tailwind' },
    // ];


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '0px solid #0a3840',
            backgroundColor: '#374151',
            borderRadius: '30px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
    };

    const incrementStar = () => {
        if (starCount < 5) {
            setStarCount(starCount + 1);
        }
    };

    const decrementStar = () => {
        if (starCount > 0) {
            setStarCount(starCount - 1);
        }
    };

    const location = useLocation();
    // const history = Link();
    const { search } = location;
    const params = new URLSearchParams(search);
    const userEmail = params.get('userEmail');


    // const moralis_apiKey = process.env.REACT_APP_MORALIS_APIKEY;
    // const moralis_chainId = process.env.REACT_APP_MORALIS_CHAIN; 
  //  const moralis_nft_contract_address = process.env.REACT_APP_MORALIS_NFT_ADDRESS;

  const moralis_apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
  const moralis_chainId = "0x13882";
   const moralis_nft_contract_address = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";

    const fetchData = async () => {
        try {
            await Moralis.start({
                apiKey: moralis_apiKey
            });

            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": moralis_chainId,
                "format": "decimal",
                "address": moralis_nft_contract_address
            });


            // const response = await Moralis.EvmApi.nft.getNFTOwners({
            //     "chain": "0xaa36a7",
            //     "format": "decimal",
            //     "address": "0x9bf9F8Ee7fE6C43F736C0147883635d3992A28e6"
            //   });



            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
         //   console.log(ipfsImage);

          //  console.log(response.raw.result);
            setNftItems((prevItems) => [...prevItems, ...response.raw.result]);



            setLoading(false);
        } catch (error) {
            if (error && error.code === 401) {
                setLoading(true); // Show loader for 401 error code
            } else {
                setLoading(false);
            }
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);

    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };

    const handleButtonClick = async (tokenIdParam) => {
        try {
    
            const Recruiter_secretKey = "Recruiter@20#31%456&$#234asd3485690567@@#$%#%#$@@*&^%$E$#$%#";

         //  const Recruiter_secretKey =  process.env.REACT_APP_RECRUITER_SECRETKEY;
            let encryptedTokenId = CryptoJS.AES.encrypt(tokenIdParam, Recruiter_secretKey).toString();
            // console.log("encryptedTokenId",tokenIdParam);
            const newPath = `/RMS/Recruiter/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
            window.location.href = newPath;

            const selectedNFT = nftItems.find(item => item.token_id === tokenIdParam);
            const metadata = JSON.parse(selectedNFT.metadata);

            const seeker_nftId = metadata.name;

            const email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');
            const seeker_email = email_typeAttribute.value;
         //   console.log("seekeremail", seeker_email);
            const seekerEmail = seeker_email;

            // call the function to transfer token to admin and save split in the db
            await pay_per_click(email, seekerEmail,seeker_nftId, tokenIdParam);

            // Call the function to save email and token ID to the database
            await saveEmailAndTokenToDatabase(email, seekerEmail, tokenIdParam);
        } catch (error) {
            console.error(error);
        }
    };

    const [ViewProfilePopup, setViewProfilePopup] = useState(false);
    const [selectedTokenId, setSelectedTokenId] = useState(null);

    // const handleViewProfile = (tokenId) => {
    //     setSelectedTokenId(tokenId);
    //     setViewProfilePopup(!ViewProfilePopup);
    // };

    const handleViewProfile = async (tokenId, seekerEmail, recruiterEmail, nftId) => {
        setSelectedTokenId(tokenId);
    
        try {

            const selectedNFT = nftItems.find(item => item.token_id === tokenId);
            const metadata = JSON.parse(selectedNFT.metadata);

            const seeker_nftId = metadata.name;

            const email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');
            const seeker_email = email_typeAttribute.value;
         //   console.log("seekeremail", seeker_email);
            const seekerEmail = seeker_email;
            console.log("Sending data:", { seekerEmail, email, tokenId, seeker_nftId });
    
            const response = await axios.post('https://ex.d5art.com/api/checkSeeker', {
                seekerEmail,
                email,
                tokenId,
                seeker_nftId
            });
    
            console.log("Server response:", response.data);
    
            if (response.data.exists) {
                // If record exists, redirect to the next page
                const Recruiter_secretKey = process.env.REACT_APP_RECRUITER_SECRETKEY;
                let encryptedTokenId = CryptoJS.AES.encrypt(tokenId, Recruiter_secretKey).toString();
                const newPath = `/RMS/Recruiter/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
                window.location.href = newPath;
            } else {
                // If record does not exist, show the popup
                setViewProfilePopup(true);  
            }
        } catch (error) {
            console.error('Error checking viewProfile:', error);
        }
    };
    
    
  

    const saveEmailAndTokenToDatabase = async (email, seekerEmail, tokenId) => {
        try {

            const response = await axios.post('https://ex.d5art.com/api/save_seeker_email_for_appearance', { email, seekerEmail, tokenId });
          //  console.log('Email and token ID saved to the database:', response.data);
        } catch (error) {
            console.error('Error saving email and token ID to the database:', error);
        }
    };


    const pay_per_click = async (email, seekerEmail, tokenId, seeker_nftId) => {
        try {

            const response = await axios.post('https://ex.d5art.com/api/pay_per_click', { email, seekerEmail, tokenId, seeker_nftId });
          //  console.log('Email and token ID saved to the database:', response.data);
        } catch (error) {
            console.error('Error saving email and token ID to the database:', error);
        }
    };

    const closeViewProfilePopup = () => {
        setViewProfilePopup(false);
    };




    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/checkSeeker?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
              //  console.log('5555', data.data[0].avatar);


                if (response.ok) {


                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);


    ////////////

    const [searchQuery, setSearchQuery] = useState('');
    const [workModeQuery, setWorkModeQuery] = useState('');
    const [minExperience, setMinExperience] = useState(0);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleWorkModeChange = (e) => {
        setWorkModeQuery(e.target.value);
    };

    const handleExperienceChange = (e) => {
        setMinExperience(parseInt(e.target.value));
    };

    const filteredItems = nftItems.filter((item) => {

        // if (item.contractAddress !== '0x797fFC09444EAe34C1700948E41ae185577aFd83') {
        //     return false;
        // }


        // const metadata = JSON.parse(item.metadata);
        // const itemName = metadata.name?.toLowerCase() || '';

        let metadata;
        try {
            metadata = item.metadata ? JSON.parse(item.metadata) : null;
        } catch (error) {
            console.error('Error parsing metadata for item:', item, error);
            return false; // Skip this item if metadata is not valid
        }
    
        if (!metadata || !metadata.attributes) {
            return false; // Skip this item if metadata or attributes are missing
        }
    
        const itemName = metadata.name ? metadata.name.toLowerCase() : '';

        
        const workModeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
        const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
        const experience = experience_Attributes.map(attr => parseInt(attr.value)).filter(exp => !isNaN(exp) && exp !== undefined);
        const hasValidExperience = experience.some(exp => exp >= minExperience || exp === 0 || exp === null);


        const workModeValue = workModeAttribute?.value?.toLowerCase() || '';


        const selectedSkills = selectedSkill.map(skill => skill.label);
       //console.log("select", metadata.attributes);


        // const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
        // const skills = skillsAttributes.flatMap(attr => JSON.parse(attr.value));

        const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
        const skills = skillsAttributes.flatMap(attr => {
            // Check if attr.value is already an object
            return typeof attr.value === 'string' ? JSON.parse(attr.value) : attr.value;
        });
        
        const hasSelectedSkills = selectedSkills.every(skill => skills.includes(skill));
        // console.log("select1",hasSelectedSkills);

        return itemName.includes(searchQuery.toLowerCase()) &&
            (!workModeQuery || (workModeValue.includes(workModeQuery.toLowerCase()))) &&
            hasValidExperience && hasSelectedSkills;
    });

   


    if (loading) {
        return <Loader />;
    }



    return (
        <div>
            {
                //  loading ? (
                //     <Loader />
                // ) : filteredItems.length === 0 ? (

                //         <ErrorPage />

                // ) : 
                (
                    <div className='font-poppins bg-[#101010]  '>
                        <LoginHeader />
                        <div className='Content1 pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}  >
                            <div className='flex justify-center'>
                                <h1 className='text-center text-3xl font-bold'
                                    style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                    }}>Skill Arena</h1>
                            </div>
                            <div className='flex justify-center'>
                                <div className='w-[90%] pt-10 text-white '>
                                    <div className='rounded-full bg-gray-300 flex'>
                                        <input className='w-full outline-none px-10 py-2 text-black w-[95%] rounded-full'
                                            placeholder='Search for Skill Id'
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <i className="fa-solid fa-magnifying-glass text-black w-[5%] mt-3 text-center"></i>
                                    </div>
                                    <p className='md:flex justify-between mt-5 text md:text-xs 2xl:text-base font-bold'>
                                        <p className='md:flex gap-7'>
                                            <p>Experience: <span className='ml-2 text-[#0087a5]'>{minExperience || '0-1'} Years </span></p>
                                            <p>Location: <span className='ml-2 text-[#0087a5]'>Any</span></p>
                                            <p>Contract type: <span className='ml-2 text-[#0087a5]'>{workModeQuery || 'Any'}</span></p>
                                        </p>
                                        <p>Sort by: <span className='ml-2 text-[#0087a5]'>Relevance</span></p>
                                    </p>
                                    <p className='text md:text-xs font-bold mt-7 2xl:text-base'>Search result: <span className='ml-2 text-[#0087a5] '>100</span></p>
                                    <div className='lg:flex gap-7 md:h-screen'>
                                        <div className='lg:w-[30%] flex'>
                                            <div className='w-full rounded-2xl border border-sky-800 mt-5 p-5 pt-6 2xl:pt-12'>
                                                <h1 className='font-bold text-xl 2xl:text-2xl'>All Filter:</h1>
                                                <div className='mt-3 px-3'>
                                                    <h2 className='text-sm 2xl:text-base font-bold'>Work mode:</h2>
                                                    <input type='search'
                                                        placeholder='Choose your preferred mode'
                                                        className='bg-gray-700 outline-none placeholder-[#d3d3d3] rounded-full py-2 px-5 w-[100%] mt-1 text-xs'
                                                        value={workModeQuery}
                                                        onChange={handleWorkModeChange}
                                                    />

                                                    {/* <h2 className='text-sm 2xl:text-base font-bold mt-8'>Locations:</h2>
                                        <div className='flex bg-gray-700 rounded-full mt-1'>
                                            <input type='search' placeholder='Add your preferred location' className='bg-gray-700 outline-none rounded-full py-2 px-5 w-[85%] text-xs'></input>
                                            <i class="fa-solid fa-plus bg-black px-2 py-2 my-1 rounded-lg"></i>
                                        </div>
                    <button className='text-sm text-black px-3 bg-white rounded-full mt-3'>x  chennai</button> */}

                                                    <h2 className='text-sm 2xl:text-base font-bold mt-3'>Experience:</h2>
                                                    <input type='range'
                                                        className='w-full'
                                                        min='0'
                                                        max='20'
                                                        value={minExperience}
                                                        onChange={handleExperienceChange}
                                                    />
                                                    <p className='flex justify-between text-sm'><p>Fresher</p><p>{minExperience || '10+'}   years</p></p>
                                                    <h2 className='text-sm 2xl:text-base font-bold mt-8'>Skill / Tools:</h2>
                                                    <div className='pt-2'>
                                                        <Select
                                                            inputValueSkill={inputValueSkill}
                                                            onInputChange={(value) => setInputValueSkill(value)}
                                                            value={selectedSkill}
                                                            onChange={handleSkill}
                                                            options={SkillOptions}
                                                            placeholder='Add Skills'
                                                            styles={customStyles}

                                                        />
                                                        <div className="h-[3rem] overflow-x-scroll">
                                                            <ul className='flex text-center gap-3 mt-3'>
                                                                {selectedSkill.map((option, index) => (
                                                                    <li className='rounded-full text-xs text-black px-3 py-1 bg-white' key={index}>
                                                                        {option.label}
                                                                        <span
                                                                            className='ml-1'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => removeOption2(index)}>
                                                                            &#x2715;
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {/* <h2 className='text-sm 2xl:text-base font-bold mt-3'>Rating:</h2>
                                        <div className='flex'>
                                            <div className='w-[50%] flex gap-2 py-3'>
                                                {[...Array(starCount)].map((_, index) => (
                                                    <i key={index} className='fa-solid fa-star text-yellow-600 py-1'></i>
                                                ))}
                                            </div>
                                            <div className='w-[50%] grid content-center'>
                                                <div className='flex bg-gray-700 rounded-full justify-around py-1'>
                                                    <button className='px-2 rounded-lg bg-black' onClick={decrementStar}>
                                                        <i className="fa-solid fa-minus"></i>
                                                    </button>
                                                    <p>{starCount}</p>
                                                    <button className='px-2 rounded-lg bg-black' onClick={incrementStar}>
                                                        <i className="fa-solid fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                                </div> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='lg:w-[70%] pt-5 overflow-y-scroll scrollbar'>



                                            {filteredItems.map((item, index) => {
                                                const metadata = JSON.parse(item.metadata);
                                                // console.log('metadata:',metadata);
                                                const ipfsImage = metadata.image.split('://')[1];
                                                //console.log('name', metadata.name);
                                                //console.log('token id', item.token_id);

                                                const locationAttribute = metadata.attributes.find(attr => attr.trait_type === 'location');
                                                //console.log('location', locationAttribute ? locationAttribute.value : 'Not specified');

                                                const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
                                                console.log("Skills Attributes:", skillsAttributes);

                                                const skills = skillsAttributes.map(attr => attr.value);


                                                // const experienceAttribute = metadata.attributes.find(attr => attr.trait_type === 'experience');
                                                // //console.log('experience', experienceAttribute ? experienceAttribute.value : 'Not specified');


                                                const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
                                                const experience = experience_Attributes.map(attr => attr.value);
                                                console.log('experience123123', experience);


                                                //const  project_noAttribute = metadata.attributes.find(attr => attr.trait_type === 'project_no');
                                                //console.log('project', project_noAttribute ? project_noAttribute.value : 'Not specified');

                                                const project_noAttribute = metadata.attributes.find(attr => attr.trait_type === 'project_no');
                                                const projectValue = project_noAttribute && project_noAttribute.value !== undefined ? project_noAttribute.value : 0;

                                                const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                                                const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                                                console.log('prefered_roll', prefered_rolls);
                                                //console.log('prefered roll', preferedAttribute ? preferedAttribute.value : 'Not specified');

                                                const tokenAttribute = metadata.attributes.find(attr => attr.trait_type === 'token_id');
                                                //console.log('toekn', tokenAttribute ? tokenAttribute.value : 'Not specified');

                                                const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
                                                //console.log('contract_type', contract_typeAttribute ? contract_typeAttribute.value : 'Not specified');

                                                const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 7) : '';
                                                
                                                return (

                                                    <div key={index} className='flex flex-col gap-7'>

                                                        <div className='bg-gradient-to-br from-[#0E2DA7] to-[#0EA4A9] p-[2px] rounded-2xl mb-4'>
                                                            <div className='bg-[#101010]  rounded-2xl lg:flex' style={{ backgroundImage: 'url("/assets/FABC.png")' }} >
                                                                <div className='2xl:ml-12 lg:w-[40%] py-5 pl-5'>
                                                                    <h1 className='text-lg 2xl:text-xl font-bold pt-1'>Skill ID : <span className='text-[#0EA4A9]'>{slicedName}</span></h1>
                                                                    <div className='flex pt-2'>
                                                                        <div className='w-[40%]'>
                                                                            <img className='h-[118px]' src={getImageFromMetadata(item.metadata)}></img>
                                                                        </div>
                                                                        <div className='w-[60%] pt-3 text-xs 2xl:text-base'>
                                                                            <div className='flex justify-around'>
                                                                                {skills.map((skillString, index) => {
                                                                                    try {
                                                                                        // Parse the JSON string into an array of skills
                                                                                        const skillsArray = JSON.parse(skillString);

                                                                                        // Render buttons for each skill in the array
                                                                                        return skillsArray.map((skill, skillIndex) => (
                                                                                            <div key={`${index}-${skillIndex}`}>
                                                                                                <button
                                                                                                    className='px-3 py-[2px] rounded-full bg-[#00768B] uppercase  mr-2'
                                                                                                >
                                                                                                    {skill}
                                                                                                </button>
                                                                                            </div>
                                                                                        ));
                                                                                    } catch (error) {
                                                                                        console.error("Error parsing skill:", error);
                                                                                        return null; // Return null if there's an error parsing the JSON
                                                                                    }
                                                                                })}
                                                                            </div>

                                                                            <div className='flex justify-center gap-2 pt-5 text-lg'>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                                <i className='fa-solid fa-star text-yellow-600'></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='lg:w-[60%] grid content-between pb-5 pr-5'>
                                                                    <div className='flex justify-end'>
                                                                        <div className=' -translate-y-[3px] h-[28px]'>
                                                                            <div className='h-[28px] w-[120px] bg-center bg-cover text-xs flex items-center justify-center' style={{ backgroundImage: `url("/assets/time.png")` }}>
                                                                                <p>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
                                                                            </div></div>
                                                                    </div>

                                                                    <div className='flex flex-col ml-10 gap-14'>
                                                                        <div>

                                                                            <p className='flex flex-col lg:flex-row gap-3 md:gap-10 text md:text-sm 2xl:text-base '>
                                                                                {/* <p><i className='fa-solid fa-location-dot mr-2'></i>{locationAttribute ? locationAttribute.value : 'Not specified'}</p> */}
                                                                                <p><i class="fa-solid fa-briefcase mr-2"></i>{experience} years</p>
                                                                                <p><i class="fa-solid fa-diagram-project mr-2"></i>Projects: {projectValue}</p>
                                                                                <button onClick={() => handleViewProfile(item.token_id)} className='px-3 py-[2px] rounded-full bg-[#00768B] uppercase mr-2'>
                                                                                    <p>View Profile</p>
                                                                                </button>
                                                                            </p>
                                                                        </div>

                                                                        <div>
                                                                            <p className='font-bold text md:text-sm 2xl:text-base mt-5 md:mt-0'>Prefered Role:</p>
                                                                            <div className='flex flex-col lg:flex-row gap-3 text-sm 2xl:text-base pt-2'>
                                                                                {prefered_rollsAttribute.map((prefered_roll, index) => {
                                                                                    if (prefered_roll.value) {
                                                                                        try {

                                                                                            const preferedRoles = JSON.parse(prefered_roll.value);
                                                                                            return preferedRoles.map((preferedRole, roleIndex) => (
                                                                                                <div key={index + '-' + roleIndex}>
                                                                                                    <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{preferedRole}</p>
                                                                                                </div>
                                                                                            ));
                                                                                        } catch (error) {
                                                                                            console.error('Error parsing preferred role data:', error);
                                                                                            return null;
                                                                                        }
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {ViewProfilePopup && (
                                                            <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black/5">
                                                                <div className='w-[95%] md:w-[30%] p-3 bg-black rounded-3xl shadow-sm shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                                                    <p className='text-end'><FontAwesomeIcon icon={faXmark} className='cursor-pointer' onClick={closeViewProfilePopup}></FontAwesomeIcon></p>
                                                                    <div className='flex'>
                                                                        <div className=' flex justify-center items-center w-[45%]'><img src='/assets/viewProfile.png'></img></div>
                                                                        <div className='w-[55%] grid content-center'>
                                                                            <p className='text-center '>Pay to view this profile ?</p>
                                                                            <div className='mt-3 flex justify-center'>
                                                                                <button className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white' onClick={() => handleButtonClick(selectedTokenId)} >
                                                                                    Confirm
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}


                                                    </div>

                                                );
                                            })}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <Footer />
        </div>
    )
}