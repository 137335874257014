import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './route';
import { store, persistor } from './store';
import { Provider } from 'react-redux';

import ReactGA from "react-ga4";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'


import {AuthProvider} from "react-auth-kit"

const projectId = '58a936400ecc50aa6c05bd0215a14824'
 
const mainnet = {
  chainId: 42161,  
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',  
  rpcUrl: 'https://arb1.arbitrum.io/rpc'  
}
const polygon = {
  chainId: 137, 
  name: 'Polygon',
  currency: 'MATIC', 
  explorerUrl: 'https://explorer-mainnet.maticvigil.com/', 
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/', 
};

const amoy = {
  chainId: 80002, 
  name: 'Polygon Amoy Testnet',
  currency: 'MATIC', 
  explorerUrl: 'https://amoy.polygonscan.com/', 
  rpcUrl: 'https://rpc-amoy.polygon.technology/', 
};

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://ex.d5art.com', 
  icons: ['https://ex.d5art.com/assets/logo.png']
}
const ethersConfig = defaultConfig({
 
  metadata,

  
  enableEIP6963: true, 
  enableInjected: true, 
  enableCoinbase: true, 
  rpcUrl: '...', 
  defaultChainId: 137, 

})
createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygon,amoy],
  projectId,
  themeVariables: {
  '--w3m-accent	':'#0db1a9',
  },
 
  enableAnalytics: true 
})



const TRACKING_ID   ="G-9K4RJ7X9Q8";



const App = () => {
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "EX D5ART"
    });
  }, []);

  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={false}
    >
      <Router>
        <Provider store={store}>
          <Routers />
        </Provider>
      </Router>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();