import React from 'react';
import ReactApexChart from 'react-apexcharts';

class Chart2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          width: 330,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          position: 'bottom', // Move the legend below the chart
          labels: {
            colors: '#FFFFFF', // Change legend text color to white
          },
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: 'bottom',
                labels: {
                  colors: '#FFFFFF',
                },
              },
            },
          },
        ],
      },
      series: [44, 55, 41, 17, 15],
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={330}
        />
      </div>
    );
  }
}

export default Chart2;
