// // store.js
// import { createStore } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const initialState = {
//   user_email: '',
// };

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'SET_USER_EMAIL':
//       return {
//         ...state,
//         user_email: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const setUserEmail = (email) => ({
//   type: 'SET_USER_EMAIL',
//   payload: email,
// });

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = createStore(persistedReducer);

// const persistor = persistStore(store);

// export { store, persistor };


import { legacy_createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use local storage

const initialState = {
  seeker_email: '',
  recruiter_email: '',
  admin_email:'',
  validator_email:''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEEKER_EMAIL':
      return {
        ...state,
        seeker_email: action.payload,
      };
    case 'SET_RECRUITER_EMAIL':
      return {
        ...state,
        recruiter_email: action.payload,
      };
    case 'SET_ADMIN_EMAIL':
        return {
          ...state,
          admin_email: action.payload,
    };
    case 'SET_VALIDATOR_EMAIL':
      return {
        ...state,
        validator_email: action.payload,
  };
    default:
      return state;
  }
};
export const setSeekerEmail = (email) => ({
  type: 'SET_SEEKER_EMAIL',
  payload: email,
});

export const setRecruiterEmail = (email) => ({
  type: 'SET_RECRUITER_EMAIL',
  payload: email,
});

export const setAdminEmail = (email) => ({
  type: 'SET_ADMIN_EMAIL',
  payload: email,
});
export const setValidatorEmail = (email) => ({
  type: 'SET_VALIDATOR_EMAIL',
  payload: email,
});

const persistConfig = {
  key: 'root', // Key for the persistor
  storage, // Use local storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = legacy_createStore(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };