import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setRecruiterEmail } from '../store';

export default function LoginHeader() {


  const email = useSelector((state) => state.recruiter_email);
  console.log("Email Logged In",email)

  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(setRecruiterEmail(""));
    window.location = '/';
  };

  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div>
      <nav className='flex p-7 lg:px-10 font-poppins'>
        <div className='w-[85%] lg:w-[15%]'>
          <div className='w-[80%]'>
          <a href='/RMS/Recruiter'>  <img src='/assets/D5ART_T White_PNG_new.png' className='h-14'></img> </a>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] hidden md:table'>
          <div className='flex'>
            <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-16 mt-3 '>
              <a href='/RMS/Recruiter'>Home</a>
              <a href='https://edu.d5art.com/' className='hidden lg:inline'>Courses</a>
              <a href='/RMS/Recruiter/SkillArena'>Skill Exchange</a>
             
              <a href='/ex/recruiter/faq' className='hidden lg:inline'>FAQ</a>
            </div>
            <div className='w-[20%] '>
              <div className="relative">
                <button className="flex px-5 py-2  text-white" onClick={handleDropdownToggle}>
                  <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                  <div className='absolute -translate-x-2 -translate-y-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>{email.substr(0, 10) + "...."}
                  <i className="fa-solid fa-chevron-down pl-3 mt-1"></i>
                </button>

                {dropdownVisible && (
                  <div className="absolute top-full z-10 left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md animate__animated animate__fadeInUp ">
                    <div className='py-2 px-9 rounded-md bg-black text-white'>
                      <a href='/RMS/Recruiter/RecruiterDashboard' className='text-center'>
                      <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                      <button onClick={Logout} className='text-center'>
                      <i className='fa-solid fa-door-open pr-3'></i>Log out
                    </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown}>
              <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
              <div className='absolute -translate-y-10 -translate-x-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>
            </button>

            {isDropdown && (
              <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
                <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                  <a href='/RMS/Recruiter'>Home</a>
                  <a href='https://edu.d5art.com/' className='hidden lg:inline'>Courses</a>
                  <a href='/RMS/Recruiter/SkillArena'>Skill Exchange</a>
             
                  <a href='/ex/recruiter/faq' className='hidden lg:inline'>FAQ</a>
                  <a href='/RMS/Recruiter/RecruiterDashboard' className='text-center'>
                  <i className="fa-solid fa-user pr-3"></i> Dashboard</a>
                  <button onClick={Logout} className='text-center'>
                  <i className='fa-solid fa-door-open pr-3'></i>Log out
                </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
