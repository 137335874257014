import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

export default function LandingPopup() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <div className={`flex justify-end items-end h-screen  inset-0 fixed z-50 transition-transform text-white duration-500 ease-in-out bg-black/50 backdrop-blur-sm overflow-hidden
             ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
            <div>
                <div className='flex justify-end px-2 md:px-5'>
                    <button
                        className='translate-y-[70px] text-gray-300'
                        onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmarkCircle} />
                    </button>
                </div>
                <div className='md:translate-y-[96px] translate-y-[80px] translate-x-3 md:translate-x-6 font-bricolagegrotesque '>
                    <p className=' text hidden md:block'>
                        Kindly update your profile to explore Skillmarket <br></br> place.
                    </p>
                    <p className=' text-sm block md:hidden'>
                        Kindly update your profile to explore <br></br> Skillmarket  place.
                    </p>
                    <a href='/updatedetails'>
                    <button className='-translate-y-5 md:translate-y-0 ml-32 bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[1px] md:p-[2px] rounded-lg'>
                        <button className='bg-black px-2  md:px-4 text-xs md:text-sm py-1 rounded-lg'>Update Profile</button>
                        </button>
                        </a>
                </div>
                <img src='/assets/message.png' className='md:w-[38rem] ' alt='message' />

            </div>
        </div>
    );
}
