import React, { useState } from "react";
import Chart from "react-apexcharts";
import "./Admin.css";
import AdminHeader from './AdminHeader'
import axios from 'axios';
import Spinners from '../Spinners';
import { Link } from 'react-router-dom'; 
import { toast, Toaster } from 'react-hot-toast';

const AdminDashboard = () => {
  const [state, setState] = useState({
    options: {
      colors: ["#2D9CDB", "#FF5B5B"], 
      stroke: { curve: "smooth", width: 2 },
      chart: {
        id: "",
      },
      xaxis: {
        categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        labels: {
          style: {
            colors: "#FFFFFF", 
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFFFFF", 
          },
        },
      },
      legend: {
        labels: {
          colors: "#FFFFFF", 
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: [0, 100, 200, 300, 400],
      },
      {
        name: "series-2",
        data: [100, 200, 300, 400, 500],
      },
    ],
  });

  const [state1, setState1] = useState({
    options1: {
      chart: {
        toolbar: {
          show: false, 
        },
      },
      labels: ["A"], 
      legend: {
        show: false, 
      },
    },
    series1: [44], 
  });



  return (
    <div>
          <div
        className="min-h-screen bg-cover font-poppins text-white "
        style={{ backgroundImage: "url('/adminassets/bg.png')" }}
      >
          <AdminHeader />
        <Toaster />
    
        <div className="p-5 md:p-10">
          <p
            className="font-semibold text-lg"
            style={{
              backgroundImage:
                "linear-gradient(to right, #FFFFFF 0%, #2DB6A4 10%, #26ADA6 30%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Dashboard
          </p>

          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
            {/* 1 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative ">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 10.png" className="w-20" />
                <p>Registered Users</p>
                <p className="text-2xl font-semibold">18K+</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} 
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* 2 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative ">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 11.png" className="w-20" />
                <p>Active Users</p>
                <p className="text-2xl font-semibold">16</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]}
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* 3 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative ">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 9.png" className="w-20" />
                <p>Certified Candidates</p>
                <p className="text-2xl font-semibold">487</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} 
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* 4 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 12.png" className="w-20" />
                <p>Total Question Sets</p>
                <p className="text-2xl font-semibold">50</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} 
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* 5 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 13.png" className="w-20" />
                <p>Placed Candidates</p>
                <p className="text-2xl font-semibold">49</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} 
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* total revenue */}
            <div className=" lg:row-span-3 md:col-span-2 lg:col-span-3 bg-[#2D2D2D] md:p-10 p-5 rounded-3xl ">
              <div>
                <div>
                  <p className="font-semibold text-[#4891B4] ">Total Revenue</p>
                </div>
                <div className="w-full">
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="line"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            {/* 6 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 14.png" className="w-20" />
                <p>No.of courses</p>
                <p className="text-2xl font-semibold">12</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} // Pass the series directly
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
            {/* 7 */}
            <div className=" bg-[#2D2D2D] flex p-3 rounded-3xl relative">
              <div className="w-[70%] flex flex-col gap-2 items-center">
                <img src="/adminassets/image 15.png" className="w-20" />
                <p>Total Paid Users</p>
                <p className="text-2xl font-semibold">8</p>
              </div>
              <div className="w-[30%] absolute top-2 right-3">
                <Chart
                  options={state1.options1}
                  series={[...state1.series1]} // Pass the series directly
                  type="donut"
                  width="110px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;