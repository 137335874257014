// import React, { useState, useEffect } from 'react'

// export default function Payloader() {
//     const [dotCount, setDotCount] = useState(0);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setDotCount((prevDotCount) => (prevDotCount + 1) % 4);
//         }, 500);

//         return () => clearInterval(interval);
//     }, []);

//     //bg-black/75 backdrop-blur-sm

//     return (
//         <div className='flex justify-center items-center bg-[#131519] h-screen'>
//             <div>
//                 <div><img src='/assets/flipping-coins-progress2.gif' className='w-[50%] mx-auto'></img></div>
//                 <p className='text-yellow-500 text-xl mt-3 font-bricolagegrotesque text-center'>Please wait Payment in process{'.'.repeat(dotCount)}</p>
//             </div>
//         </div>
//     )
// }

import React, { useState, useEffect } from 'react'

export default function Payloader() {
    const [dotCount, setDotCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDotCount((prevDotCount) => (prevDotCount + 1) % 4);
        }, 500);

        return () => clearInterval(interval);
    }, []);
  
    return (
        <div className='flex justify-center items-center bg-black/50 backdrop-blur-sm h-screen'>
            <div>
                <div><img src='/assets/flipping-coins-progress2.gif' className='w-[50%] mx-auto rounded-full'></img></div>
                <p className='text-yellow-500 text-xl mt-3 font-bricolagegrotesque text-center'>Please wait Payment in process{'.'.repeat(dotCount)}</p>
            </div>
        </div>
    )
}