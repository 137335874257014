import React, { useEffect, useState } from 'react'
//import Header from './Header';
import ValidatorHeader from './ValidatorHeader';
//import './App.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Footer from '../Footer';                                
import { toast, Toaster } from 'react-hot-toast';

import CountryList from "../CountryCode.json";
import StateList from "../States.json";
import { NavLink } from 'react-router-dom';
import CurrencyList from '../CurrencyList.json';
import Select from 'react-select';
import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';


export default function RecruiterDashboard() {
    const [validatorName, setValidatorName] = useState('');
    const [validatorEmail, setValidatorEmail] = useState('');
    const [validatorContractType, setValidatorContractType] = useState('');
    const [validatorAvatar, setValidatorAvatar] = useState('');
    const [validatorMobileNumber, setValidatorMobileNumber] = useState('');

    
   
    const [validatorWalletAddress, setValidatorWalletAddress] = useState('');
    const [validatorSkills, setValidatorSkills] = useState([]);



    const [recruiterOfferDetails, setRecruiterOfferDetails] = useState([]);

    const [balance, setBalance] = useState(null);
    const [mappedTransfers, setMappedTransfers] = useState([]);

    const [seekerName, setSeekerName] = useState([]);
    const [tokens, setTokens] = useState('');
    const [seekerContractType, setSeekerContractType] = useState('');
    const [seekerDetails, setSeekerDetails] = useState([]);
    const [SeekerEmail, setSeekerEmail] = useState('');
    const [cartId, setCartId] = useState(null);

    console.log('888888888888', cartId);



    const [fullName, setFullName] = useState('');
    const [organization, setOrganization] = useState('');
    const [mailId, setMailId] = useState('');
    const [roleInHiring, setRoleInHiring] = useState('');
    const [location, setLocation] = useState('');
    const [destination, setDestination] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const [data, setData] = useState([]);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setclosedCount] = useState(0);
    const [resolvedCount, setResolvedCount] = useState(0);

    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [months, setMonths] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [filteredStateOptions, setFilteredStateOptions] = useState([]);


    const email = useSelector((state) => state.validator_email);
    console.log("Email Logged In", email)
    const dispatch = useDispatch();

    const Logout = () => {


        dispatch(setSeekerEmail(""));
        window.location = '/';
    };





    //ticket


    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5); 


    const indexOfLastItem = currentPage * entitiesPerPage;
    const indexOfFirstItem = indexOfLastItem - entitiesPerPage;
    const currentData = Array.isArray(data.data) ? data.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    console.log("currentdata", currentData)
    const emptyRowCount = entitiesPerPage - currentData.length;
    console.log("emptyRowCount", emptyRowCount)

    const handleNextClickTicket = () => {
        if (currentPage < Math.ceil(data.data.length / entitiesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClickTicket = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset currentPage when entitiesPerPage changes
    };



    const [formData, setFormData] = useState({
        mailId: email,
        subject: '',
        category: '',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ex.d5art.com/api/ValidatorRaiseTickets', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                
                console.log('Ticket submitted successfully');
                toast.success('Ticket Submitted Successfully');
                window.location.reload(); 
            } else {
               
                console.error('Error submitting ticket');
            }
        } catch (error) {
            console.error('Error submitting ticket:', error);
        }
    };

    useEffect(() => {

        const fetchData1 = async () => {
            try {
                // const mail_id = "createsomething100@gmail.com";
                const mail_id = email;
                const response = await fetch(`https://ex.d5art.com/api/SendDataValidatorRaiseTickets/?mail_id=${mail_id}`);
                console.log('3333333333',mail_id)
                const result = await response.json();
                //console.log('666666',result)

                const openIssues = result.data.filter(item => item.status === 'Open');
                const openCount = openIssues.length;

                const closedIssues = result.data.filter(item => item.status === 'Closed');
                const closedCount = closedIssues.length;

                console.log('Number of Open Issues:', openCount);
                console.log('Number of Closed Issues:', closedCount);

                setData(result);
                setOpenCount(openCount);
                setclosedCount(closedCount);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        fetchData1();
    }, [])



// country and state

    useEffect(() => {
        setCountries(CountryList);
        setStates(StateList);

    }, []);

    useEffect(() => {
        if (location) {
            const filteredStates = states.filter(state => state.country_name === location);
            setFilteredStateOptions(filteredStates);
        }
    }, [location, states]);

     // connect wallet

     const { address, chainId, isConnected } = useWeb3ModalAccount();

     console.log("address, chainId, isConnected", address, chainId, isConnected);


     


    // my profile

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/validator_dashboard?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('5555', data.data.skills);


                if (response.ok) {

                    setValidatorName(data.data.name);
                    setValidatorEmail(data.data.email);
                    setValidatorContractType(data.data.state);
                    setValidatorWalletAddress(data.data.walletaddress);
                    setValidatorAvatar(data.data.avatar);
                    setValidatorMobileNumber(data.data.mobileNumber);

                    setValidatorSkills(data.data.skills);                    
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);


    const handleUpdateClick = async () => {
        try {

            const response = await fetch(`https://ex.d5art.com/api/update_validator_details?email=${email}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName,
                    location,
                    destination,
                    mobileNumber,
                    originalWalletAddress: address
                }),

            });
            const responseBody = await response.json();
            //console.log('response body:', body);
            console.log('response body:', responseBody);
            if (response.ok) {
                console.log('Data updated successfully');
                toast.success('Data Updated Successfully');
                window.location.reload();

            } else {
                if (responseBody.error === 'Wallet Address already exists') {
                    toast.error('Wallet Address already exists');
                } else {
                    console.error('Error updating data:', response.statusText);
                    toast.error(`Error updating data: ${responseBody.message}`);
                }
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    console.log('Type of data:', typeof data);


   





    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar)
    }

    const [activeTab, setActiveTab] = useState('nft');
    const [active, setActive] = useState('div1');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleTabClick = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
        if (tab === 'user') {
            handleAnchorClick('div1');
        } else {
            switch (tab) {
                case 'Ticket':
                    handleAnchorClick('div2');
                    break;
            
                default:
                    handleAnchorClick('div1');
            }
        }
    };

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyles = {
        width: '100%',
        paddingTop: '10px',
        color: 'white',
        backgroundImage: windowWidth <= 767 ? 'none' : 'url("./assets/image2.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const iconMap = {
        'nft': (
            <svg className="w-5 h-5 mr-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
        ),
     
        'Ticket': (
            <i className="fa-solid fa-question text-xl mr-5"></i>
        ),
        
    };

    const TabButton = ({ label, tab }) => {
        return (
            <button
                className={`flex pl-12 w-full py-2.5  ${tab === activeTab ? 'inner-shadow' : ''}`}
                onClick={() => handleTabClick(tab)}
            >
                {iconMap[tab]}
                <span className="ml-2">{label}</span>
            </button>
        );
    };

























    return (
        <div className=' bg-[#191919] font-poppins ' style={{backgroundImage:'url("/assets/FABC.png")'}}>
            <ValidatorHeader />
            <Toaster />
            <div className='p-5 lg:flex  pb-10'>
                <div class="hidden md:block w-[25%]">
                    <nav className='h-[39.5rem] 2xl:h-[40rem] bg-black border border-sky-700 rounded-3xl text-white grid content-around'>
                        <div>
                            <div className='flex py-5'>
                                <div className='w-[35%] flex justify-end'><div><img className='w-[4rem]' src={validatorAvatar ? (validatorAvatar.startsWith('./') ? validatorAvatar.slice(1) : validatorAvatar) : ''}></img></div>
                                </div>
                                <div className='w-[65%] pl-2 pt-3'>
                                    <h1 className='text-lg font-bold'>{validatorName}</h1>
                                    <p className='text-xs text-gray-400'> {validatorContractType}</p>

                                </div>
                            </div>
                            <div>
                                <TabButton label='My Profile' tab='nft' />
                                
                                <TabButton label='Tickets / Queries' tab='Ticket' />

                                <div />

                            </div>
                        </div>
                        <div className=''>
                           {/* <TabButton
                                label='Sign out'
                                tab='Signout'
                                onClick={() => {
                                    handleTabClick('Signout');
                                   
                                }}
                            /> */}
                        </div>
                    </nav>
                </div>
                <div className='block md:hidden'>
                    <button onClick={handleOpenNavbar}>
                        <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                        </svg>
                    </button>
                    {isOpenNavbar && (
                        <nav className='w-[80%] z-10 absolute border border-sky-700 rounded-3xl bg-black text-white grid content-around animate__animated animate__fadeInLeft'>
                            <div>
                                <div className='flex py-5'>
                                    <div className='w-[35%] flex justify-end'><div><img className='w-[4rem]' src={validatorAvatar ? (validatorAvatar.startsWith('./') ? validatorAvatar.slice(1) : validatorAvatar) : ''}></img></div>
                                    </div>
                                    <div className='w-[65%] pl-2 pt-3'>
                                        <h1 className='text-lg font-bold'>{validatorName}</h1>
                                        <p className='text-xs text-gray-400'>  {validatorContractType}</p>

                                    </div>
                                </div>
                                <div className='py-5'>
                                    <TabButton label='My Profile' tab='nft' />
                                  
                                    <TabButton label='Tickets / Queries' tab='Ticket' />

                                    <div />

                                </div>
                            </div>
                            <div className=''>
                               {/* <TabButton
                                    label='Sign out'
                                    tab='Signout'
                                    onClick={() => {
                                        handleTabClick('Signout');
                                        
                                    }}
                                /> */}
                            </div>
                        </nav>
                    )}
                </div>
                <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:absolute h-screen md:w-[72%] text-white right-0 top-32 scrollbar '>
                    <div className='flex justify-center mt-3 md:mt-28'>
                        <div className='w-[90%] md:w-[65%] h-[25rem] rounded-2xl  overflow-hidden group bg-center ' style={{ backgroundImage: `url('/assets/proofile card 2.png')` }}>
                            <div className='flex justify-center pt-5'><img src='/assets/image 3yr.png' className='w-[22.5rem] absolute'></img>                            </div>
                            <div className='flex justify-center'>
                                <div className='w-[80%] h-[17.5rem] bg-white/40 backdrop-blur-md p-3 md:p-4 md:px-7 rounded-2xl translate-y-[19.5rem] group-hover:translate-y-10 transform-transition duration-700 ease-in-out'>
                                    <div className='flex justify-between '>
                                        <p><p className='font-black'>{validatorName} </p>
                                            <p className='text-[10px]'>{validatorEmail}</p>
                                        </p>
                                        <div>
                                            <button className='px-2  py-1 rounded-lg bg-white'>
                                                <i className='fa-solid fa-paintbrush text-black'></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex gap-3 md:gap-0 py-5 text-xs md:text-sm'>
                                        <div className='w-[50%] flex flex-col gap-5 md:pr-5  '>
                                            <div className='flex border-b '>
                                                <i class="fa-solid fa-pen-clip mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder={validatorName || 'Full name'}
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                ></input>
                                            </div>


                                           {/* <div className='flex border-b'>
                                                <i class="fa-solid fa-building-user mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder='Organization'
                                                    value={organization}
                                                    onChange={(e) => setOrganization(e.target.value)}
                                                ></input>
                                            </div>
                                            
                                            <div className='flex border-b'>
                                                <i class="fa-solid fa-people-group mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder= {validatorSkills}
                                                    value={roleInHiring}
                                                    onChange={(e) => setRoleInHiring(e.target.value)}></input>
                            </div> */}

                                            <select class="md:py-0.5 w-full text-white  bg-transparent border-b outline-none overflow-hidden overflow-y-scroll"
                                          value={location}
                                          onChange={(e) => setLocation(e.target.value)}
                                      >
                                          <option className='bg-black'>Location</option>
                                          {countries.map((country, index) => (
                                              <option className='bg-black' key={`${country.country_code}-${country.label}-${index}`} value={country.label}>
                                                  {country.label} {country.country_flag}
                                              </option>
                                          ))}
                                        </select>
                                        
                                        <div className='flex '>
                                           <div className='flex items-center w-[5%]'><i className='fa-solid fa-wallet'></i>
                                           </div> 
                                                <div className='  flex justify-center w-[95%]' >
                                                    <w3m-button balance="hide" />

                                                </div>
                                            </div>

                                        </div>



                                        <div className='w-[50%]  md:pl-5 flex flex-col gap-5 '>
                                    
                                        <div className='flex border-b'>
                                                <i class="fa-solid fa-phone mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder={validatorMobileNumber || 'Mobile Number'}
                                                    value={mobileNumber}
                                                    onChange={(e) => setMobileNumber(e.target.value)}
                                                ></input>
                                            </div>

                                            <select class="py-0.5 w-full text-white bg-transparent border-b outline-none "
                                                value={destination}
                                                onChange={(e) => setDestination(e.target.value)}
                                            >
                                                <option className='bg-black'>States</option>
                                                {filteredStateOptions.map((state, index) => (
                                                    <option className='bg-black' key={`${state.state_code}-${state.name}-${index}`} value={state.name}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                            
                                            <button className='bg-[#4E67E7] text-white py-2 px-4 rounded-full hover:bg-blue-700' onClick={handleUpdateClick}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


               




               



              



                <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:absolute text-white md:w-[72%] right-0 top-32 scrollbar'>
                    <div >
                        <div className='mt-5 md:m-5  md md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] backdrop-blur-md h-[39rem] overflow-hidden overflow-y-scroll'>
                            <h1 className='text-center font-black mt-2 text-xl text-[#F29D38]'>Raise New Query</h1>
                            <div className='md:flex py-10'>
                                <div className='md:w-[50%] md:pl-5 flex flex-col gap-5'>
                                    {/* <input
                                        type='email'
                                        name='mailId'
                                        placeholder='Mail ID'
                                        value={formData.mailId}
                                        onChange={handleChange}
                                        className='rounded-2xl px-5 py-2 bg-black border border-gray-700 outline-none'
                                    /> */}
                                    <input
                                        type='text'
                                        name='subject'
                                        placeholder='Subject'
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className='rounded-2xl px-5 py-2 bg-black border border-gray-700 outline-none'
                                    />
                                    <select
                                        name='category'
                                        value={formData.category}
                                        onChange={handleChange}
                                        className='bg-black px-5 text-gray-400 outline-none py-2 rounded-2xl border border-gray-700'
                                    >
                                        <option value="Job Apply Issue">Job Apply Issue</option>
                                        <option value="Suggestion">Suggestion</option>
                                        <option value="Wallet Issue">Wallet Issue</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>
                                <div className='md:w-[50%] md:pl-10 md:pr-5 mt-5 md:mt-0'>
                                    <div className='border border-gray-700 p-3 rounded-2xl h-full'>
                                        <textarea
                                            name='description'
                                            placeholder='Explain your query in detail..'
                                            type="text"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className='outline-none h-[70%] w-full bg-transparent'
                                        ></textarea>
                                        <div className='flex justify-end'>
                                            <button type='submit' onClick={handleSubmit} className='px-5 py-1 bg-[#0087A57D] rounded-lg text-xs'>
                                                Raise a Ticket
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 className='flex justify-between px-5 overflow-hidden overflow-y-scroll'>
                                <h1 className='font-bold'>Previous Query</h1>
                                <p className='flex gap-5 text-xs font-bold mt-1'>
                                    <p>Open: <span className='text-[#0EA4A9]'>{openCount}</span></p>
                                    <p>Resolved: <span className='text-[#0EA4A9]'>{closedCount}</span></p>
                                </p>
                            </h1>

                             <div className='w-full  rounded-2xl p-5 '>
                                <div className='flex justify-end'>
                                    <p className='text-end text-sm px-3 mb-2'>Show no of entity
                                        <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                            <option className='text-black' value="5">5</option>
                                            <option className='text-black' value="10">10</option>
                                            <option className='text-black' value="15">15</option>
                                        </select>
                                    </p>
                                </div>
                                <div className='mb-5 rounded-xl overflow-hidden overflow-x-auto'>
                                    <table className='w-full text-center'>
                                        <thead>
                                            <tr>
                                                <th className='py-2  px-3'>S.No</th>
                                                <th className=''>Subject</th>
                                                <th className=''>Date & time</th>
                                                <th className=''>Token Id</th>
                                                <th className='py-2 '>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((issue, index) => (
                                                <tr key={index} className='text-sm bg-black'>
                                                    <td className='py-3 text-center'>{indexOfFirstItem + index + 1}.</td>
                                                    <td>{issue.subject}</td>
                                                    <td>{new Date(issue.date_created).toLocaleString()}</td>
                                                    <td># {issue.token_id}</td>
                                                    <td className={`text-${issue.status === 'Open' ? 'yellow-500' : 'green-500'}`}>
                                                        {issue.status}
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* Add empty rows if needed */}
                                            {Array.from({ length: emptyRowCount }).map((_, index) => (
                                                <tr key={`empty-${index}`} className='h-16'>
                                                    <td colSpan="5">&nbsp;</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-2 flex justify-between text-xs px-3'>
                                    <button className='bg-white/30 rounded-md px-5 py-1' onClick={handlePrevClickTicket} disabled={currentPage === 1}>Prev</button>
                                    <button className='bg-white/30 rounded-md px-5 py-1' onClick={handleNextClickTicket} disabled={currentData.length < entitiesPerPage}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}