import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import './Admin.css';

export default function RecruiterList() {
    const [recruiters, setRecruiters] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    useEffect(() => {
        axios.get('https://ex.d5art.com/api/get_employer_details')
            .then(response => {
                console.log('Fetched recruiters:', response.data);
                setRecruiters(response.data);
            })
            .catch(error => {
                console.error('Error fetching recruiter details:', error);
            });
    }, []);

    const filteredRecruiters = recruiters.filter(recruiter =>
        recruiter.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredRecruiters.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filteredRecruiters.slice(startIndex, endIndex);

    const handlePrevious = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className='font-poppins bg-[#171616] w-screen pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <AdminHeader />
            <div className='flex justify-center'>
                <div className='w-[90%] pt-10 flex flex-col gap-5'>
                    <div className='flex justify-start'>
                        <p className='text-lg font-bold' style={{
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            backgroundImage: 'linear-gradient(to right, #26ADA6, #FFFFFF)',
                        }}>Recruiter List</p>
                    </div>
                    <div className='flex gap-5'>
                        <div className='bg-[#2D2D2D] text-white flex rounded-full md:w-[40%]'>
                            <i className='fa-solid fa-magnifying-glass text-xl text-gray-400 w-[15%] md:w-[10%] text-center rounded-full mt-1'></i>
                            <input
                                type='search'
                                className='w-[85%] md:w-[90%] py-2 bg-transparent outline-none'
                                placeholder='Search by Name'
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div>
                            <button className='bg-[#26ADA6] px-3 py-2 text-white rounded-full'>Search</button>
                        </div>
                    </div>
                    <div className='text-white'>
                        <p className='flex justify-between'>
                            <p className='flex'>Filter <i className="fa-solid fa-filter mt-1 px-2"></i> <p className='pl-5'>Sort by: <span className='font-bold'>Name</span><i className="fa-solid fa-arrow-down-z-a px-2"></i></p></p>
                            <p className='cursor-pointer'>See All List<i className="fa-solid fa-angle-right px-2"></i></p>
                        </p>
                        <div className='w-full mt-3 bg-[#2D2D2D] rounded-2xl overflow-hidden overflow-x-auto'>
                            <table className='w-full text-center rounded-2xl '>
                                <thead>
                                    <tr className='text-[#26ADA6]'>
                                        <th className='py-4'>S.No</th>
                                        <th>Recruiter Name</th>
                                        <th>Company</th>
                                        <th>Location</th>
                                        <th>Designation</th>
                                        <th>Remove Account</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((recruiter, index) => (
                                        <tr key={index} className='text-sm'>
                                            <td className='py-2'>{startIndex + index + 1}.</td>
                                            <td>{recruiter.name}</td>
                                            <td>{recruiter.organizationName}</td>
                                            <td>{recruiter.country}, {recruiter.state}</td>
                                            <td>{recruiter.yourDesignation}</td>
                                            <td>
                                                <button className='text-[#FF0101] border border-[#FF0101] rounded-full py-1 px-3'>
                                                    Remove Account
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {[...Array(itemsPerPage - currentData.length)].map((_, index) => (
                                        <tr key={index + currentData.length} className='text-sm'>
                                            <td className='py-2'>{startIndex + currentData.length + index + 1}.</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='flex justify-center gap-5  py-5'>
                                <button onClick={handlePrevious}>
                                    <i className="fa-solid fa-chevron-left mt-1"></i>
                                </button>
                                {[...Array(totalPages)].map((_, index) => (
                                    <p
                                        key={index}
                                        onClick={() => setCurrentPage(index + 1)}
                                        className={currentPage === index + 1 ? 'text-yellow-600 cursor-pointer' : 'cursor-pointer'}
                                    >
                                        {index + 1}
                                    </p>
                                ))}
                                <button onClick={handleNext}>
                                    <i className="fa-solid fa-chevron-right mt-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
