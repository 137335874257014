
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import ValidatorHeader from './ValidatorHeader';
import Footer from '../Footer';                                
import Skillslist from '../Seeker/Skills.json';
import axios from 'axios';

export default function Creationpage() {
    const [questions, setQuestions] = useState([{ id: 1, question: '', type: 'type1', options: ['', '', '', ''] }]);
    const [selectedSkills, setSelectedSkills] = useState(null);
    const validatorEmail = useSelector((state) => state.validator_email);
  //  console.log(validatorEmail)

    const handleAddQuestion = () => {
        const newQuestionId = questions.length + 1;
        setQuestions([...questions, { id: newQuestionId, question: '', type: 'type1', options: ['', '', '', ''] }]);
    };

    const handleRemoveQuestion = (id) => {
        setQuestions(questions.filter(question => question.id !== id));
    };

    const handleChange = (selectedOption, id) => {
        const selectedQuestionType = selectedOption.value;
        setQuestions(questions.map(question => {
            if (question.id === id) {
                return { 
                    ...question, 
                    type: selectedQuestionType,
                    questionType: selectedQuestionType === 'type1' ? 'Multiple Choice' 
                                 : selectedQuestionType === 'type2' ? 'Descriptive' 
                                 : 'Coding' 
                };
            }
            return question;
        }));
    };

    const handleOptionChange = (event, questionId, optionIndex) => {
        const { value } = event.target;
        setQuestions(questions.map(question => {
            if (question.id === questionId) {
                const updatedOptions = [...question.options];
                updatedOptions[optionIndex] = value;
                return { ...question, options: updatedOptions };
            }
            return question;
        }));
    };

    const handleSelect = (selectedOption) => {
        setSelectedSkills(selectedOption);
    };

    const handleQuestionChange = (event, id) => {
        const { value } = event.target;
        setQuestions(questions.map(question => {
            if (question.id === id) {
                return { ...question, question: value };
            }
            return question;
        }));
    };


    const handleSubmit = async () => {
        const data = {
            setName: selectedSkills.value,
            questions: questions.map(question => ({ ...question, points: calculatePoints(question) , quesitontype: question.type,
        })),
            userEmail: validatorEmail,
        };
        console.log("data", data)                                     
        try {
            const response = await axios.post('https://ex.d5art.com/api/storeQA', data);
            console.log('Response:', response.data);

            if (response.data.success) {
                console.log('Question set stored successfully!');
                setTimeout(() => {
                    window.location.href = '/RMS/Validator';
                }, 1200);
            } else {
                console.log('Submission failed');
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const renderQuestionTypeDiv = (question) => {
        if (question.type === 'type1') { // Multiple Choice
            return (
                <div className={`border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl flex flex-col md:flex-row gap-3 md:gap-0 2xl:text-xl transition-height duration-500 ease-in-out ${question.type === 'type1' ? 'h-44 p-5 md:p-10' : 'h-0 px-0 py-0'} overflow-hidden`}>
                <div className='md:w-[100%] flex flex-row gap-3 md:gap-10' style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {question.options.map((option, index) => (
                        <input
                            key={index}
                            type="text"
                            value={option}
                            onChange={(e) => handleOptionChange(e, question.id, index)}
                            className="w-full bg-transparent border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] rounded-3xl outline-none p-7"
                            placeholder={`Option ${index + 1}`}
                        />
                    ))}
                </div>
            </div>
            );
        } else if (question.type === 'type2' || question.type === 'type3') { // Descriptive or Coding
            return (
                <div className={`transition-height duration-300 ease-in-out ${question.type === 'type2' || question.type === 'type3' ? 'h-48' : 'h-0'} overflow-hidden`}>
                    <textarea className='w-full h-44 bg-transparent border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] rounded-3xl outline-none p-7' placeholder={question.type === 'type2' ? 'Describe your answer' : 'Write the Condition / Comments for the code'}></textarea>
                </div>
            );
        }
        return null;
    };

    const SkillOptions = Skillslist;

    // const countryOptions = [
    //     { value: 'React', label: 'React' },
    //     { value: 'Node', label: 'Node' },
    //     { value: 'Python', label: 'Python' },
    //     { value: 'Html', label: 'Html' },
    //     { value: 'Java', label: 'Java' },
    //     { value: 'UI/UX Design', label: 'UI/UX Design' },
    //     { value: 'Mern', label: 'Mern' },
    //     { value: 'Solidity', label: 'Solidity' },
    //     { value: 'FullStack', label: 'FullStack' },
    //     { value: 'Rust', label: 'Rust' },
    //     { value: 'GoLang', label: 'GoLang' },
    //     { value: 'Blockcahin Basics', label: 'Blockcahin Basics' },
    //     { value: 'Mastering Blockchain', label: 'Mastering Blockchain' },
    //     { value: 'Ruby', label: 'Ruby' },
    // ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #04F7FF80',
            boxShadow: '0 0 2px #04F7FF80, 0 0 5px #04F7FF80, 0 0 5px #04F7FF80, 0 0 2px #04F7FF80',
            backgroundColor: '#090909',
            borderRadius: '12.5px',
            color: 'white',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',
        }),
    };


    const QuestionOptions = [
        { value: 'type1', label: 'Multiple Choice' },
        { value: 'type2', label: 'Descriptive' },
        { value: 'type3', label: 'Coding' },
    ];

    const calculatePoints = (question) => {
        return question.type === 'type3' ? 5 : 1;
    };

    return (
        <div className='font-poppins  bg-[#191919]  ' style={{backgroundImage:'url("/assets/FABC.png")'}}>
        <ValidatorHeader />
            <div className='flex justify-center py-10   '>
                <div className='flex flex-col w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
                {questions.map(question => (
                    <div key={question.id} className='py-12 flex justify-center text-white'>
                        <div className='w-[90%] 2xl:w-[85%] flex flex-col gap-5'>
                            <div className='flex gap-10'>
                                <p className='text-[#04f7ff] text-2xl font-bold'>Question Set Name: </p>
                                <Select
                                    className='w-[12rem]'
                                    value={selectedSkills}
                                    onChange={handleSelect}
                                    options={SkillOptions}
                                    placeholder='Choose Domain'
                                    styles={customStyles}
                                />
                            </div>
                            <div className='flex justify-between w-full'>
                                <div><p className='text-2xl font-bold'>Question - {question.id} :</p></div>
                                <div className='w-[40%] md:w-[17.5%] text-center'>
                                    <Select
                                        value={QuestionOptions.find(option => option.value === question.type)}
                                        onChange={(selectedOption) => handleChange(selectedOption, question.id)}
                                        options={QuestionOptions}
                                        placeholder='Question type'
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='px-10 py-5 text-lg font-bold bg-white/15 rounded-2xl border-[2px] border-[#04F7FF80]'>
                                <input
                                    type='text'
                                    value={question.question}
                                    onChange={(event) => handleQuestionChange(event, question.id)}
                                    className='w-full bg-transparent border border-[#04F7FF80] shadow-md shadow-[#04F7FF80] rounded-3xl outline-none p-7'
                                    placeholder='Enter your question'
                                />
                            </div>
                            {renderQuestionTypeDiv(question)}
                            <div className='flex justify-end'>
                                <p>
                                Point <span className='ml-5 text-[#FFBA00] border border-[#FFBA00] rounded-full px-3 py-1'>{calculatePoints(question)}</span>
                                </p>
                            </div>
                            {questions.length > 1 && (
                                <div className='flex justify-end'>
                                    <button onClick={() => handleRemoveQuestion(question.id)} className='px-3 py-1 text-sm text-white font-semibold rounded-md bg-red-500 hover:bg-red-600'>Remove</button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                
                    <div className='flex justify-center py-4'>
                        <button onClick={handleAddQuestion} className='px-3 py-1 text-sm text-white font-semibold rounded-md bg-green-500 hover:bg-green-600'>Add Question</button>
                    </div>
                    <div className='flex justify-center py-12'>
                        <button className='px-10 py-2 text-xl text-white font-bold rounded-2xl bg-gradient-to-r from-[#0087A5] to-[#0E2DA7]' onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
