// import React from 'react';

// const Loader = () => {
//     const loaderContainerStyle = {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         background: 'linear-gradient(to bottom, #101010, #000000), url("/assets/FABC.png")', 
//         backgroundSize: 'cover', 
//           };

//       const loaderStyle = {
//         border: '8px solid #f3f3f3',
//         borderTop: '8px solid #3498db',
//         borderRadius: '50%',
//         width: '50px',
//         height: '50px',
//         animation: 'spin 2s linear infinite',
//       };

//     return (
//         <div style={loaderContainerStyle}>
//             <div style={loaderStyle}></div>
//             <div className='bg-[#101010] backdrop-blur-sm'>
//             <div className=' text-white  '> loading... </div>
//             </div>
//         </div>
//     );
// };

// export default Loader;



import React, { useState, useEffect } from 'react';

export default function AllLoader() {
    const [dots, setDots] = useState('');

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots === '...') {
            return '';
          } else {
            return prevDots + '.';
          }
        });
      }, 500);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <div className='flex items-center justify-center h-screen bg-black'>
            <div>
                <div className='h-[8rem] w-[12.5rem]'>
                <img src='/assets/profile_loader.gif' className='w-[75%] mx-auto'></img>
                </div>
                <p className='text-center font-bricolagegrotesque text-sky-300'>Loading {dots}</p>
            </div>
        </div>
    )
}