// import React from 'react'
// import 'animate.css';
// import { useState, useEffect, useRef } from 'react';
// import ThreeJSComponent from './Threejscomponent';
// import Threejscomponent2 from './Threejscomponent2';
// import { Input } from "@material-tailwind/react";
// import Slider from 'react-slick';
// import './seeker.css'
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Select from 'react-select';
// import Spinners from './Spinners';
// import { useNavigate } from 'react-router-dom';
// import Loading from './Loading';


// export default function Header() {
//   const [isDropdown, setDropdown] = useState(false);
//   const handleDropdown = () => {
//     setDropdown(!isDropdown);
//   };
//   const [currentStep, setCurrentStep] = useState(1);
//   const sliderRef = useRef(null);
//   const sliderRef1 = useRef(null);

//   const handleNext = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickNext();
//       setCurrentStep((prevStep) => prevStep + 1);
//     }
//   };


//   const handleSlideChange1 = (index) => {

//   };
//   const handleSlideChange = (index) => {
//     setCurrentStep(index + 1);
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,

//     slidesToScroll: 1,
//     afterChange: handleSlideChange,
//   };
//   const customPaging = (i) => (
//     <div className={`dot ${currentStep === i + 1 ? 'active' : ''}`}></div>
//   );
//   const settings1 = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,

//     slidesToScroll: 1,
//     afterChange: handleSlideChange1,
//     customPaging: customPaging,
//   };
//   const countryOptions = [
//     { value: 'usa', label: 'United States' },
//     { value: 'canada', label: 'Canada' },
//     { value: 'uk', label: 'United Kingdom' },
//     // Add more countries as needed
//   ];
//   const [selectedstate, setSelectedSate] = useState(null);

//   const handleChanges = (selectedOption) => {
//     setSelectedSate(selectedOption);
//   };
//   const stateOptions = [
//     { value: 'andhra_pradesh', label: 'Andhra Pradesh' },
//     { value: 'arunachal_pradesh', label: 'Arunachal Pradesh' },
//     { value: 'assam', label: 'Assam' },
//   ];
//   const [selectedCountry, setSelectedCountry] = useState(null);

//   const handleChange = (selectedOption) => {
//     setSelectedCountry(selectedOption);
//   };

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       border: '1px solid #0a3840',
//       boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
//       backgroundColor: '#090909',
//       borderRadius: '4px',
//       color: 'white'
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? '#08f' : '#090909',
//       color: state.isSelected ? '#fff' : '#fff', // Color of the option text
//     }),
//     singleValue: (provided, state) => ({
//       ...provided,
//       color: '#fff', // Color of the selected option text
//     }),
//   };
//   const [isTransformed, setIsTransformed] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);

//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading1, setIsLoading1] = useState(false);

//   const toggleModal = () => {
//     // If modal is open, show loading
//     if (!isOpen) {
//       setIsLoading1(true);
//       setTimeout(() => {
//         setIsLoading1(false);
//         setIsOpen(!isOpen); // Close the modal after hiding the loading
//       }, 1000); // Adjust the duration as needed
//     } else {
//       setIsOpen(!isOpen); // Close the modal directly
//     }
//   };


//   useEffect(() => {
//     if (isOpen) {
//       setIsTransformed(true);
//     } else {

//       setIsTransformed(false);
//     }
//   }, [isOpen]);
//   const [active, setActive] = useState('div1');

//   const handleAnchorClick = (tab) => {
//     setActive(tab);
//   };
//   const [isOpen5, setIsOpen5] = useState(false);
//   const togglemodal5 = () => {
//     setIsOpen5(!isOpen5);
//   };




//   const handleClick = () => {
//     setIsOpen(!isOpen);
//     setIsOpen5(!isOpen5);
//     setIsLoading(true);
//     setTimeout(() => {
//       setIsLoading(false);
//       navigate('/Congrats');
//     }, 1000);
//   };
//   const navigate = useNavigate();
//   const [showCongrats, setShowCongrats] = useState(false);





//   const [formData, setFormData] = useState({
//     personalInfo: {
//       name: '',
//       email: '',
//       country: '',
//       state: '',
//       mobileNumber: '',
//       walletAddress: '',
//     },
//     educationInfo: {
//       sslcPercentage: '',
//       sslcInstitution: '',
//       sslcFile: null,
//       hscPercentage: '',
//       hscInstitution: '',
//       hscFile: null,
//       cgpaPercentage: '',
//       cgpaInstitution: '',
//       cgpaFile: null,
//     },
//     skillsInfo: {
//       resume: null,
//       skills: [],
//     },
//     projectsInfo: {
//       projectName: '',
//       projectFile: null,
//       projectDescription: '',
//       certificateName: '',
//       certificateSource: '',
//       certificateFile: null,
//     },
//   });

//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [resumeFiles, setResumeFiles] = useState([]);

//   const handleFile = (section, key, files) => {
//     if (section === 'skillsInfo' && key === 'resume') {
//       // Introducing a logical mistake by updating resumeFiles with selectedFiles
//       setSelectedFiles([...selectedFiles, ...files]);
//       setResumeFiles([...selectedFiles, ...files]); // Mistake here
//     } else {
//       setSelectedFiles([...selectedFiles, ...files]);
//     }
//   };

//   const handleRemoveFile = (section, key, fileIndex) => {
//     if (section === 'skillsInfo' && key === 'resume') {
//       const updatedFiles = [...resumeFiles];
//       updatedFiles.splice(fileIndex, 1);
//       setResumeFiles(updatedFiles);
//     } else {
//       const updatedFiles = [...selectedFiles];
//       updatedFiles.splice(fileIndex, 1);
//       setSelectedFiles(updatedFiles);
//     }
//   };

//   const handleInputChange = (section, field, value) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [section]: {
//         ...prevData[section],
//         [field]: value,
//       },
//     }));
//   };

//   const handleFileChange = (section, field, file) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [section]: {
//         ...prevData[section],
//         [field]: file,
//       },
//     }));
//   };

//   const handleSkillsChange = (selectedOptions) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       skillsInfo: {
//         ...prevData.skillsInfo,
//         skills: selectedOptions.map((option) => option.value),
//       },
//     }));
//   };

//   const handleAddSkill = (newSkill) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       skillsInfo: {
//         ...prevData.skillsInfo,
//         skills: [...prevData.skillsInfo.skills, newSkill],
//       },
//     }));
//   };


//   const [skillsOptions] = useState([
//     { value: 'HTML', label: 'HTML' },
//     { value: 'CSS', label: 'CSS' },
//   ]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//   };













//   return (
//     <div>
//       <nav className='flex p-7  lg:px-10  font-poppins'>
//         <div className='w-[85%] lg:w-[15%]'>
//           <div className='w-[80%]'>
//             <img src='./assets/fABC.png' className='h-14'></img>
//           </div>
//         </div>
//         <div className='w-[15%] lg:w-[85%] hidden md:table'>
//           <div className=' flex '>
//             <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-16 mt-3 '>
//               <a href='/'>Home</a>
//               <a href='/SkillArena'>Skill Exchange</a>
//               <a href=''>Courses</a>
//               <a href=''>Contact us</a>

//             </div>
//             <div className='w-[20%] '>
//               <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
//                 <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//               </button>
//             </div>

//           </div>
//         </div>
//         <div className='w-[15%] table lg:hidden grid content-center'>
//           <div className='flex justify-center '>
//             <button onClick={handleDropdown}>
//               <i class="fa-solid fa-ellipsis-vertical text-white text-4xl "></i>
//             </button>
//             {isDropdown && (
//               <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
//                 <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
//                   <a href='/' className='text-center'>Home</a>
//                   <a href='/SkillArena' className='text-center'>Skill Exchange</a>
//                   <a href='' className='text-center'>Courses</a>
//                   <a href='' className='text-center'>Contact us</a>
//                   <div className='flex justify-center'>
//                     <button className='flex py-1 pt-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
//                       <button className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         {isLoading1 && (
//           <div className=''>
//             <div className='z-100'>
//               <Loading isVisible1={isLoading1} />
//             </div>
//           </div>
//         )}
//         {isOpen && (

//           <div className=''>
//             <div className='z-100'>
//               <Spinners isVisible={isLoading} />
//             </div>
//             <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
//               <div className='w-[15%]'>
//                 <ThreeJSComponent />
//               </div>
//               <div
//                 className={`   ${isTransformed ? 'w-[70%]' : 'border-0 w-[0%]  '} z-10  backdrop-blur-sm bg-transparent     transform-transition duration-1000 ease-out-in rounded-3xl`}

//               >

//                 <div className='flex justify-center py-4'>

//                   <p className='text-2xl text-white'>Login As</p>
//                 </div>
//                 <div>
//                   <div className=' px-10 w-[100%] flex justify-center gap-2'>
//                     <div className='group w-[25%]   border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  group-hover:transition-transform duration-500 ' >
//                       <div className='flex justify-center py-2 text-white '>
//                         <a className={`rotate-[270deg] mt-36 cursor-pointer ${active === 'div2' ? 'hidden' : ''}`} onClick={(e) => { e.preventDefault(); handleAnchorClick('div2'); }}>Seeker </a>
//                         <a className={`rotate-[270deg] mt-36 cursor-pointer ${active === 'div2' ? '' : 'hidden'}`} id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} onClick={(e) => { e.preventDefault(); handleAnchorClick('div1'); }}>Recruiter </a>

//                       </div>

//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Recruiter Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3 '>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300'>Create new account</a>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Seeker Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3'>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' href="#" onClick={togglemodal5}>Create new account</a>

//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Validator Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3'>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300'>Create new account</a>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[25%]   border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 ' >
//                       <div className='flex justify-center py-2 text-white'>
//                         <a className={`rotate-[270deg] mt-32 cursor-pointer ${active === 'div3' ? 'hidden' : ''}`} onClick={(e) => { e.preventDefault(); handleAnchorClick("div3") }}>Validator</a>
//                         <a className={`rotate-[270deg] mt-32 cursor-pointer ${active === 'div3' ? '' : 'hidden'}`} id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} onClick={(e) => { e.preventDefault(); handleAnchorClick('div1'); }}>Recruiter </a>
//                       </div>

//                     </div>

//                   </div>
//                 </div>
//               </div>
//               <div className='w-[15%]'>
//                 <Threejscomponent2 />
//               </div>
//             </div>
//             <div className='px-28 py-10 flex justify-center -translate-y-[27rem] '>
//               <div className='rounded-full p-6 border-[6px] border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_10px_#08f,0_0_10px_#08f]'>
//                 <img src='./assets/NO_text_logo.png'></img>
//               </div>
//             </div>
//           </div>
//         )}


//         <div className='' >
//           {isOpen5 && (
//             <div className="rounded-2xl z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
//               <div className="modal-container w-4/5 ">
//                 <div className="modal-content  ">
//                   <div className='slide-in-from-top1' >

//                     <div className='bg-black'>

//                       <div>
//                         <div className=' items-center'>
//                           <div className=''>
//                             <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
//                           </div>
//                           <div className='flex justify-end '>
//                             <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={togglemodal5}><i class="fa-solid fa-xmark " ></i></button>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="roadmap">
//                         <button className={currentStep === 1 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 2 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 3 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 4 ? 'active' : ''}><img src='./assets/block.png'></img></button>


//                       </div>
//                       <form onSubmit={handleSubmit}>
//                         <div className=' mt-4 mb-20'>
//                           <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
//                             <div className='flex justify-center' >
//                               <p className='text-white text-center mt-2'>Personal information</p>
//                               <div className='flex gap-9'>
//                                 <div className='w-[60%] flex flex-col gap-6'>
//                                   <div className='flex flex-col gap-6 px-12 mt-3 '>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Name'
//                                       onChange={(e) => handleInputChange('personalInfo', 'name', e.target.value)}
//                                     ></input>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID'
//                                       onChange={(e) => handleInputChange('personalInfo', 'email', e.target.value)}
//                                     ></input>



//                                   </div>
//                                   <div className='flex gap-4 mt-5 px-12'>
//                                     <div className='w-[50%]'>

//                                       <Select
//                                         value={selectedCountry}
//                                         onChange={handleChange}
//                                         options={countryOptions}
//                                         placeholder='Country'
//                                         styles={customStyles}
//                                       />
//                                     </div>
//                                     <div className='w-[50%]'>

//                                       <Select
//                                         value={selectedstate}
//                                         onChange={handleChanges}
//                                         options={stateOptions}
//                                         placeholder='State'
//                                         styles={customStyles}
//                                       />
//                                     </div>
//                                   </div>
//                                   <div className='flex flex-col gap-6 px-12 mt-3 '>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Mobile number'
//                                       onChange={(e) => handleInputChange('personalInfo', 'mobileNumber', e.target.value)}
//                                     ></input>

//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Wallet Address'
//                                       onChange={(e) => handleInputChange('personalInfo', 'walletAddress', e.target.value)}
//                                     ></input>


//                                   </div>
//                                 </div>
//                                 <div className='w-[30%]'>

//                                   <Slider className='bg-[#111111]' ref={sliderRef1} {...settings1}>
//                                     <div >
//                                       <img className='px-3' src='./assets/image 90.png'></img>
//                                     </div>
//                                     <div>
//                                       <img className='px-3' src='./assets/image 90 (1).png'></img>
//                                     </div>
//                                     <div>
//                                       <img className='px-3' src='./assets/image 3yr.png'></img>
//                                     </div>

//                                   </Slider>
//                                   <p className='text-center text-white'>Choose Avatar</p>
//                                 </div>


//                               </div>
//                               <div className='flex justify-center mt-16'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>
//                               </div>
//                             </div>
//                             <div className='flex justify-center'>
//                               <p className='text-white text-center mt-2'>Educational information</p>
//                               <div className='flex justify-center mt-8'>
//                                 <div className='w-[70%] flex gap-28'>
//                                   <div className='flex gap-14 text-white'>
//                                     <p>Percentage</p>
//                                     <p>Institution</p>
//                                     <p></p>
//                                   </div>
//                                   <div className='flex gap-16 text-white'>
//                                     <p>Year</p>
//                                     <p>Certificate</p>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className='flex flex-col gap-8 mt-8 px-2'>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>SSLC % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'sslcFile', e.target.files)}
//                                   ></input>


//                                 </div>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>HSC % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your HSC %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your HSC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'hscFile', e.target.files)}
//                                   ></input>


//                                 </div>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>CGPA % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your GPA %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'cgpaFile', e.target.files)}
//                                   ></input>


//                                 </div>

//                               </div>
//                               <div className='flex justify-center mt-24'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>
//                               </div>
//                             </div>

//                             <div className='flex justify-center py-4'>
//                               <div className='w-[45%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] outline-dashed'>
//                                 <div className='flex justify-center'>
//                                   <img src='./assets/Upload.png' alt='Upload icon' />
//                                 </div>
//                                 <p className='text-center text-gray-500 mt-3'>Upload Your Resume</p>
//                                 <div className='flex justify-center mt-2'>
//                                   <input
//                                     className='text-white w-9/12'
//                                     type='file'
//                                     onChange={(e) => handleFileChange('skillsInfo', 'resume', e.target.files)}
//                                   />
//                                 </div>
//                                 <p className='text-center text-gray-500 mt-3'>(The file should be less than 5 mb)</p>
                              

//                               <div className='w-[45%] px-5 rounded-2xl py-4  bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>
//                                 <div className='flex gap-5'>
//                                   <Select
//                                     isMulti
//                                     value={skillsOptions.filter((option) => formData.skillsInfo.skills.includes(option.value))}
//                                     onChange={handleSkillsChange}
//                                     options={skillsOptions}
//                                   />
//                                   <button className='text-white border border-sky-200 px-3 py-1 rounded-full outline-dashed' onClick={handleAddSkill}>
//                                     Add Skill <i className="fa-solid fa-plus" style={{ color: "#f7f7f8" }}></i>
//                                   </button>
//                                 </div>
//                                 <div className='text-gray-500 text-center mt-16'>
//                                   (Selected Skills: {formData.skillsInfo.skills.join(', ')})
//                                 </div>
//                               </div>

//                               </div>

//                               <div className='px-20 mt-4'>
//                                 <div className='w-[30%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>
//                                   {resumeFiles.length > 0 && (
//                                     <div className='text-white'>
//                                       {resumeFiles.map((file, index) => (
//                                         <p key={index} className='flex justify-between items-center'>
//                                           <span>{index + 1}.&nbsp;{file.name}</span>
//                                           <i className="fa-solid fa-xmark" style={{ color: "#f7f7f8", cursor: 'pointer' }} onClick={() => handleRemoveFile('skillsInfo', 'resume', index)}></i>
//                                         </p>
//                                       ))}
//                                     </div>
//                                   )}
//                                 </div>
//                               </div>



//                               <div className='flex  justify-center'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
//                                   <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p>
//                                   <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>
//                               </div>
//                             </div>

//                             <div className='flex justify-center'>
//                               <p className='text-white text-center mt-2'>Projects & Certificates</p>
//                               <div className='flex'>
//                                 <div className='flex flex-col gap-5 w-[50%] px-3 mt-5'>
//                                   <div className='flex gap-2'>
//                                     <div>
//                                       <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Enter your Project name'></input>
//                                     </div>
//                                     <div>
//                                       <input className='text-white  w-12/12' type='file' placeholder='upload project'></input>
//                                       <p className='text-gray-500'>(upload project regarding Files if you have)</p>
//                                     </div>
//                                   </div>
//                                   <div >
//                                     <textarea className='w-[90%] text-white outline-none px-2 bg-transparent border-2 rounded-xl border-[#0a3840]' placeholder='About Project'></textarea>
//                                   </div>
//                                 </div>
//                                 <div className='flex flex-col mt-32 gap-5 w-[50%] '>
//                                   <div>
//                                     <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Enter your certificate name'></input>
//                                   </div>
//                                   <div className='flex flex-col gap-3'>
//                                     <div className='flex gap-2'>
//                                       <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Source of Obtaining'></input>
//                                       <p className='text-gray-500'>(Eg.Udemy..Etc)</p>



//                                     </div>
//                                     <div>
//                                       <input className='text-white  w-12/12' type='file' placeholder='Source of Obtaining'></input>
//                                       <p className='text-gray-500'>(Upload Certificate)</p>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className='flex  justify-center mt-16'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleClick}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[124px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Register</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>

//                               </div>


//                             </div>

//                           </Slider>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className='z-100'>
//             <Spinners isVisible={isLoading} />
//           </div>
//           <div className='slide-in-from-top1'>

//           </div>
//         </div>


//       </nav>
//     </div>
//   )
// }







// import React from 'react'
// import 'animate.css';
// import { useState, useEffect, useRef } from 'react';
// import ThreeJSComponent from './Threejscomponent';
// import Threejscomponent2 from './Threejscomponent2';
// import { Input } from "@material-tailwind/react";
// import Slider from 'react-slick';
// import './seeker.css'
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Select from 'react-select';
// import Spinners from './Spinners';
// import { useNavigate } from 'react-router-dom';
// import Loading from './Loading';


// export default function Header() {
//   const [isDropdown, setDropdown] = useState(false);
//   const handleDropdown = () => {
//     setDropdown(!isDropdown);
//   };
//   const [currentStep, setCurrentStep] = useState(1);
//   const sliderRef = useRef(null);
//   const sliderRef1 = useRef(null);

//   const handleNext = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickNext();
//       setCurrentStep((prevStep) => prevStep + 1);
//     }
//   };


//   const handleSlideChange1 = (index) => {

//   };
//   const handleSlideChange = (index) => {
//     setCurrentStep(index + 1);
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,

//     slidesToScroll: 1,
//     afterChange: handleSlideChange,
//   };
//   const customPaging = (i) => (
//     <div className={`dot ${currentStep === i + 1 ? 'active' : ''}`}></div>
//   );
//   const settings1 = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,

//     slidesToScroll: 1,
//     afterChange: handleSlideChange1,
//     customPaging: customPaging,
//   };
//   const countryOptions = [
//     { value: 'usa', label: 'United States' },
//     { value: 'canada', label: 'Canada' },
//     { value: 'uk', label: 'United Kingdom' },
//     // Add more countries as needed
//   ];
//   const [selectedstate, setSelectedSate] = useState(null);

//   const handleChanges = (selectedOption) => {
//     setSelectedSate(selectedOption);
//   };
//   const stateOptions = [
//     { value: 'andhra_pradesh', label: 'Andhra Pradesh' },
//     { value: 'arunachal_pradesh', label: 'Arunachal Pradesh' },
//     { value: 'assam', label: 'Assam' },

//     // Add more countries as needed
//   ];
//   const [selectedCountry, setSelectedCountry] = useState(null);

//   const handleChange = (selectedOption) => {
//     setSelectedCountry(selectedOption);
//   };

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       border: '1px solid #0a3840',
//       boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
//       backgroundColor: '#090909',
//       borderRadius: '4px',
//       color: 'white'
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? '#08f' : '#090909',
//       color: state.isSelected ? '#fff' : '#fff', // Color of the option text
//     }),
//     singleValue: (provided, state) => ({
//       ...provided,
//       color: '#fff', // Color of the selected option text
//     }),
//   };
//   const [isTransformed, setIsTransformed] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);

//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading1, setIsLoading1] = useState(false);

//   const toggleModal = () => {
//     // If modal is open, show loading
//     if (!isOpen) {
//       setIsLoading1(true);
//       setTimeout(() => {
//         setIsLoading1(false);
//         setIsOpen(!isOpen); // Close the modal after hiding the loading
//       }, 1000); // Adjust the duration as needed
//     } else {
//       setIsOpen(!isOpen); // Close the modal directly
//     }
//   };


//   useEffect(() => {
//     if (isOpen) {
//       setIsTransformed(true);
//     } else {

//       setIsTransformed(false);
//     }
//   }, [isOpen]);
//   const [active, setActive] = useState('div1');

//   const handleAnchorClick = (tab) => {
//     setActive(tab);
//   };
//   const [isOpen5, setIsOpen5] = useState(false);
//   const togglemodal5 = () => {
//     setIsOpen5(!isOpen5);
//   };
//  const handleClick = () => {
//     setIsOpen(!isOpen);
//     setIsOpen5(!isOpen5);
//     setIsLoading(true);
//     setTimeout(() => {
//       setIsLoading(false);
//       navigate('/Congrats');
//     }, 1000);
//   };
//   const navigate = useNavigate();
//   const [showCongrats, setShowCongrats] = useState(false);
//  const [formData, setFormData] = useState({
//     personalInfo: {
//       name: '',
//       email: '',
//       country: '',
//       state: '',
//       mobileNumber: '',
//       walletAddress: '',
//       avatar:''
//     },
//     educationInfo: {
//       sslcPercentage: '',
//       sslcInstitution: '',
//       sslcFile: null,
//       hscPercentage: '',
//       hscInstitution: '',
//       hscFile: null,
//       cgpaPercentage: '',
//       cgpaInstitution: '',
//       cgpaFile: null,
//     },
//     skillsInfo: {
//       resume: null,
//       skills: [],
//     },
//     projectsInfo: {
//       projectName: '',
//       projectFile: null,
//       projectDescription: '',
//       certificateName: '',
//       certificateSource: '',
//       certificateFile: null,
//     },
//   });
//   console.log(formData)
//   const handleAvatarSelection = (selectedAvatar) => {
//     setPersonalInfo({
//       ...personalInfo,
//       avatar: selectedAvatar,
//     });
//   };


//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [resumeFiles, setResumeFiles] = useState([]);

//   const handleFile = (section, key, files) => {
//     if (section === 'skillsInfo' && key === 'resume') {
//       // Use the spread operator to concatenate arrays without modifying the original state
//       setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//       setResumeFiles((prevFiles) => [...prevFiles, ...files]);
//     } else {
//       setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//     }
//   };

//   const handleRemoveFile = (section, key, fileIndex) => {
//     if (section === 'skillsInfo' && key === 'resume') {
//       // Use filter to create a new array without the removed file
//       const updatedFiles = resumeFiles.filter((_, index) => index !== fileIndex);
//       setResumeFiles(updatedFiles);
//     } else {
//       const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
//       setSelectedFiles(updatedFiles);
//     }
//   };

//   const handleInputChange = (section, field, value) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [section]: {
//         ...prevData[section],
//         [field]: value,
//       },
//     }));
//   };

//   const handleFileChange = (section, field, file) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       [section]: {
//         ...prevData[section],
//         [field]: file,
//       },
//     }));
//   };
//   const [selectedSkills, setSelectedSkills] = useState([]);

//   const handleSkillsChange = (selectedOptions) => {
//     setSelectedSkills(selectedOptions);
//     setShowOptions(false);
//     setFormData((prevData) => ({
//       ...prevData,
//       skillsInfo: {
//         ...prevData.skillsInfo,
//         skills: selectedOptions.map((option) => option.value),
//       },
//     }));
//   };

//   const handleAddSkill = (newSkill) => {
//     setShowOptions(!showOptions); // Toggle the visibility of the options

//     setFormData((prevData) => ({
//       ...prevData,
//       skillsInfo: {
//         ...prevData.skillsInfo,
//         skills: [...prevData.skillsInfo.skills, newSkill],
//       },
//     }));
//   };
//   const [showOptions, setShowOptions] = useState(false);



//   const handleRemoveSkill = (removedSkill) => {
//     const updatedSkills = selectedSkills.filter((skill) => skill.value !== removedSkill.value);
//     setSelectedSkills(updatedSkills);
//   };

//   const [skillsOptions] = useState([
//     { value: 'HTML', label: 'HTML' },
//     { value: 'CSS', label: 'CSS' },
//   ]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//   };
// return (
//     <div>
//       <nav className='flex p-7  lg:px-10  font-poppins'>
//         <div className='w-[85%] lg:w-[15%]'>
//           <div className='w-[80%]'>
//             <img src='./assets/fABC.png' className='h-14'></img>
//           </div>
//         </div>
//         <div className='w-[15%] lg:w-[85%] hidden md:table'>
//           <div className=' flex '>
//             <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-16 mt-3 '>
//               <a href='/'>Home</a>
//               <a href='/SkillArena'>Skill Exchange</a>
//               <a href=''>Courses</a>
//               <a href=''>Contact us</a>

//             </div>
//             <div className='w-[20%] '>
//               <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
//                 <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[112px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//               </button>
//             </div>

//           </div>
//         </div>
//         <div className='w-[15%] table lg:hidden grid content-center'>
//           <div className='flex justify-center '>
//             <button onClick={handleDropdown}>
//               <i class="fa-solid fa-ellipsis-vertical text-white text-4xl "></i>
//             </button>
//             {isDropdown && (
//               <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
//                 <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
//                   <a href='/' className='text-center'>Home</a>
//                   <a href='/SkillArena' className='text-center'>Skill Exchange</a>
//                   <a href='' className='text-center'>Courses</a>
//                   <a href='' className='text-center'>Contact us</a>
//                   <div className='flex justify-center'>
//                     <button className='flex py-1 pt-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
//                       <button className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p> <button className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         {isLoading1 && (
//           <div className=''>
//             <div className='z-100'>
//               <Loading isVisible1={isLoading1} />
//             </div>
//           </div>
//         )}
//         {isOpen && (

//           <div className=''>
//             <div className='z-100'>
//               <Spinners isVisible={isLoading} />
//             </div>
//             <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
//               <div className='w-[15%]'>
//                 <ThreeJSComponent />
//               </div>
//               <div
//                 className={`   ${isTransformed ? 'w-[70%]' : 'border-0 w-[0%]  '} z-10  backdrop-blur-sm bg-transparent     transform-transition duration-1000 ease-out-in rounded-3xl`}

//               >

//                 <div className='flex justify-center py-4'>

//                   <p className='text-2xl text-white'>Login As</p>
//                 </div>
//                 <div>
//                   <div className=' px-10 w-[100%] flex justify-center gap-2'>
//                     <div className='group w-[25%]   border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  group-hover:transition-transform duration-500 ' >
//                       <div className='flex justify-center py-2 text-white '>
//                         <a className={`rotate-[270deg] mt-36 cursor-pointer ${active === 'div2' ? 'hidden' : ''}`} onClick={(e) => { e.preventDefault(); handleAnchorClick('div2'); }}>Seeker </a>
//                         <a className={`rotate-[270deg] mt-36 cursor-pointer ${active === 'div2' ? '' : 'hidden'}`} id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} onClick={(e) => { e.preventDefault(); handleAnchorClick('div1'); }}>Recruiter </a>

//                       </div>

//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Recruiter Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3 '>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300'>Create new account</a>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Seeker Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3'>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' href="#" onClick={togglemodal5}>Create new account</a>

//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[80%]  border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} >
//                       <div className='flex justify-end '>
//                         <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
//                       </div>
//                       <div className='flex justify-center py-2 text-white'>
//                         <p>Validator Login</p>
//                       </div>
//                       <div className='flex flex-col gap-3 px-12 mt-3'>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID' ></input>
//                         <input className='bg-black bg-opacity-5 text-center text-white outline-none py-2 border-b border-white' placeholder='Enter your Password'></input>
//                         <div className='flex justify-center mt-3'>
//                           <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' >
//                             <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[104px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                           </button>

//                         </div>
//                         <div className='flex justify-center'>
//                           <p className='text-white font-bold'>OR</p>
//                         </div>
//                         <div className='flex justify-center'>
//                           <a className='text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300'>Create new account</a>
//                         </div>
//                       </div>
//                     </div>
//                     <div className='group w-[25%]   border-sky-300 shadow shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 ' >
//                       <div className='flex justify-center py-2 text-white'>
//                         <a className={`rotate-[270deg] mt-32 cursor-pointer ${active === 'div3' ? 'hidden' : ''}`} onClick={(e) => { e.preventDefault(); handleAnchorClick("div3") }}>Validator</a>
//                         <a className={`rotate-[270deg] mt-32 cursor-pointer ${active === 'div3' ? '' : 'hidden'}`} id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} onClick={(e) => { e.preventDefault(); handleAnchorClick('div1'); }}>Recruiter </a>
//                       </div>

//                     </div>

//                   </div>
//                 </div>
//               </div>
//               <div className='w-[15%]'>
//                 <Threejscomponent2 />
//               </div>
//             </div>
//             <div className='px-28 py-10 flex justify-center -translate-y-[27rem] '>
//               <div className='rounded-full p-6 border-[6px] border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_10px_#08f,0_0_10px_#08f]'>
//                 <img src='./assets/NO_text_logo.png'></img>
//               </div>
//             </div>
//           </div>
//         )}


//         <div className='' >
//           {isOpen5 && (
//             <div className="rounded-2xl z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
//               <div className="modal-container w-4/5 ">
//                 <div className="modal-content  ">
//                   <div className='slide-in-from-top1' >

//                     <div className='bg-black'>

//                       <div>
//                         <div className=' items-center'>
//                           <div className=''>
//                             <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
//                           </div>
//                           <div className='flex justify-end '>
//                             <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={togglemodal5}><i class="fa-solid fa-xmark " ></i></button>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="roadmap">
//                         <button className={currentStep === 1 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 2 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 3 ? 'active' : ''}><img src='./assets/block.png'></img></button>
//                         <p className='text-white'>---------------------</p>
//                         <button className={currentStep === 4 ? 'active' : ''}><img src='./assets/block.png'></img></button>


//                       </div>
//                       <form onSubmit={handleSubmit}>
//                         <div className=' mt-4 mb-20'>
//                           <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
//                             <div className='flex justify-center' >
//                               <p className='text-white text-center mt-2'>Personal information</p>
//                               <div className='flex gap-9'>
//                                 <div className='w-[60%] flex flex-col gap-6'>
//                                   <div className='flex flex-col gap-6 px-12 mt-3 '>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Name'
//                                       onChange={(e) => handleInputChange('personalInfo', 'name', e.target.value)}
//                                     ></input>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your mail ID'
//                                       onChange={(e) => handleInputChange('personalInfo', 'email', e.target.value)}
//                                     ></input>



//                                   </div>
//                                   <div className='flex gap-4 mt-5 px-12'>
//                                     <div className='w-[50%]'>

//                                       <Select
//                                         value={selectedCountry}
//                                         onChange={handleChange}
//                                         options={countryOptions}
//                                         placeholder='Country'
//                                         styles={customStyles}
//                                       />
//                                     </div>
//                                     <div className='w-[50%]'>

//                                       <Select
//                                         value={selectedstate}
//                                         onChange={handleChanges}
//                                         options={stateOptions}
//                                         placeholder='State'
//                                         styles={customStyles}
//                                       />
//                                     </div>
//                                   </div>
//                                   <div className='flex flex-col gap-6 px-12 mt-3 '>
//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Mobile number'
//                                       onChange={(e) => handleInputChange('personalInfo', 'mobileNumber', e.target.value)}
//                                     ></input>

//                                     <input className='bg-black bg-opacity-5  text-white outline-none py-2 border-b border-white' placeholder='Enter your Wallet Address'
//                                       onChange={(e) => handleInputChange('personalInfo', 'walletAddress', e.target.value)}
//                                     ></input>


//                                   </div>
//                                 </div>
//                                 <div className='w-[30%]'>

//                                 <Slider className='bg-[#111111]' ref={sliderRef1} {...settings1}>
//                                 <div onClick={() => handleAvatarSelection('./assets/image 90.png')}>
//                                   <img className='px-3' src='./assets/image 90.png' alt='Avatar 1' />
//                                 </div>
//                                 <div onClick={() => handleAvatarSelection('./assets/image 90 (1).png')}>
//                                   <img className='px-3' src='./assets/image 90 (1).png' alt='Avatar 2' />
//                                 </div>
//                                 <div onClick={() => handleAvatarSelection('./assets/image 3yr.png')}>
//                                   <img className='px-3' src='./assets/image 3yr.png' alt='Avatar 3' />
//                                 </div>
//                               </Slider>
//                               <p className='text-center text-white'>Choose Avatar</p>
//                               <input
//                               type="file"
//                               onChange={(e) =>
//                                 setFormData({
//                                   ...formData,
//                                   personalInfo: {
//                                     ...formData.personalInfo,
//                                     avatar: e.target.files[0],
//                                   },
//                                 })
//                               }
//                             />
//                                 </div>


//                               </div>
//                               <div className='flex justify-center mt-16'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>
//                               </div>
//                             </div>

//                             <div className='flex justify-center'>
//                               <p className='text-white text-center mt-2'>Educational information</p>
//                               <div className='flex justify-center mt-8'>
//                                 <div className='w-[70%] flex gap-28'>
//                                   <div className='flex gap-14 text-white'>
//                                     <p>Percentage</p>
//                                     <p>Institution</p>
//                                     <p></p>
//                                   </div>
//                                   <div className='flex gap-16 text-white'>
//                                     <p>Year</p>
//                                     <p>Certificate</p>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className='flex flex-col gap-8 mt-8 px-2'>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>SSLC % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'sslcYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'sslcFile', e.target.files)}
//                                   ></input>


//                                 </div>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>HSC % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your HSC %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your HSC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'hscYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'hscFile', e.target.files)}
//                                   ></input>


//                                 </div>
//                                 <div className='flex gap-4  items-center '>
//                                   <p className='w-[15%] text-white'>CGPA % :</p>
//                                   <input className='w-[23%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your GPA %'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaPercentage', e.target.value)}
//                                   ></input>
//                                   <input className='w-[40%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Enter your SSLC Institution'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaInstitution', e.target.value)}
//                                   ></input>
//                                   <input className='w-[10%] text-white px-2 bg-[#090909] rounded-full border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' placeholder='Year'
//                                     onChange={(e) => handleInputChange('educationInfo', 'cgpaYear', e.target.value)}
//                                   ></input>
//                                   <input className='bg-[#090909] text-white rounded-full border border-sky-200 outline-dashed shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]' type='file'
//                                     onChange={(e) => handleFileChange('educationInfo', 'cgpaFile', e.target.files)}
//                                   ></input>


//                                 </div>

//                               </div>
//                               <div className='flex justify-center mt-24'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>
//                               </div>
//                             </div>

//                             <div className='flex justify-center py-4'>
//                               <p className='text-white text-center '>Skills & Tools</p>
//                               <div className='flex justify-center gap-4 mt-3'>


//                                 <div className='w-[45%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] outline-dashed'>
//                                   <div className='flex justify-center'>
//                                     <img src='./assets/Upload.png' alt='Upload icon' />
//                                   </div>
//                                   <p className='text-center text-gray-500 mt-3'>Upload Your Resume</p>
//                                   <div className='flex justify-center mt-2'>
//                                     <input
//                                       className='text-white w-9/12'
//                                       type='file'
//                                       onChange={(e) => handleFile('skillsInfo', 'resume', e.target.files)}
//                                     />
//                                   </div>
//                                   <p className='text-center text-gray-500 mt-3'>(The file should be less than 5 mb)</p>
//                                 </div>


//                                 <div className='w-[45%] px-5 rounded-2xl py-4  bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>

//                                   <div>
//                                     <button className='text-white border border-sky-200 px-3 py-1 rounded-full outline-dashed' onClick={() => setShowOptions(!showOptions)}>
//                                       Add Skill <i className="fa-solid fa-plus" style={{ color: "#f7f7f8" }}></i>
//                                     </button>

//                                     {selectedSkills.length > 0 && (
//                                       <div className='gap-'>
//                                         {selectedSkills.map((skill) => (
//                                           <span key={skill.value} className="selected-skill">
//                                             {skill.label}
//                                             <button className="remove-skill" onClick={() => handleRemoveSkill(skill)}>
//                                               &times;
//                                             </button>
//                                           </span>
//                                         ))}
//                                       </div>
//                                     )}

//                                     {showOptions && (
//                                       <Select className='text-black bg-[#090909] gap-6'
//                                         isMulti
//                                         value={selectedSkills}
//                                         onChange={handleSkillsChange}
//                                         options={skillsOptions}
//                                       />
//                                     )}
//                                   </div>

//                                   <p className='text-gray-500 text-center mt-16'>(Enter the Skills, Technologies you know)</p>
//                                 </div>


//                               </div>
//                               <div className='px-20 mt-4'>
//                                 <div className='w-[30%] px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>
//                                   {resumeFiles.length > 0 && (
//                                     <div className='text-white'>
//                                       {resumeFiles.map((file, index) => (
//                                         <p key={index} className='flex justify-between items-center'>
//                                           <span>{index + 1}.&nbsp;{file.name}</span>
//                                           <i className="fa-solid fa-xmark" style={{ color: "#f7f7f8", cursor: 'pointer' }} onClick={() => handleRemoveFile('skillsInfo', 'resume', index)}></i>
//                                         </p>
//                                       ))}
//                                     </div>
//                                   )}
//                                 </div>
//                               </div>
//                               <div className='flex  justify-center'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleNext}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>

//                               </div>

//                               <div>

//                               </div>
//                             </div>

//                             <div className='flex justify-center'>
//                               <p className='text-white text-center mt-2'>Projects & Certificates</p>
//                               <div className='flex'>
//                                 <div className='flex flex-col gap-5 w-[50%] px-3 mt-5'>
//                                   <div className='flex gap-2'>
//                                     <div>
//                                       <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Enter your Project name'
//                                         type="text"
//                                         value={formData.projectsInfo.projectName}
//                                         onChange={(e) => handleInputChange('projectName', e.target.value)}
//                                       ></input>
//                                     </div>
//                                     <div>
//                                       <input className='text-white  w-12/12' type='file' placeholder='upload project'
//                                         onChange={(e) => handleFileChange('certificateFile', e.target.files[0])}
//                                       ></input>
//                                       <p className='text-gray-500'>(upload project regarding Files if you have)</p>
//                                     </div>
//                                   </div>
//                                   <div >
//                                     <textarea className='w-[90%] text-white outline-none px-2 bg-transparent border-2 rounded-xl border-[#0a3840]' placeholder='About Project'></textarea>
//                                   </div>
//                                 </div>
//                                 <div className='flex flex-col mt-32 gap-5 w-[50%] '>
//                                   <div>
//                                     <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Enter your certificate name'></input>
//                                   </div>
//                                   <div className='flex flex-col gap-3'>
//                                     <div className='flex gap-2'>
//                                       <input className=' text-white px-2 bg-[#090909] rounded-lg border border-[#0a3840] shadow-md shadow-[#0a3840]' placeholder='Source of Obtaining'></input>
//                                       <p className='text-gray-500'>(Eg.Udemy..Etc)</p>



//                                     </div>
//                                     <div>
//                                       <input className='text-white  w-12/12' type='file' placeholder='Source of Obtaining'></input>
//                                       <p className='text-gray-500'>(Upload Certificate)</p>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className='flex  justify-center mt-16'>
//                                 <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleClick}>
//                                   <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[124px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Register</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                 </button>

//                               </div>


//                             </div>

//                           </Slider>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           <div className='z-100'>
//             <Spinners isVisible={isLoading} />
//           </div>
//           <div className='slide-in-from-top1'>

//           </div>
//         </div>


//       </nav>
//     </div>
//   )
// }






// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const Demo = () => {
//   const [course, setCourse] = useState({
//     name: 'Course Name',
//     img: 'Course Image URL',
//     price: 1000,
//   });

//   const [paymentData, setPaymentData] = useState(null);
//   const [showConfirmPaymentPopup, setShowConfirmPaymentPopup] = useState(true);

//   const handlePayment = async () => {
//     try {
//       const orderResponse = await axios.post('https://ex.d5art.com/api/orders', {
//         amount: course.price,
//       });
//       console.log("orderResponse",orderResponse)

//       setPaymentData(orderResponse.data.data);
//     } catch (error) {
//       console.error('Error initiating payment:', error);
//     }
//   };

//   useEffect(() => {
//     const handleConfirmPayment = () => {
//       // Assuming you have setShowPaymentPopup and setShowConfirmPaymentPopup functions
//       // Remove setShowPaymentPopup and setShowConfirmPaymentPopup if not defined
//       setShowConfirmPaymentPopup(false);

//       if (paymentData) {
//         console.log("paymentData",paymentData)
//         initPayment(paymentData);
//         console.log('paymentData', paymentData);
//         console.log('paymentData Amount', paymentData.amount);
//         console.log('paymentData id', paymentData.id);
//       }
//     };

//     if (showConfirmPaymentPopup) {
//       handleConfirmPayment();
//     }
//   }, [showConfirmPaymentPopup, paymentData]);

//   const initPayment = async () => {
//     if (paymentData) {
//       const options = {
//         key: "rzp_test_KKmX9hZ3bVQKX6",
//         amount: course.price * 100,
//         currency: 'INR',
//         name: 'Blockchain',
//         description: 'Test Transaction',
//         image: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
//         order_id: paymentData.id,
//         handler: async (response) => {
//           try {
//             console.log('Payment response:', response);

//             const verifyResponse = await axios.post('https://ex.d5art.com/api/verify', response);
//             console.log("verifyResponse",verifyResponse)

//             if (verifyResponse.data.message === 'Payment verified successfully') {
//               console.log('Payment verified successfully');
//               // Handle success
//             } else {
//               console.log('Something went wrong! Please Try again.');
//               // Handle failure
//             }
//           } catch (error) {
//             console.error('Error verifying payment:', error);
//           }
//         },
//         theme: {
//           color: '#3399cc',
//         },
//       };

//       const rzp1 = new window.Razorpay(options);
//       rzp1.open();
//     }
//   };

//   return (
//     <div>
//       <button onClick={handlePayment}>Initiate Payment</button>
//     </div>
//   );
// };

// export default Demo;








import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Popup = ({ message, onClose }) => (
  <div className="popup">
    <div className="popup-content">
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  </div>
);

const Demo = () => {
  const [course, setCourse] = useState({
    name: 'Course Name',
    img: 'Course Image URL',
    price: 10,
  });

  const [paymentData, setPaymentData] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');

  const handlePayment = async () => {
    try {
      const orderResponse = await axios.post('https://ex.d5art.com/api/orders', {
        amount: course.price,
      });

      setPaymentData(orderResponse.data.data);
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  const handleClosePopup = () => {
    setPopupMessage('');
  };

  useEffect(() => {
    const handleConfirmPayment = () => {
      if (paymentData) {
        initPayment(paymentData);
      }
    };

    handleConfirmPayment();
  }, [paymentData]);

  const initPayment = async () => {
    if (paymentData) {
      const options = {
        key: "rzp_test_YrzYxp9mOwo6N9",
        amount: course.price * 100,
        currency: 'USD',
        name: 'Blockchain',
        description: 'Test Transaction',
        image: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
        order_id: paymentData.id,
        handler: async (response,) => {
          try {
            const verifyResponse = await axios.post('https://ex.d5art.com/api/verify',  {
              ...response,
              amount: course.price
            });
            if (verifyResponse.data.message === 'Payment verified successfully') {
              setPopupMessage('Payment verified successfully');
            } else {
              setPopupMessage('Something went wrong! Please Try again.');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
          }
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };

  return (
    <div>
      <button onClick={handlePayment}>Initiate Payment</button>
      {popupMessage && (
        <Popup message={popupMessage} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default Demo;











































