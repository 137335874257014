
import React, { useState, useEffect } from 'react';
import { Spinner } from "@material-tailwind/react";



const Spinners =   ({ isVisible }) => {

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    isVisible && (
        
      <div className='flex items-center justify-center h-screen bg-black'>
      <div>
          <div className='h-[8rem] w-[12.5rem]'>
          <img src='/assets/profile_loader.gif' className='w-[75%] mx-auto'></img>
          </div>
          <p className='text-center font-bricolagegrotesque text-sky-300'>Please wait Loading {dots}
          {/* <span className='font-poppins'
                                style={{
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    backgroundImage: 'linear-gradient(to right, #0EA4A9, #FFBA00)',
                                }}
                            > Loading {dots} </span> */}
                            </p>
      </div>
  </div>
)
  );
};

export default Spinners;