import React, { useState, useEffect } from 'react'
import Chart1 from './Chart1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDot } from '@fortawesome/free-solid-svg-icons';
import Chart2 from './Chart2';
import AdminHeader from './AdminHeader';
import { toast, Toaster } from 'react-hot-toast';
import Moralis from 'moralis';
import axios from 'axios';

export default function AdminDashboard() {
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);

    const [balance, setBalance] = useState(null);
    const [mappedTransfers, setMappedTransfers] = useState([]);
    const [loading, setLoading] = useState(false);


    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [months, setMonths] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);





    const [transactionsPerPage, setTransactionsPerPage] = useState(5);

    const handleDropdownChange = (event) => {
        setTransactionsPerPage(parseInt(event.target.value, 10));
        setStartIndex(0); // Reset to the first page when changing the number of transactions per page
    };

    const tableStyles = {
        border: '0px solid transparent',
    };

    const cellStyles = {
        backgroundColor: 'none',
        border: '0px solid #transparent',
    };




    const admin_wallet_address = "0x797fFC09444EAe34C1700948E41ae185577aFd83";


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {


                const response = await fetch(
                    `https://deep-index.moralis.io/api/v2.2/${admin_wallet_address}/erc20?chain=0x13882&token_addresses=0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'X-API-Key': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVhOGQyZGMyLTI1NzUtNDdlMy1iNDZkLTMxNjkxNmZkNzk4NiIsIm9yZ0lkIjoiMzU2MDQ0IiwidXNlcklkIjoiMzY1OTM2IiwidHlwZUlkIjoiMDZjMGEyY2QtYzA5MC00NDIzLWFmMzEtMGQxZTkwOTIzNTZhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTM5OTY4ODIsImV4cCI6NDg0OTc1Njg4Mn0.CEQ3GVp-OlaBrS2CYTI0ABr79w-oOcPhR36hXzwO3ec",
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                console.log("data", data);

                if (data && data.length > 0) {
                    const tokenData = data[0];
                    const decimals = tokenData.decimals || 18;
                    const balance = tokenData.balance;
                    const balanceOutput = (balance / 10 ** decimals).toFixed(0);

                    console.log(`Token Balance: ${balanceOutput}`);
                    setBalance(balanceOutput);
                    setLoading(false)
                } else {
                    console.log('No token data found for the specified address.');
                    setLoading(false)
                }

            } catch (error) {
                console.error('Error fetching balance data:', error);
                setLoading(false)
            }
        };

        fetchData();
    }, [admin_wallet_address]);





    const getTransaction = async () => {
        try {
            await Moralis.start({
                apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVhOGQyZGMyLTI1NzUtNDdlMy1iNDZkLTMxNjkxNmZkNzk4NiIsIm9yZ0lkIjoiMzU2MDQ0IiwidXNlcklkIjoiMzY1OTM2IiwidHlwZUlkIjoiMDZjMGEyY2QtYzA5MC00NDIzLWFmMzEtMGQxZTkwOTIzNTZhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTM5OTY4ODIsImV4cCI6NDg0OTc1Njg4Mn0.CEQ3GVp-OlaBrS2CYTI0ABr79w-oOcPhR36hXzwO3ec"
            });

            const response = await Moralis.EvmApi.token.getTokenTransfers({
                "chain": "0x13882",
                "address": "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8"
            });

            console.log('response', response.raw);
            const transfers = response.raw.result;
            const updatedTransfers = transfers.map(transfer => {

                const roundedValue = parseFloat(transfer.value_decimal).toFixed(0);
                const timestamp = new Date(transfer.block_timestamp).toLocaleString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });

                return {
                    transactionHash: transfer.transaction_hash,
                    fromAddress: transfer.from_address,
                    toAddress: transfer.to_address,
                    amount: transfer.value,
                    timestamp: timestamp,
                    value_decimal: roundedValue
                };
            });

            console.log(updatedTransfers);
            setMappedTransfers(updatedTransfers);

        } catch (e) {
            console.error(e);
        }

    }
    getTransaction();

    useEffect(() => {

        getTransaction();

    }, [])


    const [startIndex, setStartIndex] = useState(0);
    // const transactionsPerPage = 5;

    const handleNextClick = () => {
        setStartIndex(prevIndex => prevIndex + transactionsPerPage);
    };

    const handlePrevClick = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - transactionsPerPage));
    };

    // Assuming 'mappedTransfers' is your array of transactions
    // const displayedTransactions = mappedTransfers.slice(startIndex, startIndex + transactionsPerPage);

    console.log("address3333", mappedTransfers)
    console.log("address444444", admin_wallet_address);


    const filteredTransactions = mappedTransfers
        .filter(transaction =>
            (transaction.toAddress === admin_wallet_address.toLowerCase() || transaction.fromAddress === admin_wallet_address.toLowerCase())

        );
    console.log("address5555555", filteredTransactions)

    const uniqueTransactionHashes = new Set();
    const uniqueTransactions = [];

    filteredTransactions.forEach(transaction => {
        if (!uniqueTransactionHashes.has(transaction.transaction_hash)) {
            uniqueTransactionHashes.add(transaction.transaction_hash);
        }
        uniqueTransactions.push(transaction);
    });

    const displayedTransactions = uniqueTransactions
        .slice(startIndex, startIndex + transactionsPerPage);



    console.log("displayedTransactions", displayedTransactions)



    useEffect(() => {
        const getTransaction = async (admin_wallet_address) => {
            try {
                // await Moralis.start({
                // });

                // Fetch token transfers
                // const response = await Moralis.EvmApi.token.getTokenTransfers({
                //     "chain": "0x13882",
                //     "address": "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8"
                // });
                const apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVhOGQyZGMyLTI1NzUtNDdlMy1iNDZkLTMxNjkxNmZkNzk4NiIsIm9yZ0lkIjoiMzU2MDQ0IiwidXNlcklkIjoiMzY1OTM2IiwidHlwZUlkIjoiMDZjMGEyY2QtYzA5MC00NDIzLWFmMzEtMGQxZTkwOTIzNTZhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTM5OTY4ODIsImV4cCI6NDg0OTc1Njg4Mn0.CEQ3GVp-OlaBrS2CYTI0ABr79w-oOcPhR36hXzwO3ec"


                const chain = "0x13882";
                const address1 = "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8"
                const response = await axios.get(
                    `https://deep-index.moralis.io/api/v2.2/erc20/${address1}/transfers?chain=${chain}`,
                    {
                        headers: {
                            'accept': 'application/json',
                            'X-API-Key': apiKey
                        }
                    }
                );

                const transfers = response.data.result
                console.log(transfers)


                //const address = '0x787eCEE25B884b4116a5ABe9A063B70c08a7aA13';

                const address = admin_wallet_address;
                console.log("address888889998", address)

                // Get current date and date 6 months ago
                const currentDate = new Date();
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

                // Filter transfers for the last 6 months for the specified address
                const filteredTransfers = transfers.filter(trans =>
                    new Date(trans.block_timestamp) >= sixMonthsAgo &&
                    new Date(trans.block_timestamp) <= currentDate &&
                    trans.from_address.toLowerCase() === address.toLowerCase() ||
                    trans.to_address.toLowerCase() === address.toLowerCase()
                );


                // Map filtered transfers to extract required data and calculate value
                const mappedTransfers = filteredTransfers.map(data => ({
                    Month: new Date(data.block_timestamp).toLocaleString('en-us', { month: 'short' }),
                    Year: new Date(data.block_timestamp).getFullYear(),
                    Decimal: data.token_decimals,
                    Amount: data.value,
                    Value: data.value / (10 ** data.token_decimals),
                    From: data.from_address,
                    To: data.to_address
                }));

                console.log(mappedTransfers)
                const contract_address = '0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8';
                const contractAddr = contract_address;


                // Aggregate monthly data
                const monthlyData = mappedTransfers.reduce((acc, item) => {
                    const key = `${item.Month} ${item.Year}`;
                    acc[key] = acc[key] || { Month: item.Month, Year: item.Year, TotalCredit: 0, TotalDebit: 0 };

                    // Check if data.to_address is defined before accessing it
                    if (item.To && item.To.toLowerCase() === address.toLowerCase()) {
                        acc[key].TotalCredit += parseFloat(item.Value);
                    }
                    if (item.From && item.From.toLowerCase() === address.toLowerCase()) {
                        acc[key].TotalDebit += parseFloat(item.Value);
                    }
                    return acc;
                }, {});


                // Get the last 6 months in reverse order
                const months = [];
                const creditData = [];
                const debitData = [];
                for (let i = 5; i >= 0; i--) {
                    const date = new Date();
                    date.setMonth(date.getMonth() - i);
                    const monthAbbreviation = date.toLocaleString('en-us', { month: 'short' });
                    const year = date.getFullYear();
                    const key = `${monthAbbreviation} ${year}`;
                    const monthData = monthlyData[key] || { Month: monthAbbreviation, Year: year, TotalCredit: 0, TotalDebit: 0 };
                    months.push(monthData.Month);
                    creditData.push(monthData.TotalCredit);
                    debitData.push(monthData.TotalDebit);
                }

                console.log('months:', months);
                console.log('creditData:', creditData);
                console.log('debitData:', debitData);


                setCreditData(creditData);
                setDebitData(debitData);
                setMonths(months);



                setTimeout(() => {
                    setDataLoaded(true);
                }, 3000);
            } catch (error) {
                console.error('Error fetching token transfers:', error);
            }
        };

        getTransaction(admin_wallet_address);
    }, [admin_wallet_address]);


    const [isSendToken, setSendToken] = useState(false);
    const handleToken = () => {
        setSendToken(!isSendToken)
    };


    // mint 



    const [tokenAmount, setTokenAmount] = useState('');

    const mint_tokens = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/mintTokens', {
                amount: tokenAmount,
            });
            console.log("response", response);
        } catch (error) {
            console.log("error in minting the tokens", error);
        }
    };



    return (
        <div>
            <div className='font-poppins w-[100%] h-screen text-white bg-[#171616] overflow-y-scroll  pb-10' style={{ backgroundImage: `url(/adminassets/FABC.png)` }}>
                <AdminHeader />
                <Toaster />
                <div className='w-[90%] mx-auto flex flex-col gap-5 pt-10'>
                    <div className='flex flex-col md:flex-row gap-5'>
                        <div className='md:w-[40%] overflow-hidden rounded-2xl'>
                            <div className='relative flex items-end'>
                                <img src='/adminassets/Vector.png'></img>
                                <img src='/adminassets/Vector2.png' className='absolute'></img>
                                <img src='/adminassets/Vector1.png' className='absolute'></img>
                                <div className='absolute flex px-10 w-[100%] h-[100%]'>
                                    <div className='w-[50%] flex flex-col gap-4 mt-[4rem]'>
                                        <div>
                                            <button className=' border-b'>Withdraw</button>
                                        </div>
                                        <div>
                                            <button className=' border-b' onClick={handleToken}>Send Token</button>
                                        </div>
                                    </div>
                                    <div className='w-[50%] text-end mt-10'>
                                        <p className=''>Admin wallet:</p>
                                        <p className='font-extrabold mt-1'>{balance} D5ART </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isSendToken && (
                            <div className='px-5 py-10 md:px-10 md:py-0 z-20 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md overflow-y-auto'>
                                <div className='w-[90%] md:w-[40%]  text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  pb-10'>
                                    <div className='flex justify-end'>
                                        <button
                                            className='shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2'
                                            onClick={handleToken}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </button>
                                    </div>
                                    <div className='md:px-10'>
                                        <input className='w-[100%] py-2 px-3 rounded-md outline-none bg-white/20'></input>
                                        <div className='flex justify-center'><button className='px-5 py-1 rounded-full bg-sky-500 font-bold mt-5'>Send</button></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="md:w-[30%] overflow-hidden relative group">
                            <div className="relative h-full flex justify-center items-center">
                                <img src="/adminassets/Rectangle 211.png" className="w-full h-full" alt="Rectangle" />
                                <p className="text-2xl font-bold absolute">MINT</p>
                            </div>
                            <div className="absolute inset-0 bg-black/60 rounded-lg backdrop-blur-sm flex justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-500">
                                <div className="w-full text-center"> <input
                                    className="w-[85%] py-2 px-3 bg-white/50 text-black rounded-xl outline-none placeholder:text-black/75"
                                    placeholder="Increase Supply"
                                    value={tokenAmount}
                                    onChange={(e) => setTokenAmount(e.target.value)}
                                />
                                    <button className="px-5 py-1 mt-5 font-bold rounded-full shadow bg-[#26ADA6]" onClick={mint_tokens}>Mint</button>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-[30%] overflow-hidden relative group">
                            <div className="relative h-full flex justify-center items-center">
                                <img src="/adminassets/image 94.png" className="w-full h-full" alt="burn" />
                                <p className="text-2xl font-bold absolute">BURN</p>
                            </div>
                            <div className="absolute inset-0 bg-black/60 rounded-xl backdrop-blur-sm flex justify-center items-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-500">
                                <div className="w-full text-center">
                                    <input className="w-[85%] py-2 px-3 bg-white/50 text-black rounded-xl outline-none placeholder:text-black/75" placeholder="Increase Supply" />
                                    <button className="px-5 py-1 mt-5 font-bold rounded-full shadow bg-[#26ADA6]">Burn</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-5'>
                        <div className='bg-[#21222D] rounded-xl p-5 md:w-[30%]'>
                            <h1 className='capitalize font-bold'>tokens user buy</h1>
                            <p className='font-bold text-lg mt-2'>607</p>
                            <p className='text-sm text-[#87888C]'>Profit is 48% More than last Month</p>
                            <div className='mt-5 flex justify-center'>
                                <Chart2 />
                            </div>
                        </div>
                        <div className='bg-[#21222D] rounded-xl p-5 md:w-[70%]'>
                            <div className='flex justify-between'>
                                <h1 className='capitalize font-bold'>Total platform tokens</h1>
                                <div className='bg-black/30 px-3 py-1 rounded-md flex gap-1'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faCircle} className='text-[#FFBA00] text-[7px]' /></div>
                                    <p className='font-bold text-lg'>{balance}<span className='text-[10px] font-normal pl-1'>tokens</span></p>
                                </div>
                            </div>
                            <div className='text-black text-center'>
                                <Chart1 />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-hidden overflow-y-auto w-[100%]'>
                        <div className='md:w-[100%] rounded-xl'>
                            <div className='flex justify-between px-5'>
                                <div className='flex justify-start'>
                                    <h1 className='text-2xl font-bold' style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #FFFFFF, #26ADA6)',
                                    }}>Transaction History</h1>
                                </div>
                                <p className=' text-end text-xs px-3 mb-2'>Show no of entity
                                    <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={transactionsPerPage}>
                                        <option className='text-black' value="5">5</option>
                                        <option className='text-black' value="10">10</option>
                                        <option className='text-black' value="20">20</option>
                                    </select>
                                </p>
                            </div>
                            <div className='mt-5 rounded-xl overflow-hidden overflow-x-auto'>
                                <table className='w-full text-center' style={tableStyles}>
                                    <thead>
                                        <tr className='text-[#26ADA6] text-sm md:text-md'>
                                            <th className='py-5 px-5 md:px-0'>SI NO</th>
                                            <th className='py-5 px-5 md:px-0'>To Wallet Address</th>
                                            <th className='py-5 px-5 md:px-0'>Transaction Hash</th>
                                            <th className='py-5 px-5 md:px-0'>Timestamp</th>
                                            <th className='py-5 px-5 md:px-0'>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedTransactions.map((transaction, index) => (
                                            <tr key={index} className='h-16 text-xs md:text-sm rounded-md'>
                                                <td className='rounded-l-md px-5 md:px-0'>{startIndex + index + 1}</td>
                                                <td className='px-5 md:px-3 text-start'>{transaction.toAddress}</td>
                                                <td className='px-5 md:px-3 text-start'>
                                                    <a
                                                        href={`https://amoy.polygonscan.com/tx/${transaction.transactionHash}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="hover:text-[#00FFFF] hover:underline"
                                                    >
                                                        {transaction.transactionHash}
                                                    </a>
                                                </td>
                                                <td className='px-5 md:px-3 text-start'>{transaction.timestamp}</td>
                                                <td className={`px-5 md:px-3 ${transaction.value_decimal > 18 ? 'text-[#FF0000]' : 'text-[#1EEF32]'}`}>
                                                    {transaction.value_decimal}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div className=' flex justify-end gap-5 '>
                        <button className='bg-[#26ADA6] rounded-full px-5 py-2' onClick={handlePrevClick} disabled={startIndex === 1}>Prev</button>
                        <button className='bg-[#26ADA6] rounded-full px-5 py-2' onClick={handleNextClick} disabled={startIndex + transactionsPerPage >= filteredTransactions.length}>Next</button>
                    </div>
                </div>
            </div>
        </div >
    )
}