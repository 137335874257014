// import React, { useEffect, useState } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import Moralis from 'moralis';

// import Confetti from 'react-dom-confetti';
// import LoginHeader from './LoginHeader';
// import { useSelector } from 'react-redux';
// import { setSeekerEmail } from '../store';
// import axios from 'axios';
// import { toast, Toaster } from 'react-hot-toast';
// import { useDispatch } from 'react-redux';
// import Spinners from './Spinners';

// import Loader from '../loaderdemo';
// import ErrorPage from '../please_wait_page';
// import Footer from '../Footer';
// import CryptoJS from 'crypto-js';


// export default function ItemDetails() {
//     const [nftItems, setNftItems] = useState([]);
//     const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//     const [items, setItems] = useState([]);
//     const [error, setError] = useState(null);


//     const [token_id, setToken_id] = useState('');

//     const [SeekerName, setSeekerName] = useState('');
//     const [SeekerContractType, setSeekerContractType] = useState('');
//     const [SeekerEmail, setSeekerEmail] = useState('');

//     const [interestName, setInterestName] = useState('');
//     const [interestContractType, setInterestContractType] = useState('');
//     const [likedTokenId, setLikedTokenId] = useState('');


//     const [validationStatus, setValidationStatus] = useState(null);
//     const [formattedSetNames, setFormattedSetNames] = useState([]);
//     const [result, setResult] = useState('');
//     const [status, setStatus] = useState('');



//     const [loading, setLoading] = useState(true);
//     const [interest_avatar, setAvatar] = useState('');
//     const [received_avatar, setReceivedAvatar] = useState('');

//     const location = useLocation();

//     const email = useSelector((state) => state.seeker_email);
//     //  console.log("Email Logged In2222222", email)

//     const dispatch = useDispatch();

//    // const moralis_apiKey = process.env.REACT_APP_MORALIS_APIKEY;
//    // const moralis_chainId = process.env.REACT_APP_MORALIS_CHAIN;
//    // const moralis_nft_contract_address = process.env.REACT_APP_MORALIS_NFT_ADDRESS;

//     const moralis_apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
//     const moralis_chainId = "0x13882";
//     const moralis_nft_contract_address = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";


//     const fetchData = async () => {
//         const params = new URLSearchParams(location.search);
//         // const tokenId = params.get('tokenId');
//         // console.log('Token ID:', tokenId);

//          const Seeker_secretKey = "Seeker@sdfs23423@##%#$@$Y%USJNFJSWB##*$&@^23w54293y428935yjrj#$@$@#";
//        // const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
//         const encryptedTokenId = params.get('tokenId');
//         let bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedTokenId), Seeker_secretKey);
//         const tokenId = bytes.toString(CryptoJS.enc.Utf8);
//         //    console.log('Token ID:', tokenId);

//         setToken_id(tokenId);



//         try {
//             console.log('Fetching data...');
//             await Moralis.start({
//                 apiKey: moralis_apiKey

//             });
//             const response = await Moralis.EvmApi.nft.getContractNFTs({
//                 "chain": moralis_chainId,
//                 "format": "decimal",
//                 "address": moralis_nft_contract_address
//             });




//             console.log("response", response);
//             const metadata = JSON.parse(response.raw.result[0].metadata);
//             const ipfsImage = metadata.image.split('://')[1];
//             console.log('IPFS Image:', ipfsImage);
//             const temp = response.raw.result
//             console.log('Response:', response.raw.result);
//             setNftItems(response.raw.result);


//             const filteredItems = tokenId
//                 ? temp.filter(item => item.token_id === tokenId)
//                 : [];
//             console.log('Filtered Items:', filteredItems);


//             setItems(filteredItems);

//             if (filteredItems.length > 0) {
//                 const metadataString = filteredItems[0].metadata;
//                 const metadata = JSON.parse(metadataString);

//                 const name = metadata.name;

//                 const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');

//                 const contract_type = contract_typeAttribute.value;

//                 const seeker_email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');

//                 const seekerEmail = seeker_email_typeAttribute.value;


//                 //    console.log("e55555555555",metadata)



//                 setSeekerName(name);
//                 setSeekerContractType(contract_type);
//                 setSeekerEmail(seekerEmail);
//                 // setSeekerAvatar()



//                 //   console.log('Name:11111', seekerEmail);


//             } else {
//                 console.log('No matching items found.');
//                 //setLoading(false);
//             }

//             //    console.log("nameeeeeee", items.metadata.name);



//         } catch (error) {
//             console.error('Error fetching data:', error);
//             if (error && error.code === 401) {
//                 setLoading(true); // Show loader only for 401 error
//             } else {
//                 setError(error);
//                 setLoading(false);
//             }
//         }
//     };





//     const handleGetValidationStatus = async () => {
//         try {
//             const response = await axios.post('https://ex.d5art.com/api/getValidationStatusByEmail', { email: SeekerEmail });
//             const data = response.data;
//             setValidationStatus(data);


//             const formattedNames = data.map(entry => entry.Set_name.substring(11));
//             const results = data.map(entry => entry.result);
//             const statuses = data.map(entry => entry.status);
//             // console.log("response",response)
//             setFormattedSetNames(formattedNames);
//             //  console.log("formattedNames",formattedNames)

//             setResult(results);
//             //  console.log("results",result)
//             setStatus(statuses);
//             //  console.log("status",status)

//         } catch (error) {
//             setError(error);
//         }
//     };

//     useEffect(() => {
//         if (SeekerEmail) {
//             handleGetValidationStatus();
//         }
//     }, [SeekerEmail]);


//     const getImageFromMetadata = (metadata) => {
//         try {
//             const parsedMetadata = JSON.parse(metadata);
//             return parsedMetadata.image || "images/default-image.jpg";
//         } catch (error) {
//             console.error("Error parsing metadata:", error);
//             return "images/default-image.jpg";
//         }
//     };




//     useEffect(() => {
//         const fetchDataAndItem = async () => {
//             await fetchData();
//         };
//         fetchDataAndItem();

//     }, []);







//     // interest 

//     useEffect(() => {
//         const fetchData = async () => {
//             try {

//                 const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);

//                 if (!response.ok) {
//                     throw new Error(`Request failed with status: ${response.status}`);
//                 }

//                 const data = await response.json();
//                 // console.log('555566', data.data[0].contract_type);
//                 // console.log('555566', data.data[0].name);
//                 // console.log('555566', data.data[0].token_Id);
//                  console.log('555566', data.data[0]);



//                 if (response.ok) {

//                     setInterestName(data.data[0].name);
//                     setInterestContractType(data.data[0].contract_type);
//                     setLikedTokenId(data.data[0].token_Id);
//                     setAvatar(data.data[0].avatar);

//                 } else {
//                     console.error('Error fetching data:', data.error);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         if (email) {
//             fetchData();
//         }
//     }, [email]);





//     const handleButtonClick3 = async () => {
//         try {
//             const seeker_email = SeekerEmail;

//             // Fetch seeker details
//             const responseAvatar = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${SeekerEmail}`);

//             if (!responseAvatar.ok) {
//                 throw new Error(`Request failed with status: ${responseAvatar.status}`);
//             }

//             const data = await responseAvatar.json();
//             // console.log('Seeker data:', data);
//             // console.log('Seeker data:', data.data[0]);

//             const receivedAvatar = data.data[0]?.avatar;
//             // console.log('Seeker avatar:', receivedAvatar);

//             if (receivedAvatar) {
//                 setReceivedAvatar(receivedAvatar);
//             } else {
//                 console.error('Error: Avatar not found');
//             }

//             // Insert interest
//             const response = await fetch('https://ex.d5art.com/api/seeker_insert_interest', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     SeekerName,
//                     SeekerContractType,
//                     seeker_email,
//                     interestName,
//                     interestContractType,
//                     likedTokenId,
//                     email,
//                     token_id,
//                     interest_avatar,
//                     received_avatar: receivedAvatar,
//                 }),
//             });

//             const responseBody = await response.json();
//             // console.log('Response body:', responseBody);

//             if (response.ok) {
//                 if (responseBody.status === 'success') {
//                     //  console.log('Updated successfully.');
//                     toast.success('Interest inserted successfully');
//                 } else {
//                     console.error('Error updating data:', responseBody.msg);
//                     toast.error('Error updating data');
//                 }
//             } else if (response.status === 409) {
//                 console.error('Error: Email already exists');
//                 toast.error('Email already exists');
//             } else {
//                 console.error('Error updating data:', responseBody.msg);
//                 toast.error('Error updating data');
//             }
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error('Error updating data');
//         }
//     };




//     const updateInterestCount = async (email) => {
//         try {
//             const response = await fetch('https://ex.d5art.com/api/update_interest_count', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ email }),
//             });

//             if (response.ok) {
//                 //      console.log('Interest count updated successfully');
//             } else {
//                 console.error('Error updating interest count');
//             }
//         } catch (error) {
//             console.error('Error updating interest count:', error);
//         }
//     };





//     const handleButtonClick = () => {

//         const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
//         let encryptedTokenId = CryptoJS.AES.encrypt(token_id, Seeker_secretKey).toString();

//         const newPath = `/RMS/Seeker/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
//         window.location.href = newPath;
//     };



//     useEffect(() => {
//         const handleResize = () => {
//             setWindowWidth(window.innerWidth);
//         };

//         window.addEventListener('resize', handleResize);

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const [isPartypopup, setPartypopup] = useState(false);
//     const [isCongrats, setCongrats] = useState(false);

//     const handlePartypopup = () => {
//         setPartypopup(!isPartypopup)
//     }

//     const handleCongrats = () => {
//         setCongrats(!isCongrats)
//     }

//     const containerStyles = {
//         width: '100%',
//         paddingTop: '10px',
//         color: 'white',
//         backgroundImage: windowWidth <= 767 ? 'none' : 'url("/assets/Skillid.png")',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//     };

//     const [windowDimension, setWindowDimension] = useState({
//         width: window.innerWidth,
//         height: window.innerHeight,
//     });
//     const [Btn, setBtn] = useState(false);

//     const detectSize = () => {
//         setWindowDimension({
//             width: window.innerWidth,
//             height: window.innerHeight,
//         });
//     };

//     const handleBtnpop = () => {
//         setBtn(!Btn);
//     };

//     // Effect for handling resize events
//     useEffect(() => {
//         const handleResize = () => {
//             detectSize();
//         };

//         window.addEventListener('resize', handleResize);

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []); // Empty dependency array


//     const confettiConfig = {
//         angle: 90,
//         spread: 360,
//         startVelocity: 40,
//         elementCount: 70,
//         dragFriction: 0.12,
//         duration: 6000,
//         stagger: 3,
//         width: '10px',
//         height: '10px',
//         perspective: '500px',
//         colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
//     };


//     const [hoveredCourse, setHoveredCourse] = useState(null);

//     const handleMouseEnter = (courseName) => {
//         setHoveredCourse(courseName);
//     };

//     const handleMouseLeave = () => {
//         setHoveredCourse(null);
//     };

// // to update the metadata details from postman



// const update_metadata = async () => {



//     try {
//         const response = await axios.post('https://ex.d5art.com/api/get_seeker_details_to_update', {
//          email
//         });

//         console.log("update metadata details thx hash:",response)


//     } catch (error) {
//         console.error('Error:', error);
//     }
// };


//     if (loading) {
//         return <Loader />;
//     }

//     return (
//         <div className='font-poppins bg-[#101010] backdrop-blur-sm '>

//             {/* Conditional rendering for loader and blank page when their is no response from moralis */}
//             {
//                 // loading ? (
//                 //     <Loader />
//                 // ) : items.length === 0 ? (
//                 //     <ErrorPage/>

//                 // ) : 
//                 (

//                     items.map((item, index) => {
//                         const metadata = JSON.parse(item.metadata);
//                         const ipfsImage = metadata.image.split('://')[1];


//                         const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
//                         const skills = skillsAttributes.map(attr => attr.value);
//                         console.log('skills', skills);

//                         // const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
//                         // const skills = skillsAttributes.flatMap(attr => {
//                         //     try {
//                         //         return JSON.parse(attr.value) || [];
//                         //     } catch (error) {
//                         //         console.error('Error parsing skill:', error);
//                         //         return [];
//                         //     }
//                         // });
//                         // console.log('skills', skills);


//                         const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
//                         const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
//                         console.log('prefered_roll', prefered_rolls);




//                         const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');


//                         const experience_roleAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_role');
//                         const experience_roles = experience_roleAttributes.map(attr => attr.value);

//                         const experience_fromAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_from');
//                         const experience_froms = experience_fromAttributes.map(attr => attr.value);

//                         const experience_toAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_to');
//                         const experience_tos = experience_toAttributes.map(attr => attr.value);



//                         const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'experience');
//                         const experience = experience_Attributes.map(attr => attr.value);


//                         const projectAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project');
//                         const project = projectAttributes.map(attr => attr.value);


//                         const project_languageAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project_language_used');
//                         const project_language_used = project_languageAttributes.map(attr => attr.value);


//                         const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 7) : '';

//                         const platform_skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_skills');
//                         const platform_skills = platform_skillsAttributes.map(attr => attr.value);


//                         const platform_certificatesAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_certificates');
//                         const platform_certificates = platform_certificatesAttributes.map(attr => attr.value);



//                         const getCourseScore = (courseName) => {

//                             const flattenedCertificates = platform_certificates.flat();

//                             console.log("Flattened certificates:", flattenedCertificates);


//                             const certificate = flattenedCertificates.find(cert => cert.course_name === courseName);
//                             console.log("certificate....", certificate);

//                             return certificate ? certificate.score : null;
//                         };





//                         //  console.log("platform_skills", platform_skills)
//                         console.log("platform_certificates", platform_certificates)





//                         const combinedSkills = [...skillsAttributes.map(attr => attr.value), ...platform_skillsAttributes.map(attr => attr.value)];

//                         console.log("combined skills", combinedSkills);

//                         const normalSkills = [...skillsAttributes.map(attr => attr.value)];
//                         const platformSkills = [...platform_skillsAttributes.map(attr => attr.value)];

//                         const groupedPlatformSkills = platformSkills.reduce((acc, skill) => {
//                             const skillDetails = typeof skill === 'string' ? JSON.parse(skill) : skill;

//                             skillDetails.forEach((item) => {
//                                 const courseName = item.course || 'Unknown Course';
//                                 if (!acc[courseName]) {
//                                     acc[courseName] = [];
//                                 }
//                                 acc[courseName].push(item);
//                             });
//                             return acc;
//                         }, {});


//                         return (
//                             <React.Fragment key={index}>
//                                 <LoginHeader />
//                                 <Toaster />


//                                 <div className='Content1 pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
//                                     <div className='flex justify-center'>
//                                         <h1 className='text-center text-3xl font-bold'
//                                             style={{
//                                                 backgroundClip: 'text',
//                                                 WebkitBackgroundClip: 'text',
//                                                 color: 'transparent',
//                                                 backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
//                                             }}>Skill Arena</h1>
//                                     </div>
//                                     <div className='flex justify-center md:h-screen'>
//                                         <div style={containerStyles}>
//                                             <div className='flex justify-center'>
//                                                 <h1 className='font-bold 2xl:mt-12  mt-3 lg:mt-4 2xl:text-[22px] text-lg'>NFT ID: <span className='text-[#00F4FC]' title={metadata.name}> {metadata.name && metadata.name.length > 0 ? metadata.name.slice(0, 10) + '' : metadata.name}</span></h1>
//                                             </div>
//                                             <div className='flex justify-center mt-5 lg:mt-5'>
//                                                 <div className='lg:flex w-[90%] 2xl:mt-20 border md:border-0 border-gray-800 rounded-3xl py-5 md:py-0'>
//                                                     <div className='md:w-[33%] px-5'>
//                                                         <p className='text-[#FFBA00] font-bold uppercase text-2xl'>Experience</p>
//                                                         <div className='mt-7 skill1 h-[425px] overflow-hidden overflow-y-scroll'>
//                                                             {experience.map((exp, index) => {
//                                                                 const experienceDetails = JSON.parse(exp);
//                                                                 // Check if any of the fields are empty
//                                                                 if (experienceDetails && experienceDetails.companyname && experienceDetails.from && experienceDetails.to && experienceDetails.role &&
//                                                                     experienceDetails.companyname.trim() !== '' && experienceDetails.from.trim() !== '' && experienceDetails.to.trim() !== '' && experienceDetails.role.trim() !== '') {
//                                                                     return (
//                                                                         <div key={index}>
//                                                                             <div>
//                                                                                 <div>
//                                                                                     <p className='flex justify-between px-3'>
//                                                                                         <p className='font-bold text-lg'>{experienceDetails.role}</p>
//                                                                                         <p className='text-xs mt-2'> {experienceDetails.from} - {experienceDetails.to} </p>
//                                                                                     </p>
//                                                                                     <p className='flex justify-between px-3 mt-2'>
//                                                                                         <p className='font-bold text-[#0EA4A9]'>{experienceDetails.companyname}</p>
//                                                                                         <p className='text-[#36A90E] font-bold'>Verified</p>
//                                                                                     </p>
//                                                                                     <div className='flex justify-center py-4'>
//                                                                                         <div>
//                                                                                             <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                                 <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         <div className='h-[1px] w-full bg-white'></div>
//                                                                                         <div>
//                                                                                             <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                                 <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     );
//                                                                 } else {
//                                                                     return null; // Skip rendering if any field is empty
//                                                                 }
//                                                             })}
//                                                         </div>





//                                                     </div>
//                                                     <div className='md:w-[33%] grid'>

//                                                         <div className='flex items-end justify-center pt-14 2xl:pt-16'>
//                                                             <p className='text-center text-2xl 2xl:text-3xl'>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
//                                                         </div>

//                                                         <div class="nft-image-wrapper">
//                                                             <img src={getImageFromMetadata(item.metadata)} class="image-autosize mx-auto img-fluid img-rounded mb-sm-30 resized-image " alt="" />
//                                                         </div>
//                                                     </div>



//                                                     <div className='md:w-[33%] px-5'>
//                                                         <p className='text-[#FFBA00] text-end font-bold uppercase text-2xl'>SKILL AND EXPERTISE</p>
//                                                         <div className='mt-7 skill1 h-[425px] overflow-hidden overflow-y-scroll'>
//                                                             {/* Render normalSkills */}
//                                                             {normalSkills.map((skill, index) => {
//                                                                 const skillDetails = typeof skill === 'string' ? JSON.parse(skill) : skill;

//                                                                 if (!Array.isArray(skillDetails)) {
//                                                                     console.warn(`Expected skillDetails to be an array at index ${index}`, skillDetails);
//                                                                     return null;
//                                                                 }

//                                                                 return (
//                                                                     <div key={index}>
//                                                                         {skillDetails.map((item, subIndex) => {
//                                                                             const skillName = item.skill || item.name;
//                                                                             const percentage = item.percentage || item.points;

//                                                                             if (!skillName) {
//                                                                                 console.warn(`Undefined skillName at subIndex ${subIndex}`, item);
//                                                                                 return null;
//                                                                             }

//                                                                             const matchedSkill = formattedSetNames.find(name => name === skillName);
//                                                                             const shouldDisplayImage = matchedSkill && (result[index] === 'PASS' || result[index] === null);

//                                                                             return (
//                                                                                 <div key={subIndex}>
//                                                                                     <div className='flex justify-between'>
//                                                                                         <div>
//                                                                                             <p className='font-bold mt-2 text-lg text-[#FFBA00]'>
//                                                                                                 {skillName}{' '}
//                                                                                                 <span className='text-[#0EA4A9]'>
//                                                                                                     - {percentage}{' '}
//                                                                                                     {item.percentage ? '%' : 'points'}
//                                                                                                 </span>
//                                                                                             </p>
//                                                                                         </div>

//                                                                                         {/* Conditional image display */}
//                                                                                         {shouldDisplayImage && (
//                                                                                             <div>
//                                                                                                 <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />
//                                                                                             </div>
//                                                                                         )}
//                                                                                     </div>
//                                                                                     {/* Progress bar */}
//                                                                                     <div className='px-3 py-2'>
//                                                                                         <div className='h-2 w-full bg-gray-900 rounded'>
//                                                                                             <div
//                                                                                                 className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
//                                                                                                 style={{ width: `${percentage}%` }}
//                                                                                             />
//                                                                                         </div>
//                                                                                     </div>
//                                                                                     <div className='flex justify-center py-4'>
//                                                                                         <div>
//                                                                                             <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                                 <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         <div className='h-[1px] w-full bg-white'></div>
//                                                                                         <div>
//                                                                                             <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                                 <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             );
//                                                                         })}
//                                                                     </div>
//                                                                 );
//                                                             })}

//                                                             {/* Render groupedPlatformSkills */}
//                                                             {Object.keys(groupedPlatformSkills).map((courseName, index) => {
//                                                                 const courseScore = getCourseScore(courseName);
//                                                                 console.log("courseScore", courseScore)

//                                                                 return (
//                                                                     <div key={index}>
//                                                                         <div className='flex justify-between'>
//                                                                             <p
//                                                                                 className='font-bold mt-2 text-lg text-[#FFBA00] cursor-pointer '
//                                                                                 onMouseEnter={() => handleMouseEnter(courseName)}
//                                                                                 onMouseLeave={handleMouseLeave}
//                                                                             >
//                                                                                 {courseName}
//                                                                                 <span className='text-[#0EA4A9]'> - {" "}
//                                                                                        {courseScore ? `${courseScore}%` : 'N/A'} {/* Display the dynamic score */}
//                                                                                 </span>
//                                                                             </p>

//                                                                             {/* Optionally display the image and progress bar */}
//                                                                             <div>
//                                                                                 <img src='/assets/D5ART_Fav icon.png' alt='Course logo' />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className='px-3 py-2'>
//                                                                             <div className='h-2 w-full bg-gray-900 rounded'>
//                                                                                 <div
//                                                                                     className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
//                                                                                     style={{ width: `${courseScore || 0}%` }}  // Use score as width
//                                                                                 />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className='flex justify-center py-4'>
//                                                                             <div>
//                                                                                 <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                     <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                 </div>
//                                                                             </div>
//                                                                             <div className='h-[1px] w-full bg-white'></div>
//                                                                             <div>
//                                                                                 <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
//                                                                                     <div className='p-1.5 rounded-full bg-white'></div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>

//                                                                         {/* Conditionally display platform skills on hover */}
//                                                                         {hoveredCourse === courseName && (
//                                                                             <div>
//                                                                                 {groupedPlatformSkills[courseName].map((item, subIndex) => {
//                                                                                     const skillName = item.skill || item.name;
//                                                                                     const percentage = item.percentage || item.skillPoints;

//                                                                                     if (!skillName) {
//                                                                                         console.warn(`Undefined platformSkillName at subIndex ${subIndex}`, item);
//                                                                                         return null;
//                                                                                     }

//                                                                                     const matchedSkill = formattedSetNames.find(name => name === skillName);
//                                                                                     const shouldDisplayImage = matchedSkill && (result[subIndex] === 'PASS' || result[subIndex] === null);

//                                                                                     return (
//                                                                                         <div key={subIndex}>
//                                                                                             <div className='flex justify-between '>
//                                                                                                 <div>
//                                                                                                     <p className='font-bold mt-2 text-lg text-[#FFBA00]'>
//                                                                                                         {skillName}{' '}
//                                                                                                         <span className='text-[#0EA4A9]'>
//                                                                                                             - {percentage}{' '}
//                                                                                                             {item.percentage ? '%' : 'points'}
//                                                                                                         </span>
//                                                                                                     </p>
//                                                                                                 </div>

//                                                                                                 {/* Display image if condition matches */}
//                                                                                                 {shouldDisplayImage && (
//                                                                                                     <div className='flex justify-between'>
//                                                                                                         <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />
//                                                                                                     </div>
//                                                                                                 )}
//                                                                                             </div>

//                                                                                             {/* Progress bar */}
//                                                                                             {/* <div className='px-3 py-2'>
//                                                                                                 <div className='h-2 w-full bg-gray-900 rounded'>
//                                                                                                     <div
//                                                                                                         className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
//                                                                                                         style={{ width: `${percentage}%` }}
//                                                                                                     />
//                                                                                                 </div>
//                                                                                             </div> */}

//                                                                                         </div>
//                                                                                     );
//                                                                                 })}
//                                                                             </div>
//                                                                         )}
//                                                                     </div>
//                                                                 );
//                                                             })}
//                                                         </div>
//                                                     </div>







//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>








//                                     {likedTokenId !== null && interestContractType !== null ? (
//                                         <div className='flex justify-center md:-translate-y-8 mt-5 md:mt-0'>
//                                             <button key={index} className='flex py-2  px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={async () => { handlePartypopup(); handleButtonClick3(); await updateInterestCount(email); }}>
//                                                 <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[140px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '  ></button>
//                                                 <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'>Interest</p>
//                                                 <button className=' p-3.5 rounded-full  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
//                                             </button>
//                                         </div>
//                                     ) : null}

//                                     {isPartypopup && (
//                                         <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
//                                             <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
//                                                 <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handlePartypopup}></i></div>
//                                                 <div className='flex h-[17rem] 2xl:[20rem]'>
//                                                     <div className='w-[40%]'>
//                                                         <div >
//                                                             <img src='/assets/avatar.png' className='absolute h-[22.5rem] -translate-y-[90px] -translate-x-16 2xl:-translate-x-0'></img>
//                                                         </div>
//                                                     </div>
//                                                     <div className='w-[60%]  pr-7 text-white grid content-around'>
//                                                         <p className='flex justify-between text-lg'><p>NFT: </p><span className='text-[#00F4FC]'>{slicedName}</span></p>
//                                                         <p className='uppercase text-sm'>Thanks for taking interest in viewing this NFT Profile. </p>
//                                                         <div className='flex justify-between'>

//                                                             <div>
//                                                                 <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={() => {


//                                                                     handleCongrats(); handleBtnpop(); handleButtonClick();
//                                                                 }} ><button className='bg-black px-6 text-sl py-1 rounded-lg'>Thanks For Liking 👍</button></button>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     )}










//                                     <div className='flex justify-center text-white mt-10 md:mt-0'>
//                                         <div className='w-[90%] lg:flex'>
//                                             <div className='lg:w-[65%]'>
//                                                 <h1 className='uppercase text-[#646464] text-2xl font-bold'>Summary:</h1>
//                                                 <p className='p-3 uppercase'>{metadata.description} </p>
//                                             </div>

//                                             <div className='lg:w-[45%] 2xl:pl-16'>
//                                                 <h1 className='uppercase text-[#646464] text-2xl font-bold'>Preferred Role:</h1>
//                                                 <div className='flex flex-col md:flex-row gap-3 md:gap-5 p-5'>
//                                                     {prefered_rollsAttribute.map((prefered_roll, index) => {
//                                                         if (prefered_roll.value) {
//                                                             try {

//                                                                 const preferedRoles = JSON.parse(prefered_roll.value);
//                                                                 return preferedRoles.map((preferedRole, roleIndex) => (
//                                                                     <div key={index + '-' + roleIndex}>
//                                                                         <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{preferedRole}</p>
//                                                                     </div>
//                                                                 ));
//                                                             } catch (error) {
//                                                                 console.error('Error parsing preferred role data:', error);
//                                                                 return null;
//                                                             }
//                                                         } else {
//                                                             return null;
//                                                         }
//                                                     })}
//                                                 </div>
//                                             </div>




//                                         </div>
//                                     </div>

//                                     <div className='flex justify-center text-white mt-7'>
//                                         <div className='w-[90%]'>
//                                             <h1 className='uppercase text-[#646464] text-2xl font-bold'>Projects:</h1>
//                                             <div className='mt-7 md:flex justify-start gap-16'>
//                                                 {project.map((project_name, index) => (

//                                                     (project_name && project_name.trim() !== '') && (
//                                                         <div key={index} className=' bg-gradient-to-tl from-[#0EA4A9] to-[#0E2DA7] p-[2px] rounded-3xl md:w-[23%]'>
//                                                             <div className='bg-black p-5 rounded-3xl'>
//                                                                 <p className='text-center py-3'>{project_name}</p>
//                                                                 <div className='flex justify-around pt-7 pb-5 text-xs'>

//                                                                     {project_language_used[index] && JSON.parse(project_language_used[index]).map((language, subIndex) => (
//                                                                         <p key={subIndex} className='py-1 rounded-lg border border-gray-700 text-center w-[28%]'>{language}</p>
//                                                                     ))}
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     )
//                                                 ))}
//                                             </div>
//                                         </div>
//                                     </div>


//                                 </div>
//                             </React.Fragment>
//                         );
//                     })
//                 )}
//             <Footer />
//         </div>

//     )
// }





import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Moralis from 'moralis';

import Confetti from 'react-dom-confetti';
import LoginHeader from './LoginHeader';
import { useSelector } from 'react-redux';
import { setSeekerEmail } from '../store';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Spinners from './Spinners';

import Loader from '../loaderdemo';
import ErrorPage from '../please_wait_page';
import Footer from '../Footer';
import CryptoJS from 'crypto-js';


export default function ItemDetails() {
    const [nftItems, setNftItems] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);


    const [token_id, setToken_id] = useState('');

    const [SeekerName, setSeekerName] = useState('');
    const [SeekerContractType, setSeekerContractType] = useState('');
    const [SeekerEmail, setSeekerEmail] = useState('');

    const [interestName, setInterestName] = useState('');
    const [interestContractType, setInterestContractType] = useState('');
    const [likedTokenId, setLikedTokenId] = useState('');


    const [validationStatus, setValidationStatus] = useState(null);
    const [formattedSetNames, setFormattedSetNames] = useState([]);
    const [result, setResult] = useState('');
    const [status, setStatus] = useState('');



    const [loading, setLoading] = useState(true);
    const [interest_avatar, setAvatar] = useState('');
    const [received_avatar, setReceivedAvatar] = useState('');

    const location = useLocation();

    const email = useSelector((state) => state.seeker_email);
    //  console.log("Email Logged In2222222", email)

    const dispatch = useDispatch();

    // const moralis_apiKey = process.env.REACT_APP_MORALIS_APIKEY;
    // const moralis_chainId = process.env.REACT_APP_MORALIS_CHAIN; 
    // const moralis_contract_address = process.env.REACT_APP_MORALIS_NFT_ADDRESS;

    const moralis_apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
    const moralis_chainId = "0x13882";
    const moralis_contract_address = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";


    const fetchData = async () => {
        const params = new URLSearchParams(location.search);
        const tokenId = params.get('tokenId');
        console.log('Token ID:', tokenId);

        // const Seeker_secretKey = "seeker123";

        const Seeker_secretKey = "Seeker@sdfs23423@##%#$@$Y%USJNFJSWB##*$&@^23w54293y428935yjrj#$@$@#";
        // const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
        const encryptedTokenId = params.get('tokenId');
        let bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedTokenId), Seeker_secretKey);
        //const tokenId = bytes.toString(CryptoJS.enc.Utf8);
        // console.log('Token ID:', tokenId);

        setToken_id(tokenId);



        try {
            console.log('Fetching data...');
            await Moralis.start({
                apiKey: moralis_apiKey

            });
            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": moralis_chainId,
                "format": "decimal",
                "address": moralis_contract_address
            });




            console.log("response", response);
            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
            console.log('IPFS Image:', ipfsImage);
            const temp = response.raw.result
            console.log('Response:', response.raw.result);
            setNftItems(response.raw.result);


            const filteredItems = tokenId
                ? temp.filter(item => item.token_id === tokenId)
                : [];
            console.log('Filtered Items:', filteredItems);


            setItems(filteredItems);

            console.log(".....", filteredItems.length)
            if (filteredItems.length > 0) {
                const metadataString = filteredItems[0].metadata;
                const metadata = JSON.parse(metadataString);

                const name = metadata.name;

                const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');

                const contract_type = contract_typeAttribute.value;

                const seeker_email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');

                const seekerEmail = seeker_email_typeAttribute.value;


                console.log("e55555555555", metadata)

                console.log("seekername", name)



                setSeekerName(name);
                setSeekerContractType(contract_type);
                setSeekerEmail(seekerEmail);
                // setSeekerAvatar()



                //   console.log('Name:11111', seekerEmail);


            } else {
                console.log('No matching items found.');
                //setLoading(false);
            }

            //    console.log("nameeeeeee", items.metadata.name);



        } catch (error) {
            console.error('Error fetching data:', error);
            if (error && error.code === 401) {
                setLoading(true); // Show loader only for 401 error
            } else {
                setError(error);
                setLoading(false);
            }
        }
    };





    const handleGetValidationStatus = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/getValidationStatusByEmail', { email: SeekerEmail });
            const data = response.data;
            if (response.data.status === false) {
                return false;
            }

            setValidationStatus(data);


            const formattedNames = data.map(entry => entry.Set_name.substring(11));
            const results = data.map(entry => entry.result);
            const statuses = data.map(entry => entry.status);
            // console.log("response",response)
            setFormattedSetNames(formattedNames);
            //  console.log("formattedNames",formattedNames)

            setResult(results);
            //  console.log("results",result)
            setStatus(statuses);
            //  console.log("status",status)

        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        if (SeekerEmail) {
            handleGetValidationStatus();
        }
    }, [SeekerEmail]);


    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };




    useEffect(() => {
        const fetchDataAndItem = async () => {
            await fetchData();
        };
        fetchDataAndItem();

    }, []);







    // interest 

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                // console.log('555566', data.data[0].contract_type);
                // console.log('555566', data.data[0].name);
                // console.log('555566', data.data[0].token_Id);
                // console.log('555566', data.data[0].avatar);


                if (response.ok) {

                    setInterestName(data.data[0].name);
                    setInterestContractType(data.data[0].contract_type);
                    setLikedTokenId(data.data[0].token_Id);
                    setAvatar(data.data[0].avatar);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);





    const handleButtonClick3 = async () => {
        try {
            const seeker_email = SeekerEmail;

            // Fetch seeker details
            const responseAvatar = await fetch(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${SeekerEmail}`);

            if (!responseAvatar.ok) {
                throw new Error(`Request failed with status: ${responseAvatar.status}`);
            }

            const data = await responseAvatar.json();
            // console.log('Seeker data:', data);
            // console.log('Seeker data:', data.data[0]);

            const receivedAvatar = data.data[0]?.avatar;
            // console.log('Seeker avatar:', receivedAvatar);

            if (receivedAvatar) {
                setReceivedAvatar(receivedAvatar);
            } else {
                console.error('Error: Avatar not found');
            }

            // Insert interest
            const response = await fetch('https://ex.d5art.com/api/seeker_insert_interest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    SeekerName,
                    SeekerContractType,
                    seeker_email,
                    interestName,
                    interestContractType,
                    likedTokenId,
                    email,
                    token_id,
                    interest_avatar,
                    received_avatar: receivedAvatar,
                }),
            });

            const responseBody = await response.json();
            // console.log('Response body:', responseBody);

            if (response.ok) {
                if (responseBody.status === 'success') {
                    //  console.log('Updated successfully.');
                    toast.success('Interest inserted successfully');
                } else {
                    console.error('Error updating data:', responseBody.msg);
                    toast.error('Error updating data');
                }
            } else if (response.status === 409) {
                console.error('Error: Email already exists');
                toast.error('Email already exists');
            } else {
                console.error('Error updating data:', responseBody.msg);
                toast.error('Error updating data');
            }
        } catch (error) {
            console.error('Error updating data:', error);
            toast.error('Error updating data');
        }
    };




    const updateInterestCount = async (email) => {
        try {
            const response = await fetch('https://ex.d5art.com/api/update_interest_count', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                //      console.log('Interest count updated successfully');
            } else {
                console.error('Error updating interest count');
            }
        } catch (error) {
            console.error('Error updating interest count:', error);
        }
    };





    const handleButtonClick = () => {

        const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
        let encryptedTokenId = CryptoJS.AES.encrypt(token_id, Seeker_secretKey).toString();

        const newPath = `/RMS/Seeker/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
        window.location.href = newPath;
    };



    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isPartypopup, setPartypopup] = useState(false);
    const [isCongrats, setCongrats] = useState(false);

    const handlePartypopup = () => {
        setPartypopup(!isPartypopup)
    }

    const handleCongrats = () => {
        setCongrats(!isCongrats)
    }

    const containerStyles = {
        width: '100%',
        paddingTop: '10px',
        color: 'white',
        backgroundImage: windowWidth <= 767 ? 'none' : 'url("/assets/Skillid.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [Btn, setBtn] = useState(false);

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const handleBtnpop = () => {
        setBtn(!Btn);
    };

    // Effect for handling resize events
    useEffect(() => {
        const handleResize = () => {
            detectSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array


    const confettiConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 6000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
    };





    if (loading) {
        return <Loader />;
    }

    return (
        <div className='font-poppins bg-[#101010] backdrop-blur-sm '>

            {/* Conditional rendering for loader and blank page when their is no response from moralis */}
            {
                // loading ? (
                //     <Loader />
                // ) : items.length === 0 ? (
                //     <ErrorPage/>

                // ) : 
                (

                    items.map((item, index) => {
                        const metadata = JSON.parse(item.metadata);
                        const ipfsImage = metadata.image.split('://')[1];

                        console.log("metadata.....", metadata)


                        const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
                        const skills = skillsAttributes.map(attr => attr.value);
                        console.log('skills', skills);


                        const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                        const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                        console.log('prefered_roll', prefered_rolls);




                        const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');


                        const experience_roleAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_role');
                        const experience_roles = experience_roleAttributes.map(attr => attr.value);

                        const experience_fromAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_from');
                        const experience_froms = experience_fromAttributes.map(attr => attr.value);

                        const experience_toAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_to');
                        const experience_tos = experience_toAttributes.map(attr => attr.value);



                        const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'experience');
                        const experience = experience_Attributes.map(attr => attr.value);


                        const projectAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project');
                        const project = projectAttributes.map(attr => attr.value);


                        const project_languageAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project_language_used');
                        const project_language_used = project_languageAttributes.map(attr => attr.value);

                        const platform_skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_skills');
                        const platform_skills = platform_skillsAttributes.map(attr => attr.value);

                        const platform_certificatesAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_certificates');
                        const platform_certificates = platform_certificatesAttributes.map(attr => attr.value);

                        console.log("platform_skills", platform_skills)
                        console.log("platform_certificates", platform_certificates)


                        const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 7) : '';
                        return (
                            <React.Fragment key={index}>
                                <LoginHeader />
                                <Toaster />


                                <div className='Content1 pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                    <div className='flex justify-center'>
                                        <h1 className='text-center text-3xl font-bold'
                                            style={{
                                                backgroundClip: 'text',
                                                WebkitBackgroundClip: 'text',
                                                color: 'transparent',
                                                backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                            }}>Skill Arena</h1>
                                    </div>
                                    <div className='flex justify-center md:h-screen'>
                                        <div style={containerStyles}>
                                            <div className='flex justify-center'>
                                                <h1 className='font-bold 2xl:mt-12  mt-3 lg:mt-4 2xl:text-[22px] text-lg'>NFT ID: <span className='text-[#00F4FC]' title={metadata.name}> {metadata.name && metadata.name.length > 0 ? metadata.name.slice(0, 10) + '' : metadata.name}</span></h1>
                                            </div>
                                            <div className='flex justify-center mt-5 lg:mt-5'>
                                                <div className='lg:flex w-[90%] 2xl:mt-20 border md:border-0 border-gray-800 rounded-3xl py-5 md:py-0'>
                                                    <div className='md:w-[33%] px-5'>
                                                        <p className='text-[#FFBA00] font-bold uppercase text-2xl'>Experience</p>
                                                        <div className='mt-7 skill1 h-[425px] overflow-hidden overflow-y-scroll'>
                                                            {experience.map((exp, index) => {
                                                                const experienceDetails = JSON.parse(exp);
                                                                // Check if any of the fields are empty
                                                                if (experienceDetails && experienceDetails.companyname && experienceDetails.from && experienceDetails.to && experienceDetails.role &&
                                                                    experienceDetails.companyname.trim() !== '' && experienceDetails.from.trim() !== '' && experienceDetails.to.trim() !== '' && experienceDetails.role.trim() !== '') {
                                                                    return (
                                                                        <div key={index}>
                                                                            <div>
                                                                                <div>
                                                                                    <p className='flex justify-between px-3'>
                                                                                        <p className='font-bold text-lg'>{experienceDetails.role}</p>
                                                                                        <p className='text-xs mt-2'> {experienceDetails.from} - {experienceDetails.to} </p>
                                                                                    </p>
                                                                                    <p className='flex justify-between px-3 mt-2'>
                                                                                        <p className='font-bold text-[#0EA4A9]'>{experienceDetails.companyname}</p>
                                                                                        <p className='text-[#36A90E] font-bold'>Verified</p>
                                                                                    </p>
                                                                                    <div className='flex justify-center py-4'>
                                                                                        <div>
                                                                                            <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                <div className='p-1.5 rounded-full bg-white'></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='h-[1px] w-full bg-white'></div>
                                                                                        <div>
                                                                                            <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                <div className='p-1.5 rounded-full bg-white'></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return null; // Skip rendering if any field is empty
                                                                }
                                                            })}
                                                        </div>





                                                    </div>
                                                    <div className='md:w-[33%] grid'>

                                                        <div className='flex items-end justify-center pt-14 2xl:pt-16'>
                                                            <p className='text-center text-2xl 2xl:text-3xl'>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
                                                        </div>

                                                        <div class="nft-image-wrapper">
                                                            <img src={getImageFromMetadata(item.metadata)} class="image-autosize mx-auto img-fluid img-rounded mb-sm-30 resized-image " alt="" />
                                                        </div>
                                                    </div>

                                                    <div className='md:w-[33%] px-5'>
                                                        <p className='text-[#FFBA00] text-end font-bold uppercase text-2xl'>SKILL AND EXPERTISE</p>

                                                        <div className='mt-7 skill1 h-[425px] overflow-hidden overflow-y-scroll'>
                                                            {skillsAttributes.map((attr, index) => {
                                                                try {
                                                                    const skillDetails = JSON.parse(attr.value);


                                                                    if (Array.isArray(skillDetails) && skillDetails.length > 0) {
                                                                        return (
                                                                            <div key={index}>
                                                                                {skillDetails.map((skill, subIndex) => {
                                                                                    const skillName = skill.skill;
                                                                                    const matchedSkill = formattedSetNames.find(name => name === skillName);
                                                                                    const shouldDisplayImage = matchedSkill && (result[subIndex] === 'PASS' || result[subIndex] === null);

                                                                                    return (
                                                                                        <div key={subIndex}>
                                                                                            <div className='flex justify-between'>
                                                                                                <div>
                                                                                                    <p className='font-bold mt-2 text-lg text-[#FFBA00]'>{skill.skill} <span className='text-[#0EA4A9]' > - {skill.percentage}% </span></p>
                                                                                                </div>
                                                                                                {shouldDisplayImage && <div><img src='/assets/D5ART_Fav icon.png' alt='Skill logo' /></div>}
                                                                                            </div>
                                                                                            <div className='px-3 py-2'>
                                                                                                <div className='h-2 w-full bg-gray-900 rounded'>
                                                                                                    <div className='w-40 h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]' style={{ width: `${skill.percentage}% ` }} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='flex justify-center py-4'>
                                                                                                <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                    <div className='p-1.5 rounded-full bg-white' />
                                                                                                </div>
                                                                                                <div className='h-[1px] w-full bg-white' />
                                                                                                <div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'>
                                                                                                    <div className='p-1.5 rounded-full bg-white' />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                } catch (error) {
                                                                    console.error('Error parsing skill data:', error);
                                                                    return null;
                                                                }
                                                            })}
                                                        </div>






                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>








                                    {likedTokenId !== null && interestContractType !== null ? (
                                        <div className='flex justify-center md:-translate-y-8 mt-5 md:mt-0'>
                                            <button key={index} className='flex py-2  px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={async () => { handlePartypopup(); handleButtonClick3(); await updateInterestCount(email); }}>
                                                <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[140px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '  ></button>
                                                <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'>Interest</p>
                                                <button className=' p-3.5 rounded-full  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                            </button>
                                        </div>
                                    ) : null}

                                    {isPartypopup && (
                                        <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
                                            <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                                <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handlePartypopup}></i></div>
                                                <div className='flex h-[17rem] 2xl:[20rem]'>
                                                    <div className='w-[40%]'>
                                                        <div >
                                                            <img src='/assets/avatar.png' className='absolute h-[22.5rem] -translate-y-[90px] -translate-x-16 2xl:-translate-x-0'></img>
                                                        </div>
                                                    </div>
                                                    <div className='w-[60%]  pr-7 text-white grid content-around'>
                                                        <p className='flex justify-between text-lg'><p>NFT: </p><span className='text-[#00F4FC]'>{slicedName}</span></p>
                                                        <p className='uppercase text-sm'>Thanks for taking interest in viewing this NFT Profile. </p>
                                                        <div className='flex justify-between'>

                                                            <div>
                                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={() => {


                                                                    handleCongrats(); handleBtnpop(); handleButtonClick();
                                                                }} ><button className='bg-black px-6 text-sl py-1 rounded-lg'>Thanks For Liking 👍</button></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}










                                    <div className='flex justify-center text-white mt-10 md:mt-0'>
                                        <div className='w-[90%] lg:flex'>
                                            <div className='lg:w-[65%]'>
                                                <h1 className='uppercase text-[#646464] text-2xl font-bold'>Summary:</h1>
                                                <p className='p-3 uppercase'>{metadata.description} </p>
                                            </div>

                                            <div className='lg:w-[45%] 2xl:pl-16'>
                                                <h1 className='uppercase text-[#646464] text-2xl font-bold'>Preferred Role:</h1>
                                                <div className='flex flex-col md:flex-row gap-3 md:gap-5 p-5'>
                                                    {prefered_rollsAttribute.map((prefered_roll, index) => {
                                                        if (prefered_roll.value) {
                                                            try {

                                                                const preferedRoles = JSON.parse(prefered_roll.value);
                                                                return preferedRoles.map((preferedRole, roleIndex) => (
                                                                    <div key={index + '-' + roleIndex}>
                                                                        <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{preferedRole}</p>
                                                                    </div>
                                                                ));
                                                            } catch (error) {
                                                                console.error('Error parsing preferred role data:', error);
                                                                return null;
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </div>




                                        </div>
                                    </div>

                                    <div className='flex justify-center text-white mt-7'>
                                        <div className='w-[90%]'>
                                            <h1 className='uppercase text-[#646464] text-2xl font-bold'>Projects:</h1>
                                            <div className='mt-7 md:flex justify-start gap-16'>
                                                {project.map((project_name, index) => (

                                                    (project_name && project_name.trim() !== '') && (
                                                        <div key={index} className=' bg-gradient-to-tl from-[#0EA4A9] to-[#0E2DA7] p-[2px] rounded-3xl md:w-[23%]'>
                                                            <div className='bg-black p-5 rounded-3xl'>
                                                                <p className='text-center py-3'>{project_name}</p>
                                                                <div className='flex justify-around pt-7 pb-5 text-xs'>

                                                                    {project_language_used[index] && JSON.parse(project_language_used[index]).map((language, subIndex) => (
                                                                        <p key={subIndex} className='py-1 rounded-lg border border-gray-700 text-center w-[28%]'>{language}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </React.Fragment>
                        );
                    })
                )}
            <Footer />
        </div>

    )
}
