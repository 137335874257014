import {React, useEffect,useState} from 'react';
import './seeker2.css';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSeekerEmail } from './store';
import { NavLink, useNavigate } from 'react-router-dom';

export default function Congrats() {


  const [nftInfo, setNftInfo] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userEmail = urlParams.get('email');
  console.log(userEmail);
  useEffect(() => {
    const fetchNftInfo = async () => {
      try {
        const response = await axios.get(`https://ex.d5art.com/api/getNftInfo?email=${userEmail}`);
        setNftInfo(response.data);
        console.log('resssssss',response)
      } catch (error) {
        console.error('Error fetching NFT info:', error);
      }
    };

    fetchNftInfo();
  }, []);


  const handleRedirectToHome = async () => {

  setTimeout(() => {
    dispatch(setSeekerEmail(userEmail));
    navigate('/RMS/Seeker/');
});

  }


  return (
  <div>
      {nftInfo ? (
        <div className='font-play bg-black backdrop-blur-sm min-h-screen'>
          <div className='flex justify-center pt-5'>
            <div className='flex flex-col gap-10'>
              <span className='uppercase text-5xl' style={{
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                backgroundImage: 'linear-gradient(to right, #0EA4A9, #F29D38)',
              }}>Congratulations</span>
              <p className='text-white px-24'>You’re NFT ID created successfully</p>
            </div>
          </div>
          <div className='flex items-center'>
            <div className='w-[35%]'>
              <div className='flex justify-center translate-y-12'>
                <img className='slide-in-from-top' src={nftInfo[0].avatar} alt="NFT Image"></img>
              </div>
              <div className='flex justify-center '>
                <img className='h-12 w-7/12' src='./assets/Circle.png' alt="Circle"></img>
              </div>
            </div>
            <div className='w-[65%] flex justify-center mt-10'>
              <div className='w-[85%] flex flex-col gap-7 py-5 bg-[#111111] px-5 rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]'>
                <div className='flex justify-between'>
                  <p className='text-white font-bold slide-in-from-left'>NFT ID:</p>
                  <p className='text-[#0EA4A9] '>{nftInfo[0].nftId}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-white font-bold slide-in-from-left'>NFT minted on:</p>
                  <p className='text-[#0EA4A9]'>{nftInfo[0].mintedOn}</p>
                  {/* <p className='text-[#0EA4A9]'>{new Date(nftInfo[0].mintedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</p> */}
                </div>
                <div className='flex justify-between'>
                  <p className='text-white font-bold slide-in-from-left'>HASH ID:</p>
                  <p className='text-[#0EA4A9]'>{nftInfo[0].hashId}</p>
                </div>
               { /* <div className='flex justify-between'>
                  <p className='text-white font-bold slide-in-from-left'>NFT minted at:</p>
                  <p className='text-[#0EA4A9]'>{nftInfo[0].mintedAt}</p>
            </div> */}
                <div className='flex justify-between'>
                  <p className='text-white font-bold slide-in-from-left'>Token used:</p>
                  <p className='text-[#0EA4A9]'>{nftInfo[0].tokenUsed}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex  justify-center mt-12'>
            {/* <a href='/SeekerRegistration'> */}
              <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={handleRedirectToHome}>
                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out' >Home</p>
                <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
              </button>
            {/* </a> */}
          </div>
        </div>
      ) : (
        <p>Loading NFT information...</p>
      )}
    </div>
  );
}

