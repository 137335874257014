import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role }) => {
  const adminEmail = useSelector((state) => state.admin_email);
  const seekerEmail = useSelector((state) => state.seeker_email);
  const validatorEmail = useSelector((state) => state.validator_email);
  const recruiterEmail = useSelector((state) => state.recruiter_email);

  let isAuthenticated = false;
  let redirectPath = '/Login';

  switch (role) {
    case 'admin':
      isAuthenticated = adminEmail !== "";
      redirectPath = '/admin/login';
      break;
    case 'seeker':
      isAuthenticated = seekerEmail !== "";
      redirectPath = '/SeekerRegistration';
      break;
    case 'validator':
      isAuthenticated = validatorEmail !== "";
      redirectPath = '/ValidatorRegistration';
      break;
    case 'recruiter':
      isAuthenticated = recruiterEmail !== "";
      redirectPath = '/Register';
      break;
    default:
      isAuthenticated = false;
      redirectPath = '/SeekerRegistration';
  }

  return isAuthenticated ? children : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;