import React from 'react';
import { Spinner } from "@material-tailwind/react";

const Loading = ({ isVisible1 }) => {
  return (
    isVisible1 && (
      <div className="z-50 fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 backdrop-blur-lg">
        <div className='flex justify-center items-center pt-48'>
          <div className='flex justify-center items-center gap-5'>
            <div class="relative flex justify-center items-center">
              <div class=" "></div>
              <img src="/ring type 1 1.png" class="rounded-full h-52 w-52 animate-spin"/>
            </div><br/>
          </div>
        </div>
      </div>
    )
  );
};

export default Loading;
