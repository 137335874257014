import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAdminEmail } from '../store';
import {  useNavigate } from 'react-router-dom';


export default function AdminHeader() {

    const navigate = useNavigate();

    const Email = useSelector((state) => state.admin_email);
    console.log("Email Logged In2222222",Email)
  
    const dispatch = useDispatch();
  
    
    const Logout = () => {
  
   
      dispatch(setAdminEmail(""));
      window.location = '/';
    };

    const [isDropdown, setDropdown] = useState(false);
    const handleDropdown = () => {
        setDropdown(!isDropdown);
    };

  
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const handleDropdownToggle = () => {
      setDropdownVisible(!dropdownVisible);
    };

    return (
        <div>
            <nav className='flex px-7 py-5 lg:px-10 font-poppins border-b border-white/50'>
                <div className='w-[85%] lg:w-[15%]'>
                    <div className='w-[80%]'>
                        <img src='/assets/D5ART_Small_440x200 pxl.png' className=''></img>
                    </div>
                </div>
                <div className='w-[15%] lg:w-[85%] hidden md:table'>
                    <div className='flex '>
                        <div className='w-[80%] text-gray-300 font-bold flex justify-center gap-16 mt-4'>
                            <a href='/rms/admin/dashboard' className='text-yellow-500'>Dashboard</a>
                            <a href='/rms/admin/token' className=''>Profile</a>
                            <a href='/RMS/Admin/RecruiterList'>Recruiter list</a>
                            <a href='/RMS/Admin/SeekerList'>Job seeker list </a>
                            <a href='/rms/admin/ticket'>Ticket Management</a>
                            {/* <a href=''>Payment list </a> */}
                        </div>
                    
                               
                                <div className='w-[20%] mt-4 '>
                                <div className="relative flex justify-center">
                                  <button className="flex px-5 py-2  text-white" onClick={handleDropdownToggle}>
                                    <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                                    <div className='absolute -translate-x-2 -translate-y-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>{Email.substr(0, 10) + "...."}
                                    <i className="fa-solid fa-chevron-down pl-3 mt-1"></i>
                                  </button>
                  
                                  {dropdownVisible && (
                                    <div className="absolute top-full z-10 left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md animate__animated animate__fadeInUp ">
                                    <div className='py-2 px-9 rounded-md bg-black text-white'>
                                        <button onClick={Logout} className='text-center'>
                                    <i className='fa-solid fa-door-open pr-3'></i>Logout
                                  </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                                
                            </div>
                        </div>
               

                <div className='w-[15%] table lg:hidden grid content-center'>
                    <div className='flex justify-center '>
                        <button onClick={handleDropdown}>
                            <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                            <div className='absolute -translate-y-10 -translate-x-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>
                        </button>

                        {isDropdown && (
                            <div className='dropdown-content text-black z-10 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
                                <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
                                <a href='/rms/admin/dashboard' className='text-yellow-500'>Dashboard</a>
                                <a href='/rms/admin/token' className=''>Profile</a>
                                <a href='/RMS/Admin/RecruiterList'>Recruiter list</a>
                            <a href='/RMS/Admin/SeekerList'>Job seeker list </a>
                                <a href='/rms/admin/ticket'>Ticket Management</a>
                                {/* <a href=''>Payment list </a> */}
                                <button onClick={Logout} className='text-center'>
                                <i className='fa-solid fa-door-open pr-3'></i>Log out
                              </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
}
