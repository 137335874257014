
// import React, { useState, useEffect } from 'react';
// import queryString from 'query-string';
// import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios'; 
// import ValidatorHeader from './ValidatorHeader';

// export default function Validationpage() {
//     const [validatedValidations, setValidatedValidations] = useState([]);
//     const [pendingValidations, setPendingValidations] = useState([]);
//     const validatorEmail = useSelector((state) => state.validator_email);
//     const location = useLocation();
//     const { setName } = queryString.parse(location.search);

//     useEffect(() => {
//         const fetchValidations = async () => {
//             try {
//                 const response = await axios.get(`https://ex.d5art.com/api/fetchingNft_Id?setName=${setName}&validatorEmail=${validatorEmail}`);

//                 if (response.headers['content-type'].includes('application/json')) {
//                     const data = response.data;
//                     console.log(data)
//                     if (data.success) {
//                         setValidatedValidations(data.validatedData);
//                         setPendingValidations(data.pendingData);
//                     } else {
//                         console.error('Failed to fetch validations:', data.message);
//                     }
//                 } else {
//                     console.error('Error fetching validations: Response is not in JSON format');
//                 }
//             } catch (error) {
//                 console.error('Error fetching validations:', error);
//             }
//         };

//         fetchValidations();
//     }, [setName, validatorEmail]);




//     return (

//         <div className='font-poppins bg-[#191919]  h-screen overflow-hidden overflow-y-scroll' style={{backgroundImage:'url("/assets/FABC.png")'}}>
//         <ValidatorHeader />
//             <div className='flex justify-center'>
//                 <div className='w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
//                     <div className='py-12 flex justify-center text-white'>
//                         <div className='w-[90%] 2xl:w-[85%] flex flex-col gap-5'>
//                             <h1 className='text-2xl font-bold text-center'>Previous  Validation</h1>
//                             <div className='border-[2px] border-[#04F7FF80] rounded-3xl flex flex-col shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80]'>
//                                 <div className='flex text-center font-bold '>
//                                     <div className='w-[15%] py-4'>
//                                         S.NO
//                                     </div>
//                                     <div className='w-[65%] py-4 border-x-[2px] border-[#04F7FF80] '>
//                                         NFT ID
//                                     </div>
//                                     <div className='w-[20%] py-4'>
//                                         Result
//                                     </div>
//                                 </div>
//                                 {validatedValidations.map((validation, index) => (
//                                     <div key={index} className='flex text-center font-bold divide-y-[2px] divide-[#04F7FF80]'>
//                                         <div className='w-[15%] py-4'>{index + 1}</div>
//                                         <div className='w-[65%] py-4 border-x-[2px] border-[#04F7FF80] '>{validation.nft_id.slice(0,10)}</div>
//                                         <div className='w-[20%] py-4 text-[#1EEF32]'>{validation.result}</div>
//                                     </div>
//                                 ))}
//                             </div>
//                             <h1 className='text-2xl font-bold text-center mt-20'>Pending  Validation</h1>
//                             <div className='border-[2px] border-[#04F7FF80] rounded-3xl flex flex-col shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80]'>
//                                 <div className='flex text-center font-bold '>
//                                     <div className='w-[15%] py-4'>
//                                         S.NO
//                                     </div>
//                                     <div className='w-[50%] py-4 border-x-[2px] border-[#04F7FF80] '>
//                                         NFT ID
//                                     </div>
//                                     <div className='w-[20%] border-r-[2px] border-[#04F7FF80]  py-4'>
//                                         Submitted At
//                                     </div>
//                                     <div className='w-[20%] py-4'>
//                                         Validation
//                                     </div>
//                                 </div>
//                                 {pendingValidations.map((validation, index) => (
//                                     <div key={index} className='flex text-center font-bold divide-y-[2px] divide-[#04F7FF80]'>
//                                         <div className='w-[15%] py-4'>{index + 1}</div>
//                                         <div className='w-[50%] py-4 border-x-[2px] border-[#04F7FF80]'>{validation.nft_id.slice(0,10)}</div>
//                                         <div className='w-[20%] border-r-[2px] border-[#04F7FF80] py-4'>{new Date(validation.submited_at).toLocaleString()}</div>
//                                         <div className='w-[20%] flex items-center justify-center'>
//                                             <a href={`/RMS/Validator/ResultQA?nft_id=${validation.nft_id}&setName=${setName}`} className='py-2 px-5 bg-gradient-to-r from-[#0087A5] to-[#0E2DA7] rounded-lg'>Validation Now</a>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ValidatorHeader from './ValidatorHeader';

export default function Validationpage() {
    const [validatedValidations, setValidatedValidations] = useState([]);
    const [pendingValidations, setPendingValidations] = useState([]);
    const validatorEmail = useSelector((state) => state.validator_email);
    const location = useLocation();
    const { setName } = queryString.parse(location.search);

    // const [validatedValidations, setValidatedValidations] = useState([

    //     { nft_id: 'NFT1234567890', result: 'Pass' },
    //     { nft_id: 'NFT0987654321', result: 'Fail' },
    //     { nft_id: 'NFT1234567890', result: 'Pass' },
    //     { nft_id: 'NFT0987654321', result: 'Fail' },
    // ]);

    // const [pendingValidations, setPendingValidations] = useState([

    //     { nft_id: 'NFT2345678901', submited_at: new Date().toISOString() },
    //     { nft_id: 'NFT1098765432', submited_at: new Date().toISOString() },
    //     { nft_id: 'NFT2345678901', submited_at: new Date().toISOString() },
    //     { nft_id: 'NFT1098765432', submited_at: new Date().toISOString() },
    //     { nft_id: 'NFT2345678901', submited_at: new Date().toISOString() },
    // ]);



    useEffect(() => {
        const fetchValidations = async () => {
            try {
                const response = await axios.get(`https://ex.d5art.com/api/fetchingNft_Id?setName=${setName}&validatorEmail=${validatorEmail}`);

                if (response.headers['content-type'].includes('application/json')) {
                    const data = response.data;
                    console.log(data)
                    if (data.success) {
                        setValidatedValidations(data.validatedData);
                        setPendingValidations(data.pendingData);
                    } else {
                        console.error('Failed to fetch validations:', data.message);
                    }
                } else {
                    console.error('Error fetching validations: Response is not in JSON format');
                }
            } catch (error) {
                console.error('Error fetching validations:', error);
            }
        };

        fetchValidations();
    }, [setName, validatorEmail]);



    return (

        <div className='font-poppins bg-[#191919]  h-screen overflow-hidden overflow-y-scroll' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <ValidatorHeader />
            <div className='flex justify-center pb-10'>
                <div className='w-[90%] 2xl:w-[85%] border-[2px] border-[#04F7FF80] shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] rounded-3xl'>
                    <div className='py-12 flex justify-center text-white'>
                        <div className='w-[90%] 2xl:w-[85%] flex flex-col gap-5'>
                            <h1 className='text-2xl font-bold text-center'>Previous  Validation</h1>
                            <table className='text-center font-bold rounded-3xl overflow-hidden shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] w-full'>
                                <thead className='bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] text-white text-sm md:text-base'>
                                    <tr>
                                        <th className=' py-4'>S.NO</th>
                                        <th className=' py-4'>NFT ID</th>
                                        <th className=' py-4'>Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validatedValidations.map((validation, index) => (
                                        <tr key={index} className=' text-center font-bold '>
                                            <td className=' py-4'>{index + 1}</td>
                                            <td className=' py-4'>{validation.nft_id.slice(0, 10)}</td>
                                            <td className=' py-4' style={{ color: validation.result === 'PASS' ? '#1EEF32' : '#ff0000'}}>{validation.result}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h1 className='text-2xl font-bold text-center mt-20'>Pending  Validation</h1>
                            <table className='text-center font-bold rounded-3xl overflow-hidden shadow-md 2xl:shadow-lg 2xl:shadow-[#04F7FF80] shadow-[#04F7FF80] w-full'>
                                <thead className='bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] text-white text-sm md:text-base'>
                                    <tr>
                                        <th className=' py-4'>S.NO</th>
                                        <th className=' py-4'>NFT ID</th>
                                        <th className=' py-4 px-1 md:px-0'>Submitted At</th>
                                        <th className=' py-4'>Validation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingValidations.map((validation, index) => (
                                        <tr key={index} className='h-16 bg-white/20 text-xs md:text-sm'>
                                            <td className='py-4'>{index + 1}</td>
                                            <td className='py-4'>{validation.nft_id.slice(0, 10)}</td>
                                            <td className='py-4'>{new Date(validation.submited_at).toLocaleString()}</td>
                                            <td className='py-4 px-2 md:px-0'>
                                                <a href={`/RMS/Validator/ResultQA?nft_id=${validation.nft_id}&setName=${setName}`}><button className='py-2 px-2 md:px-5 bg-gradient-to-r from-[#0087A5] to-[#0E2DA7] rounded-lg text-white'>Validate Now</button></a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}