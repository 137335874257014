import React from 'react';
import ReactApexChart from 'react-apexcharts';

const series = {
  monthDataSeries1: {
    prices: [120, 130, 125, 140, 135, 150, 145, 155, 160, 165, 170, 175],
    dates: [
      '2023-01-01T00:00:00.000Z',
      '2023-02-01T00:00:00.000Z',
      '2023-03-01T00:00:00.000Z',
      '2023-04-01T00:00:00.000Z',
      '2023-05-01T00:00:00.000Z',
      '2023-06-01T00:00:00.000Z',
      '2023-07-01T00:00:00.000Z',
      '2023-08-01T00:00:00.000Z',
      '2023-09-01T00:00:00.000Z',
      '2023-10-01T00:00:00.000Z',
      '2023-11-01T00:00:00.000Z',
      '2023-12-01T00:00:00.000Z'
    ]
  }
};

class Chart1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        { name: 'Credit', data: props.creditData },
        { name: 'Debit', data: props.debitData },
      ],
      options: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        labels: series.monthDataSeries1.dates,
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
                colors: 'white'
            }
        }
        },
        yaxis: {
          opposite: false ,// Set this to false or remove it
          labels: {
            style: {
                colors: 'white'
            }
        }
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
      series: [{
        name: "D5ART Token",
        data: series.monthDataSeries1.prices
      }]
    };
  }

  render() {
    return (
      <div className='text-center' id="chart">
        <ReactApexChart 
          options={this.state.options} 
          series={this.state.series} 
          type="area" 
          height={350} 
        />
      </div>
    );
  }
}

export default Chart1;
