import React from 'react';
import { Spinner } from "@material-tailwind/react";


const Spinners =   ({ isVisible }) => {
  return (
    isVisible && (
        
    <div className="z-50 fixed top-0 left-0  w-screen h-screen bg-black bg-opacity-50 backdrop-blur-lg">
    <div className='flex justify-center items-center pt-48'>
    <div className='flex justify-center items-center gap-5'>
     
    <div class="relative flex justify-center items-center">
    <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#1eef32]"></div>
    <img src="/assets/fABC.png"  class="rounded-full h-20 w-24"/>
</div><br/>
<p className='text-white text-2xl font-bold'>Hold on a moment your <span className='text-[#0EA4A9]'>NFT</span> is minting</p>

    </div>    </div>
    
</div>
)
  );
};

export default Spinners;