import React from 'react'
import 'animate.css';
import { useState, useEffect, useRef } from 'react';
import ThreeJSComponent from './Threejscomponent';
import Threejscomponent2 from './Threejscomponent2';
import { Input } from "@material-tailwind/react";
import Slider from 'react-slick';
import './seeker.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import Spinners from './Spinners';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import axios from 'axios';
import Header from '../src/Header';
import LoginHeader from './LoginHeader';
import { toast, Toaster } from 'react-hot-toast';
import { setSeekerEmail, setRecruiterEmail, setAdminEmail, setValidatorEmail } from './store';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'js-cookie';
import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from "gapi-script";

import { useSignIn } from "react-auth-kit"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import CountryList from "./CountryCode.json";
import StateList from "./States.json";
import zxcvbn from 'zxcvbn';
import Skillslist from '../src/Seeker/Skills.json';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Register() {

    const [seekeremail, setseekerEmail] = useState('');
    const [onepassword, setonePassword] = useState('');
    const [employeremail, setemployerEmail] = useState('');
    const [oneEmpPassword, setoneEmpPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedAvatar, setSelectedAvatar] = useState('avatar1');


    const signIn = useSignIn();


    const { disconnect } = useDisconnect();

    const { address, chainId, isConnected } = useWeb3ModalAccount();

    console.log("address, chainId, isConnected", address, chainId, isConnected);


    const recaptcha = useRef()

    const [recaptchaToken, setRecaptchaToken] = useState('');
 

    const reCaptcha_public = "6LeDPCoqAAAAAH7ABnqmmz3q7YvSZIXZxbRE4mN2"

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setValidatorLogin((prevData)=> ( {
            ...prevData,
            reCaptcha: token
            }))
    
    };

    const sliderRef = useRef(null);
    const sliderRef1 = useRef(null);

    // const handleNext = () => {
    //     if (sliderRef.current) {
    //         sliderRef.current.slickNext();
    //         setCurrentStep((prevStep) => prevStep + 1);
    //     }
    // };

    const handleNext = () => {

        const requiredFields = document.querySelectorAll('input[required], select[required] ');

        // Check if any required field is empty
        const isEmpty = Array.from(requiredFields).some(field => !field.value);

        // If any required field is empty, show a toast message and prevent moving to the next slider
        if (isEmpty) {
            toast.error('All fields are required..!');
            return;
        }

        if (sliderRef.current) {
            sliderRef.current.slickNext();
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };


    const handleSlideChange1 = (index) => {

    };


    const handleSlideChange = (index) => {
        setCurrentStep(index + 1);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange,
    };
    const customPaging = (i) => (
        <div className={`dot ${currentStep === i + 1 ? 'active' : ''}`}></div>
    );
    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,

        slidesToScroll: 1,
        afterChange: handleSlideChange1,
        customPaging: customPaging,
    };
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption);

    };

    const handleStateChange = (event) => {
        const selectedStateName = event.target.value;
        setSelectedState(selectedStateName);
     
    };

    // const countryOptions = [


    //     { value: 'usa', label: 'United States' },
    //     { value: 'canada', label: 'Canada' },
    //     { value: 'uk', label: 'United Kingdom' },
    //     { value: 'ind', label: 'India' },
    //     { value: 'Germany', label: 'Germany' },
    //     { value: 'Japan', label: 'Japan' },
    //     { value: 'Australia', label: 'Australia' },
    //     { value: 'France', label: 'France' },
    //     { value: 'China', label: 'China' },
    //     { value: 'sl', label: 'Sri Lanka' },
    //     { value: 'Italy', label: 'Italy' },
    //     { value: 'South Korea', label: 'South Korea' },
    //     { value: 'New Zealand', label: 'New Zealand' },
    //     { value: 'Spain', label: 'Spain' },
    //     { value: 'Russia', label: 'Russia' },
    //     { value: 'Brazil', label: 'Brazil' },
    //     { value: 'Ireland', label: 'Ireland' },
    //     { value: 'Greece', label: 'Greece' },
    //     { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    //     { value: 'Switzerland', label: 'Switzerland' },
    //     { value: 'Austria', label: 'Austria' },
    //     { value: 'Indonesia', label: 'Indonesia' },
    //     { value: 'Netherlands', label: 'Netherlands' },
    //     { value: 'Norway', label: 'Norway' },
    //     { value: 'Turkey', label: 'Turkey' },
    // ];


    // console.log('CountryList',CountryList)

    const [state, setState] = useState([]);

    const [selectedState, setSelectedState] = useState('');
    const [filteredStates, setFilteredStates] = useState([]);

    const countryOptions = CountryList

    useEffect(() => {

        setState(StateList);
    }, []);


    const [selectedJob, setSelectedJob] = React.useState([]);
    const [inputValueJob, setInputValueJob] = React.useState('');


    ///----------opt

    const [isOtpOpen, setIsOtpOpen] = useState(false);
    const [otpData, setOtpData] = useState({
        otp: "",
    });


    const handleChangeOtp = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otpData.otp];
        newOtp[index] = element.value;
        const OTP = newOtp.join('');
        setOtpData({ ...otpData, otp: OTP });

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otpData.otp[index] && index > 0) {
            const previousInput = event.target.previousSibling;
            if (previousInput) {
                previousInput.focus();
            }
        }
    };



    const [otpEmail, setOtpemail] = useState("");


    const generateOtp = async (email) => {
        try {


            const response = await axios.post(
                "https://ex.d5art.com/api/generate-email-otp",
                { email },
                { headers: { "Content-Type": "application/json" } }
            );

            if (response.data.success) {
                toast.success(
                    "OTP generated successfully! Check your phone for the OTP."
                );
                setIsOtpOpen(true);
            } else {
                toast.error("Failed to generate OTP. Please try again.");
            }
        } catch (error) {
            console.error("Error generating OTP:", error);
            toast.error("Error generating OTP. Please try again.");
        }
    };




    const verifyOtp = async () => {
        try {
            let Email;

            if (isOpenValidator) {
                Email = ValidatorPersonalInfo.email;
            } else if (isOpen5) { //job seeker
                Email = personalInfo.email;
            }
            else {
                Email = personalInfo1.email
            }

            if (!Email) {
                toast.error("Phone number not defined.");
                return;
            }

            const response = await axios.post(
                "https://ex.d5art.com/api/verify-email-otp",
                { otp: otpData.otp, Email },
                { headers: { "Content-Type": "application/json" } }
            );

            if (response.data.status === "success") {
                if (isOpenValidator) {
                    await ValidatorSubmit1();
                } else if (isOpen5) {
                    await saveCertificateAndExpInfo();
                }
                else {

                    await submit1();
                }
            } else {
                toast.error("OTP verification failed. Please try again.");
            }

            setIsOtpOpen(false);
        } catch (error) {
            console.error("Error during OTP verification:", error);
            toast.error("Error during OTP verification. Please try again.");
        }
    };


    // const [selectedSkill, setSelectedSkill] = React.useState([]);
    // const [inputValueSkill, setInputValueSkill] = React.useState('');
    // const handleJobRole = (selectedOption) => {
    //     if (skillsInfo.jobrole.length < 3) {
    //         setSkillsInfo((prevInfo) => ({
    //             ...prevInfo,
    //             jobrole: [...prevInfo.jobrole, selectedOption.label],
    //         }));
    //     }
    // };

    // const handleSkill = (selectedOption) => {
    //     if (skillsInfo.skills.length < 6) {
    //         setSkillsInfo((prevInfo) => ({
    //             ...prevInfo,
    //             skills: [...prevInfo.skills, selectedOption.label],
    //         }));
    //     }
    // };
    const handleRemoveSkill = (removedSkill) => {
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            skills: prevInfo.skills.filter((skill) => skill !== removedSkill),
        }));
    };

    const handleRemoveJobRole = (removedRole) => {
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            jobrole: prevInfo.jobrole.filter((role) => role !== removedRole),
        }));
    };

    const handleResumeUpload = (event) => {
        const file = event.target.files[0];
        setSkillsInfo((prevInfo) => ({
            ...prevInfo,
            resume: file,
        }));
    };

    const removeOption = (index) => {
        const updatedOptions = [...selectedJob];
        updatedOptions.splice(index, 1);
        setSelectedJob(updatedOptions);
    };
    const removeOption2 = (index) => {
        const updatedOptions = [...selectedSkill];
        updatedOptions.splice(index, 1);
        setSelectedSkill(updatedOptions);
    };
    const JobOptions = [
        { value: 'frontend', label: 'Frontend Developer' },
        { value: 'designer', label: 'Designer' },
        { value: 'testing', label: 'Testing' },
        { value: 'backend', label: 'Backend Developer' },
        { value: 'smartcontract', label: 'Smart Contract Developer' },
        { value: 'fullstack', label: 'Full Stack Developer' },
        // Add more countries as needed
    ];
    // const SkillOptions = [
    //     { value: 'Skill1', label: 'HTML' },
    //     { value: 'Skill2', label: 'React' },
    //     { value: 'Skill3', label: 'Node' },
    //     { value: 'Skill4', label: 'Next' },
    //     { value: 'Skill5', label: 'Bootstrap' },
    //     { value: 'Skill6', label: 'Tailwind' },

    //     // Add more countries as needed
    // ];

    const [selectedEmp, setSelectedEmp] = useState(null);

    const handleChanges = (selectedOption) => {
        setSelectedEmp(selectedOption);
    };
    const employmentOptions = [
        { value: 'Full Time ', label: 'Full Time' },
        { value: 'Part TIme', label: 'Part TIme' },
        { value: 'Freelancer', label: 'Freelancer' },

    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
    };
    const customStylesJob = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
    };

    const [selectedSkillValidator, setSelectedSkillValidator] = React.useState([]);
    const [inputValueSkillValidator, setInputValueSkillValidator] = React.useState('');

    const handleSkillValidator = (selectedOption) => {
        if (selectedSkill.length < 6) {
            setSelectedSkillValidator([...selectedSkillValidator, selectedOption]);
        }
        setInputValueSkillValidator('');
    };

    const removeOption3 = (index) => {
        const updatedOptions = [...selectedSkillValidator];
        updatedOptions.splice(index, 1);
        setSelectedSkillValidator(updatedOptions);
    };

    // const SkillOptionsValidator = [
    //     { value: 'Skill1', label: 'HTML' },
    //     { value: 'Skill2', label: 'React' },
    //     { value: 'Skill3', label: 'Node' },
    //     { value: 'Skill4', label: 'Next' },
    //     { value: 'Skill5', label: 'Bootstrap' },
    //     { value: 'Skill6', label: 'Tailwind' },
    // ];

    const SkillOptions = Skillslist;

    const [selectedstate, setSelectedSate] = useState(null);

    const handleChanges1 = (selectedOption) => {
        setSelectedSate(selectedOption);
    };

    const stateOptions = StateList;

    // const stateOptions = [
    //     { value: 'Full Time ', label: 'Full Time' },
    //     { value: 'Part TIme', label: 'Part TIme' },
    //     { value: 'Freelancer', label: 'Freelancer' },

    // ];
    const customStyles1 = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff',
        }),
    };
    const customStylesJob1 = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
    };

    const [isTransformed, setIsTransformed] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);

    const toggleModal = () => {
        setIsLoading1(true);
        navigate('/');
        if (isOpen) {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(false);
                setIsTransformed(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setIsLoading1(false);
                setIsOpen(true);
                setIsTransformed(true);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsTransformed(true);
        } else {

            setIsTransformed(false);
        }
    }, [isOpen]);
    const [active, setActive] = useState('div3');

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };
    const [isOpen5, setIsOpen5] = useState(false);
    const togglemodal5 = () => {
        setIsOpen5(!isOpen5);

        //navigate('/SeekerRegistration#');


    };
    const [isOpenRecruiter, setIsOpenRecruiter] = useState(false);
    const toggleRecruiter = () => {
        setIsOpenRecruiter(!isOpenRecruiter);

    };
    const [isOpenValidator, setIsOpenValidator] = useState(false);
    const toggleValidator = () => {
        setIsOpenValidator(!isOpenValidator);
    };

    // const handleClick = () => {
    //     setIsOpen(!isOpen);
    //     setIsOpen5(!isOpen5);
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         navigate('/RMS');
    //     }, 1000);
    // };

    const handleClick = async () => {
        setIsOpen(!isOpen);
        setIsOpen5(!isOpen5);
        setIsLoading(true);
        try {

            const walletAddress = await generate_wallet_address();
            console.log('Generated wallet address:', walletAddress);


            navigate('/');
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);

        }
    };



    const handleClickValidator = async () => {
        setIsOpen(!isOpen);
        setIsOpen5(!isOpen5);
        setIsLoading(true);
        try {

            const walletAddress = await validator_generate_wallet_address();
            console.log('Generated wallet address:', walletAddress);


            navigate('/');
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);

        }
    };



    const navigate = useNavigate();

    // const handleClick2 = () => {
    //     setIsOpen(!isOpen);
    //     setIsOpen5(!isOpen5);
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         navigate('/');
    //     }, 1000);
    // };

    const handleClick2 = async () => {

        if (!recruiterLoginValidate()) {
            return;
        }

        setIsOpen(!isOpen);
        // setIsOpen5(!isOpen5);
        setIsLoading(true);
        recruiterLoginSubmit();

    };

    const handleClick3 = () => {

        if (!seekerLoginValidate()) {
            return;
        }

        setIsOpen(!isOpen);
        //  setIsOpen5(!isOpen5);
        setIsLoading(true);
        seekerLoginSubmit();

    };

    const handleClick4 = () => {

        if (!validatorLoginValidate()) {
            return;
        }


        setIsOpen(!isOpen);
        //setIsOpen5(!isOpen5);
        setIsLoading(true);
        validatorLoginSubmit();

    };

    const avatarSources = ['./assets/image 90.png', './assets/image 90 (1).png', './assets/image 3yr.png'];
    const serveravatar = ['./pictures/image 90.png', './pictures/image 90 (1).png', './image 3yr.png']

    const [selectedSkill, setSelectedSkill] = React.useState([]);
    const [inputValueSkill, setInputValueSkill] = React.useState('');
    const handleJobRole = (selectedOption) => {
        if (selectedJob.length < 3) {
            setSelectedJob([...selectedJob, selectedOption]);
            setSkillsInfo({ ...skillsInfo, jobrole: [...skillsInfo.jobrole, selectedOption.label] });
        }
        setInputValueJob('');
    };

    // const handleSkill = (selectedOption) => {
    //     if (selectedSkill.length < 6) {
    //         // Display a range input to get proficiency percentage
    //         const proficiencyPercentage = prompt(`Enter proficiency percentage for ${selectedOption.label}`);
    //         if (proficiencyPercentage !== null) {
    //             const skillInfo = `${selectedOption.label} (${proficiencyPercentage}%)`;
    //             setSelectedSkill([...selectedSkill, { label: skillInfo, value: selectedOption.value }]);
    //             setSkillsInfo({
    //                 ...skillsInfo,
    //                 skills: [...skillsInfo.skills, selectedOption.label],
    //                 proficiency: [...skillsInfo.proficiency, { skill: selectedOption.label, percentage: proficiencyPercentage }]
    //             });
    //         }
    //     }
    //     setInputValueSkill('');
    // };



    //  const handleSkill = (selectedOption) => {
    //         if (selectedSkill.length < 6) {
    //             setSelectedSkill([...selectedSkill, selectedOption]);
    //         }
    //         setInputValueSkill('');
    //     };


    //     const handleSkillPercentageChange = (index, percentage) => {
    //         setSelectedSkill(prevSkills => {
    //             const updatedSkills = [...prevSkills];
    //             updatedSkills[index] = { ...updatedSkills[index], percentage };
    //             return updatedSkills;

    //         });
    //     };

    const handleSkill = (selectedOption) => {
        if (selectedSkill.length < 6) {
            const proficiencyPercentage = 0;
            setSelectedSkill([...selectedSkill, { ...selectedOption, percentage: proficiencyPercentage }]);
            setSkillsInfo({
                ...skillsInfo,
                skills: [...skillsInfo.skills, selectedOption.label],
                proficiency: [...skillsInfo.proficiency, { skill: selectedOption.label, percentage: proficiencyPercentage }],
            });
        }
        setInputValueSkill('');
    };

    const handleSkillPercentageChange = (index, percentage) => {
        setSelectedSkill((prevSkills) => {
            const updatedSkills = [...prevSkills];
            updatedSkills[index] = { ...updatedSkills[index], percentage };
            return updatedSkills;
        });

        setSkillsInfo({
            ...skillsInfo,
            proficiency: skillsInfo.proficiency.map((proficiency, i) => {
                if (i === index) {
                    return { ...proficiency, percentage };
                }
                return proficiency;
            }),
        });
        setInputValueSkill('');
    };


    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const [personalInfo, setPersonalInfo] = useState({
        name: '',
        email: '',
        country: '',
        state: [],
        mobileNumber: '',
        avatar: './assets/image 90.png',
        description: '',
        password: '',
        confirmpassword: '',
        walletAddress: '',
    });

    useEffect(() => {
        if (isConnected === true) {
            setPersonalInfo(prevInfo => ({
                ...prevInfo,
                walletAddress: address,
            }));
        }

    }, [address]);

    console.log(personalInfo);

    const [educationInfo, setEducationInfo] = useState({
        sslcPercentage: '',
        sslcInstitution: '',
        sslcYear: '',
        sslcDescription: '',
        sslcFile: null,
        hscPercentage: '',
        hscInstitution: '',
        hscFile: null,
        hscYear: '',
        hscDescription: '',
        cgpaPercentage: '',
        cgpaInstitution: '',
        cgpaYear: '',
        cgpaDescription: '',
        cgpaFile: null,
    });

    console.log(educationInfo)

    const [skillsInfo, setSkillsInfo] = useState({
        resume: null,
        skills: [],
        proficiency: [],
        jobrole: []
    });
    console.log(skillsInfo)

    const [projectInfo, setProjectInfo] = useState({
        project: {
            no: '0'
        },
        project1: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
        project2: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
        project3: {
            name: '',
            description: '',
            keywords: [],
            link: '',
            file: null,
        },
    });

    console.log("000000000000000", projectInfo)

    const [certificateandexpInfo, setCertificateandexpInfo] = useState({

        certificate1: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        certificate2: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        certificate3: {
            name: '',
            source: '',
            link: '',
            file: null
        },
        experienceYears: {
            no: '0'
        },
        experience1: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
        experience2: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
        experience3: {
            companyname: '',
            from: '',
            to: '',
            role: ''
        },
    })
    console.log(certificateandexpInfo)




    const handleInputData = (section, field, value) => {
        switch (section) {
            case 'personalInfo':
                if (field === 'avatar') {
                    setSelectedAvatar(value);
                } else if (field === 'country' || field === 'state') {
                    // setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label }));

                    if (Array.isArray(value)) {

                        const selectedValues = value.map(option => option.label);
                        setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: selectedValues }));
                    } else {
                        setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label }));
                    }
                } else if (field === 'password' || field === 'confirmpassword') {
                    setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));

                    setPasswordsMatch(value === personalInfo.password);

                } else {
                    setPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
                }
                break;



            default:
                break;
        }
    };


    const handleSliderAfterChange = (index) => {
        const avatarIndex = index + 1;
        const newAvatar = `avatar${avatarIndex}`;
        const avatarImageSrc = avatarSources[index];
        console.log("newavatar", newAvatar);
        setSelectedAvatar(newAvatar);

        setPersonalInfo((prevInfo) => ({
            ...prevInfo,
            avatar: avatarImageSrc,
        }));
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Personal Info:', personalInfo);
        console.log('education Info', educationInfo);
        console.log("skills&tools", skillsInfo);
        console.log("projects info", projectInfo);
        console.log("certificate and exp info", certificateandexpInfo);

    };


    // const [isTransformed, setIsTransformed] = useState(false);
    // const [isOpen, setIsOpen] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isLoading1, setIsLoading1] = useState(false);

    // const toggleModal = () => {
    //     setIsLoading1(true);

    //     if (isOpen) {
    //         setTimeout(() => {
    //             setIsLoading1(false);
    //             setIsOpen(false);
    //             setIsTransformed(false);
    //         }, 1000);
    //     } else {
    //         setTimeout(() => {
    //             setIsLoading1(false);
    //             setIsOpen(true);
    //             setIsTransformed(true);
    //         }, 1000);
    //     }
    // };

    // useEffect(() => {
    //     if (isOpen) {
    //         setIsTransformed(true);
    //     } else {

    //         setIsTransformed(false);
    //     }
    // }, [isOpen]);
    // const [active, setActive] = useState('div1');

    // const handleAnchorClick = (tab) => {
    //     setActive(tab);
    // };
    // const [isOpen5, setIsOpen5] = useState(false);
    // const togglemodal5 = () => {
    //     setIsOpen5(!isOpen5);
    // };
    // const [isOpenRecruiter, setIsOpenRecruiter] = useState(false);
    // const toggleRecruiter = () => {
    //     setIsOpenRecruiter(!isOpenRecruiter);
    // };
    // const [isOpenValidator, setIsOpenValidator] = useState(false);
    // const toggleValidator = () => {
    //     setIsOpenValidator(!isOpenValidator);
    // };

    // const handleClick = () => {
    //     setIsOpen(!isOpen);
    //     setIsOpen5(!isOpen5);
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         navigate(`/Congrats?email=${personalInfo.email}`);
    //     }, 1000);
    // };
    // const navigate = useNavigate();

    const [showCongrats, setShowCongrats] = useState(false);

    const [sslcPercentage, setSslcPercentage] = useState('');
    const [hscPercentage, setHscPercentage] = useState('');
    const [cgpa, setCgpa] = useState('');

    const [sslcFile, setSslcFile] = useState(null);
    const [hscFile, setHscFile] = useState(null);
    const [cgpaFile, setCgpaFile] = useState(null);

    const [ProjectFile, setProjectFile] = useState(null);
    const [Project2File, setProject2File] = useState(null);
    const [Project3File, setProject3File] = useState(null);

    const [CeritificateFile, setCeritificateFile] = useState(null);
    const [Ceritificate2File, setCeritificate2File] = useState(null);
    const [Ceritificate3File, setCeritificate3File] = useState(null);


    const sslcFileInputRef = useRef(null);
    const hscFileInputRef = useRef(null);
    const cgpaFileInputRef = useRef(null);

    const ProjectFileInputRef = useRef(null);
    const Project2FileInputRef = useRef(null);
    const Project3FileInputRef = useRef(null);

    const CeritificateFileInputRef = useRef(null);
    const Ceritificate2FileInputRef = useRef(null);
    const Ceritificate3FileInputRef = useRef(null);

    const handleSslcIconClick = () => {
        sslcFileInputRef.current.click();
    };

    const handleHscIconClick = () => {
        hscFileInputRef.current.click();
    };

    const handleCgpaIconClick = () => {
        cgpaFileInputRef.current.click();
    };
    const handleProjectIconClick = (projectId) => {
        if (projectId === 'project1') {
            ProjectFileInputRef.current.click();
        } else if (projectId === 'project2') {
            Project2FileInputRef.current.click();
        } else if (projectId === 'project3') {
            Project3FileInputRef.current.click();
        }
    };
    const handleProject2IconClick = () => {
        Project2FileInputRef.current.click();
    };

    const handleProject3IconClick = () => {
        Project3FileInputRef.current.click();
    };

    const handleCeritificateIconClick = (certificateId) => {
        if (certificateId == "certificate1") {
            CeritificateFileInputRef.current.click();
        }
        else if (certificateId == "certificate2") {
            Ceritificate2FileInputRef.current.click();
        }
        else if (certificateId == "certificate3") {
            Ceritificate3FileInputRef.current.click();
        }

    };

    const handleCeritificate2IconClick = () => {
        Ceritificate2FileInputRef.current.click();
    };

    const handleCeritificate3IconClick = () => {
        Ceritificate3FileInputRef.current.click();
    };

    const handlePercentageInputChange = (event, setPercentage) => {
        const value = event.target.value;

        if (/^[0-9%.]*$/.test(value)) {
            setPercentage(value);
        }
    };

    const handleSslcFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, sslcFile: file }));
    };


    const handleHscFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, hscFile: file }));
    };

    const handleCgpaFileInputChange = (event) => {
        const file = event.target.files[0];
        setEducationInfo((prevInfo) => ({ ...prevInfo, cgpaFile: file }));
    };

    const handleProjectFileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProjectFile(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };

    // const handleProjectInputChange = (e, projectId, field) => {
    //     const value = e.target.value;

    //     setProjectInfo((prevProjectInfo) => ({
    //         ...prevProjectInfo,
    //         [projectId]: {
    //             ...prevProjectInfo[projectId],
    //             [field]: field === 'keywords' ? value.split(',').map(keyword => keyword.trim()) : value,
    //         },
    //     }));
    // };



    const handleCertificateInputChange = (event, certificateId, field) => {
        const value = event.target.value;
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                [field]: value
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);
    };

    const handleCeritificateFileInputChange = (event, certificateId) => {
        const file = event.target.files[0];
        setCeritificateFile(file);

        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);



    };




    const handleExperienceInputChange = (event, experienceId, field) => {
        const value = event.target.value;
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            [experienceId]: {
                ...certificateandexpInfo[experienceId],
                [field]: value
            }
        };

        setCertificateandexpInfo(updatedExperienceInfo);
    };


    // const handleProjectInputChange = (e, projectId, field) => {
    //     const value = e.target.value;
    //     console.log('Input value:', e.target.value); // Log the input value
    //     console.log('Field:', field);

    //     // Check if the field is 'keywords' and transform the value into an array
    //     const fieldValue = field === 'keywords' ? value.split(',').map(keyword => keyword.trim()) : value;
    //     console.log('Transformed value:', fieldValue);

    //     setProjectInfo(prevProjectInfo => ({
    //         ...prevProjectInfo,
    //         [projectId]: {
    //             ...prevProjectInfo[projectId],
    //             [field]: fieldValue,
    //         },
    //     }));
    // };


    // const [texts, setTexts] = useState([]);
    // const [texts2, setTexts2] = useState([]);
    // const [texts3, setTexts3] = useState([]);

    // const handleAddText = () => {
    //     if (projectInfo.project1.keywords.length > 0 && texts.length < 3) {
    //         setTexts((prevTexts) => [...prevTexts, projectInfo.project1.keywords.join(',').trim()]);
    //         setProjectInfo((prevProjectInfo) => ({
    //             ...prevProjectInfo,
    //             project1: {
    //                 ...prevProjectInfo.project1,
    //                 keywords: []
    //             }
    //         }));
    //     }
    // };

    // const handleAddText2 = () => {
    //     if (projectInfo.project2.keywords.length > 0 && texts2.length < 3) {
    //         setTexts2((prevTexts) => [...prevTexts, projectInfo.project2.keywords.join(',').trim()]);
    //         setProjectInfo((prevProjectInfo) => ({
    //             ...prevProjectInfo,
    //             project2: {
    //                 ...prevProjectInfo.project2,
    //                 keywords: [],
    //             },
    //         }));
    //     }
    // };

    // const handleAddText3 = () => {
    //     if (projectInfo.project3.keywords.length > 0 && texts3.length < 3) {
    //         setTexts3((prevTexts) => [...prevTexts, projectInfo.project3.keywords.join(',').trim()]);
    //         setProjectInfo((prevProjectInfo) => ({
    //             ...prevProjectInfo,
    //             project3: {
    //                 ...prevProjectInfo.project3,
    //                 keywords: [],
    //             },
    //         }));
    //     }
    // };

    // const handleRemoveText = (index) => {
    //     setTexts((prevTexts) => prevTexts.filter((_, i) => i !== index));
    // };
    // const handleRemoveText2 = (index) => {
    //     setTexts2((prevTexts) => prevTexts.filter((_, i) => i !== index));
    // };
    // const handleRemoveText3 = (index) => {
    //     setTexts3((prevTexts) => prevTexts.filter((_, i) => i !== index));
    // };

    const handleProjectInputChange = (e, projectId, field) => {
        const value = e.target.value;


        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                [field]: field === 'keywords' ? value.split(',').map(keyword => keyword.trim()) : value,
            },
        }));
    };



    const handleProject2FileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProject2File(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };
    const handleProject3FileInputChange = (event, projectId) => {
        const file = event.target.files[0];
        setProject3File(file);
        setProjectInfo((prevProjectInfo) => ({
            ...prevProjectInfo,
            [projectId]: {
                ...prevProjectInfo[projectId],
                file: file,
            },
        }));
    };


    const handleCeritificate2FileInputChange = (event, certificateId) => {
        const file = event.target.files[0];
        setCeritificate2File(file);
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);

    };
    const handleCeritificate3FileInputChange = (event, certificateId) => {
        const file = event.target.files[0];
        setCeritificate3File(file);
        const updatedCertificateInfo = {
            ...certificateandexpInfo,
            [certificateId]: {
                ...certificateandexpInfo[certificateId],
                file: file
            }
        };

        setCertificateandexpInfo(updatedCertificateInfo);
    };

    const [inputValue, setInputValue] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };
    const handleInputChange2 = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue2(value);
        }
    };
    const handleInputChange3 = (event) => {
        const value = event.target.value;

        if (/^\d*$/.test(value)) {
            setInputValue3(value);
        }
    };
    const [inputFrom, setInputFrom] = useState('');
    const [inputFrom2, setInputFrom2] = useState('');
    const [inputFrom3, setInputFrom3] = useState('');


    const handleInputFrom2 = (date) => {
        setInputFrom2(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience2: {
                ...certificateandexpInfo.experience2,
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };
    const handleInputFrom3 = (date) => {
        setInputFrom3(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience3: {
                ...certificateandexpInfo.experience3,
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const [inputTo, setInputTo] = useState('');
    const [inputTo2, setInputTo2] = useState('');
    const [inputTo3, setInputTo3] = useState('');

    const handleInputFrom = (date, experienceId) => {
        setInputFrom(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            [experienceId]: {
                ...certificateandexpInfo[experienceId],
                from: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const handleInputTo = (date, experienceId) => {
        setInputTo(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            [experienceId]: {
                ...certificateandexpInfo[experienceId],
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const handleInputTo2 = (date) => {
        setInputTo2(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience2: {
                ...certificateandexpInfo.experience2,
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };

    const handleInputTo3 = (date) => {
        setInputTo3(date);
        const formattedDate = date ? date.toLocaleDateString() : '';
        const updatedExperienceInfo = {
            ...certificateandexpInfo,
            experience3: {
                ...certificateandexpInfo.experience3,
                to: formattedDate,
            },
        };
        setCertificateandexpInfo(updatedExperienceInfo);
    };


    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const [isToggle2Open, setIsToggle2Open] = useState(false);
    const [isToggle3Open, setIsToggle3Open] = useState(false);

    const handleToggleOpen = () => {
        setIsToggleOpen(!isToggleOpen);
    };

    const handleToggle2Open = () => {
        setIsToggle2Open(!isToggle2Open);
    };

    const handleToggle3Open = () => {
        setIsToggle3Open(!isToggle3Open);
    };
    const [isToggleDrawer, setIsToggleDrawer] = useState(false);

    const handleToggleDrawer = () => {
        setIsToggleDrawer(!isToggleDrawer);
    };
    const [isToggle2Drawer, setIsToggle2Drawer] = useState(false);

    const handleToggle2Drawer = () => {
        setIsToggle2Drawer(!isToggle2Drawer);
    };

    const [isToggleEXP, setIsToggleEXP] = useState(false);

    const handleToggleEXP = () => {
        setIsToggleEXP(!isToggleEXP);
    };
    const [isToggle2EXP, setIsToggle2EXP] = useState(false);

    const handleToggle2EXP = () => {
        setIsToggle2EXP(!isToggle2EXP);
    };

    const [isOpenOtp, setOpenOtp] = useState(false);
    const [isOpenOtpRecruiter, setOpenOtpRecruiter] = useState(false);
    const [isOpenOtpValidator, setOpenOtpValidator] = useState(false);

    const handleOtp = () => {
        setOpenOtp(!isOpenOtp)
    }
    const handleOtpRecruiter = () => {
        setOpenOtpRecruiter(!isOpenOtpRecruiter)
    }
    const handleOtpValidator = () => {
        setOpenOtpValidator(!isOpenOtpValidator)
    }

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [password, setPassword] = useState('');

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const [email, setEmail] = useState('');
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const [passwordVisibleSeeker, setPasswordVisibleSeeker] = useState(false);
    const [passwordSeeker, setPasswordSeeker] = useState('');

    const handlePasswordChange = (e) => {
        setPasswordSeeker(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handlePasswordVisibility = () => {
        setPasswordVisibleSeeker(!passwordVisibleSeeker);
    };
    const handleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    console.log(email)
    console.log(passwordSeeker)
    console.log(confirmPassword)
    const [passwordVisibleRecruiter, setPasswordVisibleRecruiter] = useState(false);
    const [passwordRecruiter, setPasswordRecruiter] = useState('');

    const handlePasswordRecruiter = () => {
        setPasswordVisibleRecruiter(!passwordVisibleRecruiter);
    };
    const [passwordVisibleValidator, setPasswordVisibleValidator] = useState(false);
    const [passwordValidator, setPasswordValidator] = useState('');

    const handlePasswordValidator = () => {
        setPasswordVisibleValidator(!passwordVisibleValidator);
    };
    const [emailOTP, setEmailOTP] = useState(['', '', '', '']);
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const inputRefs = useRef(Array(4).fill(null));

    const handleOTPChange = (index) => (e) => {
        const value = e.target.value;

        const updatedEmailOTP = [...emailOTP];
        updatedEmailOTP[index] = value;
        setEmailOTP(updatedEmailOTP);

        if (value.length === 1 && index < 3) {
            inputRefs.current[index + 1].focus();
        }

        setButtonEnabled(updatedEmailOTP.join('').length === 4);
    };
    const handleVerifyClick = () => {
        const combinedEmailOTP = emailOTP.join('');
        console.log('Verifying Email OTP:', combinedEmailOTP);
    };
    console.log("emailotp", emailOTP.join(''));


    const [emailOTPRecruiter, setEmailOTPRecruiter] = useState(['', '', '', '']);
    const [isButtonEnabledRecruiter, setButtonEnabledRecruiter] = useState(false);
    const inputRefsRecruiter = useRef(Array(4).fill(null));

    const handleOTPRecruiter = (index) => (e) => {
        const value = e.target.value;

        const updatedEmailOTP = [...emailOTPRecruiter];
        updatedEmailOTP[index] = value;
        setEmailOTPRecruiter(updatedEmailOTP);

        if (value.length === 1 && index < 3) {
            inputRefsRecruiter.current[index + 1].focus();
        }

        setButtonEnabledRecruiter(updatedEmailOTP.join('').length === 4);
    };

    const handleVerifyRecruiter = () => {
        const combinedEmailOTP = emailOTPRecruiter.join('');
        console.log('Verifying Email OTP:', combinedEmailOTP);
    };

    const [emailOTPValidator, setEmailOTPValidator] = useState(['', '', '', '']);
    const [isButtonEnabledValidator, setButtonEnabledValidator] = useState(false);
    const inputRefsValidator = useRef(Array(4).fill(null));

    const handleOTPValidator = (index) => (e) => {
        const value = e.target.value;

        const updatedEmailOTP = [...emailOTPValidator];
        updatedEmailOTP[index] = value;
        setEmailOTPValidator(updatedEmailOTP);

        if (value.length === 1 && index < 3) {
            inputRefsValidator.current[index + 1].focus();
        }

        setButtonEnabledValidator(updatedEmailOTP.join('').length === 4);
    };

    const handleVerifyValidator = () => {
        const combinedEmailOTP = emailOTPValidator.join('');
        console.log('Verifying Email OTP:', combinedEmailOTP);
    };

    const [currentIndex, setCurrentIndex] = useState(0);



    const handleNextClick = async () => {
        if (currentIndex === 0) {
            try {
                await axios.post('https://ex.d5art.com/api/seekerResetPasswordOtp', { email });
                setCurrentIndex(1);
                toast.success("OTP sent successfully. Please check your email.");
            } catch (error) {
                console.error('Error sending OTP:', error);
            }
        } else if (currentIndex === 1) {
            try {
                await axios.post('https://ex.d5art.com/api/verifyOTPAndUpdatePassword', {
                    email,
                    emailOtp: emailOTP.join(''),
                    password: passwordSeeker,
                    confirmpassword: passwordSeeker,
                });
                console.log(email, emailOTP, password, confirmPassword)
                console.log("emailotp", emailOTP.join(''))
                toast.success("Password reset is successful")
                setTimeout(() => {
                    navigate('/');
                }, 2000);

            } catch (error) {
                toast.error('Error verifying OTP and updating password:', error);
            }

        }
    };

    const handleNextClick1 = async () => {
        if (currentIndex === 0) {
            try {
                await axios.post('https://ex.d5art.com/api/resetEmployerPasswordOtp', { email });
                setCurrentIndex(1);
                toast.success("OTP sent successfully. Please check your email.");
            } catch (error) {
                console.error('Error sending OTP:', error);
            }
        } else if (currentIndex === 1) {
            try {
                await axios.post('https://ex.d5art.com/api/verifyEmployerOTPAndUpdatePassword', {
                    email,
                    emailOtp: emailOTPRecruiter.join(''),
                    password: passwordRecruiter,
                    confirmpassword: passwordRecruiter,
                });
                console.log(email, emailOTP, password, confirmPassword)
                console.log("emailotp", emailOTP.join(''))
                toast.success("Password reset is successful")
                setTimeout(() => {
                    navigate('/');
                }, 2000);

            } catch (error) {
                toast.error('Error verifying OTP and updating password:', error);
            }

        }
    };


    const [confirmPasswordValidator , setConfirmPasswordValidator]  = useState("");


    const handleNextClick2 = async () => {
        if (currentIndex === 0) {
            try {
                await axios.post('https://ex.d5art.com/api/resetValidatorPasswordOtp', { email });
                setCurrentIndex(1);
                toast.success("OTP sent successfully. Please check your email.");
            } catch (error) {
                console.error('Error sending OTP:', error);
                toast.error(error.response?.data?.message || "Failed to send OTP. Please try again.");
              }
        } else if (currentIndex === 1) {

            if (passwordValidator !== confirmPasswordValidator) {
                toast.error("Password and confirm password do not match");
                return;
              }
          
              const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,}$/;
              if (!passwordValidator.match(passwordRegex)) {
                toast.error("Password must contain at least one number, one letter, and one special character");
                return;
              }


            try {
              const response = await axios.post('https://ex.d5art.com/api/verifyValidatorOTPAndUpdatePassword', {
                    email,
                    emailOtp: emailOTPValidator.join(''),
                    password: passwordValidator,
                    
                });
                console.log(email, emailOTP, password)
               
            
                if (response.data.status === "success") {
                  
                    toast.success("Password reset is successful");
                    setTimeout(() => {
                     
                      navigate('/ValidatorRegistration');
                      window.location.reload(); 
                    }, 2000);
                    
                  } else {
                    toast.error("Password reset is unsuccessful");
                  }

            } catch (error) {
                console.error('Error verifying OTP and updating password:', error);
                toast.error(error.response?.data?.message || "Invalid OTP ");            }

        }
    };


    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex));
    };

    const generateRandomNumber = () => {
        const min = Math.pow(10, 9);
        const max = Math.pow(10, 10) - 1;

        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    console.log(generateRandomNumber)




    const seekerValidatePersonalInfo = () => {

        for (const key in personalInfo) {
            if (key === 'walletAddress') continue;
            if (personalInfo.hasOwnProperty(key) && (Array.isArray(personalInfo[key]) ? personalInfo[key].length === 0 : personalInfo[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(personalInfo.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        const passwordValidation = validatePassword(personalInfo.password);
        if (!passwordValidation.valid) {
            toast.error(passwordValidation.message);
            return false;
        }

        const passwordStrength = getPasswordStrength(personalInfo.password);
        if (passwordStrength.score < 3) {
            toast.warn(`Password is weak: ${passwordStrength.feedback}`);
        } else {
            toast.success('Password is strong.');
        }




        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }

        // if (isConnected === false) {
        //     toast.error('Please connect your wallet ');
        //     return;
        // }

        return true;
    };




    /////////////


    const validatePassword = (password) => {
        const capitalLetterRegex = /[A-Z]/;
        const lowercaseLetterRegex = /(?:.*[a-z]){5,}/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const minLength = 8;

        if (!capitalLetterRegex.test(password)) {
            return { valid: false, message: 'Password must contain at least one capital letter.' };
        }
        if (!lowercaseLetterRegex.test(password)) {
            return { valid: false, message: 'Password must contain at least four lowercase letters.' };
        }
        if (!numberRegex.test(password)) {
            return { valid: false, message: 'Password must contain at least one number.' };
        }
        if (!specialCharRegex.test(password)) {
            return { valid: false, message: 'Password must contain at least one special character.' };
        }
        if (password.length < minLength) {
            return { valid: false, message: `Password must be at least ${minLength} characters long.` };
        }

        return { valid: true, message: 'Strong password.' };
    };

    const getPasswordStrength = (password) => {
        const result = zxcvbn(password);
        const score = result.score;
        const feedback = result.feedback.suggestions.join(' ');

        return { score, feedback };
    };



    const savePersonalInfo = async () => {
        try {

            if (!seekerValidatePersonalInfo()) {
                console.log('All Fields Are Required!!!');
                return;
            }

            // if (isConnected === false) {
            //     toast.error('Please connect your wallet ');
            //     return;
            // }

            const response = await axios.post('https://ex.d5art.com/api/savePersonalInfo', personalInfo);
            console.log("data", response.data);

            toast.success("Registered Successfully");



            setTimeout(() => {
                window.location.href = '/SeekerRegistration';
            }, 2000);

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error saving personal info:', error);
            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('An error occurred. Please try again.');
            }
        }
    };

    // try {

    //     const response = await axios.post('https://ex.d5art.com/api/nftId_Address', {
    //         name: personalInfo.name,
    //         email: personalInfo.email,
    //         salt: generateRandomNumber(),
    //         avatar: personalInfo.avatar

    //     });
    //     const data = await response.wait();
    //     console.log(data.status)

    //     console.log("nft id", response.data);



    // } catch (error) {
    //     console.error('Errdor getting nft id:', error.response?.data || error.message);
    // }

    // };



    // const handleClickCongrats = () => {
    //     setIsOpen(!isOpen);
    //     setIsOpen5(!isOpen5);
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         navigate(`/Congrats?email=${personalInfo.email}`);
    //     }, 1000);
    // };


    // const handleClickCongrats = () => {
    //     setIsOpen(!isOpen);
    //     setIsOpen5(!isOpen5);
    //     setIsLoading(true);
    //     setTimeout(() => {
    //         setIsLoading(false);
    //         if (personalInfo.email) {
    //             navigate(`/Congrats?email=${personalInfo.email}`);
    //         } else {

    //             toast.error('OOPS!! Email ID not found..');

    //         }
    //     }, 1000);
    // };




    const getEducationFormData = () => {
        const formData = new FormData();
        formData.append('email', personalInfo.email);

        // SSLC
        formData.append('sslcPercentage', educationInfo.sslcPercentage);
        formData.append('sslcInstitution', educationInfo.sslcInstitution);
        formData.append('sslcYear', educationInfo.sslcYear);
        formData.append('sslcDescription', educationInfo.sslcDescription);
        formData.append('sslcFile', educationInfo.sslcFile);

        // HSC
        formData.append('hscPercentage', educationInfo.hscPercentage);
        formData.append('hscInstitution', educationInfo.hscInstitution);
        formData.append('hscYear', educationInfo.hscYear);
        formData.append('hscDescription', educationInfo.hscDescription);
        formData.append('hscFile', educationInfo.hscFile);

        // CGPA
        formData.append('cgpaPercentage', educationInfo.cgpaPercentage);
        formData.append('cgpaInstitution', educationInfo.cgpaInstitution);
        formData.append('cgpaYear', educationInfo.cgpaYear);
        formData.append('cgpaDescription', educationInfo.cgpaDescription);
        formData.append('cgpaFile', educationInfo.cgpaFile);

        return formData;
    };

    const validateEducationInfo = () => {
        const requiredFields = ['sslcPercentage', 'sslcInstitution', 'sslcYear', 'sslcDescription', 'sslcFile',
            'hscPercentage', 'hscInstitution', 'hscYear', 'hscDescription', 'hscFile',
            'cgpaPercentage', 'cgpaInstitution', 'cgpaYear', 'cgpaDescription', 'cgpaFile'];

        const missingFields = requiredFields.filter(field => !educationInfo[field]);

        if (missingFields.length > 0) {
            toast.error(`Please fill in all the education info fields: ${missingFields.join(', ')}`);
            return false;
        }

        // Add more specific validation rules if necessary

        return true;
    };


    const handleEducationSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateEducationInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveEducationInfo', getEducationFormData());

            console.log('Server response:', response.data);

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error sending educationInfo to server:', error.message);
        }
    };

    const getSkillsFormData = () => {

        const formData1 = new FormData();
        formData1.append('email', personalInfo.email);

        formData1.append('resume', skillsInfo.resume);
        formData1.append('skills', JSON.stringify(skillsInfo.skills));
        formData1.append('proficiency', JSON.stringify(skillsInfo.proficiency));
        formData1.append('jobrole', JSON.stringify(skillsInfo.jobrole));
        return formData1;
    };

    const validateSkillsInfo = () => {
        if (skillsInfo.skills.length === 0 || skillsInfo.proficiency.length === 0 || skillsInfo.jobrole.length === 0 || !skillsInfo.resume) {
            toast.error('Please fill in all the skill info fields.');
            return false;
        }


        return true;
    };

    const handleSkillInfoSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateSkillsInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveSkillsInfo', getSkillsFormData());

            console.log('Server response:', response.data);

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }


        } catch (error) {
            console.error('Error sending skillInfo to server:', error.message);
        }
    }

    const getProjectsFormData = () => {

        const formData2 = new FormData();
        formData2.append('email', personalInfo.email);

        formData2.append('totalProjectNo', projectInfo.project.no);
        formData2.append('project1Name', projectInfo.project1.name);
        formData2.append('project1Description', projectInfo.project1.description);
        formData2.append('project1Keywords', JSON.stringify(projectInfo.project1.keywords));
        formData2.append('project1Link', projectInfo.project1.link);
        formData2.append('project1File', projectInfo.project1.file);

        formData2.append('project2Name', projectInfo.project2.name);
        formData2.append('project2Description', projectInfo.project2.description);
        formData2.append('project2Keywords', JSON.stringify(projectInfo.project2.keywords));
        formData2.append('project2Link', projectInfo.project2.link);
        formData2.append('project2File', projectInfo.project2.file);

        formData2.append('project3Name', projectInfo.project3.name);
        formData2.append('project3Description', projectInfo.project3.description);
        formData2.append('project3Keywords', JSON.stringify(projectInfo.project3.keywords));
        formData2.append('project3Link', projectInfo.project3.link);
        formData2.append('project3File', projectInfo.project3.file);
        return formData2;
    };

    const validateProjectInfo = () => {
        const projects = [projectInfo.project1, projectInfo.project2, projectInfo.project3];
        for (const project of projects) {
            if (project.name || project.description || project.keywords.length || project.link || project.file) {
                // If any field of the project is entered, then all fields must be filled out
                if (!project.name || !project.description || project.keywords.length === 0 || !project.link || !project.file) {
                    toast.error('Please fill in all fields for each project.');
                    return false;
                }
            }
        }
        return true;
    };

    const handleProjectInfoSubmit = async (event) => {
        event.preventDefault();

        if (!seekerValidatePersonalInfo() || !validateProjectInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/saveProjectsInfo', getProjectsFormData());

            console.log('Server response:', response.data);

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error('Error sending projects Info to server:', error.message);
        }

    }

    const generate_seeker_wallet_address = async () => {

        if (!seekerValidatePersonalInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        try {

            if (!personalInfo.email) {

                toast.error('Email ID not found!');

                return;
            }
            const response = await fetch('https://ex.d5art.com/api/generate_SeekerWalletAddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: personalInfo.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);

            return data;
        } catch (error) {
            console.error(error);
        }
    };



    const getCertandExpFormData = () => {

        const formData3 = new FormData();
        formData3.append('email', personalInfo.email);

        formData3.append('certificate1', JSON.stringify(certificateandexpInfo.certificate1));
        formData3.append('certificate2', JSON.stringify(certificateandexpInfo.certificate2));
        formData3.append('certificate3', JSON.stringify(certificateandexpInfo.certificate3));

        formData3.append('experienceYears', certificateandexpInfo.experienceYears.no);
        formData3.append('experience1', JSON.stringify(certificateandexpInfo.experience1));
        formData3.append('experience2', JSON.stringify(certificateandexpInfo.experience2));
        formData3.append('experience3', JSON.stringify(certificateandexpInfo.experience3));

        formData3.append('certificate1File', certificateandexpInfo.certificate1.file);
        formData3.append('certificate2File', certificateandexpInfo.certificate2.file);
        formData3.append('certificate3File', certificateandexpInfo.certificate3.file);
        return formData3;
    };

    const saveCertificateAndExpInfo = async () => {
        //    e.preventDefault();

        if (!seekerValidatePersonalInfo()) {
            toast.error('All Fields are Required!!!');
            return;
        }

        setIsLoading(true);

        try {

            const response = await axios.post('https://ex.d5art.com/api/nftId_Address', {
                name: personalInfo.name,
                email: personalInfo.email,
                salt: generateRandomNumber(),
                avatar: personalInfo.avatar

            });
            const data = await response.data;
            console.log(data.status)

            console.log("nft id", response.data);


            // setTimeout(() => {
            //     setIsLoading(false);
            //     if (personalInfo.email) {
            //         console.log("Navigating to Congrats page with email:", personalInfo.email);
            //         navigate(`/Congrats?email=${personalInfo.email}`);
            //     } else {

            //         toast.error('OOPS!! Email ID not found..');

            //     }
            // }, 1000);


            if (response.data) {
                setTimeout(() => {
                    setIsLoading(false);
                    if (personalInfo.email) {
                        console.log("Navigating to Congrats page with email:", personalInfo.email);
                        navigate(`/Congrats?email=${personalInfo.email}`);
                    } else {
                        toast.error('OOPS!! Email ID not found..');
                    }
                }, 1000);
            } else {
                toast.error('Failed to obtain NFT ID.');
            }

        } catch (error) {
            console.error('Errdor getting nft id:', error.response?.data || error.message);
        }

        try {

            const response = await axios.post('https://ex.d5art.com/api/saveCertandExpInfo', getCertandExpFormData());

            console.log('Server response:', response.data);

            console.log('Server response:', personalInfo.email);



        } catch (error) {
            console.error('Error while saving certificate and experience:', error);
        } finally {

            setIsLoading(false);
        }

        // try {
        //     const avatarIndex = parseInt(selectedAvatar.replace('avatar', '')) - 1;
        //     const uploadFilePath = serveravatar[avatarIndex] || '';
        //     console.log("uploadFilePath", uploadFilePath)
        //     const response1 = await axios.post('https://ex.d5art.com/api/mintnft', {




        //         email: personalInfo.email,
        //         image: uploadFilePath,
        //         name: personalInfo.name,
        //         description: personalInfo.description,
        //         country: personalInfo.country,
        //         state: personalInfo.state,
        //         number: personalInfo.mobileNumber,


        //         sslcyear: educationInfo.sslcYear,
        //         sslcDescription: educationInfo.sslcDescription,
        //         sslcPercentage: educationInfo.sslcPercentage,
        //         sslcInstitution: educationInfo.sslcInstitution,

        //         hscYear: educationInfo.hscYear,
        //         hscDescription: educationInfo.hscDescription,
        //         hscPercentage: educationInfo.hscPercentage,
        //         hscInstitution: educationInfo.hscInstitution,

        //         cgpaYear: educationInfo.cgpaYear,
        //         cgpaDescription: educationInfo.cgpaDescription,
        //         cgpaPercentage: educationInfo.cgpaPercentage,
        //         cgpaInstitution: educationInfo.cgpaInstitution,

        //         skills: skillsInfo.skills,
        //         proficiency: skillsInfo.proficiency,
        //         preferredrole: skillsInfo.jobrole,

        //         project1name: projectInfo.project1.name,
        //         project1desc: projectInfo.project1.description,
        //         project1link: projectInfo.project1.link,
        //         project1keywords: projectInfo.project1.keywords,

        //         project2name: projectInfo.project2.name,
        //         project2desc: projectInfo.project2.description,
        //         project2link: projectInfo.project2.link,
        //         project2keywords: projectInfo.project2.keywords,

        //         project3name: projectInfo.project3.name,
        //         project3desc: projectInfo.project3.description,
        //         project3link: projectInfo.project3.link,
        //         project3keywords: projectInfo.project3.keywords,

        //         cert1name: certificateandexpInfo.certificate1.name,
        //         cert1source: certificateandexpInfo.certificate1.source,
        //         cert1link: certificateandexpInfo.certificate1.link,

        //         cert2name: certificateandexpInfo.certificate2.name,
        //         cert2source: certificateandexpInfo.certificate2.source,
        //         cert2link: certificateandexpInfo.certificate2.link,

        //         cert3name: certificateandexpInfo.certificate3.name,
        //         cert3source: certificateandexpInfo.certificate3.source,
        //         cert3link: certificateandexpInfo.certificate3.link,

        //         exp1compname: certificateandexpInfo.experience1.companyname,
        //         exp1from: certificateandexpInfo.experience1.from,
        //         exp1to: certificateandexpInfo.experience1.to,
        //         exp1role: certificateandexpInfo.experience1.role,

        //         exp2compname: certificateandexpInfo.experience2.companyname,
        //         exp2from: certificateandexpInfo.experience2.from,
        //         exp2to: certificateandexpInfo.experience2.to,
        //         exp2role: certificateandexpInfo.experience2.role,

        //         exp3compname: certificateandexpInfo.experience3.companyname,
        //         exp3from: certificateandexpInfo.experience3.from,
        //         exp3to: certificateandexpInfo.experience3.to,
        //         exp3role: certificateandexpInfo.experience3.role,





        //     });
        //     console.log()

        //     console.log('NFT data successfully submitted to the server:', response1.data);
        // } catch (error) {
        //     console.error('Error submitting NFT data:', error);

        // }


    };






    //    const generate_nftid = async () => {
    //     try {

    //         if (!seekerValidatePersonalInfo() ) {
    //             toast.error('All Fields are Required!!!');
    //             return;
    //         }

    //         console.log("Personal Info11111111:", personalInfo.email);

    //         const nftIdResponse = await axios.post('https://ex.d5art.com/api/nftId_Address', {
    //             name: personalInfo.name,
    //             email: personalInfo.email,
    //             salt: generateRandomNumber(),
    //             avatar: personalInfo.avatar
    //         });
    //         // const nftIdData = nftIdResponse.data;
    //         // console.log("NFT ID response:", nftIdData);

    //         // setTimeout(() => {
    //         //     setIsLoading(false);
    //         //     if (personalInfo.email) {
    //         //         navigate(`/Congrats?email=${personalInfo.email}`);
    //         //     } else {
    //         //         toast.error('OOPS!! Email ID not found..');
    //         //     }
    //         // }, 1000);

    //         const nftIdData = nftIdResponse.data;
    //         console.log("NFT ID response:", nftIdData);

    //         if (nftIdData.status === "success") {
    //             setIsLoading(false);

    //                 console.log("Redirecting to Congrats page...");
    //                 navigate(`/Congrats?email=${personalInfo.email}`);
    //             } else {
    //                 toast.error('OOPS!! Email ID not found..');
    //             }


    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };


    //    const saveCertificateAndExpInfo = async (e) => {
    //     e.preventDefault();

    //     if (!seekerValidatePersonalInfo() || !validateEducationInfo() || !validateSkillsInfo()) {
    //         toast.error('All Fields are Required!!!!!');
    //         return;
    //     }

    //     try {

    //         const saveCertAndExpResponse = await axios.post('https://ex.d5art.com/api/saveCertandExpInfo', getCertandExpFormData());
    //         console.log('Certificate and experience info saved:', saveCertAndExpResponse.data);




    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };





    const handleSavePersonalInfo = () => {
        savePersonalInfo();
    };










    //////////////////////////////////////////////////////////////////////////////////



    // Recruiter registration
    // Recruiter personal Information



    const [personalInfo1, setPersonalInfo1] = useState({
        name: '',
        email: '',
        country: '',
        state: '',
        mobileNumber: '',
        avatar: './assets/image 90.png',
        description: '',
        password: '',
        confirmpassword: '',

    });

    console.log('personalInfo1', personalInfo1);

    const handleInputChange4 = (section, field, value) => {
        switch (section) {
            case 'personalInfo1':
                if (field === 'avatar') {
                    setSelectedAvatar(value);
                } else if (field === 'country' || field === 'state') {
                    setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value.label }));
                } else if (field === 'password' || field === 'confirmpassword') {
                    setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value }));
                    setPasswordsMatch(value === personalInfo1.confirmpassword || field === 'confirmpassword' && personalInfo1.password === value);
                } else {
                    setPersonalInfo1((prevInfo) => ({ ...prevInfo, [field]: value }));
                }

            default:
                break;
        }
    };

    const handleSliderAfterChange1 = (index) => {
        const avatarIndex = index + 1;
        const newAvatar = `avatar${avatarIndex}`;
        const avatarImageSrc = avatarSources[index];
        console.log("newavatar", newAvatar);
        setSelectedAvatar(newAvatar);

        setPersonalInfo1((prevInfo) => ({
            ...prevInfo,
            avatar: avatarImageSrc,
        }));
    };



    const handleSubmit1 = (e) => {
        e.preventDefault();
        console.log('Personal Info:', personalInfo1);

        if (!passwordsMatch) {
            console.error('Passwords do not match');
            return;
        }

    };

    const recruiterValidatePersonalInfo = () => {

        for (const key in personalInfo1) {
            if (personalInfo1.hasOwnProperty(key) && (Array.isArray(personalInfo1[key]) ? personalInfo1[key].length === 0 : personalInfo1[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(personalInfo1.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return;
        }

        return true;
    };



    const submit = async () => {
        try {
            console.log("submit111111", personalInfo1);

            if (!recruiterValidatePersonalInfo()) {
                return;
            }


            const response = await axios.post('https://ex.d5art.com/api/getRecruiterDetails', personalInfo1);
            console.log(response.data);

            toast.success('Data stored successfully!');

            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }



        } catch (error) {
            console.error(error);

            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('An error occurred. Please try again.');
            }
        }
    };






    // Recruiter organization details

    const [organizationDetails, setorganizationDetails] = useState({
        organizationName: '',
        organizationDescription: '',
        industryType: '',
        websiteURL: '',
        yourDesignation: '',
        walletAddress: '',
    });
    console.log('organizationDetails', organizationDetails, address);

    useEffect(() => {
        if (isConnected === true) {
            setorganizationDetails(prevDetails => ({
                ...prevDetails,
                walletAddress: address,
            }));
        }

    }, [address]);

    const handleInputChange5 = (section, field, value) => {
        switch (section) {
            case 'organizationDetails':
                if (field === 'organizationName') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        organizationName: value,
                    }));
                } else if (field === 'organizationDescription') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        organizationDescription: value,
                    }));
                } else if (field === 'industryType') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        industryType: value,
                    }));
                } else if (field === 'websiteURL') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        websiteURL: value,
                    }));
                } else if (field === 'yourDesignation') {
                    setorganizationDetails((prevDetails) => ({
                        ...prevDetails,
                        yourDesignation: value,
                    }));
                }
                break;
            default:
                break;
        }
    };
    const handleSubmit2 = async (e) => {
        e.preventDefault();
        console.log('setorganizationDetails:', organizationDetails);


        console.log('Temp Name and Email:', tempNameAndEmail);


    };


    const [tempNameAndEmail, setTempNameAndEmail] = useState({

        name: '',
        email: '',
    });



    const recruiterValidateOrganizationDetails = () => {

        if (!recruiterValidatePersonalInfo()) {
            return;
        }

        for (const key in organizationDetails) {
            if (key === 'walletAddress') continue;
            if (organizationDetails.hasOwnProperty(key) && (Array.isArray(organizationDetails[key]) ? organizationDetails[key].length === 0 : organizationDetails[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }

        // if (isConnected === false) {
        //     toast.error('Please connect your wallet ');
        //     return;
        // }

        console.log("address", address)


        return true;
    };


    const submit1 = async () => {
        console.log('5555555', tempNameAndEmail, organizationDetails);

        try {
            if (!recruiterValidateOrganizationDetails()) {
                return;
            }

            const generatedWalletAddress = await generate_wallet_address();

            const response = await fetch('https://ex.d5art.com/api/getRecruiterOrgDetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ organizationDetails, email: personalInfo1.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            toast.success('Registered successfully!');
            window.location.href = '/Register';

        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('Wallet address already exists');
            }
        }
    };


    const generate_wallet_address = async () => {
        console.log('5555555', tempNameAndEmail);

        try {

            if (!personalInfo1.email) {

                toast.error('Email ID not found!');

                return;
            }
            const response = await fetch('https://ex.d5art.com/api/generateWalletAddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: personalInfo1.email })

            });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setTempNameAndEmail((prevState) => ({
            ...prevState,
            name: personalInfo1.name,
            email: personalInfo1.email,
        }));
        console.log('3333333', personalInfo1.name)
    }, [personalInfo1.name, personalInfo1.email]);


    useEffect(() => {
        console.log("Submitting tempNameAndEmail:", tempNameAndEmail);
    }, [tempNameAndEmail]);












    // Validator Registration
    // Validator Personal information

    const [ValidatorPersonalInfo, setValidatorPersonalInfo] = useState({
        name: '',
        email: '',
        country: '',
        countryCode: '',
        state: '',
        mobileNumber: '',
        avatar: './assets/image 90.png',
        description: '',
        password: '',
        confirmpassword: '',

    });


    console.log('ValidatorPersonalInfo', ValidatorPersonalInfo);

    const [filteredStateOptions, setFilteredStateOptions] = useState([]);

    const handleInputChange6 = (section, field, value) => {
        switch (section) {
            case 'ValidatorPersonalInfo':
                if (field === 'avatar') {
                    setSelectedAvatar(value);
                } else if (field === 'country' || field === 'state' || field === 'countryCode') {
                    if (Array.isArray(value)) {
                        const selectedValues = value.map(option => option.label);
                        setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: selectedValues }));
                    } else {
                        if (field === 'country') {
                            setValidatorPersonalInfo((prevInfo) => ({
                                ...prevInfo,
                                country: value.label,
                                countryCode: value.value
                            }));

                            const filteredStates = stateOptions.filter(state => state.country_name === value.label);
                            console.log("filtered states", filteredStates);
                            setFilteredStateOptions(filteredStates);
                            setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, state: '' }));
                        } else if (field === 'state') {
                            setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, state: value.name }));
                        } else if (field === 'countryCode') {
                            setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, countryCode: value.value }));
                        } else {
                            setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label || value.value }));
                        }
                    }
                } else if (field === 'password' || field === 'confirmpassword') {
                    setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
                    setPasswordsMatch(value === ValidatorPersonalInfo.confirmpassword || field === 'confirmpassword' && ValidatorPersonalInfo.password === value);
                } else {
                    setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
                }
                break;
            default:
                break;
        }
    };


    // const handleInputChange6 = (section, field, value) => {
    //     switch (section) {
    //         case 'ValidatorPersonalInfo':
    //             if (field === 'avatar') {
    //                 setSelectedAvatar(value);
    //             } else if (field === 'country' || field === 'state') {
    //                 setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value.label }));
    //             } else if (field === 'password' || field === 'confirmpassword') {
    //                 setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    //                 setPasswordsMatch(value === ValidatorPersonalInfo.confirmpassword || field === 'confirmpassword' && ValidatorPersonalInfo.password === value);
    //             } else {
    //                 setValidatorPersonalInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const handleSliderAfterChange2 = (index) => {
        const avatarIndex = index + 1;
        const newAvatar = `avatar${avatarIndex}`;
        const avatarImageSrc = avatarSources[index];
        console.log("newavatar", newAvatar);
        setSelectedAvatar(newAvatar);

        setValidatorPersonalInfo((prevInfo) => ({
            ...prevInfo,
            avatar: avatarImageSrc,
        }));
    };



    const handleSubmit3 = (e) => {
        e.preventDefault();
        console.log('ValidatorPersonalInfo Info:', ValidatorPersonalInfo);

        if (!passwordsMatch) {
            console.error('Passwords do not match');
            return;
        }

    };



    const validateForm = () => {

        for (const key in ValidatorPersonalInfo) {
            if (ValidatorPersonalInfo.hasOwnProperty(key) && (Array.isArray(ValidatorPersonalInfo[key]) ? ValidatorPersonalInfo[key].length === 0 : ValidatorPersonalInfo[key] === '')) {
                toast.error(`The ${key} field is required.`);
                return false;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(ValidatorPersonalInfo.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(ValidatorPersonalInfo.password)) {
        toast.error("Password must contain at least one number, one letter, and one special character, and be at least 8 characters long.");
        return false;
    }



        if (!passwordsMatch) {
            toast.error('Passwords do not match');
            return false;
        }

        return true;
    };



    const ValidatorSubmit = async () => {
        try {
            console.log("submit111111111", ValidatorPersonalInfo);

            if (!validateForm()) {
                return;
            }

            const response = await axios.post('https://ex.d5art.com/api/saveValidator_personalInfo', ValidatorPersonalInfo);
            console.log(response.data)

            toast.success('Data stored successfully!');


            if (sliderRef.current) {
                sliderRef.current.slickNext();
                setCurrentStep((prevStep) => prevStep + 1);
            }

        } catch (error) {
            console.error(error);

            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('An error occurred. Please try again.');
            }
        }
    };







    // validator skills and tools

    const [validatorSkillsAndTools, setValidatorSkillsAndTools] = useState({
        uploadFiles: [],
        skills: [],
        walletAddress: '',
    });

    useEffect(() => {
        if (isConnected === true) {
            setValidatorSkillsAndTools(prevDetails => ({
                ...prevDetails,
                walletAddress: address,
            }));
        }

    }, [address]);


    console.log('validatorSkillsAndTools', validatorSkillsAndTools, address);

    const handleRemoveFile1 = (index) => {
        setValidatorSkillsAndTools((prevDetails) => ({
            ...prevDetails,
            uploadFiles: prevDetails.uploadFiles.filter((_, i) => i !== index),
        }));
    };

    // const handleInputChange7 = (section, field, value) => {
    //     console.log('Selected Skills:', value); // Log the selected skills
    //     switch (section) {
    //         case 'validatorSkillsAndTools':
    //             if (field === 'uploadFiles') {
    //                 setValidatorSkillsAndTools((prevDetails) => ({
    //                     ...prevDetails,
    //                     uploadFiles: [...prevDetails.uploadFiles, ...value],
    //                 }));
    //             } else if (field === 'skills') {
    //                 const selectedSkills = Array.isArray(value) ? value : [value];
    //                 const updatedSkills = selectedSkills.map(skill => ({
    //                     value: skill.value,
    //                     label: skill.label
    //                 }));
    //                 setValidatorSkillsAndTools((prevDetails) => ({
    //                     ...prevDetails,
    //                     skills: [...prevDetails.skills, ...updatedSkills],
    //                 }));
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // };


    const handleInputChange7 = (section, field, value) => {
        console.log('Selected Skills:', value); // Log the selected skills
        switch (section) {
            case 'validatorSkillsAndTools':
                if (field === 'uploadFiles') {
                    const validFiles = [];
                    for (const file of value) {
                        if (file.size > 2 * 1024 * 1024) { // 2 MB in bytes
                            toast.error(`${file.name} is larger than 2 MB.`);
                        } else if (!['application/pdf', 'application/msword'].includes(file.type)) {
                            toast.error(`${file.name} has an invalid file type.`);
                        } else {
                            validFiles.push(file);
                        }
                    }
                    if (validFiles.length > 0) {
                        setValidatorSkillsAndTools((prevDetails) => ({
                            ...prevDetails,
                            uploadFiles: [...prevDetails.uploadFiles, ...validFiles],
                        }));
                    }
                } else if (field === 'skills') {
                    const selectedSkills = Array.isArray(value) ? value : [value];
                    const updatedSkills = selectedSkills.map(skill => ({
                        value: skill.value,
                        label: skill.label
                    }));
                    setValidatorSkillsAndTools((prevDetails) => ({
                        ...prevDetails,
                        skills: [...prevDetails.skills, ...updatedSkills],
                    }));
                }
                break;
            default:
                break;
        }
    };

    

    const removeSkill = (index) => {
        setValidatorSkillsAndTools((prevDetails) => {
            const updatedSkills = [...prevDetails.skills];
            updatedSkills.splice(index, 1);
            return {
                ...prevDetails,
                skills: updatedSkills,
            };
        });
    };



    const handleSubmit4 = async (e) => {
        e.preventDefault();
        console.log('validatorSkillsAndTools:', validatorSkillsAndTools);
        // Perform any necessary actions with the form data
    };


    const validateSkillsAndTools = () => {

        if (!validateForm()) {
            return;
        }

        if (validatorSkillsAndTools.skills.length === 0) {
            toast.error('Please select at least one skill.');
            return false;
        }

        if (validatorSkillsAndTools.uploadFiles.length === 0) {
            toast.error('Please upload at least one document.');
            return false;
        }

        // if (isConnected === false) {
        //     toast.error('Please connect your wallet ');
        //     return;
        // }

        return true;
    };



    const ValidatorSubmit1 = async () => {
        try {

            if (!validateSkillsAndTools()) {
                return;
            }

            // if (isConnected === false) {
            //     toast.error('Please connect your wallet ');
            //     return;
            // }

            const generatedWalletAddress = await validator_generate_wallet_address();

            const formData = new FormData();
            validatorSkillsAndTools.uploadFiles.forEach((file) => {
                formData.append('uploadFiles', file);
            });
            formData.append('skills', JSON.stringify(validatorSkillsAndTools.skills));
            formData.append('email', ValidatorPersonalInfo.email); // Include the email in the form data
            formData.append('walletAddress', validatorSkillsAndTools.walletAddress);

            const response = await axios.post('https://ex.d5art.com/api/validatorskills', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);

            setValidatorSkillsAndTools({
                uploadFiles: [],
                skills: [],
                walletAddress: '',

            });
            console.log("Submitting validatorSkillsAndTools:", validatorSkillsAndTools);
            toast.success('Registered successfully!');
            window.location.href = '/ValidatorRegistration';
        } catch (error) {
            console.error(error);

            if (error.response && error.response.data && error.response.data.error) {

                toast.error(error.response.data.error);
            } else {

                toast.error('An error occurred. Please try again.');
            }
        }
    };





    const validator_generate_wallet_address = async () => {
        console.log('5555555', ValidatorPersonalInfo);

        try {
            const response = await fetch('https://ex.d5art.com/api/generate_ValidatorWalletAddress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: ValidatorPersonalInfo.email })

            });



            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);

            }

            const data = await response.json();
            console.log(data);

            return data;
        } catch (error) {
            console.error(error);
        }
    };






    // Login


    // recruiter login


    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const dispatch = useDispatch();
    //const navigate = useNavigate();


    const [recruiterLogin, setRecruiterLogin] = useState({
        email: '',
        password: '',
    });



    console.log('recruiterLogin', recruiterLogin);

    const handleInputChange8 = (section, field, value) => {
        switch (section) {
            case 'recruiterLogin':
                if (field === 'email') {
                    setRecruiterLogin((prevDetails) => ({
                        ...prevDetails,
                        email: value,
                    }));
                } else if (field === 'password') {
                    setRecruiterLogin((prevDetails) => ({
                        ...prevDetails,
                        password: value,
                    }));
                }
                break;
            default:
                break;
        }
    };

    // validation 
    const recruiterLoginValidate = () => {
        if (!recruiterLogin.email || !recruiterLogin.password) {
            toast.error('Email and password are required.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(recruiterLogin.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        return true;
    };

    const handleSubmit5 = async (e) => {
        e.preventDefault();

        if (!recruiterLoginValidate()) {
            return;
        }

        const response = await recruiterLoginSubmit();

        if (response.status === 'success') {
            console.log('Login successful. Redirecting to the home page.');

            Cookies.set('authToken', response.data.token, { expires: 1 });

            const userEmail = response.data.user.email;
            setRecruiterLogin({ email: '', password: '' });
            setIsLoggedIn(true);
            fetchUserDetails(userEmail);
            window.location.href = '/RMS/Recruiter/';

        } else {
            console.log('Login failed. Display an error message or stay on the same page.');

        }
    };





    // recruiter login
    const recruiterLoginSubmit = async () => {
        console.log('submit111111', recruiterLogin);
        try {
            console.log('submit123123213', recruiterLogin);

            if (!recruiterLogin.email || !recruiterLogin.password) {
                console.log('Email and password are required.');
                toast.error('Email and password are required.');
                return { status: 'error' };
            }

            const response = await axios.post('https://ex.d5art.com/api/login', recruiterLogin);
            console.log('3333333333', response);

            if (response.data.status === 'success') {
                toast.success('Welcome to D5Art!');
                // dispatch(setUserEmail(formData.email));
                setTimeout(() => {
                    dispatch(setRecruiterEmail(recruiterLogin.email));
                    navigate('/RMS/Recruiter/');
                }, 2000);
                console.log('rrrrrrrrrrrrrrrrrr', recruiterLogin.email)

                console.log('Login successful.');
                return response;
            } else {
                console.log('Login failed.');
                toast.error('OOPS!! Login failed');
                return { status: 'error' };
            }
        } catch (error) {
            console.error(error);
            toast.error('OOPS!! Login failed try correct credentials');

            setTimeout(() => {

                window.location.href = '/Register';

            }, 3000);
            return { status: 'error' };
        } finally {

            setTimeout(() => {
                setIsLoading(false);


            }, 3000);
        }
    }



    const fetchUserDetails = async (email) => {
        try {
            const response = await axios.get(`https://ex.d5art.com/api/login/${email}`);
            console.log(response.data);
            if (response.data.status === 'success') {
                const userName = response.data.user.name;
                const userEmail = response.data.user.email;
                setUserInfo({ name: userName, email: userEmail });
            } else {
                console.log('Failed to fetch user details.');
            }
        } catch (error) {
            console.error(error);
        }
    };


    // seeker login


    const [seekerLogin, setSeekerLogin] = useState({
        email: '',
        password: '',
    });



    console.log('seekerLogin', seekerLogin);

    const handleInputChange9 = (section, field, value) => {
        switch (section) {
            case 'seekerLogin':
                if (field === 'email') {
                    setSeekerLogin((prevDetails) => ({
                        ...prevDetails,
                        email: value,
                    }));
                } else if (field === 'password') {
                    setSeekerLogin((prevDetails) => ({
                        ...prevDetails,
                        password: value,
                    }));
                }
                break;
            default:
                break;
        }
    };

    // validation
    const seekerLoginValidate = () => {
        if (!seekerLogin.email || !seekerLogin.password) {
            toast.error('Email and password are required.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(seekerLogin.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        return true;
    };


    const handleSubmit6 = async (e) => {
        e.preventDefault();

        if (!seekerLoginValidate()) {
            return;
        }

        const response = await seekerLoginSubmit();

        if (response.status === 'success') {
            console.log('Login successful. Redirecting to the home page.');
            const userEmail = response.data.user.email; // Extract user email from response data
            setSeekerLogin({ email: '', password: '' });
            setIsLoggedIn(true);
            fetchUserDetails1(userEmail); // Fetch user details using the user's email
            window.location.href = '/RMS/Seeker/';

        } else {
            console.log('Login failed. Display an error message or stay on the same page.');
        }
    };





    // const seekerLoginSubmit = async () => {
    //     console.log('submit111111', seekerLogin);
    //     try {
    //         console.log('submit123123213', seekerLogin);



    //         const response = await axios.post('https://ex.d5art.com/api/seekerLogin', seekerLogin);
    //         console.log('3333333333', response);
    //         console.log('3333333333', response.user.verify_step);

    //         if (response.user.verify_step === 'step4') {

    //         }

    //         if (response.data.status === 'success') {
    //             toast.success('Login successful')
    //             // dispatch(setUserEmail(formData.email));
    //             setTimeout(() => {
    //                 dispatch(setSeekerEmail(seekerLogin.email));
    //                 navigate('/RMS/Seeker/');
    //             }, 2000);
    //             console.log('rrrrrrrrrrrrrrrrrr', seekerLogin.email)

    //             console.log('Login successful.');
    //             return response;
    //         } else {
    //             console.log('Login failed.');
    //             return { status: 'error' };
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         toast.error('OOPS!! Login failed try correct credentials');
    //         setTimeout(() => {

    //             window.location.href = '/Register';

    //         }, 3000);
    //         return { status: 'error' };
    //     } finally {
    //         setTimeout(() => {
    //             setIsLoading(false);
    //         }, 3000);
    //     }
    // };

    const seekerLoginSubmit = async () => {
        console.log('submit111111', seekerLogin);
        try {
            console.log('submit123123213', seekerLogin);

            const response = await axios.post('https://ex.d5art.com/api/seekerLogin', seekerLogin);
            console.log('3333333333', response);
            console.log('3333333333', response.data.user.verify_step);

            if (response.data.user.verify_step === 'step1') {
                //togglemodal5();
                toast.success('Welcome to D5Art!');
                setTimeout(() => {
                    dispatch(setSeekerEmail(seekerLogin.email));
                    navigate('/rms/seeker/updatedetails');
                }, 2000);
                return response;
            } else {
                if (response.data.status === 'success') {
                    toast.success('Welcome to D5Art!');


                    setTimeout(() => {
                        dispatch(setSeekerEmail(seekerLogin.email));
                        navigate('/RMS/Seeker/');
                    }, 2000);
                    console.log('rrrrrrrrrrrrrrrrrr', seekerLogin.email);
                    console.log('Login successful.');
                    return response;
                } else {
                    console.log('Login failed.');
                    return { status: 'error' };
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('OOPS!! Login failed try correct credentials');
            setTimeout(() => {
                window.location.href = '/SeekerRegistration';
            }, 3000);
            return { status: 'error' };
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }
    };



    const fetchUserDetails1 = async (email) => {
        try {
            const response = await axios.get(`https://ex.d5art.com/api/seekerLogin/${email}`);
            console.log(response.data);
            if (response.data.status === 'success') {
                const userName = response.data.user.name;
                const userEmail = response.data.user.email;
                setUserInfo({ name: userName, email: userEmail });
            } else {
                console.log('Failed to fetch user details.');
            }
        } catch (error) {
            console.error(error);
        }
    };




    // validator login

    const [validatorLogin, setValidatorLogin] = useState({
        email: '',
        password: '',
    });



    console.log('validatorLogin', validatorLogin);

    const handleInputChangeValidator = (section, field, value) => {
        switch (section) {
            case 'validatorLogin':
                if (field === 'email') {
                    setValidatorLogin((prevDetails) => ({
                        ...prevDetails,
                        email: value,
                    }));
                } else if (field === 'password') {
                    setValidatorLogin((prevDetails) => ({
                        ...prevDetails,
                        password: value,
                    }));
                }
                break;
            default:
                break;
        }
    };


    // validation
    const validatorLoginValidate = () => {
        if (!validatorLogin.email || !validatorLogin.password) {
            toast.error('Email and password are required.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(validatorLogin.email)) {
            toast.error('Invalid email format.');
            return false;
        }

        return true;
    };


    const handleSubmitValidator = async (e) => {
        e.preventDefault();

        if (!validatorLoginValidate()) {
            return;
        }


        const response = await validatorLoginSubmit();

        if (response.status === 'success') {
            console.log('Login successful. Redirecting to the home page.');
            const userEmail = response.data.user.email;
            setValidatorLogin({ email: '', password: '' });
            setIsLoggedIn(true);
            fetchUserDetails3(userEmail);
            window.location.href = '/RMS/Validator/';

        } else {
            console.log('Login failed. Display an error message or stay on the same page.');
        }
    };





    const validatorLoginSubmit = async () => {
        console.log('submit111111', validatorLogin);

        if (!recaptchaToken) {
            toast.error('Please complete the reCAPTCHA');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            return;
        }
        
        try {
            console.log('submit123123213', validatorLogin);



            const response = await axios.post('https://ex.d5art.com/api/validatorLogin', validatorLogin);
            console.log('3333333333', response);

            if (response.data.status === 'success') {
                toast.success('Welcome to D5Art!');
                // dispatch(setUserEmail(formData.email));

                setTimeout(() => {
                    dispatch(setValidatorEmail(validatorLogin.email));
                    navigate('/RMS/Validator/');
                }, 2000);

                console.log('rrrrrrrrrrrrrrrrrr', validatorLogin.email)

                console.log('Login successful.');
                return response;
            } else {
                console.log('Login failed.');
                return { status: 'error' };
            }
        } catch (error) {
            console.error(error);
            toast.error('OOPS!! Login failed try correct credentials');

            setTimeout(() => {
                window.location.href = '/ValidatorRegistration';

            }, 3000);
            return { status: 'error' };
        } finally {

            setTimeout(() => {
                setIsLoading(false);


            }, 3000);
        }
    }



    const fetchUserDetails3 = async (email) => {
        try {
            const response = await axios.get(`https://ex.d5art.com/api/validatorLogin/${email}`);
            console.log(response.data);
            if (response.data.status === 'success') {
                const userName = response.data.user.name;
                const userEmail = response.data.user.email;
                setUserInfo({ name: userName, email: userEmail });
            } else {
                console.log('Failed to fetch user details.');
            }
        } catch (error) {
            console.error(error);
        }
    };











    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleClickDocuments = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        const newSelectedFiles = [...selectedFiles];

        for (let i = 0; i < files.length; i++) {
            newSelectedFiles.push(files[i]);
        }

        setSelectedFiles(newSelectedFiles);
    };

    const handleRemoveFile = (index) => {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles.splice(index, 1);
        setSelectedFiles(newSelectedFiles);
    };


    // const handleSeekerWalletLogin = async () => {

    //     if (!isConnected) {
    //         console.log('Please connect your wallet first');
    //         return;
    //     }

    //     console.log("address", address)
    //     try {
    //         const response = await axios.post('https://ex.d5art.com/api/seeker_walletAddress_login', { WalletAddress: address });

    //         console.log('handleSeekerWalletLogin', response)
    //         const { email, accessToken, message } = response.data;

    //         if (message === "exists") {
    //             toast.success("Welcome to FABC!");



    //             signIn({
    //                 token: accessToken,
    //                 expiresIn: 24 * 60 * 60 * 100,
    //                 tokenType: "Bearer",
    //                 authState: { email }
    //             })



    //             setTimeout(() => {
    //                 dispatch(setSeekerEmail(email));
    //                 navigate('/RMS/Seeker/');
    //             }, 2000);

    //         }

    //         else {
    //             toast.error('Please register your wallet address');
    //         }
    //     } catch (error) {
    //         console.error('Error checking wallet address:', error);
    //     }
    // };

    const handleSeekerWalletLogin = async () => {
        console.log('submit111111', seekerLogin);
        if (!isConnected) {
            console.log('Please connect your wallet first');
            return;
        }

        console.log("address", address);

        try {
            console.log('submit123123213', seekerLogin);

            const response = await axios.post('https://ex.d5art.com/api/seeker_walletAddress_login', { WalletAddress: address });
            console.log('3333333333', response);
            console.log('3333333333', response.data.user.verify_step);

            if (response.data.user.verify_step === 'step1') {
                //togglemodal5();
                toast.success('Welcome to D5Art!')
                setTimeout(() => {
                    dispatch(setSeekerEmail(response.data.user.email));
                    navigate('/rms/seeker/updatedetails');
                }, 2000);
                return response;
            } else {
                if (response.data.status === 'success') {
                    toast.success('Welcome to D5Art!');


                    setTimeout(() => {
                        dispatch(setSeekerEmail(response.data.user.email));
                        navigate('/RMS/Seeker/');
                    }, 2000);
                    console.log('rrrrrrrrrrrrrrrrrr', response.data.user.email);
                    console.log('Welcome to D5Art!');
                    return response;
                } else {
                    console.log('Login failed.');
                    return { status: 'error' };
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('OOPS!! Login failed try correct credentials');
            setTimeout(() => {
                window.location.href = '/SeekerRegistration';
            }, 3000);
            return { status: 'error' };
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }
    };


    const handleValidatorWalletLogin = async () => {

        if (!isConnected) {
            console.log('Please connect your wallet first');
            return;
        }

        console.log("address", address)
        try {
            const response = await axios.post('https://ex.d5art.com/api/validator_walletAddress_login', { WalletAddress: address });

            console.log("handleValidatorWalletLogin", response)

            console.log('response', response)
            const { email, accessToken, message } = response.data;

            if (message === "exists") {
                toast.success('Welcome to D5Art!');

                // const response = await axios.post('https://ex.d5art.com/api/new-access-token', { email});
                // const newAccessToken = response.data.accessToken;


                // signIn({
                //   token: accessToken,
                //    expiresIn : 3600,
                //   tokenType: "Bearer",
                //   authState:{email}
                // })



                setTimeout(() => {
                    dispatch(setValidatorEmail(email));
                    navigate('/RMS/Validator/');
                }, 2000);

            }

            else {
                toast.error('Please register your wallet address');
            }
        } catch (error) {
            console.error('Error checking wallet address:', error);
        }
    };

    const handleRecruiterWalletLogin = async () => {

        if (!isConnected) {
            console.log('Please connect your wallet first');
            return;
        }

        console.log("address", address)
        try {
            const response = await axios.post('https://ex.d5art.com/api/recruiter_walletAddress_login', { WalletAddress: address });


            console.log("handleRecruiterWalletLogin", response)

            console.log('response', response)
            const { email, accessToken, message } = response.data;

            if (message === "exists") {
                toast.success('Welcome to D5Art!');

                // const response = await axios.post('https://ex.d5art.com/api/new-access-token', { email});
                // const newAccessToken = response.data.accessToken;


                // signIn({
                //   token: accessToken,
                //    expiresIn : 3600,
                //   tokenType: "Bearer",
                //   authState:{email}
                // })



                setTimeout(() => {
                    dispatch(setRecruiterEmail(email));
                    navigate('/RMS/Recruiter/');
                }, 2000);

            }

            else {
                toast.error('Please register your wallet address');
            }
        } catch (error) {
            console.error('Error checking wallet address:', error);
        }
    };




    //   useEffect(() => {
    //     if (isConnected) {
    //       handleSeekerWalletLogin();
    //     }
    //   }, [isConnected]);


    //   useEffect(() => {
    //     if (isConnected) {
    //       handleRecruiterWalletLogin();
    //     }
    //   }, [isConnected]);


    //   useEffect(() => {
    //     if (isConnected) {
    //         handleWalletLogOut();
    //     }
    //   }, [isConnected]);





    const handleWalletLogOut = async () => {

        if (!isConnected) {
            toast.error("connect you wallet")
            return
        }


        disconnect()
    }



    //////----google login -------



    const [isGoogleRegister, setIsGoogleRegister] = useState(false);

    const [googleUser, setGoogleUser] = useState(null);

    //const clientId ="678267799693-nb358u8abna1booq6ft20jgnmth8iffv.apps.googleusercontent.com";


    const clientId = "678267799693-nb358u8abna1booq6ft20jgnmth8iffv.apps.googleusercontent.com";




    // const handleGoogleSignIn_seeker = async (res) => {
    //     console.log("SignUp with Google button clicked");
    //     setIsGoogleRegister(true);

    //     if (res && res.profileObj) {
    //         const email = res.profileObj.email;

    //         console.log('Google Login Response:', res);
    //         console.log("isGoogleRegister onSuccess", isGoogleRegister);

    //         setGoogleUser(res.profileObj);

    //         try {
    //             const response = await axios.post("https://ex.d5art.com/api/google_seeker_login", { email });
    //             const { accessToken, message } = response.data;

    //             console.log("API Response:", response);
    //             if (message === "exists") {
    //                 toast.success("Welcome to FABC!");

    //                 signIn({
    //                     token: accessToken,
    //                     expiresIn: 3600,
    //                     tokenType: "Bearer",
    //                     authState: { email },
    //                 });

    //                 setTimeout(() => {
    //                     dispatch(setSeekerEmail(email));
    //                     navigate('/RMS/Seeker/');
    //                 }, 2000);
    //             } else {
    //                 toast.error("Email does not exist. Please register.");
    //             }
    //         } catch (error) {
    //             console.error("Error during Google register check:", error);
    //             toast.error("Error during Google register check. Please try again later.");
    //         }
    //     } else {
    //         console.error("Google login response does not contain profile information.");
    //         //  toast.error("Google sign-in failed. Please try again.");
    //         setIsGoogleRegister(false);
    //     }
    // };


    const handleGoogleSignIn_seeker = async (res) => {
        console.log("SignUp with Google button clicked");
        setIsGoogleRegister(true);

        if (res && res.profileObj) {
            const email = res.profileObj.email;

            console.log('Google Login Response:', res);
            console.log("isGoogleRegister onSuccess", isGoogleRegister);

            setGoogleUser(res.profileObj);

            try {
                const response = await axios.post("https://ex.d5art.com/api/google_seeker_login", { email });
                const user = response.data.user;
                const status = response.data.status;

                console.log('Server Response:', response);
                console.log('Verification Step:', response.data.user.verify_step);

                if (user.verify_step === 'step1') {
                    toast.success('Welcome to D5Art!');
                    setTimeout(() => {
                        dispatch(setSeekerEmail(email));
                        navigate('/rms/seeker/updatedetails');
                    }, 2000);
                    return response;
                } else if (status === 'success') {
                    toast.success('Welcome to D5Art!');
                    setTimeout(() => {
                        dispatch(setSeekerEmail(email));
                        navigate('/RMS/Seeker/');
                    }, 2000);
                    console.log('Login successful:', email);
                    return response;
                } else {
                    console.log('Login failed.');
                    return { status: 'error' };
                }
            } catch (error) {
                console.error('Error during login:', error);
                toast.error('OOPS!! Login failed, please try again with correct credentials.');
                setTimeout(() => {
                    window.location.href = '/SeekerRegistration';
                }, 3000);
                return { status: 'error' };
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 3000);
            }
        } else {
            console.log('No Google profile object found in response.');
        }
    };


    const handleGoogleSignIn_recruiter = async (res) => {
        console.log("SignUp with Google button clicked");
        setIsGoogleRegister(true);

        if (res && res.profileObj) {
            const email = res.profileObj.email;

            console.log('Google Login Response:', res);
            console.log("isGoogleRegister onSuccess", isGoogleRegister);

            setGoogleUser(res.profileObj);

            try {
                const response = await axios.post("https://ex.d5art.com/api/google_recruiter_login", { email });
                const { accessToken, message } = response.data;

                console.log("API Response:", response);
                if (message === "exists") {
                    toast.success("Welcome to FABC!");

                    signIn({
                        token: accessToken,
                        expiresIn: 3600,
                        tokenType: "Bearer",
                        authState: { email },
                    });

                    setTimeout(() => {
                        dispatch(setRecruiterEmail(email));
                        navigate('/RMS/Recruiter/');
                    }, 2000);
                } else {
                    toast.error("Email does not exist. Please register.");
                }
            } catch (error) {
                console.error("Error during Google register check:", error);
                toast.error("Error during Google register check. Please try again later.");
            }
        } else {
            console.error("Google login response does not contain profile information.");
            //  toast.error("Google sign-in failed. Please try again.");
            setIsGoogleRegister(false);
        }
    };


    const handleGoogleSignIn_validator = async (res) => {
        console.log("SignUp with Google button clicked");
        setIsGoogleRegister(true);

        if (res && res.profileObj) {
            const email = res.profileObj.email;

            console.log('Google Login Response:', res);
            console.log("isGoogleRegister onSuccess", isGoogleRegister);

            setGoogleUser(res.profileObj);

            try {
                const response = await axios.post("https://ex.d5art.com/api/google_validator_login", { email });
                const { accessToken, message } = response.data;

                console.log("API Response:", response);
                if (message === "exists") {
                    toast.success("Welcome to FABC!");

                    signIn({
                        token: accessToken,
                        expiresIn: 3600,
                        tokenType: "Bearer",
                        authState: { email },
                    });

                    setTimeout(() => {
                        dispatch(setValidatorEmail(email));
                        navigate('/RMS/Validator/');
                    }, 2000);

                } else {
                    toast.error("Email does not exist. Please register.");
                }
            } catch (error) {
                console.error("Error during Google register check:", error);
                toast.error("Error during Google register check. Please try again later.");
            }
        } else {
            console.error("Google login response does not contain profile information.");
            //   toast.error("Google sign-in failed. Please try again.");
            setIsGoogleRegister(false);
        }
    };






    const onFailure = (error) => {
        console.error("Google sign-up failed:", error);
        setIsGoogleRegister(false);

    };





    //--------------google register---

    const [GoogleValidatorformData, setGoogleValidatorFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        imageUrl: "",
        googleId: "",
        phoneNumber: "",
        countryCode: "",
        walletAddress: "",
    });

    const [showPopup, setShowPopup] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dob, setDob] = useState("");

    const [countryCode, setCountryCode] = useState('');










    const handleGoogleSignUp_validator = async (res) => {
        console.log("SignUp with Google button clicked");
        setIsGoogleRegister(true);

        if (res && res.profileObj) {
            const email = res.profileObj.email;

            console.log('Google Login Response:', res);
            console.log("isGoogleRegister onSuccess", isGoogleRegister);

            setGoogleUser(res.profileObj);

            const { googleId, imageUrl, givenName, familyName } = res.profileObj;
            setGoogleValidatorFormData({
                ...GoogleValidatorformData,
                firstName: givenName,
                lastName: familyName,
                email,
                imageUrl,
                googleId,
            });

            try {
                const response = await axios.post("https://ex.d5art.com/api/GoogleEmail_Registercheck", { email });
                const { accessToken, message } = response.data;

                console.log("API Response:", response);
                if (message === "exists") {
                    toast.error("Email already registered , Kindly Login.");

                } else {
                    setShowPopup(true);
                    console.log("Google Validator Login Success");
                }
            } catch (error) {
                console.error("Error during Google register check:", error);
                toast.error("Error during Google register check. Please try again later.");
            }
        } else {
            console.error("Google login response does not contain profile information.");
            //   toast.error("Google sign-in failed. Please try again.");
            setIsGoogleRegister(false);
        }
    };



    const googleRegister = async () => {
        try {
            const { firstName, lastName, email, imageUrl, googleId } = GoogleValidatorformData;
            const response = await axios.post("https://ex.d5art.com/api/Google_register_validator", {
                firstName,
                lastName,
                email,
                imageUrl,
                googleId,
                phoneNumber: phoneNumber,
                dob: dob
            });
            console.log(response.data);
            if (response.data.status === "success") {
                toast.success("Registration successful")
                setTimeout(() => {
                    dispatch(setSeekerEmail(email));
                    navigate('/');
                }, 2000);
                console.log("Registration successful");
            } else {
                console.error("Google registration failed:", response.data.message);
            }
        } catch (error) {
            console.error("Google registration error:", error.message);
        }
    };


    const handlePopupSubmit = async () => {

        setShowPopup(false);
        try {
            const response = await axios.post("https://ex.d5art.com/api/GooglePhoneNo_Registercheck", {
                phoneNumber,
            });
            if (response.data.message === "exists") {
                toast.error("Phone number already Registered")

                console.error("Phone number already exists");

            } else {
                await generateOtp(phoneNumber, countryCode)
            }
        } catch (error) {
            console.error("Phone number check error:", error);

        }

    };









    // navigate to respective user registration

    const handleRedirectSeeker = () => {
        navigate('/SeekerRegistration')

    };

    const handleRedirectRecruiter = () => {
        navigate('/Register')

    };


    const handleRedirectValidator = () => {
        navigate('/ValidatorRegistration')

    };


    const handleKeyEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div className='bg-[#101010] text-white h-screen font-poppins bg-cover' style={{ backgroundImage: `url(./assets/Loginbg.png)` }}>
            <Toaster />
            {isLoading1 && (
                <div className=''>
                    <div className='z-100'>
                        <Loading isVisible1={isLoading1} />
                    </div>
                </div>
            )}
            {isOpen && (

                <div className=''>
                    <div className='z-100'>
                        <Spinners isVisible={isLoading} />
                    </div>
                    <div className='px-7 md:px-10 rounded-2xl z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 overflow-y-auto'>
                        <div className='md:w-[15%] flex justify-center md:justify-end'>
                            <img src='/assets/LeftSide.png' className='rotate-90 md:rotate-0'></img>
                        </div>
                        <div className={`${isTransformed ? 'md:w-[70%]' : 'border-0 w-0  '} z-10  backdrop-blur-sm bg-transparent transform-transition duration-1000 ease-out-in rounded-3xl`}>
                            <div className='flex justify-center py-4'>
                                <p className='text-2xl text-white'>Login As</p>
                            </div>
                            <div>
                                <div className=' md:px-10 w-[100%] flex flex-col md:flex-row justify-center gap-2'>
                                    <div className='group md:w-[20%] border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90  group-hover:transition-transform duration-500 ' >
                                        <div className='flex justify-center py-5 md:py-2 text-white '>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? 'hidden' : ''}`} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectSeeker();
                                                handleAnchorClick('div2');
                                            }}>Seeker </a>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div2' ? '' : 'hidden'}`} id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectRecruiter();
                                                handleAnchorClick('div1');
                                            }}>Recruiter </a>
                                        </div>
                                    </div>
                                    <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} >
                                        <div className='flex justify-end '>
                                            <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                                        </div>
                                        <form onSubmit={handleSubmit5}>
                                            <div className='flex justify-center py-2 text-white'>
                                                <p>Recruiter Login</p>
                                            </div>
                                            <div className='flex flex-col gap-3 px-7 md:px-12 mt-3 '>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full' placeholder='Enter your mail ID'
                                                        required
                                                        onChange={(e) => handleInputChange8('recruiterLogin', 'email', e.target.value)}></input>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-[95%]'
                                                        placeholder='Enter your Password'
                                                        required
                                                        onChange={(e) => handleInputChange8('recruiterLogin', 'password', e.target.value)}></input>
                                                        <i
                                                            className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
                                                            onClick={handleTogglePasswordVisibility}
                                                        ></i>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtpRecruiter}>Forget password</button></div>

                                                <div className='flex justify-center mt-3'>
                                                    <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
                                                        handleClick2();


                                                    }}>
                                                        <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>

                                                </div>

                                                <div className='flex justify-center '>
                                                    <p className='text-white font-bold text-sm'>or</p>
                                                </div>

                                                <div className="flex flex-col md:flex-row justify-center gap-2 md:pl-5">
                                                    <div className='flex justify-center'>
                                                        <div onClick={handleRecruiterWalletLogin}>
                                                            <w3m-button
                                                                balance="hide" />
                                                        </div>

                                                        <>
                                                            {isConnected && (
                                                                <button onClick={handleWalletLogOut}>
                                                                    <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                </button>
                                                            )}
                                                        </>

                                                    </div>

                                                    <div className='flex justify-center'>
                                                        <div className="md:w-[1px] md:h-10 bg-white/50"></div>
                                                    </div>

                                                    <div className='flex justify-center'>
                                                        <div className='flex justify-center overflow-hidden rounded-full' onClick={handleGoogleSignIn_recruiter}>
                                                            <GoogleLogin
                                                                clientId={clientId}
                                                                buttonText="Signin as Recruiter"
                                                                onSuccess={handleGoogleSignIn_recruiter}
                                                                onFailure={onFailure}
                                                                cookiePolicy={"single_host_origin"}
                                                                isSignedIn={false}
                                                                prompt="select_account"

                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center'>
                                                    <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' onClick={toggleRecruiter}>Create new account</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} >
                                        <div className='flex justify-end '>
                                            <button className=' shadow-xl rounded-lg text-xl text-white font-bold  transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                                        </div>
                                        <form onSubmit={handleSubmit6}>
                                            <div className='flex justify-center py-2 text-white'>
                                                <p>Seeker Login</p>
                                            </div>
                                            <div className='flex flex-col gap-3 px-7 md:px-12 mt-3'>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full' placeholder='Enter your mail ID'
                                                        required
                                                        onChange={(e) => handleInputChange9('seekerLogin', 'email', e.target.value)}></input>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%]'><input
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        className='bg-black bg-opacity-5 text-center text-white outline-none py-2 w-[95%] '
                                                        placeholder='Enter your Password'
                                                        required
                                                        onChange={(e) => handleInputChange9('seekerLogin', 'password', e.target.value)}></input>
                                                        <i
                                                            className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
                                                            onClick={handleTogglePasswordVisibility}
                                                        ></i>
                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtp}>Forget password</button></div>

                                                <div className='flex justify-center mt-3'>
                                                    <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
                                                        handleClick3();

                                                    }} >
                                                        <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <p className='text-white font-bold text-sm'>or</p>
                                                </div>

                                                <div className='flex flex-col md:flex-row gap-2 justify-center md:pl-7'>
                                                    <div className='flex justify-center'>
                                                        <div onClick={handleSeekerWalletLogin}>
                                                            <w3m-button
                                                                balance="hide" />
                                                        </div>
                                                        <>
                                                            {isConnected && (
                                                                <button onClick={handleWalletLogOut}>
                                                                    <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                </button>
                                                            )}
                                                        </>
                                                    </div>


                                                    <div className='flex justify-center'>
                                                        <div className="md:w-[1px] md:h-10 bg-white/50"></div>
                                                    </div>
                                                    <div className='flex justify-center '>
                                                        <div className='flex justify-center rounded-full overflow-hidden' onClick={handleGoogleSignIn_seeker}>
                                                            <GoogleLogin
                                                                clientId={clientId}
                                                                buttonText="Sign-in as Seeker "
                                                                onSuccess={handleGoogleSignIn_seeker}
                                                                onFailure={onFailure}
                                                                cookiePolicy={"single_host_origin"}
                                                                isSignedIn={false}
                                                                prompt="select_account"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center'>
                                                    <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' href="#" onClick={togglemodal5}>Create new account</a>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='group md:w-[80%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10' id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} >
                                        <div className='flex justify-end '>
                                            <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleModal}><i class="fa-solid fa-xmark " ></i></button>
                                        </div>
                                        <form onSubmit={handleSubmitValidator}>
                                            <div className='flex justify-center py-2 text-white'>
                                                <p>Validator Login</p>
                                            </div>
                                            <div className='flex flex-col gap-3 px-7 md:px-12 mt-3'>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>

                                                    <div className='w-[98%]'><input
                                                        className='bg-black bg-opacity-5 text-center text-white outline-none py-2  w-full'
                                                        placeholder='Enter your mail ID'
                                                        required
                                                        onKeyDown={handleKeyEnter}
                                                        onChange={(e) => handleInputChangeValidator('validatorLogin', 'email', e.target.value)} >
                                                    </input>
                                                    </div>

                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex border-b border-gray-500'>
                                                    <div className='flex items-end '><div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                    <div className='w-[98%] flex'>

                                                        <input
                                                            type={passwordVisible ? 'text' : 'password'}
                                                            className='w-full bg-black bg-opacity-5 text-center text-white outline-none py-2  '
                                                            placeholder='Enter your Password'
                                                            required
                                                            onKeyDown={handleKeyEnter}
                                                            onChange={(e) => handleInputChangeValidator('validatorLogin', 'password', e.target.value)} >
                                                        </input>
                                                        <i
                                                            className={`  fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} w-[5%] text-gray-300 text-xl  mt-2 cursor-pointer text-center `}
                                                            onClick={handleTogglePasswordVisibility}
                                                        ></i>

                                                    </div>
                                                    <div className='flex items-end'><div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-end'><button className='text-sm text-sky-700' onClick={handleOtpValidator}>Forget password</button></div>

                                                <div className='flex justify-center mt-3'>
                                                    <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {
                                                        handleClick4();

                                                    }} >
                                                        <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[106px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                        <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Login</p>
                                                        <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>

                                                </div>

                                                <div className="flex justify-center  px-1">
                                                <div className=" rounded-lg overflow-hidden flex justify-center items-center bg-gray-100">
                                                    <ReCAPTCHA
                                                        sitekey={reCaptcha_public}
                                                        onChange={handleRecaptchaChange}
                                                        className="w-full"
                                                    />
                                                </div>
                                                </div>

                                                <div className='flex justify-center'>
                                                    <p className='text-white font-bold text-sm'>or</p>
                                                </div>

                                               

                                                <div className="flex flex-col md:flex-row justify-center gap-2 md:pl-5">
                                                    <div className='flex justify-center'>

                                                        <div onClick={handleValidatorWalletLogin}>
                                                            <w3m-button
                                                                balance="hide" />
                                                        </div>
                                                        <>
                                                            {isConnected && (
                                                                <button onClick={handleWalletLogOut}>
                                                                    <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                </button>
                                                            )}
                                                        </>
                                                    </div>

                                                    <div className='flex justify-center'>
                                                        <div className="md:w-[1px] bg-white/50 md:h-10"></div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex justify-center overflow-hidden rounded-full' onClick={handleGoogleSignIn_validator}>
                                                            <GoogleLogin
                                                                clientId={clientId}
                                                                buttonText="Sign-in as Validator"
                                                                onSuccess={handleGoogleSignIn_validator}
                                                                onFailure={onFailure}
                                                                cookiePolicy={"single_host_origin"}
                                                                isSignedIn={false}
                                                                prompt="select_account"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex justify-center'>
                                                    <a className='text-sm text-white underline cursor-pointer hover:-translate-y-1 hover:transform-transition duration-300' onClick={toggleValidator}>Create new account</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='group md:w-[20%]  border-sky-300 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 ' >
                                        <div className='flex justify-center py-5 md:py-2 text-white'>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? 'hidden' : ''}`} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectValidator()
                                                //  handleAnchorClick("div3") 
                                            }}>Validator</a>
                                            <a className={` md:rotate-[270deg] md:mt-44 cursor-pointer ${active === 'div3' ? '' : 'hidden'}`} id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} onClick={(e) => {
                                                e.preventDefault();
                                                handleRedirectRecruiter()
                                                // handleAnchorClick('div1');
                                            }}>Recruiter </a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='md:w-[15%] flex justify-center md:justify-start'>
                            <img src='/assets/RightSide.png' className='rotate-90 md:rotate-0'></img>
                        </div>
                    </div>
                    <div className='px-28 py-10 flex justify-center -translate-y-[27rem] '>
                        <div className='rounded-full p-6 border-[6px] border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_10px_#08f,0_0_10px_#08f]'>
                            <img src='/assets/NO_text_logo.png' ></img>
                        </div>
                    </div>
                </div >
            )
            }
            {
                isOpenOtp && (
                    <div className="rounded-2xl overflow-y-scroll z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
                        <div className="modal-container w-[40%] absolute top-1">
                            <div className="modal-content  ">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleOtp}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-20">
                                            <div className="flex justify-between mt-5">
                                                <button onClick={handlePrevClick}>
                                                    <i className="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                                <div className="p-5 w-[85%] rounded-3xl bg-white/5 grid gap-3">

                                                    {currentIndex === 0 && (
                                                        <div className={`content${currentIndex + 1}`}>
                                                            <div className='content1 p-5 '>
                                                                <div className='w-full flex flex-col'>
                                                                    <div className='flex border-b border-gray-500  '>
                                                                        <div className='flex items-end '>
                                                                            <div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='w-[98%]'>
                                                                            <input
                                                                                type="email"
                                                                                className='bg-black bg-opacity-5 pl-5 text-white outline-none py-2 w-full'
                                                                                placeholder='Enter your email'
                                                                                value={email}
                                                                                onChange={handleEmailChange}
                                                                            />
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center pt-5'>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: '#2563eb',
                                                                                color: 'white',
                                                                                padding: '7px 20px',
                                                                                border: 'none',
                                                                                borderRadius: '50px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={handleNextClick}
                                                                        >
                                                                            Verify<i className="fa-solid fa-circle-check ml-2" style={{ color: "#ffffff" }}></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {currentIndex === 1 && (
                                                        <div className={`content${currentIndex + 1} `}>
                                                            <div className='content2 p-5'>
                                                                <div className='flex justify-center '>
                                                                    <div className='flex flex-col gap-5 w-full'>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input
                                                                                        type={passwordVisibleSeeker ? 'text' : 'password'}
                                                                                        className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                        placeholder='New Password'
                                                                                        value={passwordSeeker}
                                                                                        onChange={(e) => setPasswordSeeker(e.target.value)}
                                                                                    />
                                                                                    <i
                                                                                        className={`fa-solid ${passwordVisibleSeeker ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                        onClick={handlePasswordVisibility}
                                                                                    ></i>
                                                                                </div>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input type='password' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Confirm Password' ></input>
                                                                                </div>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center px-5'>
                                                                            <p className='text-center'>Kindly refer to your mail we have sent an OTP</p>
                                                                        </div>
                                                                        <div className='flex justify-center gap-4 '>
                                                                            {emailOTP.map((otp, index) => (
                                                                                <input
                                                                                    key={`email-${index}`}
                                                                                    type="text"
                                                                                    className="bg-gray-300 text-black pl-3 py-1 w-[2.5rem] text-xl rounded-xl"
                                                                                    maxLength={1}
                                                                                    value={otp}
                                                                                    onChange={handleOTPChange(index)}
                                                                                    ref={(input) => (inputRefs.current[index] = input)}
                                                                                />
                                                                            ))}
                                                                        </div>

                                                                        <div className='flex justify-center'>
                                                                            <button
                                                                                onClick={handleNextClick}
                                                                                disabled={!isButtonEnabled}
                                                                                style={{
                                                                                    backgroundColor: isButtonEnabled ? '#16a34a' : '#2563eb',
                                                                                    color: 'white',
                                                                                    padding: '7px 20px',
                                                                                    border: 'none',
                                                                                    borderRadius: '50px',
                                                                                    cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
                                                                                }}>Verify<i class="fa-solid fa-circle-check ml-2" style={{ color: "#ffffff" }}></i>
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                                <button onClick={handleNextClick}>
                                                    <i className="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isOpenOtpRecruiter && (
                    <div className="rounded-2xl overflow-y-scroll z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
                        <div className="modal-container w-[40%] absolute top-1">
                            <div className="modal-content  ">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Recruiter Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleOtpRecruiter}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-20">
                                            <div className="flex justify-between mt-5">
                                                <button onClick={handlePrevClick}>
                                                    <i className="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                                <div className="p-5 w-[85%] rounded-3xl bg-white/5 grid gap-3">
                                                    {currentIndex === 0 && (
                                                        <div className={`content${currentIndex + 1}`}>
                                                            <div className='content1 p-5 '>
                                                                <div className='w-full flex flex-col'>
                                                                    <div className='flex border-b border-gray-500  '>
                                                                        <div className='flex items-end '>
                                                                            <div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='w-[98%]'>
                                                                            <input
                                                                                type="email"
                                                                                className='bg-black bg-opacity-5 pl-5 text-white outline-none py-2 w-full'
                                                                                placeholder='Enter your email'
                                                                                value={email}
                                                                                onChange={handleEmailChange}
                                                                            />
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center pt-5'>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: '#2563eb',
                                                                                color: 'white',
                                                                                padding: '7px 20px',
                                                                                border: 'none',
                                                                                borderRadius: '50px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={handleNextClick1}
                                                                        >
                                                                            Verify<i className="fa-solid fa-circle-check ml-2" style={{ color: "#ffffff" }}></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {currentIndex === 1 && (
                                                        <div className={`content${currentIndex + 1} `}>
                                                            <div className='content2 p-5'>
                                                                <div className='flex justify-center '>
                                                                    <div className='flex flex-col gap-5 w-full'>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input
                                                                                        type={passwordVisibleRecruiter ? 'text' : 'password'}
                                                                                        className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                        placeholder='New Password'
                                                                                        value={passwordRecruiter}
                                                                                        onChange={(e) => setPasswordRecruiter(e.target.value)}
                                                                                    />
                                                                                    <i
                                                                                        className={`fa-solid ${passwordVisibleRecruiter ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                        onClick={handlePasswordRecruiter}
                                                                                    ></i>
                                                                                </div>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input type='password' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Confirm Password' ></input>
                                                                                </div>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center px-5'>
                                                                            <p className='text-center'>Kindly refer to your mail we have sent an OTP</p>
                                                                        </div>
                                                                        <div className='flex justify-center gap-4 '>
                                                                            {emailOTPRecruiter.map((otp, index) => (
                                                                                <input
                                                                                    key={`emailRecruiter-${index}`}
                                                                                    type="text"
                                                                                    className="bg-gray-300 text-black pl-3 py-1 w-[2.5rem] text-xl rounded-xl"
                                                                                    maxLength={1}
                                                                                    value={otp}
                                                                                    onChange={handleOTPRecruiter(index)}
                                                                                    ref={(input) => (inputRefsRecruiter.current[index] = input)}
                                                                                />
                                                                            ))}
                                                                        </div>

                                                                        <div className='flex justify-center'>
                                                                            <button
                                                                                onClick={handleNextClick1}
                                                                                disabled={!isButtonEnabledRecruiter}
                                                                                style={{
                                                                                    backgroundColor: isButtonEnabledRecruiter ? '#16a34a' : '#2563eb',
                                                                                    color: 'white',
                                                                                    padding: '7px 20px',
                                                                                    border: 'none',
                                                                                    borderRadius: '50px',
                                                                                    cursor: isButtonEnabledRecruiter ? 'pointer' : 'not-allowed',
                                                                                }}
                                                                            >
                                                                                Verify <i className="fa-solid fa-circle-check  text-[#ffffff]" ></i>
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <button onClick={handleNextClick1}>
                                                    <i className="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isOpenOtpValidator && (
                    <div className="rounded-2xl overflow-y-scroll z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
                        <div className="modal-container w-[40%] absolute top-1">
                            <div className="modal-content  ">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Validator Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={handleOtpValidator}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-20">
                                            <div className="flex justify-between mt-5">
                                                <button onClick={handlePrevClick}>
                                                    <i className="fa-solid fa-chevron-left px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                                <div className="p-5 w-[85%] rounded-3xl bg-white/5 grid gap-3">
                                                    {currentIndex === 0 && (
                                                        <div className={`content${currentIndex + 1}`}>
                                                            <div className='content1 p-5 '>
                                                                <div className='w-full flex flex-col'>
                                                                    <div className='flex border-b border-gray-500  '>
                                                                        <div className='flex items-end '>
                                                                            <div className='-translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                        <div className='w-[98%]'>
                                                                            <input
                                                                                type="email"
                                                                                className='bg-black bg-opacity-5 pl-5 text-white outline-none py-2 w-full'
                                                                                placeholder='Enter your email'
                                                                                value={email}
                                                                                onChange={handleEmailChange}
                                                                            />
                                                                        </div>
                                                                        <div className='flex items-end'>
                                                                            <div className='translate-x-1 translate-y-1 w-2 h-2 rounded bg-gray-500'></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center pt-5'>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: '#2563eb',
                                                                                color: 'white',
                                                                                padding: '7px 20px',
                                                                                border: 'none',
                                                                                borderRadius: '50px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={handleNextClick2}
                                                                        >
                                                                            Verify<i className="fa-solid fa-circle-check ml-2" style={{ color: "#ffffff" }}></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {currentIndex === 1 && (
                                                        <div className={`content${currentIndex + 1} `}>
                                                            <div className='content2 p-5'>
                                                                <div className='flex justify-center '>
                                                                    <div className='flex flex-col gap-5 w-full'>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input
                                                                                        type={passwordVisibleValidator ? 'text' : 'password'}
                                                                                        className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                        placeholder='New Password'
                                                                                        value={passwordValidator}
                                                                                        onChange={(e) => setPasswordValidator(e.target.value)}
                                                                                    />
                                                                                    <i
                                                                                        className={`fa-solid ${passwordVisibleValidator ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                        onClick={handlePasswordValidator}
                                                                                    ></i>
                                                                                </div>
                                                                                <div className='flex items-end'>
                                                                                    <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col gap-3'>
                                                                            <div className='flex justify-center'>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                                <div className='flex border-b border-gray-500 w-full'>
                                                                                    <input type='password'
                                                                                     className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                      placeholder='Confirm Password'
                                                                                      value={confirmPasswordValidator}
                                                                                      onChange={(e) => setConfirmPasswordValidator(e.target.value)}
                                                                                       ></input>
                                                                                </div>
                                                                                <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center px-5'>
                                                                            <p className='text-center'>Kindly refer to your mail we have sent an OTP</p>
                                                                        </div>
                                                                        <div className='flex justify-center gap-4 '>
                                                                            {emailOTPValidator.map((otp, index) => (
                                                                                <input
                                                                                    key={`email-${index}`}
                                                                                    type="text"
                                                                                    className="bg-gray-300 text-black pl-3 py-1 w-[2.5rem] text-xl rounded-xl"
                                                                                    maxLength={1}
                                                                                    value={otp}
                                                                                    onChange={handleOTPValidator(index)}
                                                                                    ref={(input) => (inputRefsValidator.current[index] = input)}
                                                                                />
                                                                            ))}
                                                                        </div>

                                                                        <div className='flex justify-center'>
                                                                            <button
                                                                                onClick={handleNextClick2}
                                                                                disabled={!isButtonEnabledValidator}
                                                                                style={{
                                                                                    backgroundColor: isButtonEnabledValidator ? '#16a34a' : '#2563eb',
                                                                                    color: 'white',
                                                                                    padding: '7px 20px',
                                                                                    border: 'none',
                                                                                    borderRadius: '50px',
                                                                                    cursor: isButtonEnabledValidator ? 'pointer' : 'not-allowed',
                                                                                }}>Verify<i class="fa-solid fa-circle-check ml-2 text-[#ffffff]"></i>
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <button onClick={handleNextClick2}>
                                                    <i className="fa-solid fa-chevron-right px-3 py-2 rounded-full bg-white/10"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div>
                {isOpen5 && (
                    <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
                        <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
                            <div className="modal-content  ">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Seeker Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={togglemodal5}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="roadmap">
                                            <button className={currentStep === 1 ? 'active' : ''}><img src='./assets/block.png'></img></button>
                                            <p className='text-white md:pt-3 flex'>-<span className='hidden md:block'>--------------</span></p>
                                            <button className={currentStep === 2 ? 'active' : ''}><img src='./assets/block.png'></img></button>
                                            <p className='text-white md:pt-3 flex'>-<span className='hidden md:block'>--------------</span></p>
                                            <button className={currentStep === 3 ? 'active' : ''}><img src='./assets/block.png'></img></button>
                                            <p className='text-white md:pt-3 flex'>-<span className='hidden md:block'>--------------</span></p>
                                            <button className={currentStep === 4 ? 'active' : ''}><img src='./assets/block.png'></img></button>
                                            <p className='text-white md:pt-3 flex'>-<span className='hidden md:block'>--------------</span></p>
                                            <button className={currentStep === 5 ? 'active' : ''}><img src='./assets/block.png'></img></button>
                                        </div> */}

                                        <form onSubmit={handleSubmit}>
                                            <div className=' mt-4 mb-20'>
                                                <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
                                                    <div className='flex justify-center' >
                                                        <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                        <div className='flex flex-col md:flex-row  gap-5 md:gap-9'>
                                                            <div className='md:w-[60%] flex items-center'>
                                                                <div className='w-full flex flex-col gap-7'>
                                                                    <div className='flex flex-col gap-7 px-7 md:px-12  '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='text' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Name'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'name', e.target.value)}></input>                                                                            <i class="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>

                                                                        </div>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' type='email' placeholder='Enter your mail ID'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'email', e.target.value)}
                                                                                ></input>
                                                                                <i class="fa-solid fa-envelope text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col md:flex-row  gap-4 mt-5 px-7 md:px-12'>
                                                                        <div className='md:w-[50%]'>
                                                                            <Select
                                                                                value={countryOptions.find(option => option.country_name === personalInfo.country)}
                                                                                onChange={(selectedOption) => handleInputData('personalInfo', 'country', selectedOption)}
                                                                                options={countryOptions}
                                                                                placeholder='Country'
                                                                                styles={customStyles}
                                                                            />
                                                                        </div>
                                                                        <div className='md:w-[50%]'>

                                                                            <Select
                                                                                value={employmentOptions.find(option => option.value === personalInfo.state)}
                                                                                onChange={(selectedOption) => handleInputData('personalInfo', 'state', selectedOption)}
                                                                                options={employmentOptions}
                                                                                placeholder='Are you looking for ?'
                                                                                styles={customStyles}
                                                                                isMulti
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='number' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Mobile number'
                                                                                    onChange={(e) => handleInputData('personalInfo', 'mobileNumber', e.target.value)}  ></input>                                                                           <i class="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisible ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter Password'
                                                                                    value={personalInfo.password}
                                                                                    onChange={(e) => handleInputData('personalInfo', 'password', e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handleTogglePasswordVisibility}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={personalInfo.confirmpassword}
                                                                                    onChange={(e) => handleInputData('personalInfo', 'confirmpassword', e.target.value)}
                                                                                />
                                                                                {passwordsMatch ? (
                                                                                    <i className='fa-solid fa-check text-green-500 text-xl mt-3'></i>
                                                                                ) : (
                                                                                    <i className='fa-solid fa-times text-red-500 text-xl mt-3'></i>
                                                                                )}
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='md:w-[30%]'>
                                                                <Slider
                                                                    className='bg-[#111111] w-[75%] md:w-[100%] mx-auto '
                                                                    ref={sliderRef1}
                                                                    {...settings1}
                                                                    afterChange={(index) => handleSliderAfterChange(index)}
                                                                >
                                                                    <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar1')}>
                                                                        <img className='px-3' src='./assets/image 90.png' alt='Avatar 1'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar2')}>
                                                                        <img className='px-3' src='./assets/image 90 (1).png' alt='Avatar 2'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputData('personalInfo', 'avatar', 'avatar3')}>
                                                                        <img className='px-3' src='./assets/image 3yr.png' alt='Avatar 3'></img>
                                                                    </div>
                                                                </Slider>
                                                                <p className='text-center text-white'>Choose Avatar</p>
                                                                <div className='flex justify-center'>
                                                                    <input type='text' className='w-[90%] md:w-full rounded-[20px] outline-none bg-[#090909] h-28 mt-5 border border-sky-700 inner-shadow2 p-3 text-sm' placeholder='About yourself....'
                                                                        onChange={(e) => handleInputData('personalInfo', 'description', e.target.value)}></input>
                                                                </div>
                                                            </div>



                                                        </div>

                                                        <div className='flex justify-left px-10 py-4'>
                                                            <p className='text-white font-bold'>Connect your wallet (optional) </p>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-2'>
                                                            <div onClick={handleInputData}
                                                            >
                                                                <w3m-button
                                                                    balance="hide" />
                                                            </div>

                                                            <>
                                                                {isConnected && (
                                                                    <button onClick={handleWalletLogOut}>
                                                                        <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                    </button>
                                                                )}
                                                            </>

                                                        </div>

                                                        <div className='flex justify-center mb-5 mt-5 2xl:mt-0'>

                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => { savePersonalInfo(); }}>
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[120px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Submit</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>


                                                        </div>
                                                    </div>


                                                    {/* education , skills, projects, certificates, experience */}

                                                </Slider>



                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>
            </div>
            <div className='' >
                {isOpenRecruiter && (
                    <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
                        <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
                            <div className="modal-content">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Job Recruiter Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleRecruiter}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="roadmap">
                                            <button className={currentStep === 1 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                            <p className='text-white pt-3 w-[75%] md:w-auto flex'>-----------------<span className='hidden md:block'>----</span></p>
                                            <button className={currentStep === 2 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        </div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit1(e);
                                            handleSubmit2(e);
                                        }}>
                                            <div className=' mt-4 mb-20'>

                                                <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
                                                    <div className='flex justify-center' >
                                                        <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                        <div className='flex flex-col md:flex-row gap-5 md:gap-9'>
                                                            <div className='md:w-[60%] flex items-center'>
                                                                <div className='w-full flex flex-col  gap-5 md:gap-14'>
                                                                    <div className='flex flex-col gap-5 md:gap-14 px-7 md:px-12  '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter your Name'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'name', e.target.value)} ></input>
                                                                                <i class="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>

                                                                        </div>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    type='email'
                                                                                    placeholder='Enter your mail ID'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'email', e.target.value)} ></input>
                                                                                <i class="fa-solid fa-envelope text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-4 mt-5 px-7 md:px-12'>
                                                                        <div className='w-[50%]'>

                                                                            <Select
                                                                                // value={personalInfo1?.country}
                                                                                // onChange={(selectedOption, e) => {
                                                                                //     handleChange(selectedOption);
                                                                                //     handleInputChange4('personalInfo1', 'country', e);
                                                                                // }}
                                                                                // options={countryOptions}
                                                                                // placeholder='Country'
                                                                                // styles={customStyles}
                                                                                // required

                                                                                value={countryOptions.find(option => option.value === personalInfo1?.country)}
                                                                                onChange={(selectedOption) => handleInputChange4('personalInfo1', 'country', selectedOption)}
                                                                                options={countryOptions}
                                                                                placeholder='Country'
                                                                                styles={customStyles}


                                                                                onChange={(e) => handleInputChange4('personalInfo1', 'country', e)}


                                                                            />
                                                                        </div>
                                                                        <div className='w-[50%]'>

                                                                            <Select
                                                                                // value={{ value: personalInfo1?.state, label: selectedstate?.label }}
                                                                                // onChange={(selectedOption, e) => {
                                                                                //     handleChanges1(selectedOption);
                                                                                //     handleInputChange4('personalInfo1', 'State', e);
                                                                                // }}
                                                                                // options={stateOptions}
                                                                                // placeholder='State'
                                                                                // styles={customStyles}
                                                                                // required

                                                                                value={stateOptions.find(option => option.value === personalInfo1?.state)}
                                                                                onChange={(selectedOption) => handleInputChange4('personalInfo1', 'state', selectedOption)}
                                                                                options={stateOptions}
                                                                                placeholder='Are you looking for ?'
                                                                                styles={customStyles}


                                                                                onChange={(e) => handleInputChange4('personalInfo1', 'state', e)}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter your Mobile number'

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'mobileNumber', e.target.value)}   ></input>
                                                                                <i class="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisible ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter Password'
                                                                                    value={personalInfo1.password}

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'password', e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handleTogglePasswordVisibility}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={personalInfo1.confirmpassword}

                                                                                    onChange={(e) => handleInputChange4('personalInfo1', 'confirmpassword', e.target.value)}
                                                                                />
                                                                                {passwordsMatch ? (
                                                                                    <i className='fa-solid fa-check text-green-500 text-xl mt-3'></i>
                                                                                ) : (
                                                                                    <i className='fa-solid fa-times text-red-500 text-xl mt-3'></i>
                                                                                )}

                                                                            </div>

                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div className='md:w-[30%]'>

                                                                <Slider className='bg-[#111111] w-[75%] md:w-[100%] mx-auto'
                                                                    ref={sliderRef1}
                                                                    {...settings1}
                                                                    afterChange={(index) => handleSliderAfterChange1(index)}>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar1')}>
                                                                        <img className='px-3 md:ml-10 2xl:ml-20' src='/assets/image 90.png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar2')}>
                                                                        <img className='px-3' src='/assets/image 90 (1).png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange4('personalInfo1', 'avatar', 'avatar3')}>
                                                                        <img className='px-3' src='/assets/image 3yr.png'></img>
                                                                    </div>

                                                                </Slider>
                                                                <p className='text-center text-white'>Choose Avatar</p>
                                                                <div className='flex justify-center'>
                                                                    <textarea type="text" placeholder='About the YourSelf...' className='w-[90%] md:mx-0 md:w-full rounded-[20px] outline-none bg-[#090909] h-28  mt-5 border border-sky-700 inner-shadow2 p-3 text-sm'
                                                                        onChange={(e) => handleInputChange4('personalInfo1', 'description', e.target.value)}></textarea>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='flex justify-center mb-5 mt-5 2xl:mt-0'>
                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => {

                                                                submit();
                                                            }}>
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'  ></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out' >Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center p-5'>
                                                        <p className='text-white text-center text-2xl font-play '>Organization Details</p>
                                                        <div className='flex flex-col gap-5 md:flex-row pt-10'>
                                                            <div className='md:w-[55%] flex flex-col gap-3 px-2 md:px-10 '>
                                                                <p className='text-sm'>Organization Name:</p>
                                                                <input type='text' placeholder='Enter your Company name' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                    onChange={(e) => handleInputChange5('organizationDetails', 'organizationName', e.target.value)}></input>
                                                                <textarea type="text" placeholder='About the Company...' className='w-full rounded-[20px] outline-none bg-[#090909] h-full mt-2 md:mt-5 border border-sky-700 inner-shadow2 p-5 text-sm'
                                                                    onChange={(e) => handleInputChange5('organizationDetails', 'organizationDescription', e.target.value)}></textarea>
                                                            </div>
                                                            <div className='md:w-[45%] flex flex-col gap-7'>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Industry type:</p>
                                                                    <input type='text' placeholder='Select from below' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'industryType', e.target.value)}></input>
                                                                </div>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Website URL:</p>
                                                                    <input type='text' placeholder='Enter your URL' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'websiteURL', e.target.value)}></input>
                                                                </div>
                                                                <div className='flex flex-col gap-3 w-[100%] md:w-[70%] pl-2 pr-2 md:pr-0 md:pl-7'>
                                                                    <p className='text-sm'>Your Designation:</p>
                                                                    <input type='text' placeholder='Enter your designation' className='bg-[#090909] py-2 px-5 text-sm outline-none rounded-xl border border-sky-700 shadow-sm shadow-sky-400'
                                                                        onChange={(e) => handleInputChange5('organizationDetails', 'yourDesignation', e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-4'>
                                                            <p className='text-white font-bold'>Connect your wallet (optional) </p>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-2'>
                                                            <div onClick={handleInputChange5}
                                                                required>
                                                                <w3m-button
                                                                    balance="hide" />
                                                            </div>

                                                            <>
                                                                {isConnected && (
                                                                    <button onClick={handleWalletLogOut}>
                                                                        <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                    </button>
                                                                )}
                                                            </>

                                                        </div>


                                                        <div><img className='md:absolute md:translate-x-[50rem] 2xl:translate-x-[65rem] 2xl:translate-y-10 mt-5 md:mt-0 mx-auto md:mx-0 w-[75%] md:w-auto' src='/assets/image 90.png' ></img></div>

                                                        <div className='flex  justify-center mt-5 md:mt-16'>
                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={async () => {
                                                                // handleClick();
                                                                submit1();
                                                                //   generateOtp(personalInfo1.email)
                                                                // await generate_wallet_address();

                                                            }}>

                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[128px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'  ></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out' >Register</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>

                                                            </button>

                                                        </div>
                                                    </div>
                                                </Slider>



                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>
            </div>
            <div className='' >
                {isOpenValidator && (
                    <div className="overflow-y-auto z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md">
                        <div className="modal-container w-4/5 mx-auto md:mx-0 md:absolute top-1">
                            <div className="modal-content  ">
                                <div className='slide-in-from-top1' >
                                    <div>
                                        <div>
                                            <div className=' items-center'>
                                                <div className=''>
                                                    <p className='text-[#0ea4a9] font-bold text-center mt-20'>Validator Registration</p>
                                                </div>
                                                <div className='flex justify-end h-7'>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' onClick={toggleValidator}><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="roadmap">
                                            <button className={currentStep === 1 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                            <p className='text-white w-[75%] md:w-auto flex pt-3'>-----------------<span className='hidden md:block'>----</span></p>
                                            <button className={currentStep === 2 ? 'active' : ''}><img src='/assets/block.png'></img></button>
                                        </div>

                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit3(e);
                                            handleSubmit4(e);

                                        }}>
                                            <div className=' mt-4 mb-20'>


                                                <Slider className='bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]' ref={sliderRef} {...settings}>
                                                    <div className='flex justify-center' >
                                                        <p className='text-white text-center mt-5 text-2xl font-play'>Personal information</p>
                                                        <div className='flex flex-col md:flex-row gap-5 md:gap-9'>
                                                            <div className='md:w-[60%] flex items-center'>
                                                                <div className='w-full flex flex-col gap-5 md:gap-14'>
                                                                    <div className='flex flex-col gap-5 md:gap-14 px-7 md:px-12  '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='text' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter your Name'
                                                                                    required
                                                                                    onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'name', e.target.value)}></input>
                                                                                <i class="fa-solid fa-user text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>

                                                                        </div>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' type='email' placeholder='Enter your mail ID'
                                                                                    required
                                                                                    onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'email', e.target.value)}></input>
                                                                                <i class="fa-solid fa-envelope text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col md:flex-row  gap-4 mt-5 px-7 md:px-12'>
                                                                        <div className='md:w-[50%]'>
                                                                            <Select
                                                                                value={countryOptions.find(option => option.label === personalInfo.country)}
                                                                                onChange={(selectedOption) => {
                                                                                    handleInputChange6('ValidatorPersonalInfo', 'country', selectedOption);
                                                                                    handleInputChange6('ValidatorPersonalInfo', 'countryCode', selectedOption);
                                                                                }}
                                                                                options={countryOptions}
                                                                                placeholder='Country'
                                                                                styles={customStyles}
                                                                                getOptionLabel={(option) => `${option.label} (${option.value}) ${option.country_flag}`}
                                                                                getOptionValue={(option) => option.label}
                                                                            />
                                                                        </div>
                                                                        <div className='md:w-[50%]'>
                                                                            <Select
                                                                                value={filteredStateOptions.find(option => option.name === personalInfo.state)}
                                                                                onChange={(selectedOption) => handleInputChange6('ValidatorPersonalInfo', 'state', selectedOption)}
                                                                                options={filteredStateOptions}
                                                                                placeholder='State'
                                                                                styles={customStyles}
                                                                                getOptionLabel={(option) => option.name}

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input type='tel' className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7' placeholder='Enter your Mobile number'
                                                                                    required
                                                                                    maxLength={15}
                                                                                    onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'mobileNumber', e.target.value)} ></input>
                                                                                <i class="fa-solid fa-phone text-gray-300 text-xl mt-3"></i>
                                                                            </div>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type={passwordVisible ? 'text' : 'password'}
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Enter Password'
                                                                                    value={ValidatorPersonalInfo.password}
                                                                                    required
                                                                                    onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'password', e.target.value)}
                                                                                />
                                                                                <i
                                                                                    className={`fa-solid ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} text-gray-300 text-xl mt-3 cursor-pointer`}
                                                                                    onClick={handleTogglePasswordVisibility}
                                                                                ></i>
                                                                            </div>
                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='flex flex-col gap-3 px-7 md:px-12 '>
                                                                        <div className='flex justify-center'>
                                                                            <div className='flex items-end'><div className='w-2 h-2 rounded translate-x-1 translate-y-1 bg-gray-500'></div></div>
                                                                            <div className='flex border-b border-gray-500 w-full'>
                                                                                <input
                                                                                    type='password'
                                                                                    className='bg-black bg-opacity-5 w-[93%] text-white outline-none py-2 pl-7'
                                                                                    placeholder='Confirm Password'
                                                                                    value={ValidatorPersonalInfo.confirmpassword}
                                                                                    required
                                                                                    onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'confirmpassword', e.target.value)}
                                                                                />
                                                                                {passwordsMatch ? (
                                                                                    <i className='fa-solid fa-check text-green-500 text-xl mt-3'></i>
                                                                                ) : (
                                                                                    <i className='fa-solid fa-times text-red-500 text-xl mt-3'></i>
                                                                                )}

                                                                            </div>

                                                                            <div className='flex items-end'>
                                                                                <div className='w-2 h-2 rounded -translate-x-1 translate-y-1 bg-gray-500'></div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='md:w-[30%]'>

                                                                <Slider className='bg-[#111111] w-[75%] md:w-[100%] mx-auto'
                                                                    ref={sliderRef1}
                                                                    {...settings1}
                                                                    afterChange={(index) => handleSliderAfterChange2(index)}>
                                                                    <div onClick={() => handleInputChange6('ValidatorPersonalInfo', 'avatar', 'avatar1')}>
                                                                        <img className='px-3 md:ml-10 2xl:ml-20' src='/assets/image 90.png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange6('ValidatorPersonalInfo', 'avatar', 'avatar2')}>
                                                                        <img className='px-3' src='/assets/image 90 (1).png'></img>
                                                                    </div>
                                                                    <div onClick={() => handleInputChange6('ValidatorPersonalInfo', 'avatar', 'avatar3')}>
                                                                        <img className='px-3' src='/assets/image 3yr.png'></img>
                                                                    </div>

                                                                </Slider>
                                                                <p className='text-center text-white'>Choose Avatar</p>
                                                                <div className="flex justify-center">
                                                                    <textarea type="text" placeholder='About the YourSelf...' className='w-[90%] md:w-full rounded-[20px] outline-none bg-[#090909] h-28 mt-5 border border-sky-700 inner-shadow2 p-3 text-sm'
                                                                        onChange={(e) => handleInputChange6('ValidatorPersonalInfo', 'description', e.target.value)}></textarea>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='flex flex-col md:flex-row justify-center gap-0 md:gap-3 mb-5 mt-5 2xl:mt-0'>
                                                            <div className='flex justify-center'>
                                                                <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white' onClick={() => { ValidatorSubmit(); }} >
                                                                    <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[98px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button>
                                                                    <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Next</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                                </button>
                                                            </div>



                                                        </div>
                                                    </div>

                                                    <div className='flex justify-center py-4'>
                                                        <p className='text-white text-center mt-1 mb-5 font-play text-2xl'>Skills & Tools</p>
                                                        <div className='flex flex-col md:flex-row justify-center gap-5 md:gap-10 mt-7 px-7 md:px-0'>
                                                            <div className='md:w-[45%]'>
                                                                <p className='text-sm text-[#F29D38] font-play mb-5'>Kindly make sure that you have to submit Resume, Bonafide from organization, Certification.</p>
                                                                <div className=' px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] outline-dashed'>

                                                                    <div className='flex justify-center'>
                                                                        <img src='/assets/Upload.png' alt='Upload' onClick={handleClickDocuments} style={{ cursor: 'pointer' }} />
                                                                        <input
                                                                            type='file'
                                                                            ref={fileInputRef}
                                                                            style={{ display: 'none' }}
                                                                            accept='.pdf,.doc' // Accept txt, pdf, and doc files
                                                                            multiple
                                                                            required
                                                                            onChange={(e) => handleInputChange7('validatorSkillsAndTools', 'uploadFiles', e.target.files)}
                                                                        />

                                                                    </div>
                                                                    <p className='text-center text-gray-500 mt-3'>Upload Your Documents (accepted type: pdf, doc)</p>

                                                                    <p className='text-center text-gray-500 mt-3'>(The file should be less than 2 mb)</p>
                                                                </div>
                                                            </div>
                                                            <div className='md:w-[45%] p-5 md:mt-14 2xl:mt-9 rounded-2xl flex items-center  bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>
                                                                <div className='flex flex-col w-full'>
                                                                    <div className='flex flex-col gap-5 '>
                                                                        <div className="flex justify-center">
                                                                            <div className='md:w-[40%]'>
                                                                                <Select
                                                                                    inputValueSkillValidator={inputValueSkillValidator}
                                                                                    onInputChange={(value) => setInputValueSkillValidator(value)}
                                                                                    value={null}
                                                                                    options={SkillOptions}
                                                                                    placeholder='Add Skills'
                                                                                    styles={customStylesJob}
                                                                                    required
                                                                                    onChange={(selectedOption) => handleInputChange7('validatorSkillsAndTools', 'skills', selectedOption)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="h-[3rem] w-full overflow-x-scroll">
                                                                            <ul className='flex  text-center gap-2'>
                                                                                {validatorSkillsAndTools.skills.map((skill, index) => (
                                                                                    <li className='rounded-full text-xs px-3 py-1 bg-[#00768b]' key={index}>
                                                                                        {skill.label}
                                                                                        <span className='ml-1' style={{ cursor: 'pointer' }} onClick={() => removeSkill(index)}>
                                                                                            &#x2715;
                                                                                        </span>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div><p className='text-gray-500 text-center '>(Enter the expertise, Technologies you know)</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='px-8 2xl:px-12 mt-4'>
                                                            <p>Checklist</p>
                                                            <div className='md:w-[47.5%] h-[8rem] mt-2 px-5 rounded-2xl py-4 bg-[#090909] border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] '>
                                                                <p>Selected Files:</p>
                                                                {validatorSkillsAndTools.uploadFiles && validatorSkillsAndTools.uploadFiles.length > 0 && (
                                                                    <div>
                                                                        <ul>
                                                                            {Array.from(validatorSkillsAndTools.uploadFiles).map((file, index) => (
                                                                                <li key={index}>
                                                                                    {file.name}
                                                                                    <span style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => handleRemoveFile1(index)}>
                                                                                        &#10006; {/* Close icon (you can replace this with your own close icon) */}
                                                                                    </span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>


                                                        <div className='flex justify-left px-10 py-4'>
                                                            <p className='text-white font-bold'>Connect your wallet (optional) </p>
                                                        </div>

                                                        <div className='flex justify-left px-10 py-2'>
                                                            <div onClick={handleInputChange7}
                                                                required >
                                                                <w3m-button
                                                                    balance="hide" />
                                                            </div>

                                                            <>
                                                                {isConnected && (
                                                                    <button onClick={handleWalletLogOut}>
                                                                        <i className="fas fa-sign-out-alt" style={{ color: "#f4f5f6" }}></i>
                                                                    </button>
                                                                )}
                                                            </>

                                                        </div>


                                                        <div><img className='mt-5 md:mt-0 ml-5 md:ml-0 md:absolute md:-translate-y-[8rem] md:translate-x-[37rem] 2xl:-translate-y-[5rem] 2xl:translate-x-[55rem]' src='/assets/avatarcom.png'></img></div>
                                                        <div className='flex justify-center mt-5'>
                                                            <button className='flex pt-3 pb-1 px-2 bg-black rounded-full group border border-sky-200 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_5px_#08f,0_0_1px_#08f,0_0_1px_#08f] text-white'

                                                                onClick={() => {
                                                                    ValidatorSubmit1();
                                                                    // generateOtp(ValidatorPersonalInfo.email)
                                                                }} >
                                                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[128px] hover:transform-transition duration-500 ease-in-out border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:-translate-x-6 trasnform-transition duration-500 ease-in-out'>Register</p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>

                                                        </div>

                                                        <div>

                                                        </div>
                                                    </div>
                                                </Slider>



                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='z-100'>
                    <Spinners isVisible={isLoading} />
                </div>
                {/*Google register pop and otp popup*/}

                {showPopup && (
                    <div class="fixed top-0 left-0 w-full h-full z-50 backdrop-blur-md">
                        <div class="flex items-center justify-center h-screen">
                            <div class="max-w-md mx-auto p-8 bg-[#111111]  rounded-3xl border border-[#0a3840] shadow-lg shadow-[#0a3840]">
                                <div className="flex justify-end"><FontAwesomeIcon icon={faXmarkCircle} /></div>
                                <h3 class="text-xl font-bold mb-4">Additional Information</h3>
                                <form onSubmit={handlePopupSubmit}>
                                    <label class="mb-2 block font-medium text-sm">
                                        Country Code<span className="text-red-500">*</span>


                                        <input
                                            type="number"

                                            placeholder="Enter your  Country code"
                                            value={countryCode}
                                            onChange={(e) =>
                                                setCountryCode({ ...countryCode, countryCode: e.target.value })
                                            }
                                            required
                                            class="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-blue-500 focus:ring-2"
                                        />

                                    </label>


                                    <label class="mb-2 block font-medium text-sm">
                                        Phone Number<span className="text-red-500">*</span>
                                        <input
                                            type="text"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            required
                                            class="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-blue-500 focus:ring-2"
                                        />
                                    </label>



                                    <label class="mb-2 block font-medium text-sm">
                                        Connect Your Wallet (optional) <span className="text-red-500">*</span>

                                        <>
                                            <w3m-button
                                                balance="hide"
                                            />
                                        </>


                                    </label>




                                    <label class="mb-2 block font-medium text-sm">
                                        Date of Birth<span className="text-red-500">*</span>
                                        <input
                                            type="date"
                                            value={dob}
                                            onChange={(e) => setDob(e.target.value)}
                                            required
                                            class="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-blue-500 focus:ring-2"
                                        />
                                    </label>

                                    <div class="flex justify-center mt-4">
                                        <button type="submit" class="inline-flex items-center px-4 py-2 bg-FABcBlue hover:bg-blue-600 text-white font-bold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                )}
            </div>


            {
                isOtpOpen && (
                    <div className="fixed inset-0 z-50 flex items-center text-black justify-center">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        <div className="relative w-full max-w-md p-5 bg-white rounded-lg shadow-md">
                            <div className="flex justify-end mb-2">
                                <button
                                    className="text-gray-600 text-lg"
                                    onClick={() => setIsOtpOpen(false)}
                                >
                                    <span>&times;</span>
                                </button>
                            </div>
                            <h1 className="text-2xl font-bold mb-5">Email OTP Verification</h1>
                            <label>Enter OTP</label>
                            <div className="flex justify-between mb-5">
                                {[...Array(6)].map((_, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        className="border border-gray-300 rounded-lg py-2 px-3 outline-none text-center w-12"
                                        maxLength="1"
                                        value={otpData.otp[index] || ''}
                                        onChange={(e) => handleChangeOtp(e.target, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                ))}
                            </div>
                            <div>
                                <button
                                    className="rounded-full px-8 py-3 text-sm text-black bg-Blue"
                                    onClick={() => verifyOtp(otpData.otp)}
                                >
                                    Verify
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >

    )
}